import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const initialVimeoObject = {
  id: uuidv4(),
  url: '',
  description: '',
}

const initialState = {
  lesson_name: '',
  lesson_image_url: null,
  lesson_description: '',
  vimeo_urls: [initialVimeoObject],
  quizzes: [],
  games: [],
}

export const lessonSlice = createSlice({
  name: 'lesson',
  initialState: initialState,
  reducers: {
    updateLessonState: (state, action) => {
      if (action.payload !== undefined) {
        let { type, data } = action.payload

        switch (type) {
          case 'lesson':
            return Object.assign({}, data)

          case 'quiz':
            state.quizzes.push(data)
            break

          default:
            break
        }
      }
    },
    resetLessonState: () => {
      return Object.assign({}, initialState)
    },
    resetQuizState: (state, action) => {
      if (action.payload !== undefined) {
        let { type, data } = action.payload
        if (type === 'quiz') {
          state.quizzes = data
        }
      }
    },
  },
})

export const { updateLessonState, resetLessonState, resetQuizState } = lessonSlice.actions

export default lessonSlice.reducer
