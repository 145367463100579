import { Outlet, Route, Routes } from 'react-router-dom'
import { RequireAuth } from '../../login/auth'
import AllLessons from '.'
import ReorderLessonContent from './reorder-lesson-contents'
import { createContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import AddLesson from './add-lesson'
import CopyToClipboardButton from '../components/copy-to-clipboard-button'
export const LessonWrapperContext = createContext()

const LessonWrapper = () => {
  const initialVimeoObject = {
    id: uuidv4(),
    url: '',
    description: '',
  }

  const initialState = {
    lesson_name: '',
    lesson_image: null,
    lesson_image_url: null,
    lesson_description: '',
    time_to_complete: '',
    vimeo_urls: [initialVimeoObject],
    quizzes: [],
    games: [],
  }

  const [lessonState, setLessonState] = useState(initialState)

  return (
    <>
      <LessonWrapperContext.Provider value={{ lessonState, setLessonState }}>
        <Routes>
          <Route
            path="/all"
            element={
              <RequireAuth>
                <AllLessons />
              </RequireAuth>
            }
          />
          <Route
            path="/add-lesson"
            element={
              <RequireAuth>
                <AddLesson />
              </RequireAuth>
            }
          />
          <Route
            path="/edit-lesson/:lessonId"
            element={
              <RequireAuth>
                <AddLesson />
              </RequireAuth>
            }
          />
          <Route
            path="/add-lesson-to-module/:moduleId"
            element={
              <RequireAuth>
                <AddLesson />
              </RequireAuth>
            }
          />
          <Route
            path="/reorder-lesson-content"
            element={
              <RequireAuth>
                <ReorderLessonContent />
              </RequireAuth>
            }
          />
        </Routes>

        <Outlet />
      </LessonWrapperContext.Provider>
    </>
  )
}

export default LessonWrapper
