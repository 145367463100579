import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Button, Link, TextField, Typography, Card, CardContent } from '@mui/material'

import './index.css'
import logo from '../top-bar/examfly-logo-blue.png'

const ResetPass = (props) => {
  let navigate = useNavigate()
  let { search } = useLocation()
  const username = localStorage.getItem('currentUser')
  const [newPassword, setNewPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [resettingPass, setResettingPass] = useState(false)
  const [done, setDone] = useState(false)
  const [passwordErrMsg, setPasswordErrMsg] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const doPasswordReset = () => {
    setSubmitting(true)
    axios({
      url: '/auth/reset-pass',
      method: 'post',
      data: {
        verificationCode,
        newPassword,
        username,
      },
    })
      .then((r) => {
        setDone(true)
        setSubmitting(false)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          console.log(error.response.data.message)

          if (error.response.data.code === 'InvalidParameterException') {
            setPasswordErrMsg('Invalid verification code or password does not meet policy requirements. Please try again.')
          } else {
            setPasswordErrMsg(error.response.data.message)
          }
        } else {
          console.error(error)
        }
        setSubmitting(false)
      })
  }
  // useEffect(() => {
  //   if (resettingPass) {
  //     axios({
  //       url: '/auth/reset-pass',
  //       method: 'post',
  //       data: {
  //         verificationCode,
  //         newPassword,
  //         username,
  //       },
  //     })
  //       .then((r) => {
  //         setDone(true)
  //       })
  //       .catch((error) => {
  //         if (error.response && error.response.status === 400) {
  //           console.log(error.response.data.message)

  //           if (error.response.data.code === 'InvalidParameterException') {
  //             setPasswordErrMsg('Invalid verification code or password does not meet policy requirements. Please try again.')
  //           } else {
  //             setPasswordErrMsg(error.response.data.message)
  //           }
  //         } else {
  //           console.error(error)
  //         }
  //       })
  //   }
  // }, [resettingPass])

  if (done) {
    return (
      <div className="login-wrapper">
        <img className="logo" src={logo} />
        <div className="login">
          <Typography>
            Password reset successful! Please <Link href="/credentials-login">login</Link> to continue.
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div className="login-wrapper">
      <form
        className="login"
        onSubmit={(e) => {
          e.preventDefault()
          // setResettingPass(false)
          // setResettingPass(true)
          doPasswordReset()
        }}
      >
        <Typography variant="h1">Reset password</Typography>
        <Typography>Please set a new password to continue to Examfly</Typography>
        <TextField
          sx={{ marginTop: '20px' }}
          name="password"
          type="password"
          label="New Password"
          variant="outlined"
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword}
        />
        <TextField
          sx={{ marginTop: '20px' }}
          name="code"
          type="text"
          label="Verification Code"
          variant="outlined"
          onChange={(e) => setVerificationCode(e.target.value)}
          value={verificationCode}
        />
        <Button sx={{ marginTop: '20px' }} variant="contained" color="primary" type="submit" disabled={submitting}>
          Set password
        </Button>
        {passwordErrMsg && <Typography sx={{ marginTop: '20px', color: 'red' }}>{passwordErrMsg}</Typography>}
        <Card style={{ marginTop: '20px' }}>
          <CardContent>
            <Typography>
              <b> Password Policy:</b>
            </Typography>
            <ul>
              <li>At least 8 characters long</li>
              <li>Contains at least 1 number</li>
              <li>Contains at least 1 special character</li>
              <li>Contains at least 1 uppercase letter</li>
              <li>Contains at least 1 lowercase letter</li>
            </ul>
          </CardContent>
        </Card>
      </form>
    </div>
  )
}

export default ResetPass
