import { Box, Typography } from '@mui/material'
import { usePoints } from 'points/context'
import TrophyImage from './trophy.svg'

const Points = () => {
  const { points } = usePoints()

  return (
    <Box
      sx={{
        border: '1px solid #EEEEEE',
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        gap: '15px',
      }}
    >
      <img src={TrophyImage} alt="Trophy" height="40px" width="40px" />
      <Typography textAlign="center" fontSize="18px" fontWeight="bold">
        Points
      </Typography>
      <Typography textAlign="center" fontSize="18px" fontWeight="bold">
        {points}
      </Typography>
    </Box>
  )
}

export default Points
