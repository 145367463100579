function getKnockKey() {
  const ui_env = document.location.hostname
  let KNOCK_PUBLIC_API_KEY = ''

  switch (ui_env) {
    case 'dev.examfly-app.com':
      KNOCK_PUBLIC_API_KEY = 'pk_test_20lxNIWSA6IKmDVZYGG1guKAe6eerM0Zyz6ImAtQJ_I'
      break
    case 'staging.examfly-app.com':
      KNOCK_PUBLIC_API_KEY = 'pk_S8_lP_WxT_eDqvA_M92IoKlwwuD1gBxG1MBvXMeN-xw'
      break
    case 'www.examfly-app.com':
      KNOCK_PUBLIC_API_KEY = 'pk_S8_lP_WxT_eDqvA_M92IoKlwwuD1gBxG1MBvXMeN-xw'
      break
    default:
      KNOCK_PUBLIC_API_KEY = 'pk_test_20lxNIWSA6IKmDVZYGG1guKAe6eerM0Zyz6ImAtQJ_I'
      break
  }
  return KNOCK_PUBLIC_API_KEY
}

function getKnockFeedID() {
  return 'dbae42b8-2b33-4096-9f8e-acb304705e63'
}

export { getKnockKey, getKnockFeedID }
