import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Box, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Link from '@mui/material/Link'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import Typography from '@mui/material/Typography'
import { Button, Divider } from '@mui/material'
import { Global, css } from '@emotion/react'
import useWindowSize from 'react-use/lib/useWindowSize'
import mixpanel from 'mixpanel-browser'

import theme from 'theme'
import { useNavigate } from 'react-router-dom'
const globalStyles = css`
  @keyframes pulseBorder {
    0% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(23, 86, 163, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0);
    }
  }
`
const StartAssessmentModal = ({ setShowAssessmentModal, startAssessment }) => {
  const [open, setOpen] = useState(true)
  const { width, height } = useWindowSize()
  const navigate = useNavigate()
  const onClose = () => {
    setShowAssessmentModal(false)
  }

  const [ready, setReady] = useState(false)
  const [resize, setResize] = useState(false)

  const ref = useRef(null)

  const [widthModal, setModalWidth] = useState(0)

  function handleResize() {
    setModalWidth(Math.round(width * 0.4))
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    setModalWidth(Math.round(width * 0.4))
  }, [resize, width])

  useLayoutEffect(() => {
    setModalWidth(ref.current && Math.round(ref.current.offsetWidth))
  }, [ready])

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#FFFFFF',
          border: '0',
          boxShadow: 24,
          padding: '55px',
          paddingTop: '35px',
          maxWidth: '1500px',
          minwidth: '360px',
          width: widthModal > 360 ? widthModal : 360,
          '&:focus': {
            border: '0',
            outline: 'none',
          },
        }}
      >
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <AccessAlarmIcon sx={{ fontSize: '6rem', color: 'primary.main', marginTop: '0' }} />
          <Typography variant="h5" component="h2" sx={{ mt: 2, fontWeight: 'bold', fontSize: '2rem' }}>
            Are you ready to start the Assessment?
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            The assessment timer will start once you click the button below.
          </Typography>

          <Global styles={globalStyles} />
          <Button
            sx={{
              animation: 'pulseBorder 2s infinite',
              marginTop: '20px',
              cursor: 'pointer', // Add this line
            }}
            variant="contained"
            onClick={() => {
              mixpanel.track('button_clicked', {
                Feature: 'assessment',
                Location: 'assessment_button_start',
                Name: 'Start Assessment',
              })
              startAssessment()
            }}
          >
            Start Assessment
          </Button>

          <Divider />
          <Box textAlign="center" sx={{ margin: '20px' }}>
            <Link
              style={{ fontWeight: 'bold', textDecoration: 'none', color: theme.palette.primary.main, cursor: 'pointer' }}
              to={`/`}
              onClick={(event) => {
                event.preventDefault()
                mixpanel.track('link_clicked', {
                  Feature: 'assessment',
                  Location: 'assessment_start_modal_link_back_to_dashboard',
                  Name: 'Back to Dashboard',
                })
                navigate(`/`)
              }}
            >
              Back to Dashboard
            </Link>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default StartAssessmentModal
