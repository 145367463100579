import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Box, CircularProgress, List, ListItem, Typography, Stack, Item } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'

import { useUser } from '../login/user-context'

function firstNameAndInitial(fullName) {
  const bits = fullName.split(/\s/)
  return `${bits[0]} ${bits[bits.length - 1][0]}`
}

function numberOrEmoji(number) {
  switch (number) {
    case 1: {
      return (
        <span role="img" aria-label="1st">
          🥇
        </span>
      )
    }
    case 2: {
      return (
        <span role="img" aria-label="2nd">
          🥈
        </span>
      )
    }
    case 3: {
      return (
        <span role="img" aria-label="3rd">
          🥉
        </span>
      )
    }
    default: {
      return number
    }
  }
}

const Leaderboard = (props) => {
  const [loading, setLoading] = useState(true)
  const [leaderboard, setLeaderboard] = useState([])

  const { name, username } = useUser()

  useEffect(() => {
    if (loading) {
      axios('/points/leaderboard/?limit=200').then((r) => {
        setLeaderboard(r.data)
        setLoading(false)
      })
    }
  }, [loading])

  const top = leaderboard.slice(0, 5)
  const userPoints = leaderboard.length && leaderboard.find((l) => l.username === username)
  const userIndex = leaderboard.length && leaderboard.findIndex((l) => l.username === username)

  return (
    <Box
      sx={{
        background: '#FFFFFF',
        display: 'flow-root',
        height: '100%',
        paddingBottom: '40px',
        mt: 2,
      }}
    >
      {loading ? null : (
        <List sx={{ padding: '0 35px', maxWidth: '600px', alignContent: 'center', mx: 'auto' }}>
          <LeaderboardHeader />
          {top.length ? (
            top.map((item, index) => <LeaderboardItem position={index + 1} isUser={item.username === username} key={`leaderboard-${index}`} {...item} />)
          ) : (
            <Typography sx={{ display: 'block', textAlign: 'center' }} variant="h5" color="textSecondary">
              No activity on Leaderboard at the moment
            </Typography>
          )}
          {userIndex >= 5 && <LeaderboardItem position={userIndex + 1} isUser={true} key={`leaderboard-user`} points={userPoints.points || 0} name={name} />}
        </List>
      )}
    </Box>
  )
}

const LeaderboardItem = ({ isUser, name, points, position, numOfProficiencies }) => {
  return (
    <ListItem
      sx={{
        background: isUser ? '#1756A3' : '#F2F6F7',
        borderRadius: '20px',
        display: 'flex',
        // justifyContent: 'space-between',
        justifyContent: 'space-between',
        paddingLeft: '40px',
        marginBottom: '10px',
      }}
    >
      <Typography component="span" variant="h3" sx={{ color: isUser ? '#FFFFFF' : undefined }}>
        {numberOrEmoji(position)}
      </Typography>
      <Typography component="span" sx={{ color: isUser ? '#FFFFFF' : undefined }}>
        {name ? firstNameAndInitial(name) : 'Unnamed'}
      </Typography>
      <Typography component="span" sx={{ color: isUser ? '#FFFFFF' : undefined }}>
        {numOfProficiencies}
      </Typography>
      <Typography component="span" sx={{ color: isUser ? '#FFFFFF' : undefined }}>
        <Stack direction="row" spacing={8}>
          {points}
          <StarIcon sx={{ '&&': { color: 'orange' }, mx: '5px' }} />
        </Stack>
      </Typography>
    </ListItem>
  )
}

const LeaderboardHeader = () => {
  return (
    <ListItem
      sx={{
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '20px',
        marginBottom: '10px',
      }}
    >
      <Typography component="span" sx={{ color: false ? '#FFFFFF' : undefined, fontWeight: 'bold' }}>
        Position
      </Typography>
      <Typography component="span" sx={{ color: false ? '#FFFFFF' : undefined, fontWeight: 'bold' }}>
        Name
      </Typography>
      <Typography component="span" sx={{ color: false ? '#FFFFFF' : undefined, fontWeight: 'bold' }}>
        Proficiencies
      </Typography>
      <Typography component="span" sx={{ color: false ? '#FFFFFF' : undefined, fontWeight: 'bold' }}>
        <Stack direction="row" spacing={8}>
          Points
        </Stack>
      </Typography>
    </ListItem>
  )
}
export default Leaderboard
