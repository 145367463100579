import React, { useState } from 'react'

export const LessonContext = React.createContext(null)

export const LessonProvider = (props) => {
  return <LessonContext.Provider value={{
    moduleId: props.value.moduleId,
    lessonId: props.value.lessonId,
  }}>{props.children}</LessonContext.Provider>
}

export function useLesson() {
  return React.useContext(LessonContext)
}