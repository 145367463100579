import React, { useState, useEffect } from 'react'
import { Backdrop, Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
// import 'react-quill/dist/quill.snow.css'
import * as Yup from 'yup'
import { FastField, FieldArray, Form, Formik, getIn } from 'formik'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import theme from '../../theme'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { debounce } from '@mui/material/utils'
import '@mdxeditor/editor/style.css'
import { MDXEditor } from '@mdxeditor/editor'

import { tablePlugin } from '@mdxeditor/editor/plugins/table'
import { quotePlugin } from '@mdxeditor/editor/plugins/quote'
import { listsPlugin } from '@mdxeditor/editor/plugins/lists'
import { toolbarPlugin } from '@mdxeditor/editor/plugins/toolbar'
import { headingsPlugin } from '@mdxeditor/editor/plugins/headings'
import { markdownShortcutPlugin } from '@mdxeditor/editor/plugins/markdown-shortcut'

import { UndoRedo } from '@mdxeditor/editor/plugins/toolbar/components/UndoRedo'
import { ListsToggle } from '@mdxeditor/editor/plugins/toolbar/components/ListsToggle'
import { InsertTable } from '@mdxeditor/editor/plugins/toolbar/components/InsertTable'
import { BlockTypeSelect } from '@mdxeditor/editor/plugins/toolbar/components/BlockTypeSelect'
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles'

import './mdx.css'

const AddRemedialQuestionSchema = Yup.object().shape({
  question: Yup.string().trim().required('Question is required!'),
  feedback: Yup.string().trim().required('Feedback is required!'),
  is_correct: Yup.string().trim().required('Select an option!'),
  options: Yup.array()
    .of(
      Yup.object({
        option_title: Yup.string().trim().required('Option cannot be empty!'),
      })
    )
    .min(2, 'Minimum 2 options should be added!'),
})

const AddRemedialQuestion = ({ open, handleClose, quizId, question }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [isQuestionActionAdd, setIsQuestionActionAdd] = useState(true)
  const [remedialDataLoaded, setRemedialDataLoaded] = useState(false)

  const [remedialData, setRemedialData] = useState({
    remedial_question_parent_uuid: question.id,
    quizId: quizId,
    question: '',
    question_uuid: uuidv4(),
    feedback: '',
    is_correct: '',
    options: [],
  })

  const getErrorMessage = (name, errors, touched) => {
    return {
      status: Boolean(getIn(errors, name) && getIn(touched, name)),
      message: getIn(errors, name),
    }
  }

  const handleDelete = async () => {
    try {
      await axios.post(`/admin/question/delete-question`, {
        remedial_question_uuid: question.remedial_question_uuid,
        question_uuid: question.id,
        quizId: quizId,
      })
      handleClose()
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Oops, Something went wrong! Please contact IT.', { variant: 'error' })
    }
  }

  useEffect(() => {
    if (question?.id) {
      setLoading(true)
      axios.get(`/admin/question/${question.id}`).then((response) => {
        if (response.data.remedial_question_uuid !== null) {
          axios.get(`/admin/question/${response.data.remedial_question_uuid}`).then((response) => {
            if (response.data?.is_remedial_question) {
              let obj = {
                ...remedialData,
                question_uuid: response.data.uuid,
                question: response.data.text,
                feedback: response.data.feedback,
                is_correct: response.data.is_correct,
                is_remedial_question: response.data.is_remedial_question,
                options: response.data.answers.map((answer) => {
                  return { option_id: answer.uuid, option_title: answer.text }
                }),
              }

              setRemedialData(obj)
              setTimeout(() => {
                setRemedialDataLoaded(true)
                setLoading(false)
              }, 300)

              setIsQuestionActionAdd(false)
            }
          })
          setIsQuestionActionAdd(false)
        } else {
          setTimeout(() => {
            setRemedialDataLoaded(true)
            setLoading(false)
          }, 300)

          setIsQuestionActionAdd(true)
        }

        setLoading(false)
      })
    }
  }, [question.id, setIsQuestionActionAdd])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create Remedial Question</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <Formik
            initialValues={remedialData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={AddRemedialQuestionSchema}
            onSubmit={async (values, actions) => {
              try {
                actions.setSubmitting(false)

                if (isQuestionActionAdd) {
                  await axios.post(`/admin/question/save-question`, values)
                } else {
                  await axios.post(`/admin/question/update-question`, values)
                }
                handleClose()
              } catch (e) {
                console.log(e)
                enqueueSnackbar('Oops, Something went wrong! Please contact IT.', { variant: 'error' })
              }
            }}
          >
            {({ errors, touched, values, handleSubmit, setFieldValue }) => (
              <Form onSubmit={(event) => handleSubmit(event)}>
                <Grid container rowSpacing={3} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Grid sx={{ height: '300px' }} item xs={12}>
                        {remedialDataLoaded ? (
                          <FastField name={`question`}>
                            {({ field }) => (
                              <MDXEditor
                                placeholder="Write remedial question here..."
                                onChange={(content) => debounce(setFieldValue('question', content), 100)}
                                className="mdx-container"
                                markdown={values.question || ''}
                                plugins={[
                                  toolbarPlugin({
                                    toolbarContents: () => (
                                      <>
                                        <UndoRedo />
                                        <BoldItalicUnderlineToggles />
                                        <ListsToggle />
                                        <BlockTypeSelect />
                                        <InsertTable />
                                      </>
                                    ),
                                  }),
                                  tablePlugin(),
                                  quotePlugin(),
                                  listsPlugin(),
                                  headingsPlugin(),
                                  markdownShortcutPlugin(),
                                ]}
                              />
                            )}
                          </FastField>
                        ) : (
                          ''
                        )}

                        <Typography
                          sx={{
                            color: theme.palette.error.main,
                            marginTop: 2,
                          }}
                        >
                          {getErrorMessage(`question`, errors, touched).status ? getErrorMessage(`question`, errors, touched).message : ''}
                        </Typography>
                      </Grid>

                      <br />

                      <FormControl sx={{ marginBottom: 2 }}>
                        <FormLabel>
                          <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                            Options
                          </Typography>
                          <Typography
                            sx={{
                              color: theme.palette.error.main,
                              marginBottom: 2,
                            }}
                          >
                            {Boolean(getIn(errors, `is_correct`)) ? getErrorMessage(`is_correct`, errors, touched).message : ''}
                          </Typography>
                          <Typography
                            sx={{
                              color: theme.palette.error.main,
                              marginBottom: 2,
                            }}
                          >
                            {typeof getIn(errors, `options`) !== 'object' ? getErrorMessage(`options`, errors, touched).message : ''}
                          </Typography>
                        </FormLabel>
                        <RadioGroup row onChange={(event) => setFieldValue(`is_correct`, event.target.value)}>
                          <FieldArray
                            name={`options`}
                            render={(optionArrayHelpers) => (
                              <Grid container rowSpacing={1} columnSpacing={1}>
                                {values['options'].map((option_value, option_index) => {
                                  return (
                                    <Grid key={option_value.option_id} item xs={12} marginY={1}>
                                      <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={1} key={option_value.option_id}>
                                        <div className="option-width" style={{ display: 'flex', flexDirection: 'column', height: '250px', gap: '0.2rem' }}>
                                          {remedialDataLoaded ? (
                                            <FastField name={`options[${option_index}][option_title]`}>
                                              {({ field }) => (
                                                <MDXEditor
                                                  placeholder="Write option here..."
                                                  onChange={(content) => debounce(setFieldValue(`options[${option_index}][option_title]`, content), 100)}
                                                  className="mdx-container"
                                                  markdown={values['options'][option_index]['option_title'] || ''}
                                                  plugins={[
                                                    toolbarPlugin({
                                                      toolbarContents: () => (
                                                        <>
                                                          <UndoRedo />
                                                          <BoldItalicUnderlineToggles />
                                                          <ListsToggle />
                                                          <BlockTypeSelect />
                                                          <InsertTable />
                                                        </>
                                                      ),
                                                    }),
                                                    tablePlugin(),
                                                    quotePlugin(),
                                                    listsPlugin(),
                                                    headingsPlugin(),
                                                    markdownShortcutPlugin(),
                                                  ]}
                                                />
                                              )}
                                            </FastField>
                                          ) : (
                                            ''
                                          )}

                                          <Typography
                                            component="span"
                                            sx={{
                                              color: theme.palette.error.main,
                                            }}
                                          >
                                            {getErrorMessage(`options[${option_index}][option_title]`, errors, touched).status
                                              ? getErrorMessage(`options[${option_index}][option_title]`, errors, touched).message
                                              : ''}
                                          </Typography>
                                        </div>
                                        <FormControlLabel
                                          sx={{ margin: 0 }}
                                          value={option_index}
                                          control={<Radio checked={option_index === parseInt(values['is_correct']) ? true : false} />}
                                        />
                                        {values['options'].length > 1 && (
                                          <IconButton
                                            onClick={() => {
                                              setFieldValue(`is_correct`, '')
                                              optionArrayHelpers.remove(option_index)
                                            }}
                                            aria-label="delete"
                                            size="large"
                                          >
                                            <DeleteIcon fontSize="inherit" />
                                          </IconButton>
                                        )}
                                      </Stack>
                                    </Grid>
                                  )
                                })}
                                <Grid item alignSelf="flex-end">
                                  <Button
                                    onClick={() => {
                                      optionArrayHelpers.push({
                                        option_id: uuidv4(),
                                        option_title: '',
                                      })
                                    }}
                                    size="small"
                                    variant="contained"
                                    type="button"
                                  >
                                    Add Option
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          />
                        </RadioGroup>
                      </FormControl>

                      <Grid sx={{ height: '300px' }} item xs={12}>
                        <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                          Feedback
                        </Typography>
                        {remedialDataLoaded ? (
                          <FastField name={`feedback`}>
                            {({ field }) => (
                              <MDXEditor
                                placeholder="Write feedback here..."
                                onChange={(content) => debounce(setFieldValue('feedback', content), 100)}
                                className="mdx-container"
                                markdown={values.feedback || ''}
                                plugins={[
                                  toolbarPlugin({
                                    toolbarContents: () => (
                                      <>
                                        <UndoRedo />
                                        <BoldItalicUnderlineToggles />
                                        <ListsToggle />
                                        <BlockTypeSelect />
                                        <InsertTable />
                                      </>
                                    ),
                                  }),
                                  tablePlugin(),
                                  quotePlugin(),
                                  listsPlugin(),
                                  headingsPlugin(),
                                  markdownShortcutPlugin(),
                                ]}
                              />
                            )}
                          </FastField>
                        ) : (
                          ''
                        )}
                        <Typography
                          sx={{
                            color: theme.palette.error.main,
                            marginTop: 1,
                          }}
                        >
                          {getErrorMessage(`feedback`, errors, touched).status ? getErrorMessage(`feedback`, errors, touched).message : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container gap={1} direction="row" justifyContent="flex-end">
                      {!isQuestionActionAdd && (
                        <Grid item>
                          <Button onClick={handleDelete} variant="delete" type="button" color="error">
                            Delete Question
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Button onClick={handleClose} variant="contained" type="reset">
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" type="submit">
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AddRemedialQuestion
