import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import axios from 'axios'
import MobileNotSupported from '../components/MobileNotSupported'
import InteractionProgress from '../common-components/interaction-progress'
import { useLesson } from '../lesson/context'

import Account from './account'
import Balance from './balance'
import Summary from './summary'
import FeedbackSection from './feedback-section'
import { BalanceProvider, useBalance } from './balance-context'

import { CREDIT, DEBIT } from './definitions'
import getMistakes from './get-mistakes'

export const DEBK_GAME = 'DEBKGame'

// Picks 10 events:
// - 8 with 2 accounts only
// - 2 with more than 2 accounts
// It attempts to pick events which have not been completed, however will fall back to completed ones if none available.
function pickEvents(allEvents, completedEvents) {
  const { easy, hard } = allEvents.reduce(
    (acc, event) => {
      if (event.accounts.length > 2) {
        acc.hard.push(event)
      } else {
        acc.easy.push(event)
      }
      return acc
    },
    { easy: [], hard: [] }
  )
  const incompleteEasy = easy.filter(({ uuid }) => !completedEvents.includes(uuid))
  const incompleteHard = hard.filter(({ uuid }) => !completedEvents.includes(uuid))
  return [...randomList(8, incompleteEasy >= 8 ? incompleteEasy : easy), ...randomList(2, incompleteHard >= 2 ? incompleteHard : hard)]
}

export function randomList(limit, list) {
  return list.sort(() => 0.5 - Math.random()).slice(0, limit)
}

const DEBKGame = ({ uuid, next }) => {
  const { moduleId } = useLesson()
  const [completedEvents, setCompletedEvents] = useState(
    localStorage.getItem(`DEBK-${uuid}-events-completed`) ? JSON.parse(localStorage.getItem(`DEBK-${uuid}-events-completed`)) : []
  )
  const [gameData, setGameData] = useState({})
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [disableNext, setDisableNext] = useState(false)
  const [eventIndex, setEventIndex] = useState(0)

  useEffect(() => {
    if (loading) {
      axios(`/debk/${uuid}`).then((r) => {
        setGameData(r.data)
        setEvents(pickEvents(r.data.events, completedEvents))
        setLoading(false)
      })
    }
  }, [loading, uuid])

  const nextEvent = () => {
    const currentEventUuid = events[eventIndex].uuid
    const newEventSet = new Set([...completedEvents, currentEventUuid])
    const newEventList = Array.from(newEventSet)
    setCompletedEvents(newEventList)
    localStorage.setItem(`DEBK-${uuid}-events-completed`, JSON.stringify(newEventList))
    setEventIndex(eventIndex + 1)
  }

  const restart = () => {
    setEventIndex(0)
    setEvents(pickEvents(gameData.events, completedEvents))
  }

  useEffect(() => {
    if (events.length && eventIndex >= events.length) {
      setDisableNext(true)
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: DEBK_GAME,
          uuid: uuid,
        },
      }).then(() => {
        setDisableNext(false)
      })
    }
  }, [eventIndex, events])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <BalanceProvider accountDefinitions={gameData.definitions}>
      <MobileNotSupported />
      <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
        <Box sx={{ background: '#FFFFFF', display: 'flex', flexDirection: 'column' }}>
          {eventIndex < events.length ? (
            <>
              <InteractionProgress current={eventIndex + 1} description={gameData.description} total={events.length} />
              <Event gameId={uuid} key={events[eventIndex].uuid} next={nextEvent} definitionsType={gameData.definitionsType} symbol={gameData.symbol} {...events[eventIndex]} />
            </>
          ) : (
            <Summary
              accountDefinitions={gameData.definitions}
              definitionsType={gameData.definitionsType}
              disableNext={disableNext}
              completedEvents={completedEvents}
              eventsTotal={gameData.events}
              next={next}
              restart={restart}
              symbol={gameData.symbol}
            />
          )}
        </Box>
      </Box>
    </BalanceProvider>
  )
}

const Event = ({ accounts, definitionsType, gameId, next, symbol, text, uuid }) => {
  const { addTransactions, accountDefinitions, resetData } = useBalance()
  const { moduleId } = useLesson()

  const [values, setValues] = useState(
    accounts.reduce((acc, id) => {
      acc[id] = {
        [CREDIT]: 0,
        [DEBIT]: 0,
      }
      return acc
    }, {})
  )
  const [correctValues, setCorrectValues] = useState(null)
  const [checking, setChecking] = useState(false)
  const [mistakes, setMistakes] = useState({})
  const [feedback, setFeedback] = useState(0)

  useEffect(() => {
    if (checking && !correctValues) {
      axios({
        url: `/debk/${gameId}/check`,
        method: 'post',
        data: {
          eventId: uuid,
          moduleId: moduleId,
          userTransactions: values,
        },
      }).then((r) => {
        const { correctTransactions } = r.data
        const mistakesByAccount = getMistakes(accountDefinitions, values, correctTransactions)
        let feedback = r.data.feedback || ''
        const hasMistakes = Object.values(mistakesByAccount).some((m) => m.length)
        if (hasMistakes) {
          feedback += '\n\n**Your Mistakes**:\n\n'
          feedback += Object.values(mistakesByAccount)
            .map((mistakes) => mistakes.slice(0, -1).join('\n\n'))
            .join('\n\n')
        }
        addTransactions(values, correctTransactions, mistakesByAccount)
        setFeedback(feedback)
        setCorrectValues(correctTransactions)
        setMistakes(mistakesByAccount)
      })
    }
  }, [checking, values])

  const changeValue = (value, account, type) => {
    setValues({
      ...values,
      [account]: {
        ...values[account],
        [type]: value,
      },
    })
  }

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: 0 }}>
        {text}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          [theme.breakpoints.down('xl')]: {
            flexDirection: 'column',
          },
        })}
      >
        <Box sx={{ padding: '20px' }}>
          {accounts.map((id) => (
            <Account
              key={id}
              id={id}
              checking={checking}
              correctValues={correctValues ? correctValues[id] || {} : null}
              changeValue={changeValue}
              symbol={symbol}
              values={values[id]}
            />
          ))}
          {!feedback ? (
            <Box
              sx={{
                border: '2px solid green',
                backgroundColor: 'rgba(0, 255, 0, 0.05)',
                borderRadius: '2px',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
              }}
            >
              <Typography sx={{ fontSize: '0.9rem', color: 'green' }}>Correct answers will appear in the statements in green after you click check!</Typography>
            </Box>
          ) : null}
          <Box sx={{ display: 'flex', alignContent: 'center', flexDirection: 'column' }}>
            {correctValues ? (
              <Button
                onClick={() => {
                  resetData()
                  next()
                }}
                sx={{ alignSelf: 'center', marginTop: '20px' }}
                variant="contained"
              >
                Next
              </Button>
            ) : (
              <Button onClick={() => setChecking(true)} sx={{ alignSelf: 'center', marginBottom: '20px', marginTop: '20px' }} variant="contained">
                {checking && <CircularProgress />}
                Check
              </Button>
            )}
          </Box>
        </Box>
        <Balance definitionsType={definitionsType} symbol={symbol} feedback={feedback} />
      </Box>
      {feedback ? <FeedbackSection sx={{ width: '200px' }} feedback={feedback} mistakes={mistakes} /> : null}
    </>
  )
}

export function parseAccountingNumber(value) {
  return Number(value.toString().replace(',', ''))
}

export function toAccountingNumber(value) {
  const isNegative = value < 0
  const asString = Math.abs(value).toLocaleString()
  return isNegative ? `(${asString})` : asString
}

export default DEBKGame
