import React, { useEffect, useState, useCallback } from 'react'
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableRow, Fade, Typography } from '@mui/material'
import axios, { spread } from 'axios'
import Spreadsheet, { DataViewer, Cell, createFormulaParser, Matrix, CellBase } from 'react-spreadsheet'
import Feedback from '../../results/feedback'
import ResultsSummary from '../../results/summary'
import MobileNotSupported from '../../components/MobileNotSupported'
import { useLesson } from '../../lesson/context'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import _, { set } from 'lodash'
import './spreadsheet-game.css'

export const MI_SPREADSHEET_GAME = 'MI_SPREADSHEET_GAME'

const MISpreadSheetGame = ({ next, uuid = 'd6fd0f5e-114b-4e2b-98e4-efb11a158644' }) => {
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)
  const [results, setResults] = useState([])
  const { moduleId } = useLesson()
  const [allCorrect, setAllCorrect] = React.useState(false)
  const [spreadSheetData, setSpreadSheetData] = useState([])
  const [spreadSheetName, setSpreadSheetName] = useState('')
  const [spreadSheetLabel, setSpreadSheetLabel] = useState('')
  const [spreadSheetType, setSpreadSheetType] = useState('')

  const debouncedSetSpreadSheetData = useCallback(
    _.debounce((data) => {
      setSpreadSheetData(data)
    }, 300),
    []
  )

  useEffect(() => {
    axios(`/spreadsheet-game/${uuid}`).then((r) => {
      setSpreadSheetData(r.data.spreadSheetData)
      setSpreadSheetName(r.data.name)
      setSpreadSheetLabel(r.data.label)
      setSpreadSheetType(r.data.type)
      setLoading(false)
    })
  }, [uuid])

  useEffect(() => {
    if (checked) {
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          type: spreadSheetType,
          uuid: uuid,
          moduleId: moduleId,
        },
      }).then(() => {
        console.log('Progress updated')
      })
    }
  }, [checked])

  const customCreateFormulaParser = (data) =>
    createFormulaParser(data, {
      functions: {
        MIN: (number1, number2) => {
          // number = FormulaHelpers.accept(number, Types.NUMBER)
          // if (number > 255 || number < 1) throw FormulaError.VALUE
          if (number1.value < number2.value) {
            return number1.value
          } else {
            return number2.value
          }
        },
      },
    })

  if (loading) {
    return <CircularProgress />
  }

  const handleChange = (data) => {
    console.log('handleChange')
    debouncedSetSpreadSheetData(data)
  }

  const sendGridData = () => {
    axios({
      url: `/spreadsheet-game/${uuid}/check`,
      method: 'post',
      data: {
        gameId: uuid,
        spreadSheetDataAttempt: spreadSheetData,
        moduleId: moduleId,
      },
    })
      .then((r) => {
        setResults(r.data)
        const incorrectCells = r.data.wrong.map((cell) => {
          return cell
        })

        setSpreadSheetData((prevData) => {
          const spreadSheetDataCopy = JSON.parse(JSON.stringify(prevData))

          const newData = spreadSheetDataCopy.map((row, rowIndex) => {
            return row.map((cell, colIndex) => {
              if (!cell.readOnly) {
                const incorrectCell = incorrectCells.find((incorrect) => incorrect.row === rowIndex && incorrect.column === colIndex)
                if (incorrectCell) {
                  console.log('Incorrect cell', incorrectCell)
                  return {
                    ...cell,
                    className: 'spreadsheet-cell-incorrect',
                  }
                } else {
                  return {
                    ...cell,
                    className: 'spreadsheet-cell-highlight',
                  }
                }
              }
              return cell
            })
          })
          return newData
        })

        setChecked(true)
        setAllCorrect(r.data.allCorrect)
      })
      .catch((error) => {
        console.error('Opps we have encountered an error. Please wait a moment and try again. Otherwise contact support@examfly.com. Thank you', error)
      })
  }

  return (
    <>
      <MobileNotSupported />

      <Box sx={{ display: { xs: 'none', sm: 'flex', minWidth: '800px', maxWidth: '800px', margin: '0px auto 0px auto' }, justifyContent: 'center' }}>
        <Box sx={{ backgroundColor: '#F2F6F7', padding: '20px', marginBottom: '20px', width: '100%' }}>
          <Typography variant="h4">{spreadSheetName}</Typography>
          <Typography>{spreadSheetLabel}</Typography>
          <Typography>Enter the correct values in the blue cells below. When you are ready click 'Check' to check your answers.</Typography>
        </Box>
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
        <Box sx={{ background: '#FFFFFF', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ background: '#ffffff', margin: '0px 0px 0px 10px' }}>
            <Spreadsheet data={spreadSheetData} createFormulaParser={customCreateFormulaParser} onChange={handleChange} />
          </Box>

          {results.correct?.length > 0 && results.wrong?.length === 0 && allCorrect && (
            <Fade in={allCorrect}>
              <Box sx={{ margin: '10px' }}>
                <Feedback
                  feedback={
                    <Typography component="div">
                      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                        All correct! Well done!
                      </ReactMarkdown>
                    </Typography>
                  }
                  isCorrect={true}
                />
              </Box>
            </Fade>
          )}
          {results.wrong?.length > 0 && (
            <Fade in={results.wrong?.length > 0}>
              <Box sx={{ margin: '10px' }} key={`question-wrong`}>
                <Feedback
                  key={`feedback-wrong`}
                  feedback={
                    <Typography component="div">
                      {results.wrong.map((wrongAnswer, index) => {
                        return (
                          <ReactMarkdown key={index} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                            {wrongAnswer.feedback}
                          </ReactMarkdown>
                        )
                      })}
                    </Typography>
                  }
                  isCorrect={false}
                />
              </Box>
            </Fade>
          )}
          {/* {results.correct?.length > 0 && (
            <Box sx={{ margin: '10px' }} key={`question-correct`}>
              <Feedback
                key={`feedback-correct`}
                feedback={
                  <Typography component="div">
                    {results.correct.map((correctAnswer, index) => {
                      return (
                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                          {correctAnswer.feedback}
                        </ReactMarkdown>
                      )
                    })}
                  </Typography>
                }
                isCorrect={true}
              />
            </Box>
          )} */}
          <Box sx={{ background: '#F2F6F7', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '15px', padding: '5px' }}>
            <Button sx={{ margin: '0 10px' }} onClick={sendGridData} variant="contained" color="primary">
              Check
            </Button>
            <Button sx={{ margin: '0 10px' }} variant="contained" onClick={() => next(true)}>
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default MISpreadSheetGame
