import React, { useState } from 'react'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'

import { useBalance } from './balance-context'
import { CREDIT, DEBIT, ACTIONS } from './definitions'
import { parseAccountingNumber, toAccountingNumber } from './index'

const Account = ({ checking, changeValue, correctValues, id, label, symbol, values }) => {
  const { accountDefinitions } = useBalance()
  let debitError = false
  let creditError = false
  if (correctValues) {
    if (correctValues[DEBIT]) {
      debitError = correctValues[DEBIT] === values[DEBIT] ? false : `The correct value to debit ${accountDefinitions[id].label} is: ${correctValues[DEBIT]}`
    }
    if (correctValues[CREDIT]) {
      creditError = correctValues[CREDIT] === values[CREDIT] ? false : `The correct value to credit ${accountDefinitions[id].label} is: ${correctValues[CREDIT]}`
    }
  }

  return (
    <Box
      sx={{
        background: '#F2F6F7',
        flex: '1',
        marginBottom: '20px',
        flexDirection: 'column',
        display: 'flex',
      }}
    >
      <Typography variant="h4" sx={{ lineHeight: '50px', textAlign: 'center' }}>
        Account: {accountDefinitions[id].label}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '40px',
          paddingTop: '20px',
        }}
      >
        {correctValues ? (
          <AccountWithFeedback correctValue={correctValues[DEBIT]} label={ACTIONS[DEBIT].label} symbol={symbol} value={parseInt(values[DEBIT])} />
        ) : (
          <AccountInput
            disabled={checking}
            label={ACTIONS[DEBIT].label}
            onChange={(value) => changeValue(parseAccountingNumber(value), id, DEBIT)}
            value={toAccountingNumber(values[DEBIT])}
            symbol={symbol}
          />
        )}
        {correctValues ? (
          <AccountWithFeedback correctValue={correctValues[CREDIT]} label={ACTIONS[CREDIT].label} symbol={symbol} value={parseInt(values[CREDIT])} />
        ) : (
          <AccountInput
            disabled={checking}
            label={ACTIONS[CREDIT].label}
            onChange={(value) => changeValue(parseAccountingNumber(value), id, CREDIT)}
            value={toAccountingNumber(values[CREDIT])}
            symbol={symbol}
          />
        )}
      </Box>
    </Box>
  )
}

const AccountInput = ({ disabled, onChange, label, symbol, value }) => (
  <TextField
    name="answer"
    disabled={disabled}
    value={value}
    onChange={(e) => onChange(parseInt(e.target.value.replace(/[^0-9]/g, '')) || 0)}
    label={`${label} value`}
    variant="outlined"
    sx={{
      background: '#FFFFFF',
      flex: 1,
      marginRight: '20px',
    }}
    size="small"
    InputProps={{
      startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>,
    }}
  />
)

const AccountWithFeedback = ({ correctValue, label, symbol, value }) => {
  let isIncorrect
  let color = null
  let inputColor = 'rgba(0, 0, 0, 0.38)'
  let underLineColor = null
  if (correctValue === value) {
    color = '#5EC2A6'
    inputColor = '#5EC2A6'
  } else if (
    // we have correctValue and it's different from our value
    (correctValue !== undefined && correctValue !== value) ||
    // we don't have correct value, but we have value ourselves
    (correctValue === undefined && value !== 0)
  ) {
    color = '#EB464C'
    inputColor = '#EB464C'
    underLineColor = '#5EC2A6'
    isIncorrect = true
  }
  return (
    <Box>
      <TextField
        disabled={true}
        error={isIncorrect}
        label={`${label} value`}
        value={value}
        sx={{
          background: '#FFFFFF',
          flex: 1,
          marginRight: '20px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${color} !important`,
          },
        }}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ '> p': { color: inputColor } }} position="start">
              {symbol}
            </InputAdornment>
          ),
        }}
      />

      {isIncorrect && (
        <Typography sx={{ color: underLineColor, fontWeight: 'bold', textAlign: 'center' }}>
          {symbol}
          {toAccountingNumber(correctValue || 0)}
        </Typography>
      )}
    </Box>
  )
}

export default Account
