import React, { useEffect, useState, useCallback } from 'react'
import { Box, Button, Card, CardHeader, CardContent, CircularProgress, Stack, Divider, List, ListItem, TextField, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { v4 as uuidv4 } from 'uuid'

import axios from 'axios'

import EditableGroup from './edit-group'

const Groups = (props) => {
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)
  const [activeFilter, setActiveFilter] = useState('true')
  const [groups, setGroups] = useState([])

  const getGroupsAPI = useCallback(
    (isActive) => {
      setLoading(true)
      setActiveFilter(isActive)
      axios(`/group/?is_active=${activeFilter}`).then((r) => {
        setGroups(r.data)
        setLoading(false)
      })
    },
    [activeFilter]
  )

  useEffect(() => {
    if (loading) {
      getGroupsAPI(activeFilter)
    }
  }, [loading, activeFilter, getGroupsAPI])

  const groupsToDisplay = company ? groups.filter((g) => g.company === company) : groups
  const companies = groups.reduce((acc, g) => {
    if (g.company && !acc.includes(g.company)) {
      acc.push(g.company)
    }
    return acc
  }, [])

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Typography sx={{ marginBottom: '20px' }} variant="h4">
        Groups
      </Typography>
      <NewGroup onSave={(group) => setGroups([group, ...groups])} />
      <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
      <Stack direction="row" spacing={2} justifyContent="space-between" divider={<Divider orientation="vertical" flexItem />}>
        {!loading && <CompanyFilter filter={company} setFilter={(c) => setCompany(c)} companies={companies} />}
        {!loading && <AvtiveFilter activeFilter={activeFilter} setActiveFilter={getGroupsAPI} />}
      </Stack>
      <Divider sx={{ marginTop: '10px' }} />
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {groupsToDisplay.map((group) => (
            <ListItem key={group.uuid}>
              <EditableGroup
                key={group.uuid}
                onDelete={(uuid) => setGroups(groups.filter((g) => g.uuid !== uuid))}
                onUpdate={(data) =>
                  setGroups(
                    groups.map((g) => {
                      if (g.uuid === data.uuid) {
                        return data
                      } else {
                        return g
                      }
                    })
                  )
                }
                {...group}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  )
}

const NewGroup = ({ onSave }) => {
  const [name, setName] = useState('')
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (saving) {
      axios({
        url: '/group',
        method: 'post',
        data: {
          name,
        },
      }).then((r) => {
        setSaving(false)
        onSave(r.data)
      })
    }
  }, [name, saving])

  return (
    <>
      <Card sx={{ maxWidth: 345, marginLeft: 'auto' }} variant="outlined">
        <CardHeader title="Create New Group" variant="adminPanelCard" />

        <CardContent>
          <TextField name="name" label="Group Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} sx={{ flex: 1 }} size="small" />
          <Button disabled={saving} onClick={() => setSaving(true)}>
            Create Group
          </Button>
        </CardContent>
      </Card>
    </>
  )
}

const AvtiveFilter = ({ activeFilter, setActiveFilter }) => {
  const handleChange = (event) => {
    setActiveFilter(event.target.value)
  }
  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>Filter:</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Active</InputLabel>

          <Select defaultValue="" labelId="demo-simple-select-label" id="demo-simple-select" sx={{ minWidth: '200px' }} value={activeFilter || 'active'} onChange={handleChange}>
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">Inactive</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

const CompanyFilter = ({ companies, filter, setFilter }) => {
  const [companyFilter, setCompanyFilter] = useState('Choose Company')

  const handleChange = (event) => {
    setCompanyFilter(event.target.value)
    setFilter(event.target.value)
  }

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>Filter:</Typography>
        <FormControl fullWidth={true}>
          <InputLabel id="demo-simple-select-label">Company</InputLabel>

          <Select defaultValue="" sx={{ minWidth: '200px' }} labelId="demo-simple-select-label" id="demo-simple-select" value={filter || ''} onChange={handleChange}>
            {companies.map((c) => (
              <MenuItem key={uuidv4()} value={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

export default Groups
