import React from 'react'
import { Box, Button, Link, Typography, Card } from '@mui/material'
import Countdown from 'react-countdown'
import DynamicSessionImage from './dynamic-session.svg'

import ScheduleIcon from '@mui/icons-material/Schedule'

const DynamicSessionCard = React.forwardRef((props, ref) => {
  const { endTime, name, startTime, uuid } = props

  return (
    <Card
      ref={ref}
      sx={{
        maxWidth: 345,
        minWidth: { xs: '320px', sm: '400px', lg: '450px' },
        width: { xs: '320px', sm: '400px', lg: '450px' },
      }}
    >
      <Box
        sx={{
          backgroundColor: '#EAB7E4',
          backgroundImage: `url(${DynamicSessionImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '400px 170px',
          backgroundPosition: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          height: '170px',
          width: '100%',
        }}
      >
        <Typography sx={{ textAlign: 'center', fontWeight: 800 }} variant="h2">
          {name || 'Dynamic Session'}
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: '40px',
        }}
      >
        <DynamicSessionActions endTime={endTime} startTime={startTime} uuid={uuid} />
      </Box>
    </Card>
  )
})

const DynamicSessionActions = ({ endTime, startTime, uuid }) => {
  const now = Date.now()

  if (startTime > now) {
    return (
      <>
        <Typography>Session will start at:</Typography>
        <Typography sx={{ fontWeight: 'bold', marginBottom: '20px' }}>{new Date(startTime).toLocaleString()}</Typography>
        <Link href={`/dynamic-session/${uuid}`}>
          <Button variant="contained">Take a peek</Button>
        </Link>
      </>
    )
  }
  if (endTime < now) {
    return <Typography variant="h2">Session ended</Typography>
  }
  if (startTime < now && endTime > now) {
    return (
      <>
        <Countdown
          date={endTime}
          renderer={({ hours, minutes, seconds, completed }) => (
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontWeight: 'bold',
                height: '40px',
                marginBottom: '20px',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <ScheduleIcon sx={{ marginRight: '10px' }} />
              {hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds} left
            </Typography>
          )}
        />
        <Link href={`/dynamic-session/${uuid}`}>
          <Button variant="contained">Join session</Button>
        </Link>
      </>
    )
  }
  return null
}

export default DynamicSessionCard
