// ==============================|| OVERRIDES - CARD CONTENT ||============================== //

export default function CardContent() {
  return {
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 10,
          '&:last-child': {
            paddingBottom: 20,
          },
          title: {
            fontSize: 14,
          },
        },
      },

      variants: [
        {
          props: { variant: 'feedbackPanelCorrect' },
          style: {
            padding: '10px 5px 5px 15px',
            '&:last-child': {
              paddingBottom: 20,
            },
            p: {
              margin: 0,
              padding: 0,
            },
            color: '#FFFFFF',
            backgroundColor: 'rgb(244, 251, 239)',
            textAlign: 'left',
            fontSize: '1.5rem',
            title: {
              fontWeight: 600,
              fontSize: '1.5rem',
              lineHeight: 1.33,
              color: 'FFFFFF',
            },
          },

          title: {
            fontWeight: 600,
            fontSize: '1.2rem',
            lineHeight: 1.2,
            color: 'FFFFFF',
            padding: '0px 0px 0px 0px',
          },
        },

        {
          props: { variant: 'feedbackPanelIncorrect' },
          style: {
            padding: '10px 5px 5px 15px',
            '&:last-child': {
              paddingBottom: 20,
            },
            p: {
              margin: 0,
              padding: 0,
            },
            color: '#FFFFFF',
            backgroundColor: 'rgb(255, 245, 245)',
            textAlign: 'left',
            fontSize: '1.5rem',
            title: {
              fontWeight: 600,
              fontSize: '1.5rem',
              lineHeight: 1.33,
              color: 'FFFFFF',
            },
          },

          title: {
            fontWeight: 600,
            fontSize: '1.2rem',
            lineHeight: 1.2,
            color: 'FFFFFF',
            padding: '0px 0px 0px 0px',
          },
        },
      ],
    },
  }
}
