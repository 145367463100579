import React, {useState} from 'react'
import { 
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material'
import classnames from 'classnames'

const MultiStepQuestion = ({answers, questionNumber, next, text, totalQuestions}) => {
  const [answerIndex, setAnswerIndex] = useState(null)

  return (
    <>
      <FormControl className="question-mui-wrapper">
        <FormLabel id="question">
          <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '16px', margin: '20px 0'}} gutterBottom component="div">Question {questionNumber} of {totalQuestions}. {text}</Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="question"
          name="answers"
          value={answerIndex}
          onChange={e => setAnswerIndex(e.target.value)}
        >
          {answers.map((answer, index) => {
          return (
          <FormControlLabel
            className={classnames({
            'answer-index': answerIndex,
            'mcq-item': true,
            'mcq-item-selected': answerIndex === index
            })}
            disabled={false}
            key={`answer-${index}`}
            value={answer.uuid}
            control={<Radio />}
            label={answer.text}
          />
          )})}
        </RadioGroup>
      </FormControl>
      <Button 
        sx={{alignSelf: 'center'}}
        disabled={answerIndex === null}
        variant="contained"
        color='primary'
        onClick={() => next(answerIndex)}
      >
        Next
      </Button>
    </>
  )
}

export default MultiStepQuestion