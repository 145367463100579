import React, { useState, useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import MultiStepStory from './story'
import MultiStepFeedback from './feedback'
import axios from 'axios'

import { useLesson } from '../lesson/context'
import './index.css'

export const MULTI_STEP = 'MultiStep'

const MultiStepGame = ({ next, uuid }) => {
  const [stories, setStories] = useState(null)
  const [loading, setLoading] = useState(true)
  const [description, setDescription] = useState(null)

  const [progress, setProgress] = useState(0)
  const [totalAnswers, setTotalAnswers] = useState(0)
  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [disableNext, setDisableNext] = useState(false)

  const { moduleId } = useLesson()

  useEffect(() => {
    if (!loading && progress >= stories.length) {
      setDisableNext(true)
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: MULTI_STEP,
          uuid: uuid,
        },
      }).then(() => {
        setDisableNext(false)
      })
    }
  }, [loading, progress, stories])

  useEffect(() => {
    axios.get(`/multi-step/${uuid}`).then((r) => {
      setStories(r.data.order)
      setDescription(r.data.description)
      setLoading(false)
    })
  }, [uuid])

  const nextStory = (answerCount, correctCount) => {
    setTotalAnswers(totalAnswers + answerCount)
    setCorrectAnswers(correctAnswers + correctCount)
    setProgress(progress + 1)
  }

  if (loading) {
    return <CircularProgress />
  }

  const finished = progress >= stories.length
  const currentStoryId = stories[progress]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {finished === true ? (
        <MultiStepFeedback next={next} stories={stories} totalAnswers={totalAnswers} correctAnswers={correctAnswers} />
      ) : (
        <MultiStepStory
          description={description}
          disableNext={disableNext}
          storyNumber={progress + 1}
          totalStories={stories.length}
          multiStepId={uuid}
          key={currentStoryId}
          index={progress}
          id={currentStoryId}
          next={nextStory}
        />
      )}
    </Box>
  )
}

export default MultiStepGame
