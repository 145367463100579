import { useSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const CopyToClipboardButton = ({ content }) => {
  const { enqueueSnackbar } = useSnackbar()

  return (
    <>
      <IconButton
        onClick={() => {
          enqueueSnackbar('Copied to clipboard', { variant: 'success' })
          navigator.clipboard.writeText(content)
        }}
        aria-label="copy-video-id"
      >
        <ContentCopyIcon />
      </IconButton>
    </>
  )
}

export default CopyToClipboardButton
