import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import CopyToClipboardButton from '../components/copy-to-clipboard-button'
import * as Yup from 'yup'
import { FastField, FieldArray, Form, Formik, getIn } from 'formik'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import AddQuiz from './add-quiz'
import { LessonWrapperContext } from './lesson-wrapper'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { debounce } from '@mui/material/utils'
import '@mdxeditor/editor/style.css'
import { MDXEditor } from '@mdxeditor/editor'

import { tablePlugin } from '@mdxeditor/editor/plugins/table'
import { quotePlugin } from '@mdxeditor/editor/plugins/quote'
import { listsPlugin } from '@mdxeditor/editor/plugins/lists'
import { toolbarPlugin } from '@mdxeditor/editor/plugins/toolbar'
import { headingsPlugin } from '@mdxeditor/editor/plugins/headings'
import { markdownShortcutPlugin } from '@mdxeditor/editor/plugins/markdown-shortcut'

import { UndoRedo } from '@mdxeditor/editor/plugins/toolbar/components/UndoRedo'
import { ListsToggle } from '@mdxeditor/editor/plugins/toolbar/components/ListsToggle'
import { InsertTable } from '@mdxeditor/editor/plugins/toolbar/components/InsertTable'
import { BlockTypeSelect } from '@mdxeditor/editor/plugins/toolbar/components/BlockTypeSelect'
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles'

import './mdx.css'

const MAX_FILE_SIZE = 2000000
const validFileExtensions = { image: ['jpg', 'png', 'jpeg', 'webp'] }

let AddLessonSchema = Yup.object().shape({
  lesson_name: Yup.string().trim().required('Lesson Name is required!'),
  time_to_complete: Yup.number().min(1, 'Time to complete must be greater than or equal to 1'),
  lesson_image: Yup.mixed()
    .required('Upload Image file')
    .test('is-valid-type', 'Not a valid image type!', (value) => value.name && validFileExtensions['image'].indexOf(value.name.toLowerCase().split('.').pop()) > -1)
    .test('is-valid-size', 'Max allowed size is 2MB', (value) => (typeof value === 'string' ? true : typeof value === 'object' && value.size <= MAX_FILE_SIZE)),
  vimeo_urls: Yup.array().of(
    Yup.object({
      url: Yup.string().trim().required('Vimeo url is required!').url('Please enter a valid url!'),
    })
  ),
})

const getErrorMessage = (name, errors, touched) => ({
  status: Boolean(getIn(errors, name)),
  message: getIn(errors, name),
})

const MultipleSelectCheckmarks = ({ initialGameData, setGameData, apiGameData }) => {
  const [gameName, setGameName] = useState(initialGameData)

  useEffect(() => {
    setGameName(initialGameData)
  }, [initialGameData])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    setGameData(value)
    setGameName(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="multiple-game-selection">Select Games</InputLabel>
        <Select
          defaultValue=""
          labelId="multiple-game-selection"
          id="multiple-game-checkbox"
          multiple
          value={gameName}
          onChange={handleChange}
          input={<OutlinedInput label="Select Games" />}
          renderValue={(selected) => {
            let text = selected.map((id) => {
              let text = apiGameData.filter((x) => x.id === id)
              text = text.length > 0 ? text[0].value : ''

              return text
            })
            return text.join(', ')
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 230,
                width: 250,
              },
            },
          }}
        >
          {apiGameData.map((name) => (
            <MenuItem key={name.id} value={name.id}>
              <Checkbox checked={gameName.indexOf(name.id) > -1} />
              <ListItemText primary={name.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

const ContentListing = ({ listingData, changeListingData, editQuizData }) => {
  const [deleteQuizId, setdeleteQuizId] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDialogOpen = (quiz) => {
    setdeleteQuizId(quiz)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setdeleteQuizId('')
    setDialogOpen(false)
  }

  const callChangeListingData = (deleteQuizId) => {
    changeListingData(deleteQuizId)
    handleDialogClose()
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listingData.length > 0 &&
              listingData.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>Quiz - {row.quiz_title}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        editQuizData(row)
                      }}
                      aria-label="delete"
                      size="large"
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton onClick={() => handleDialogOpen(row)} aria-label="delete" size="large">
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => callChangeListingData(deleteQuizId)} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const AddLesson = () => {
  const { enqueueSnackbar } = useSnackbar()

  const location = useLocation()
  const navigate = useNavigate()
  const { moduleId, lessonId } = useParams()

  const formikRef = useRef()

  const initialVimeoObject = {
    id: uuidv4(),
    url: '',
    description: '',
  }

  const initialState = {
    lesson_name: '',
    lesson_image: null,
    lesson_image_url: null,
    lesson_description: '',
    time_to_complete: '',
    vimeo_urls: [initialVimeoObject],
    quizzes: [],
    games: [],
  }

  const { lessonState, setLessonState } = useContext(LessonWrapperContext)

  const [quizData, setQuizData] = useState(lessonState.quizzes)
  const [lessonData, setLessonData] = useState(lessonState)
  const [referenceGameData, setReferenceGameData] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)

  useEffect(() => {
    if (lessonId) {
      AddLessonSchema = Yup.object().shape({
        lesson_name: Yup.string().trim().required('Lesson Name is required!'),
        time_to_complete: Yup.number().min(1, 'Time to complete must be greater than or equal to 1'),
        vimeo_urls: Yup.array().of(
          Yup.object({
            url: Yup.string().trim().required('Vimeo url is required!').url('Please enter a valid url!'),
          })
        ),
      })

      if (location.state && location.state.no_update) {
        let data = location.state
        setQuizData(data.quizzes)
        setLessonData(data)
        setLessonState(data)
        setDataLoaded(true)
      } else {
        setLoading(true)

        axios
          .get(`/lesson/get-lesson/${lessonId}`)
          .then((data) => {
            let result = data.data

            if (result.vimeo_urls.length <= 0) {
              result.vimeo_urls = [initialVimeoObject]
            }

            setReferenceGameData(result.games)
            result = { ...result, games: result.games.map((x) => x.uuid) }

            setQuizData(result.quizzes)
            // setLessonData(result)
            setLessonData({ ...result, time_to_complete: result.time_to_complete ? result.time_to_complete : '' })
            setLessonState({ ...result, time_to_complete: result.time_to_complete ? result.time_to_complete : '' })

            formikRef.current.setValues({ ...result, time_to_complete: result.time_to_complete ? result.time_to_complete : '' })
            setTimeout(() => {
              setDataLoaded(true)
            }, 300)
          })
          .catch(() => {
            setLoading(false)
            enqueueSnackbar('Something went wrong!', { variant: 'error' })
            navigate('/admin/lesson/all')
          })
          .finally(() => setLoading(false))
      }
    } else {
      setDataLoaded(true)
      setLoading(false)
    }
  }, [])

  const [loading, setLoading] = useState(false)
  const [displayQuiz, setDisplayQuiz] = useState(false)

  const [editQuizData, setEditQuizData] = useState({})
  const [apiGameData, setApiGameData] = useState([])

  const [deleteVideoId, setdeleteVideoId] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDisplayQuiz = (value) => {
    setDisplayQuiz(value)
  }

  const handleDialogOpen = (index) => {
    setdeleteVideoId(index)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setdeleteVideoId('')
    setDialogOpen(false)
  }

  useEffect(() => {
    axios
      .get(`/game/all-games`)
      .then((data) => {
        let result = data.data
        let newData = []

        result.forEach((game) => {
          newData.push({
            id: game.uuid,
            value: game.name,
          })
        })

        setApiGameData(newData)
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong!', { variant: 'error' })
        navigate('/admin/lesson/all')
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <Container display="flex">
      {!displayQuiz && (
        <>
          <Typography sx={{ margin: 5 }} variant="h1">
            {lessonId ? 'Edit Lesson' : 'Add Lesson'}
          </Typography>
          <Box sx={{ background: '#ffffff', padding: 5, position: 'relative' }}>
            <Link
              to={'/admin/lesson/all'}
              onClick={() => {
                setLessonState(initialState)
                navigate('/admin/lesson/all')
              }}
            >
              <Button sx={{ padding: '0 10px' }} variant="text">
                <ArrowBackIcon />
                Back
              </Button>
            </Link>
            <br />
            <Box sx={{ width: '100%' }}>
              {loading && (
                <Backdrop
                  sx={{
                    background: 'rgba(255, 255, 255, 0.8)',
                    color: '#ffffff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    position: 'absolute',
                  }}
                  open={true}
                >
                  <CircularProgress />
                </Backdrop>
              )}
              <Formik
                innerRef={formikRef}
                initialValues={lessonData}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={AddLessonSchema}
                onSubmit={async (values, actions) => {
                  try {
                    actions.setSubmitting(false)
                    let requestData = { ...values }
                    requestData['change_api'] = false

                    requestData['contents'] = []

                    if (requestData.hasOwnProperty('vimeo_urls') && requestData['vimeo_urls'].length > 0) {
                      let temp = requestData['vimeo_urls'].map((x) => {
                        return { ...x, type: 'video' }
                      })

                      requestData['contents'].push(...temp)
                    }

                    if (requestData.hasOwnProperty('quizzes') && requestData['quizzes'].length > 0) {
                      let temp = requestData['quizzes'].map((x) => {
                        return { ...x, type: 'quiz' }
                      })

                      requestData['contents'].push(...temp)
                    }

                    if (requestData.hasOwnProperty('games') && requestData['games'].length > 0) {
                      let temp = requestData['games'].map((game) => {
                        let text = apiGameData.filter((x) => x.id === game)
                        text = text.length > 0 ? text[0].value : ''

                        let order = referenceGameData.filter((x) => x.uuid === game)
                        order = order.length > 0 ? order[0].order : 999999

                        return {
                          id: game,
                          type: 'game',
                          title: text ?? '',
                          order: order,
                        }
                      })

                      requestData['contents'].push(...temp)
                    }

                    let newValues = { ...values }

                    if (moduleId) {
                      requestData['moduleId'] = moduleId
                      newValues['moduleId'] = moduleId
                    }

                    setLessonData(newValues)
                    setLessonState(newValues)

                    if (lessonId) {
                      requestData['change_api'] = true
                    }

                    if (location.state) {
                      let old_content = [...location.state.contents]

                      requestData['contents'] = requestData['contents'].map((content) => {
                        let order = old_content.filter((e) => e.id === content.id)
                        order = order.length > 0 ? order[0].order : content.order
                        return { ...content, order: order }
                      })
                    }

                    requestData['contents'].sort((a, b) => {
                      return parseInt(a.order) - parseInt(b.order)
                    })

                    navigate('/admin/lesson/reorder-lesson-content', {
                      state: requestData,
                    })
                  } catch (e) {
                    enqueueSnackbar('Something went wrong!', { variant: 'error' })
                    navigate('/admin/lesson/all')
                  }
                }}
              >
                {({ errors, touched, values, handleSubmit, setFieldValue, validateField }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container rowSpacing={3} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                      <Grid item xs={8}>
                        <FastField name="lesson_name">
                          {({ field }) => (
                            <TextField
                              fullWidth
                              {...field}
                              name="lesson_name"
                              label="Lesson Name"
                              error={getErrorMessage('lesson_name', errors, touched).status}
                              helperText={getErrorMessage('lesson_name', errors, touched).status ? getErrorMessage('lesson_name', errors, touched).message : undefined}
                            />
                          )}
                        </FastField>
                      </Grid>

                      <Grid item xs={3}>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                          {values.lesson_image !== null && (
                            <img
                              src={
                                typeof values.lesson_image === 'object'
                                  ? URL.createObjectURL(values.lesson_image)
                                  : `${process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' : ''}${values.lesson_image_url}`
                              }
                              style={{
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                              alt="Module"
                              width={100}
                              height={100}
                            />
                          )}
                          {values.lesson_image_url !== null && (
                            <img
                              src={`${process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' : ''}${values.lesson_image_url}`}
                              style={{
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                              alt="Module"
                              width={100}
                              height={100}
                            />
                          )}
                          <Button fullWidth variant="contained" component="label">
                            Upload Image
                            <FastField
                              name="lesson_image"
                              validate={(image) => {
                                if (lessonId && formikRef.current.values.lesson_image_url === null) {
                                  const imageSchema = Yup.mixed()
                                    .required('Upload Image file')
                                    .test(
                                      'is-valid-type',
                                      'Not a valid image type!',
                                      (value) => value.name && validFileExtensions['image'].indexOf(value.name.toLowerCase().split('.').pop()) > -1
                                    )
                                    .test('is-valid-size', 'Max allowed size is 2MB', (value) =>
                                      typeof value === 'string' ? true : typeof value === 'object' && value.size <= MAX_FILE_SIZE
                                    )

                                  try {
                                    imageSchema.validateSync(image, {
                                      abortEarly: true,
                                    })
                                  } catch (error) {
                                    return error.errors[0]
                                  }
                                }
                              }}
                              as={() => (
                                <input
                                  hidden
                                  id="file"
                                  // name="lesson_image"
                                  type="file"
                                  accept=".jpg,.jpeg,.png"
                                  onChange={(event) => {
                                    let newState = { ...lessonState }
                                    newState.lesson_image = event.currentTarget.files[0]
                                    setLessonState(newState)
                                    setFieldValue('lesson_image', event.currentTarget.files[0])

                                    if (lessonId) {
                                      setFieldValue('lesson_prev_image_url', values.lesson_image_url)
                                      setFieldValue('lesson_image_url', null)
                                      AddLessonSchema = Yup.object().shape({
                                        lesson_name: Yup.string().trim().required('Lesson Name is required!'),
                                        time_to_complete: Yup.number().min(1, 'Time to complete must be greater than or equal to 1'),
                                        lesson_image: Yup.mixed()
                                          .required('Upload Image file')
                                          .test(
                                            'is-valid-type',
                                            'Not a valid image type!',
                                            (value) => value.name && validFileExtensions['image'].indexOf(value.name.toLowerCase().split('.').pop()) > -1
                                          )
                                          .test('is-valid-size', 'Max allowed size is 2MB', (value) =>
                                            typeof value === 'string' ? true : typeof value === 'object' && value.size <= MAX_FILE_SIZE
                                          ),
                                        vimeo_urls: Yup.array().of(
                                          Yup.object({
                                            url: Yup.string().trim().required('Vimeo url is required!').url('Please enter a valid url!'),
                                          })
                                        ),
                                      })
                                    }

                                    setTimeout(() => {
                                      validateField('lesson_image')
                                    }, 200)
                                  }}
                                />
                              )}
                            />
                          </Button>
                        </Stack>
                        <Typography sx={{ color: 'error.main' }} variant="subtitle2" gutterBottom>
                          {errors.lesson_image ? String(errors.lesson_image) : undefined}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <FastField name="time_to_complete">
                          {({ field }) => (
                            <TextField
                              fullWidth
                              {...field}
                              type="number"
                              name="time_to_complete"
                              label="Time to Complete"
                              error={getErrorMessage('time_to_complete', errors, touched).status}
                              helperText={getErrorMessage('time_to_complete', errors, touched).status ? getErrorMessage('time_to_complete', errors, touched).message : undefined}
                            />
                          )}
                        </FastField>
                      </Grid>

                      <Grid sx={{ height: '400px' }} item xs={12}>
                        <Typography sx={{ margin: 0, marginBottom: 0.8 }} variant="h4" gutterBottom>
                          Lesson Description
                        </Typography>
                        {dataLoaded ? (
                          <FastField name="lesson_description">
                            {({ field }) => (
                              <MDXEditor
                                placeholder="Write lesson description here..."
                                onChange={(content) => debounce(setFieldValue('lesson_description', content), 100)}
                                className="mdx-container"
                                markdown={values.lesson_description || ''}
                                plugins={[
                                  toolbarPlugin({
                                    toolbarContents: () => (
                                      <>
                                        <UndoRedo />
                                        <BoldItalicUnderlineToggles />
                                        <ListsToggle />
                                        <BlockTypeSelect />
                                        <InsertTable />
                                      </>
                                    ),
                                  }),
                                  tablePlugin(),
                                  quotePlugin(),
                                  listsPlugin(),
                                  headingsPlugin(),
                                  markdownShortcutPlugin(),
                                ]}
                              />
                            )}
                          </FastField>
                        ) : (
                          ''
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Divider variant="middle" />
                      </Grid>

                      <FieldArray
                        name="vimeo_urls"
                        render={(arrayHelpers) => (
                          <Grid item xs={12}>
                            <Typography sx={{ margin: 0 }} variant="h2">
                              Add videos
                            </Typography>

                            <br />

                            {values.vimeo_urls.map((value, index) => {
                              return (
                                <Grid key={value.id} item xs={12}>
                                  <Grid item xs={12}>
                                    <div
                                      style={{
                                        marginTop: '1rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h2">
                                        {index + 1}. Video
                                      </Typography>

                                      <Typography sx={{ margin: 0, marginBottom: 1 }} variant="">
                                        <CopyToClipboardButton content={value.id} />
                                        ID: {value.id}
                                      </Typography>

                                      {values.vimeo_urls.length > 1 && (
                                        <IconButton
                                          onClick={() => {
                                            handleDialogOpen(index)
                                            // arrayHelpers.remove(index)
                                          }}
                                          aria-label="delete"
                                          size="large"
                                        >
                                          <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                      )}
                                    </div>
                                    <FastField name={`vimeo_urls[${index}][url]`}>
                                      {({ field }) => (
                                        <>
                                          <TextField
                                            fullWidth
                                            {...field}
                                            name={`vimeo_urls[${index}][url]`}
                                            label="Vimeo Url"
                                            error={getErrorMessage(`vimeo_urls[${index}][url]`, errors, touched).status}
                                            helperText={
                                              getErrorMessage(`vimeo_urls[${index}][url]`, errors, touched).status
                                                ? getErrorMessage(`vimeo_urls[${index}][url]`, errors, touched).message
                                                : undefined
                                            }
                                          />
                                        </>
                                      )}
                                    </FastField>
                                  </Grid>

                                  <br />

                                  <Grid sx={{ height: '400px' }} item xs={12}>
                                    <Typography sx={{ margin: 0, marginBottom: 0.8 }} variant="h4" gutterBottom>
                                      Video Description
                                    </Typography>
                                    {dataLoaded ? (
                                      <FastField name={`vimeo_urls[${index}][description]`}>
                                        {({ field }) => (
                                          <MDXEditor
                                            placeholder="Write video description here..."
                                            onChange={(content) => debounce(setFieldValue(`vimeo_urls[${index}]['description']`, content), 100)}
                                            className="mdx-container"
                                            markdown={values.vimeo_urls[index]['description'] || ''}
                                            plugins={[
                                              toolbarPlugin({
                                                toolbarContents: () => (
                                                  <>
                                                    <UndoRedo />
                                                    <BoldItalicUnderlineToggles />
                                                    <ListsToggle />
                                                    <BlockTypeSelect />
                                                    <InsertTable />
                                                  </>
                                                ),
                                              }),
                                              tablePlugin(),
                                              quotePlugin(),
                                              listsPlugin(),
                                              headingsPlugin(),
                                              markdownShortcutPlugin(),
                                            ]}
                                          />
                                        )}
                                      </FastField>
                                    ) : (
                                      ''
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            })}

                            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleDialogClose}>Cancel</Button>
                                <Button
                                  onClick={() => {
                                    arrayHelpers.remove(deleteVideoId)
                                    handleDialogClose()
                                  }}
                                  color="error"
                                  autoFocus
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>

                            <br />

                            <Grid item xs={12}>
                              <Grid container gap={1} direction="row" justifyContent="flex-end">
                                <Grid item>
                                  <Button
                                    onClick={() => {
                                      arrayHelpers.push({
                                        id: uuidv4(),
                                        url: '',
                                        description: '',
                                      })
                                    }}
                                    variant="contained"
                                    type="button"
                                  >
                                    Add New
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      />

                      <Grid item xs={12}>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs={12}>
                        <MultipleSelectCheckmarks
                          apiGameData={apiGameData}
                          initialGameData={lessonData.games}
                          setGameData={(value) => {
                            let newValues = values
                            newValues.games = value
                            setLessonState({ ...newValues })
                            setLessonData({ ...newValues })
                            setFieldValue('games', value)
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container gap={1} direction="row" justifyContent="flex-end">
                          <Grid item>
                            <Button
                              onClick={() => {
                                let newValues = values
                                newValues.quizzes = quizData
                                setLessonData({ ...newValues })
                                setLessonState(newValues)
                                setEditQuizData({})
                                handleDisplayQuiz(true)
                              }}
                              variant="contained"
                              type="button"
                            >
                              Add Quiz
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      {quizData.length > 0 && (
                        <>
                          <Grid item xs={12}>
                            <Divider variant="middle" />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography sx={{ margin: 0 }} variant="h2">
                              Added Quizzes
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <ContentListing
                              listingData={quizData}
                              changeListingData={(row) => {
                                let newData = quizData.filter((x) => x.id !== row.id)
                                setQuizData(newData)
                                let newState = { ...lessonState }
                                newState.quizzes = newData
                                setLessonData(newState)
                                setLessonState(newState)
                              }}
                              editQuizData={(row) => {
                                setEditQuizData(row)
                                setDisplayQuiz(true)
                              }}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12}>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container gap={1} direction="row" justifyContent="flex-end">
                          <Grid item>
                            <Button
                              variant="contained"
                              type="button"
                              onClick={() => {
                                setLessonState(initialState)
                                navigate('/admin/lesson/all')
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button variant="contained" type="submit">
                              Next
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </>
      )}

      {displayQuiz && (
        <AddQuiz
          setDisplayQuiz={handleDisplayQuiz}
          setQuizzes={(data) => {
            if (data.hasOwnProperty('is_update') && data.is_update) {
              delete data['is_update']
              let idx = quizData.findIndex((x) => x.id === data.id)
              if (idx !== -1) {
                quizData[idx] = data
              }
              setQuizData([...quizData])
              let newState = { ...lessonState }
              newState.quizzes = quizData
              setLessonData(newState)
              setLessonState(newState)
            } else {
              setQuizData([...quizData, data])
              let newState = { ...lessonState }
              newState.quizzes.push(data)
              setLessonData(newState)
              setLessonState(newState)
            }
          }}
          preFillQuiz={editQuizData}
          newQuestionVideoID={lessonData.vimeo_urls.map((vimeo_url) => {
            return vimeo_url.id
          })}
        />
      )}
    </Container>
  )
}

export default AddLesson
