import React, { useEffect, useState } from 'react'
import { Box, Button, Chip, CircularProgress, Link, Typography, Stack } from '@mui/material'

import StarIcon from '@mui/icons-material/Star'
import { usePoints } from '../points/context'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardHeader from '@mui/material/CardHeader'

const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const Module = React.forwardRef((props, ref) => {
  const { imageUrl, imageUrl2, lessons, name, order, uuid, isLast } = props
  const { loading, perModule } = usePoints()
  const [showAnimation, setShowAnimation] = useState(false)
  const completedLessons = lessons.filter((l) => !!l.complete).map((l) => l.uuid)
  const { url } = getLessonURL(uuid, lessons)
  const [isCompleted, setIsCompleted] = useState(false)

  const animationStartSeconds = getRandomNumber(10, 40)
  const animationDuration = 4

  useEffect(() => {
    setIsCompleted(completedLessons.length === lessons.length)

    const showAnimationInterval = setInterval(() => {
      setShowAnimation(true)

      // After the animation duration, hide the animation
      const hideTimer = setTimeout(() => {
        setShowAnimation(false)
      }, animationDuration * 1000)

      // Cleanup hide timer
      return () => clearTimeout(hideTimer)
    }, (animationStartSeconds + animationDuration) * 1000) // The total interval includes the animation duration and pause

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(showAnimationInterval)
  }, [isCompleted])

  return (
    <Card
      ref={ref}
      sx={{
        maxWidth: 345,
        minWidth: { xs: '320px', sm: '400px', lg: '450px' },
        width: { xs: '320px', sm: '400px', lg: '450px' },
      }}
    >
      <Link href={`/module/${uuid}/explore`} underline="none" aria-label={`Link to module ${name}`}>
        <CardHeader title={name} />
      </Link>
      <Link href={url} aria-label={`Link to module ${name}`}>
        <Box
          sx={{
            textDecoration: 'none',
            position: 'relative',
            '& :hover': {
              opacity: [1, 1, 1],
            },
          }}
        >
          <CardMedia sx={{ height: 200, opacity: 1 }} image={process.env.NODE_ENV === 'development' ? `https://dev.examfly-app.com${imageUrl}` : imageUrl} title={name} />
          {isCompleted && (
            <CheckCircleIcon
              color="greenTint"
              sx={{
                backgroundColor: '#FFFFFF',
                padding: 0,
                borderRadius: '50%',
                width: '50px',
                height: '50px',
                position: 'absolute',
                top: '10px',
                left: '10px',
              }}
            />
          )}
          {!isCompleted && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height: '100%',
                width: '100%',
                opacity: showAnimation ? 1 : 0,
                transition: '.3s ease',
                '& :hover': {
                  opacity: [1, 1, 1],
                },
              }}
            >
              {imageUrl2 && (
                <CardMedia
                  sx={{
                    height: 200,
                    opacity: showAnimation ? 1 : 0,
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                  image={process.env.NODE_ENV === 'development' ? `https://dev.examfly-app.com${imageUrl2}` : imageUrl2}
                  title={name}
                />
              )}
            </Box>
          )}
        </Box>
      </Link>

      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Typography sx={{ textTransform: 'capitalize' }} variant="body1" color="text.secondary">
            {completedLessons.length === lessons.length ? ' Module Completed' : completedLessons.length + '/' + lessons.length + ' lessons complete'}
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Stack sx={{ marginLeft: '10px' }}>
              <Points points={perModule[uuid] || 0} />
            </Stack>
          )}
        </Box>
      </CardContent>
      <CardActions sx={{ marginBottom: '15px', padding: '0 4px 4px 4px' }}>
        <Link sx={{ mx: 'auto' }} href={`/module/${uuid}/explore`} aria-label={`Link to module ${name} overview`}>
          <Button variant="outlined" size="small" aria-label={`Link to module ${name} overview`}>
            Overview
          </Button>
        </Link>
        <GoToLesson moduleId={uuid} lessons={lessons} />
      </CardActions>
    </Card>
  )
})

const Points = ({ points }) => (
  <Chip
    sx={{ backgroundColor: '#efefef' }}
    label={`${points} Points`}
    icon={<StarIcon sx={{ '&&': { backgroundColor: '#efefef', color: 'orange' }, animation: 'rotation 5s 1 linear' }} />}
  />
)

const getLessonURL = (moduleId, lessons) => {
  const elements = lessons.reduce(
    (acc, l) =>
      acc.concat(
        l.elements.map((e) => ({
          ...e,
          lessonId: l.uuid,
        }))
      ),
    []
  )

  let label
  let url

  const firstIncompleteElementIndex = elements.findIndex((e) => !e.complete)
  const firstIncompleteElement = elements[firstIncompleteElementIndex]

  if (firstIncompleteElementIndex === -1) {
    label = 'Restart'
    url = `/module/${moduleId}/lesson/${lessons[0].uuid}`
  } else if (firstIncompleteElementIndex !== 0) {
    label = 'Resume'
    url = `/module/${moduleId}/lesson/${firstIncompleteElement.lessonId}/${firstIncompleteElement.uuid}`
  } else {
    label = 'Start'
    url = `/module/${moduleId}/lesson/${lessons[0].uuid}`
  }
  return {
    label: label,
    url: url,
  }
}
const GoToLesson = ({ moduleId, lessons }) => {
  const { label, url } = getLessonURL(moduleId, lessons)
  return (
    <Link sx={{ mx: 'auto' }} href={url} aria-label={`Link to module lesson ${label}`}>
      <Button variant="contained">{label}</Button>
    </Link>
  )
}

export default Module
