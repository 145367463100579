// ==============================|| OVERRIDES - LINK ||============================== //

export default function Link() {
  return {
    // MuiLink: {
    //   defaultProps: {
    //     underline: 'hover'
    //   }
    // }
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontFamily: ['"Inter"'],
          underline: 'hover',
        },
      },
    },
  }
}
