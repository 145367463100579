import React from 'react'
import { Box, Typography } from '@mui/material'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
const ResultsSummary = ({ correct, total }) => {
  const { width, height } = useWindowSize()

  const percentCompleted = ((correct / total) * 100).toFixed(2)

  return (
    <>
      {percentCompleted >= 80 && <Confetti width={width} height={height} recycle={false} numberOfPieces={500} />}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: '#F2F6F7',
          height: '195px',
          marginTop: '50px',
          padding: '36px',
          width: '300px',
        }}>
        <Typography sx={{ textAlign: 'center', margin: 0 }}>You answered</Typography>
        <Typography sx={{ textAlign: 'center', margin: 0 }} variant="h1">
          {correct} out of {total}
        </Typography>
        <Typography sx={{ textAlign: 'center', margin: 0 }}>correctly ({((correct / total) * 100).toFixed(2)}%)</Typography>
      </Box>
    </>
  )
}

export default ResultsSummary
