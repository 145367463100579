import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import Balance from './balance'

const Summary = ({ accountDefinitions, completedEvents, definitionsType, disableNext, eventsTotal, restart, next, symbol }) => {
  const eventsLeft = eventsTotal.length - completedEvents.length
  const { width, height } = useWindowSize()

  const percentCompleted = ((completedEvents.length / eventsTotal.length) * 100).toFixed(2)

  return (
    <>
      {percentCompleted >= 80 && <Confetti width={width} height={height} recycle={false} numberOfPieces={500} />}
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant="h2">Congratulations, you finished this set of transactions.</Typography>
        <Balance accountDefinitions={accountDefinitions} definitionsType={definitionsType} symbol={symbol} />
        <Box sx={{ backgroundColor: '#F2F6F7', padding: '20px', marginBottom: '20px' }}>
          <Typography>This game has a total of {eventsTotal.length} transactions for you to learn with.</Typography>
          {eventsLeft ? (
            <Typography>There are {eventsLeft} new ones left that you can practice!</Typography>
          ) : (
            <Typography>You completed them all, however you can always practice using them again</Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            width: '100%',
          }}>
          <Button variant="contained" color="primary" onClick={restart}>
            <RestartAltIcon />
            Play Again
          </Button>
          <Button disabled={disableNext} variant="contained" color="primary" onClick={() => next(true)}>
            Next Step
            <ArrowForwardIosIcon />
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Summary
