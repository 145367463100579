import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { CircularProgress, Box, Card, Typography } from '@mui/material'
import Question from './question'
import Summary from './summary'
import { useLesson } from '../../lesson/context'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import HighlightableContent from './highlight'
import MobileNotSupported from '../../components/MobileNotSupported'
export const LEGISLATIVE_INTERPRETATION_GAME = 'LegislativeInterpretationGame'

const LegislativeInterpretationGame = ({ next, uuid }) => {
  const [loading, setLoading] = useState(true)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [data, setData] = useState({})
  const [error, setError] = useState(false)
  const [disableNext, setDisableNext] = useState(false)
  const [questionBank, setQuestionBank] = useState([])
  const [questions, setQuestions] = useState([])
  const [quizComplete, setQuizComplete] = useState(false)
  const [activeSection, setActiveSection] = useState(0)
  const { moduleId } = useLesson()
  const [highlights, setHighlights] = useState({})
  const [correctHighlights, setCorrectHighlights] = useState([])

  // Only send the progress when the game is complete.
  useEffect(() => {
    if (questions.length && currentQuestion >= questions.length) {
      setDisableNext(true)
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: LEGISLATIVE_INTERPRETATION_GAME,
          uuid: uuid,
          gamePercentScore: ((correctAnswers / questions.length) * 100).toFixed(2),
        },
      }).then(() => {
        setDisableNext(false)
      })
    }
  }, [currentQuestion, questions])

  useEffect(() => {
    if (uuid) {
      axios(`/li-game/${uuid}`)
        .then((r) => {
          setData(r.data)
          setQuestionBank(r.data.questions)
          setQuestions(r.data.questions)
          let newHighlights = {}
          r.data.questions[0].sections.forEach((section) => {
            newHighlights[section['section-name']] = []
          })
          setHighlights(newHighlights)
          setActiveSection(0)
          setLoading(false)
          setCorrectHighlights([])
        })
        .catch(setError)
    }
  }, [uuid])

  const resetQuiz = async () => {
    setQuestions(questionBank)
    setCurrentQuestion(0)
    setCorrectAnswers(0)
    axios(`/li-game/${uuid}`)
      .then((r) => {
        setData(r.data)
        setQuestionBank(r.data.questions)
        setQuestions(r.data.questions)
        let newHighlights = {}
        r.data.questions[0].sections.forEach((section) => {
          newHighlights[section['section-name']] = []
        })
        setHighlights(newHighlights)
        setActiveSection(0)
        setLoading(false)
        setCorrectHighlights([])
      })
      .catch(setError)
  }

  if (loading || error) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }
  const isLastQuestion = currentQuestion === questions.length - 1

  return currentQuestion < questions.length ? (
    <>
    <MobileNotSupported />

    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        },
      })}
    >
      <Box
        sx={{
          height: '520px',
          padding: '20px',
          margin: '20px 0 20px 0',
          maxWidth: '100%',
          minWidth: '60ch',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: questions[currentQuestion].sections.length > 1 ? 'space-between' : 'center', marginBottom: '20px' }}>
          {questions[currentQuestion].sections.length > 1 && (
            <ArrowBackIosNewIcon
              sx={{ cursor: 'pointer', color: activeSection === 0 ? 'grey' : 'black', cursor: activeSection === 0 ? 'not-allowed' : 'pointer' }}
              onClick={() => {
                setActiveSection(Math.max(activeSection - 1, 0))
              }}
            />
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center' }}>
            <Typography component="div" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
              {questions[currentQuestion].sections[activeSection]['section-name']}
            </Typography>
            {questions[currentQuestion].sections.length > 1 && (
              <Typography component="div" sx={{ fontSize: '0.6rem' }}>
                {activeSection + 1} of {questions[currentQuestion].sections.length}
              </Typography>
            )}
          </Box>
          {questions[currentQuestion].sections.length > 1 && (
            <ArrowForwardIosIcon
              sx={{
                cursor: 'pointer',
                color: activeSection === questions[currentQuestion].sections.length - 1 ? 'grey' : 'black',
                cursor: activeSection === questions[currentQuestion].sections.length - 1 ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                setActiveSection(Math.min(activeSection + 1, questions[currentQuestion].sections.length - 1))
              }}
            />
          )}
        </Box>

        <Card
          sx={{
            height: '450px',
            overflow: 'auto', // Add this line to make the card scrollable
          }}
        >
          <Typography component="div" sx={{ fontSize: '0.9rem', pointerEvents: questions[currentQuestion].type === 'MCQ' ? 'none' : 'auto' }}>
            <HighlightableContent
              content={questions[currentQuestion].sections[activeSection]['section-text']}
              setHighlights={setHighlights}
              activeHighlightName={questions[currentQuestion].sections[activeSection]['section-name']}
              correctHighlights={correctHighlights}
              highlights={highlights}
              maxHighlightLength={(questions[currentQuestion].sections[activeSection]['max-highlight-length'] || 200) * 1.5}
            />
          </Typography>
        </Card>
      </Box>
      <Box sx={{ padding: '20px', margin: '20px 5px 20px 5px', maxWidth: '100%', minWidth: '60ch' }}>
        <Question
          questionNumber={currentQuestion + 1}
          totalQuestions={questions.length}
          quizId={uuid}
          key={`question-${questions[currentQuestion].uuid}`}
          questionId={questions[currentQuestion].uuid}
          userAnswerHighlighteds={highlights}
          type={questions[currentQuestion].type}
          next={(wasCorrect) => {
            wasCorrect && setCorrectAnswers(correctAnswers + 1)
            setCurrentQuestion(currentQuestion + 1)
            setHighlights(questions[Math.min(currentQuestion + 1, questions.length - 1)].sections.map((section) => ({ [section['section-name']]: [] })))
            setActiveSection(0)
            setCorrectHighlights([])
          }}
          setCorrectHighlights={setCorrectHighlights}
          isLastQuestion={isLastQuestion}
        />
      </Box>
    </Box>
    </>
  ) : (
    <Summary disableNext={disableNext} next={next} resetQuiz={resetQuiz} correctAnswers={correctAnswers} totalQuestions={questions.length} />
  )
}

export default LegislativeInterpretationGame
