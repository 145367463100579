import React, { useState } from 'react'
import { Box, CircularProgress, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { ArrowUpward } from '@mui/icons-material'
import { ArrowDownward } from '@mui/icons-material'
import { getAllAccountIds, ASSETS, CAPITAL, EXPENSES, LIABILITIES, EQUITY, REVENUE, SOLE_TRADER, SALES, SUB_ACCOUNT, GROUP } from './definitions'
import { useBalance } from './balance-context'
import { toAccountingNumber } from './index'

const Balance = ({ definitionsType, symbol, feedback }) => {
  const { accountDefinitions, getBothBalanceSheets, userMistakes } = useBalance()
  const r = getBothBalanceSheets()
  if (!accountDefinitions) {
    return <CircularProgress />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '20px',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={(theme) => ({
          background: '#E6F5F9',
          display: 'flex',
          flexDirection: 'row',
          padding: '40px',
          width: '700px',
          maxHeight: '60vh',
          overflowY: 'auto',
          [theme.breakpoints.down('xl')]: {
            width: '100%',
          },
        })}
      >
        <Box sx={{ borderRight: '1px solid #000000', width: '50%', paddingRight: '40px' }}>
          <Typography variant="h4">Statement of profit or loss</Typography>
          <ProfitLoss accounts={r.userAccounts} mistakes={userMistakes} />
        </Box>
        <Box sx={{ paddingLeft: '40px', width: '50%' }}>
          <Typography variant="h4">Statement of financial position</Typography>
          <Table padding="none">
            <TableBody>
              <Account accountId={ASSETS} data={r.userAccounts} mistakes={userMistakes} />
              {[LIABILITIES, definitionsType === SOLE_TRADER ? CAPITAL : EQUITY].map((accountId) => (
                <Account key={accountId} accountId={accountId} mistakes={userMistakes} data={r.userAccounts} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  )
}

const Account = ({ accountId, data, mistakes, symbol }) => {
  const { accountDefinitions } = useBalance()
  const { color, label, groups, subAccounts } = accountDefinitions[accountId]
    return (
    <>
      <TableRow>
        <TableCell
          sx={{
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '0',
            paddingRight: '0',
            borderBottom: '1px solid rgb(224, 224, 224)',
          }}
        >
          <Typography
            sx={{
              backgroundColor: color,
              display: 'inline-block',
              color: '#3A4950',
              borderRadius: '5px',
              fontWeight: 'bold',
              padding: '5px 10px',
              textTransform: 'capitalize',
            }}
          >
            {label}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '0',
            paddingRight: '0',
            borderBottom: '1px solid rgb(224, 224, 224)',
          }}
        >
          {symbol}
        </TableCell>
      </TableRow>
      {groups
        ? groups.map((groupId) => <Group key={groupId} id={groupId} data={data} mistakes={mistakes} />)
        : subAccounts.map((accountId) => <SubAccount key={accountId} id={accountId} data={data} mistakes={mistakes} />)}
    </>
  )
}

const Group = ({ data, id, mistakes }) => {
  const { accountDefinitions } = useBalance()
  const group = accountDefinitions[id]

  return (
    <>
      <TableRow>
        <TableCell
          sx={{
            padding: '0',
            borderBottom: '1px solid rgb(224, 224, 224)',
          }}
        >
          <Tooltip placement="right" title={mistakes[id] && mistakes[id].length ? mistakes[id].slice(0, -1).join('\n') : ''}>
            <Typography variant="h4" sx={{ width: '100%' }}>
              {group.label}:
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell
          sx={{
            padding: '0',
            borderBottom: '1px solid rgb(224, 224, 224)',
          }}
        >
          {
            //toAccountingNumber(group.subAccounts.reduce((acc, subAccount) => acc + (data[subAccount] || 0), 0))}
          }
        </TableCell>
      </TableRow>
      {group.subAccounts.map((subAccount) => (
        <SubAccount data={data} key={subAccount} id={subAccount} mistakes={mistakes} />
      ))}
    </>
  )
}

const SubAccount = ({ data, id, mistakes }) => {
  const { accountDefinitions } = useBalance()
  if (accountDefinitions[id].label === 'Total Assets') {
    let currentAssets = 0
    let nonCurrentAssets = 0
    currentAssets = accountDefinitions['CURRENT_ASSETS'].subAccounts.reduce((acc, subAccount) => {
      return acc + (mistakes[subAccount] && mistakes[subAccount].length > 1 ? mistakes[subAccount][mistakes[subAccount].length-1] : data[subAccount] ? data[subAccount] : 0)
    }, 0)
    nonCurrentAssets = accountDefinitions['NON_CURRENT_ASSETS'].subAccounts.reduce((acc, subAccount) => {
      return acc + (mistakes[subAccount] && mistakes[subAccount].length > 1 ? mistakes[subAccount][mistakes[subAccount].length-1] : data[subAccount] ? data[subAccount] : 0)
    }, 0)
        let totalAssets = currentAssets + nonCurrentAssets
    return (
      <TableRow>
        <TableCell
          sx={{
            padding: '0',
            borderBottom: '1px solid rgb(224, 224, 224)',
          }}
        >
          <Typography
            sx={{
              color: totalAssets ? 'green' : null,
              fontWeight: totalAssets ? 'bold' : null,
              width: '100%',
            }}
          >
            {accountDefinitions[id].label}:
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            padding: '0',
            borderBottom: '1px solid rgb(224, 224, 224)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          {totalAssets > 0 ? <ArrowUpward sx={{ color: 'green' }} /> : null}
          {totalAssets < 0 ? <ArrowDownward sx={{ color: 'green' }} /> : null}

          <Typography component="span" sx={{ alignSelf: 'flex-end', marginLeft: 'auto', color: totalAssets ? 'green' : null, fontWeight: totalAssets ? 'bold' : null }}>
            {toAccountingNumber(totalAssets)}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }
  return (
    <TableRow>
      <TableCell
        sx={{
          padding: '0',
          borderBottom: '1px solid rgb(224, 224, 224)',
        }}
      >
        <Tooltip placement="right" title={mistakes[id] ? mistakes[id].slice(0, -1).join('\n') : ''}>
          <Typography
            sx={{
              color: (mistakes[id] && mistakes[id].length) || data[id] ? 'green' : null,
              fontWeight: (mistakes[id] && mistakes[id].length) || data[id] ? 'bold' : null,
              width: '100%',
            }}
          >
            {accountDefinitions[id].label}:
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell
        sx={{
          padding: '0',
          borderBottom: '1px solid rgb(224, 224, 224)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}
      >
        {(mistakes[id] && mistakes[id].length && mistakes[id][mistakes[id].length-1] > 0) || (data[id] && data[id] > 0) ? (
          <ArrowUpward sx={{ color: 'green' }} />
        ) : (mistakes[id] && mistakes[id].length && mistakes[id][mistakes[id].length-1] < 0) || (data[id] && data[id] < 0) ? (
          <ArrowDownward sx={{ color: 'green' }} />
        ) : null}

        <Typography
          component="span"
          sx={{ alignSelf: 'flex-end', marginLeft: 'auto', color: mistakes[id] || data[id] ? 'green' : null, fontWeight: mistakes[id] || data[id] ? 'bold' : null }}
        >
          {mistakes[id] && mistakes[id].length ? toAccountingNumber(mistakes[id][mistakes[id].length-1]) : toAccountingNumber(data[id] || 0)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

const ProfitLoss = ({ accounts, mistakes }) => (
  <Table padding="none">
    <TableBody>
      {[REVENUE, EXPENSES].map((accountId) => (
        <Account key={accountId} accountId={accountId} mistakes={mistakes} data={accounts} />
      ))}
    </TableBody>
  </Table>
)

export default Balance
