import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import PointAward from '../common-components/custom-snackbars/point-award'

export const PointsContext = React.createContext(null)

export const PointsProvider = ({ children }) => {
  const [points, setPoints] = useState(0)
  const [perModule, setPerModule] = useState({})
  const [loading, setLoading] = useState(true)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (loading) {
      axios(`/points/in-detail`).then((r) => {
        setPoints(r.data.total)
        setPerModule(r.data.perModule)
        setLoading(false)
      })
    }
  }, [loading])

  function awardPoints(value) {
    enqueueSnackbar(
      {
        value: value,
      },
      {
        disableWindowBlurListener: false,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        content: (key, message) => <PointAward id={key} message={message} />,
      }
    )
  }

  return <PointsContext.Provider value={{ points, perModule, awardPoints, setPoints }}>{children}</PointsContext.Provider>
}

export function usePoints() {
  return React.useContext(PointsContext)
}
