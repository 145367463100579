import { Button, Container, Box, Typography } from '@mui/material'
import React, { useState } from 'react'

// import Import from './import'
import Groups from './groups'
import ManageUsers from './users/manage'
import ManageModules from './modules/index'
import { useSearchParams } from 'react-router-dom'
import ManageDynamicSessions from './dynamic_sessions'
import UploadShareableContentLink from './share-link/index'
import ManageAssessments from './assessments'

const Admin = (props) => {
  const [tabIndex, setTabIndex] = useState(0)

  const [queryParams] = useSearchParams()

  if (queryParams.get('tab') !== null) {
    setTabIndex(parseInt(queryParams.get('tab')))
    queryParams.delete('tab')
  }

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        Admin
      </Typography>
      <Box sx={{ background: '#ffffff', padding: 5 }}>
        <Box sx={{ borderBottom: '2px solid #F2F6F7' }}>
          <Button onClick={() => setTabIndex(0)} disabled={tabIndex === 0}>
            Manage Groups
          </Button>
          <Button onClick={() => setTabIndex(1)} disabled={tabIndex === 1}>
            Manage Users
          </Button>
          <Button onClick={() => setTabIndex(2)} disabled={tabIndex === 2}>
            Manage Modules
          </Button>
          <Button onClick={() => setTabIndex(3)} disabled={tabIndex === 3}>
            Manage Dynamic Sessions
          </Button>
          <Button onClick={() => setTabIndex(4)} disabled={tabIndex === 4}>
            Assessments
          </Button>
          <Button onClick={() => setTabIndex(5)} disabled={tabIndex === 5}>
            Upload Shareable Content Link
          </Button>
        </Box>
        {tabIndex === 0 && <Groups />}
        {tabIndex === 1 && <ManageUsers />}
        {tabIndex === 2 && <ManageModules />}
        {tabIndex === 3 && <ManageDynamicSessions />}
        {tabIndex === 4 && <ManageAssessments />}
        {tabIndex === 5 && <UploadShareableContentLink />}
      </Box>
    </Container>
  )
}

export default Admin
