import { Alert, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormLabel, Radio, RadioGroup,TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import axios from 'axios'

import GroupSelect from './group-select'

const CreateUser = ({ groupId, onAdd }) => {
  const [ groupUuid, setGroupUuid ] = useState(groupId || '')
  
  const [ email, setEmail ] = useState('')
  const [ name, setName ] = useState('')
  const [ username, setUsername ] = useState('')

  const [ ssoStep, setSsoStep ] = useState(false)
  const [ isSSO, setIsSSO ] = useState(null)

  const [ createUser, setCreteUser ] = useState(false)

  useEffect(() => {
    if(createUser) {
      axios({
        url: '/user/create',
        method: 'post',
        data: { email, groupUuid, isSSO, name, username }
      }).then(r => {
        onAdd({
          name,
          email,
          isSSO,
          username : isSSO ? undefined : username
        })
      })
    }
  },[createUser])

  return (
    <Box sx={{
      background: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      padding: '40px',
      minWidth: '600px'
    }}>
      {ssoStep ? 
      <SSO
        create={() => setCreteUser(true)}
        creating={createUser}
        isSSO={isSSO}
        setIsSSO={setIsSSO}
        username={username}
        setUsername={setUsername}
      /> :
      <BasicData
        email={email}
        groupUuid={groupUuid}
        name={name}
        nextStep={() => setSsoStep(true)}
        setEmail={(email) => {
          setEmail(email)
          setUsername((email || '').split('@')[0].replace(/[^a-zA-Z]/g,''))
        }}
        setName={setName}
        setGroupUuid={setGroupUuid}
      />}
    </Box>
  )
}

const BasicData = ({email, groupUuid, name, nextStep, setEmail, setGroupUuid, setName}) => {
  const [ checkingEmail, setCheckingEmail ] = useState(false)
  const [ emailExists, setEmailExists ] = useState(null)

  useEffect(() => {
    if(emailExists === null && checkingEmail) {
      axios({
        method: 'post',
        url: '/user/find',
        data: { email }
      }).then(r => {
        if(r.data.length) {
          setEmailExists(true)
        } else {
          nextStep()
        }
      }).catch(e => {
        if(e.response && e.response.status === 404) {
          nextStep()
        }
      })
    }
  }, [checkingEmail, emailExists])

  let disabled = true
  if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) && name.length && groupUuid !== '') {
    disabled = false
  }
  return <>
    <TextField
      sx={{marginTop: '20px'}} 
      label="Email"
      variant="outlined"
      name="email"
      value={email}
      onChange={(e) => {
        setCheckingEmail(false)
        setEmailExists(null)
        setEmail(e.target.value.replace(/\s/g,''))
      }}
      />
    <TextField
      sx={{marginTop: '20px'}} 
      label="Name"
      variant="outlined"
      name="name"
      value={name}
      onChange={(e) => setName(e.target.value)}
    />
    <GroupSelect initialUuid={groupUuid} onChange={setGroupUuid} />
    {emailExists && <Alert severity="error">A user with the email {email} already exists! Please either edit the existing user or change to a different email.</Alert>}
    <Button disabled={disabled || emailExists} onClick={() => {setCheckingEmail(true)}}>Next</Button>
  </>
}

export const SSO = ({create, creating, isSSO, setIsSSO, username, setUsername}) => {
  let disabled = true
  if(isSSO || (isSSO === false  && username)) {
    disabled = false
  }
  return (
    <>
      <FormLabel id="SSO-label">Is this an SSO user?</FormLabel>
      <RadioGroup aria-labelledby="SSO-label">
        <FormControlLabel selected={isSSO === true} onChange={() => setIsSSO(true)} value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel selected={isSSO === false} onChange={() => setIsSSO(false)} value="no" control={<Radio />} label="No" />
      </RadioGroup>
      {isSSO === false ?<TextField
        sx={{marginTop: '20px'}} 
        label="Username"
        variant="outlined"
        name="username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      /> : null}
      <Button disabled={disabled || creating} onClick={create}>
        {creating && <CircularProgress />}
        Create
      </Button>
    </>
  )
}

export default CreateUser