export const COMPANY = 'COMPANY'
export const SOLE_TRADER = 'SOLE_TRADER'

export const CREDIT = 'CREDIT'
export const DEBIT = 'DEBIT'
// ACCOUNT IDS
export const ASSETS = 'ASSETS'
export const EXPENSES = 'EXPENSES'
export const LIABILITIES = 'LIABILITIES'
export const EQUITY = 'EQUITY'
export const REVENUE = 'REVENUE'
export const CAPITAL = 'CAPITAL'
export const SALES = 'SALES'

export const SUB_ACCOUNT = 'SUB_ACCOUNT'
export const GROUP = 'GROUP'

export const ACTIONS = {
  [DEBIT]: {
    label: 'Debit',
  },
  [CREDIT]: {
    label: 'Credit',
  },
}

export const REL_MAP = {
  [DEBIT]: {
    [ASSETS]: 1,
    [SALES]: 1,
    [EXPENSES]: 1,
    [LIABILITIES]: -1,
    [EQUITY]: -1,
    [CAPITAL]: -1,
    [REVENUE]: -1,
  },
  [CREDIT]: {
    [ASSETS]: -1,
    [SALES]: -1,
    [EXPENSES]: -1,
    [LIABILITIES]: 1,
    [EQUITY]: 1,
    [CAPITAL]: 1,
    [REVENUE]: 1,
  },
}

// add up multiple transactions sets (per account)
export function addUpTransactions(accountDefinitions, transactions) {
  return transactions.reduce((acc, transaction) => {
    Object.entries(transaction).forEach(([accountId, values]) => {
      if (!acc[accountId]) {
        acc[accountId] = 0
      }
      Object.entries(values).forEach(([action, value]) => {
        acc[accountId] += value * REL_MAP[action][accountDefinitions[accountId].account ? accountDefinitions[accountId].account : accountId]
      })
    })
    return acc
  }, {})
}

// get all the account keys for a top level account
export function getAllAccountIds(accountDefinitions, account) {
  return [account, ...accountDefinitions[account].subAccounts, ...(accountDefinitions[account].groups || [])]
}

function calcualteAccountTotal(accountDefinitions, accountId, accounts) {
  return getAllAccountIds(accountDefinitions, accountId).reduce((acc, id) => {
    if (accounts[id]) {
      acc += accounts[id]
    }
    return acc
  }, 0)
}

export function calculateAccountEntryValue(action, relation, value) {
  return REL_MAP[action][relation] ? value * REL_MAP[action][relation] : value
}

// calculate each line in the balance sheet based on individual account values
export const calculateBalanceSheet = (accountDefinitions, accounts) => {
  // const topValue = calcualteAccountTotal(accountDefinitions, ASSETS, accounts)
  // const bottomValue = calcualteAccountTotal(accountDefinitions, LIABILITIES, accounts) +
  // calcualteAccountTotal(accountDefinitions, EQUITY, accounts) +
  // calcualteAccountTotal(accountDefinitions, REVENUE, accounts) -
  // calcualteAccountTotal(accountDefinitions, EXPENSES, accounts)

  return { bottomValue: 0, topValue: 0 }

  // ASSETS === LIABILITIES + EQUITY + (REVENUE - EXPENSES)
}
