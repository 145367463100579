import { Box, Button, CircularProgress, LinearProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DropTarget from './drop-target'
import DragItem from './drag-item'
import Done from './done'
import MobileNotSupported from '../components/MobileNotSupported'
import { useLesson } from '../lesson/context'

export const PARAGRAPHS_GAME = 'ParagraphsGame'
const PARAGRAPHS_TARGET = 'ParagraphsTarget'

const ParagraphsGame = ({ next, uuid }) => {
  const [instances, setInstances] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [answers, setAnswers] = useState({})
  const [disableNext, setDisableNext] = useState(false)
  const [description, setDescription] = useState(null)

  const { moduleId } = useLesson()

  useEffect(() => {
    if (!loading && currentIndex >= instances.length) {
      setDisableNext(true)
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: PARAGRAPHS_GAME,
          uuid: uuid,
        },
      }).then(() => {
        setDisableNext(false)
      })
    }
  }, [loading, currentIndex, instances])

  useEffect(() => {
    if (loading) {
      axios(`/paragraphs/${uuid}`)
        .then((r) => {
          setInstances(r.data.paragraphs)
          setDescription(r.data.description)
          setLoading(false)
        })
        .catch((e) => setLoading(false))
    }
  }, [loading, uuid])

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '1000px',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (currentIndex >= instances.length) {
    return <Done disableNext={disableNext} answers={answers} next={next} reset={() => setCurrentIndex(0)} />
  }

  return (
    <Paragraphs
      description={description}
      key={instances[currentIndex]}
      gameId={uuid}
      uuid={instances[currentIndex]}
      instanceNumber={currentIndex + 1}
      totalInstances={instances.length}
      next={(instanceAnswers) => {
        setAnswers({
          ...answers,
          ...instanceAnswers,
        })
        setCurrentIndex(currentIndex + 1)
      }}
    />
  )
}

// TODO: Support clicks, not just drag 'n' drop for accessiblity
// - must be ble to tab through targets & select one
// - must be able to tab through answers & select one
// Regular flow other than that

// TODO: This entire thing sucks. It's hard to read and hard to follow, and complex. It's very likely to fail. Rebuild it properly
export default ParagraphsGame

const Paragraphs = ({ description, gameId, next, uuid, instanceNumber, totalInstances }) => {
  const [dragging, setDragging] = useState(false)
  const [checking, setChecking] = useState(false)

  const [elements, setElements] = useState([])
  const [feedback, setFeedback] = useState('')
  const [items, setItems] = useState([])
  const [pairs, setPairs] = useState({})
  const [answers, setAnswers] = useState(null)

  const { moduleId } = useLesson()

  const showCheckButton = !answers && Object.keys(pairs).length === elements.filter((el) => el.type === PARAGRAPHS_TARGET).length

  useEffect(() => {
    if (uuid) {
      axios(`/paragraphs/instance/${uuid}`).then((r) => {
        setItems(r.data.items)
        setElements(r.data.elements)
      })
    }
  }, [uuid])

  useEffect(() => {
    if (checking) {
      axios({
        url: `/paragraphs/${gameId}/instance/${uuid}/check`,
        method: 'post',
        data: {
          moduleId,
          pairs,
        },
      }).then((r) => {
        setAnswers(r.data.answers)
        setFeedback(r.data.feedback)
        setChecking(false)
      })
    }
  }, [checking])

  const onDrop = (target, item) => {
    setPairs({
      ...pairs,
      [target]: item,
    })
  }

  const lines = elements.reduce(
    (lines, el) => {
      if (el.type === 'ParagraphsText') {
        const split = el.text.split(/\n/)
        if (split.length > 1) {
          split.forEach((text, index) => {
            if (index === 0) {
              lines[lines.length - 1].push(text)
            } else {
              lines.push([text])
            }
          })
        } else {
          lines[lines.length - 1].push(el.text)
        }
      } else {
        lines[lines.length - 1].push(el)
      }
      return lines
    },
    [[]]
  )

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <MobileNotSupported />
      <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', width: '1000px' }}>
        <Typography sx={{ alignSelf: 'flex-start', textAlign: 'left' }}>
          <Typography component="span" sx={{ margin: '5px', fontWeight: 'bold' }}>
            {instanceNumber}
          </Typography>
          of
          <Typography component="span" sx={{ margin: '5px', fontWeight: 'bold' }}>
            {totalInstances}
          </Typography>
        </Typography>
        <LinearProgress color={'greenTint'} variant="determinate" value={(instanceNumber / totalInstances) * 100} />
        {description && <Description text={description} />}
        {lines.map((elements, index) => {
          return (
            <Typography sx={{ lineHeight: '50px' }} key={`line-${index}`}>
              {elements.map((el) => {
                if (el.type) {
                  const droppedItem = pairs[el.uuid] && items.find((i) => i.uuid === pairs[el.uuid])

                  if (answers && answers[el.uuid]) {
                    const { isCorrect } = answers[el.uuid]
                    const background = isCorrect ? '#5EC2A610' : '#EB464C10'
                    const border = isCorrect ? '2px solid #5EC2A6' : '2px solid #EB464C'
                    return (
                      <Box
                        key={el.uuid}
                        component="span" // for valid HTML
                        sx={{
                          background,
                          border,
                          borderRadius: '10px',
                          display: 'inline-block',
                          lineHeight: '50px',
                          padding: '0 10px',
                          minWidth: '100px',
                        }}
                      >
                        {droppedItem.text}
                        {isCorrect ? (
                          <CheckCircleIcon color="greenTint" sx={{ marginLeft: '20px', verticalAlign: 'middle' }} />
                        ) : (
                          <CancelIcon color="error" sx={{ marginLeft: '20px', verticalAlign: 'middle' }} />
                        )}
                      </Box>
                    )
                  }

                  return (
                    <DropTarget
                      key={el.uuid}
                      checking={checking}
                      dragging={dragging}
                      setDragging={setDragging}
                      droppedItem={droppedItem}
                      onDrop={(uuid) => {
                        onDrop(uuid, dragging)
                      }}
                      uuid={el.uuid}
                    />
                  )
                }
                return el
              })}
            </Typography>
          )
        })}
        <Box
          sx={{
            background: '#F2F6F7',
            borderRadius: '5px',
            padding: '20px',
            marginTop: '20px',
          }}
        >
          {answers ? (
            <CorrectSentence answers={answers} elements={elements} items={items} />
          ) : (
            <ItemsList checking={checking} items={items} pairs={pairs} setDragging={setDragging} />
          )}
        </Box>
        {answers && feedback && <Typography>{feedback}</Typography>}
        {showCheckButton && (
          <Button variant="contained" sx={{ alignSelf: 'center', marginTop: '20px' }} onClick={() => setChecking(true)}>
            Check
          </Button>
        )}
        {answers && (
          <Button variant="contained" sx={{ alignSelf: 'center', marginTop: '20px' }} onClick={() => next(answers)}>
            Next
          </Button>
        )}
      </Box>
    </Box>
  )
}

const ItemsList = ({ checking, pairs, items, setDragging }) => {
  return items
    .filter((item) => !Object.values(pairs).some((uuid) => item.uuid === uuid))
    .map((item) => (
      <DragItem
        key={item.uuid}
        checking={checking}
        onDragStart={(e) => {
          setDragging(item.uuid)
          e.dataTransfer.dropEffect = 'move'
          e.dataTransfer.setData('uuid', item.uuid)
        }}
        onDragEnd={(e) => setDragging(false)}
        text={item.text}
        uuid={item.uuid}
      />
    ))
}

const CorrectSentence = ({ answers, elements, items }) => (
  <>
    <Typography sx={{ fontWeight: 'bold' }}>Correct Solution:</Typography>
    {elements
      .map((element) => {
        if (element.type === 'ParagraphsTarget') {
          return items.find((item) => item.uuid === answers[element.uuid].correctIds[0]).text
        } else {
          return element.text
        }
      })
      .join('')
      .split(/\n/)
      .map((text, index) => (
        <Typography key={`correct-text-line-${index}`}>{text}</Typography>
      ))}
  </>
)

const Description = ({ text }) => {
  return (
    <Box sx={{ backgroundColor: '#F2F6F7', padding: '20px', marginBottom: '20px' }}>
      {text.split(/\n/).map((line, index) => (
        <Typography key={`description-line-${index}`}>{line}</Typography>
      ))}
    </Box>
  )
}
