import React, { useState, useEffect } from 'react'
import UpsertQuestion from '../question/upsert'
import { Button, Typography } from '@mui/material'

const UpsertQuestions = (props) => {
  const [questions, setQuestions] = useState(props.questions)
  const [newQuestion, setNewQuestion] = useState(false)

  return (
    <div className="questions-wrapper">
      <Typography>Questions ({questions.length})</Typography>
      {questions.map((q, i) => (
        <UpsertQuestion
          key={`question-${q.uuid}`}
          {...q}
          onDelete={(uuid) =>
            setQuestions(questions.filter((q) => q.uuid !== uuid))
          }
          onSave={() => {}}
        />
      ))}
      {newQuestion ? (
        <UpsertQuestion
          key={`question-new`}
          {...newQuestion}
          onSave={(q) => {
            props.linkQuestion(q.uuid)
            setQuestions([...questions, q])
            setNewQuestion(false)
          }}
          onDelete={() => {
            setNewQuestion(false)
          }}
        />
      ) : null}
      <Button disabled={!!newQuestion} onClick={() => setNewQuestion({})}>
        New question
      </Button>
    </div>
  )
}

export default UpsertQuestions
