import React, { useEffect, useState, useRef } from 'react'
import { Box, Button, Container, MenuItem, Stack, Divider, IconButton, Menu } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

const AssessmentQuestions = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [assessmentQuestions, setAssessmentQuestions] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [assessment, setAssessment] = useState({})
  const { assessmentId } = useParams()
  const columns = [
    { field: 'id', headerName: 'id', headerAlign: 'center', align: 'center', type: 'number', filterable: false },

    { field: 'question_text', headerName: 'Question Text', flex: 4, headerAlign: 'center' },
    { field: 'lesson_name', headerName: 'Lesson Proficiency', flex: 2, headerAlign: 'center' },
    { field: 'question_is_active', headerName: 'Is Active', flex: 1, headerAlign: 'center' },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return <ActionMenu param={param} assessmentId={assessmentId} />
      },
    },
  ]

  const fetchSessionData = async (assessmentId) => {
    setLoading(true)
    const data = await axios.get(`/admin/assessment/${assessmentId}/questions`)
    return data.data
  }

  const getAssessmentData = async (assessmentId) => {
    setLoading(true)
    const data = await axios.get(`/admin/assessment/${assessmentId}`)
    setAssessment(data.data)
  }

  useEffect(() => {
    if (assessmentId) {
      getAssessmentData(assessmentId)
      fetchSessionData(assessmentId)
        .then((data) => {
          let questions = data.map((question, index) => {
            return {
              id: index + 1,
              uuid: question.uuid,
              lesson_name: question.lesson_name,
              question_text: question.text,
              question_is_active: question.is_active,
            }
          })

          setAssessmentQuestions(questions)
          setLoading(false)
        })
        .catch((data) => {
          setLoading(false)
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
          navigate('/admin-dashboard?tab=4')
        })
    } else {
      setLoading(false)
    }
  }, [assessmentId])

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        View Assessment Questions
      </Typography>
      <Typography sx={{ margin: 5 }} variant="h3">
        Assessment: {assessment.name}
      </Typography>
      <Box sx={{ background: '#ffffff', padding: 5, position: 'relative' }}>
        <Link variant="body1" to="/admin-dashboard?tab=4">
          <Button sx={{ padding: '0 10px', marginBottom: 1 }} variant="text">
            <ArrowBackIcon />
            Back To Assessments
          </Button>
        </Link>
        <Box sx={{ width: '100%' }}></Box>
        <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />

        <Stack direction="row" spacing={1} sx={{ margin: 1 }} justifyContent="flex-end" flexWrap="wrap">
          <Link className="top-bar-button" to={`/admin-panel/assessment/${assessmentId}/add-question`}>
            <Button variant="contained">Create New Question</Button>
          </Link>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            onCellClick={() => null}
            onCellDoubleClick={() => null}
            onCellFocusOut={() => null}
            onRowClick={() => null}
            onColumnHeaderClick={() => null}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu={true}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 } },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                quickFilterProps: { debounceMs: 200 },
              },
            }}
            rows={assessmentQuestions}
            columns={columns}
            pageSizeOptions={[10, 20, 50]}
            loading={loading}
            autoHeight={loading}
          />
        </Box>
      </Box>
    </Container>
  )
}

const ActionMenu = ({ param, assessmentId }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        id={`basic-button-${param.row.id}`}
        aria-controls={open ? `basic-menu-${param.row.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label="actions"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`basic-menu-${param.row.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-${param.row.id}`,
        }}
      >
        <Link style={{ textDecoration: 'none' }} to={`/admin-panel/assessment/${assessmentId}/add-question/${param.row.uuid}`}>
          <MenuItem onClick={handleClose}>Edit Question</MenuItem>
        </Link>
      </Menu>
    </div>
  )
}

export default AssessmentQuestions
