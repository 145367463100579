import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
} from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import axios from 'axios'
import theme from '../../theme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSnackbar } from 'notistack'

const LessonState = ({ lesson, moduleId }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [value, setValue] = useState(lesson.isActive)

  let timer = undefined

  const handleChange = async (lesson) => {
    try {
      await axios.post('/lesson/change-module-lesson-state', {
        lesson_uuid: lesson.id,
        module_uuid: moduleId,
        isActive: !value,
      })

      setValue(!value)
      enqueueSnackbar('Updated Successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }
  }

  const debounce = (lesson, timeout = 300) => {
    clearTimeout(timer)
    timer = setTimeout(() => handleChange(lesson), timeout)
  }

  return (
    <FormControlLabel
      control={<Switch onChange={() => debounce(lesson)} checked={value} />}
      label={value ? 'Active' : 'Inactive'}
    />
  )
}

const ActionMenu = ({ param, moduleData, moduleId, updateTable }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteLessonId, setdeleteLessonId] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [addLessonId, setAddLessonId] = useState('')
  const [selectedModuleId, setSelectedModuleId] = useState('')
  const [moduleDialogOpen, setModuleDialogOpen] = useState(false)

  if (moduleId) {
    moduleData = moduleData.filter((module) => module.id !== moduleId)
  }

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDialogOpen = (lessonId) => {
    setdeleteLessonId(lessonId)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setdeleteLessonId('')
    setDialogOpen(false)
  }

  const handleModuleDialogOpen = (lessonId) => {
    setAddLessonId(lessonId)
    setModuleDialogOpen(true)
  }

  const handleSelectChange = (event) => {
    setSelectedModuleId(event.target.value)
  }

  const handleModuleDialogClose = () => {
    setAddLessonId('')
    setSelectedModuleId('')
    setModuleDialogOpen(false)
  }

  const deleteLesson = async (lessonId) => {
    try {
      await axios.post(`/lesson/delete-module-lesson`, { lessonId: lessonId, moduleId: moduleId })
      enqueueSnackbar('Deleted Successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }

    handleDialogClose()
    handleClose()
    updateTable({ id: lessonId, action: 'delete' })
  }

  const addLessonToModule = async (addLessonId) => {
    try {
      await axios.post(`/lesson/add-lesson-to-module`, { lessonId: addLessonId, moduleId: selectedModuleId })
      enqueueSnackbar('Added Successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }

    handleModuleDialogClose()
    handleClose()
  }

  return (
    <div>
      <IconButton
        id={`basic-button-${param.id}`}
        aria-controls={open ? `basic-menu-${param.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label="actions"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`basic-menu-${param.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-${param.id}`,
        }}
      >
        <Link style={{ textDecoration: 'none' }} to={`/admin/lesson/edit-lesson/${param.id}`}>
          <MenuItem onClick={handleClose}>Edit Lesson</MenuItem>
        </Link>
        <MenuItem onClick={() => handleModuleDialogOpen(param.id)}>Add to Module</MenuItem>
        <MenuItem sx={{ color: theme.palette.error.main }} onClick={() => handleDialogOpen(param.id)}>
          Delete Lesson
        </MenuItem>
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => deleteLesson(deleteLessonId)} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={moduleDialogOpen}
        onClose={handleModuleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box>
            <DialogContentText id="alert-dialog-description">Add To Module</DialogContentText>
            <br />
            <FormControl fullWidth>
              <InputLabel id="module-filter">Select Module</InputLabel>
              <Select
                defaultValue=""
                labelId="add-module-filter-label"
                id="add-module-filter"
                value={selectedModuleId}
                onChange={handleSelectChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                {moduleData.map((module) => (
                  <MenuItem value={module.id} key={module.id}>
                    {module.moduleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModuleDialogClose} color="info">
            Cancel
          </Button>
          <Button
            disabled={selectedModuleId ? false : true}
            onClick={() => addLessonToModule(addLessonId)}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const ViewModule = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [moduleData, setModuleData] = useState([])
  const [moduleName, setModuleName] = useState('')

  const { moduleId } = useParams()

  const updateTable = ({ id, action }) => {
    switch (action) {
      case 'delete':
        const updateData = tableData.filter((row) => row.id !== id)
        setTableData(updateData)
        break

      default:
        break
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // styles we need to apply on draggables
      ...draggableStyle,

      display: isDragging ? 'table' : '',
    }
  }

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(tableData, result.source.index, result.destination.index)

    if (result.source.index !== result.destination.index) {
      setLoading(true)

      try {
        await axios.post(`/lesson/change-module-lessons-order`, { lessons: items, moduleId: moduleId })
        enqueueSnackbar('Order changed Successfully!', { variant: 'success' })

        const newItems = items.map((lesson, key) => {
          return {
            key: key + 1,
            id: lesson.id,
            lessonName: lesson.lessonName,
            lessonOrder: lesson.lessonOrder,
            isActive: lesson.isActive,
          }
        })
        setTableData(newItems)
      } catch (e) {
        enqueueSnackbar('Something went wrong!', { variant: 'error' })
      }

      setLoading(false)
    }
  }

  useEffect(() => {
    axios.get(`/lesson/get-module-lessons/${moduleId}`).then((data) => {
      if (data.data.length) {
        setModuleName(data.data[0].module_name)
      }

      const tableData = data.data.map((lesson, key) => {
        return {
          key: key + 1,
          id: lesson.uuid,
          lessonName: lesson.name,
          lessonOrder: lesson.order,
          isActive: lesson.is_active_in_module,
        }
      })

      setTableData(tableData)
      setLoading(false)
    })
  }, [moduleId])

  useEffect(() => {
    axios.get('/module/get-all-modules').then((data) => {
      const responseData = data.data.map((module) => {
        return {
          id: module.uuid,
          moduleName: module.name,
          isActive: module.is_active,
          lessonCount: module.lesson_count,
        }
      })

      setModuleData(responseData)
    })
  }, [])

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        {moduleName}
      </Typography>
      <Box sx={{ background: '#fff', padding: 5 }}>
        <Link to="/admin-dashboard?tab=2">
          <Button sx={{ padding: '0 10px' }} variant="text">
            <ArrowBackIcon />
            Back
          </Button>
        </Link>
        <br />
        <Grid
          container
          direction="row"
          columnSpacing={1}
          sx={{ margin: 1, marginTop: 0, marginLeft: 1 }}
          justifyContent="flex-end"
          alignItems="center"
          flexWrap="wrap"
        >
          <Grid item sx={{ padding: 0 }} xs={2}>
            <Link className="top-bar-button" to={`/admin/lesson/add-lesson-to-module/${moduleId}`}>
              <Button variant="contained">Add Lesson</Button>
            </Link>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%', background: '#ffffff', position: 'relative' }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          {tableData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Sr No.</TableCell>
                    <TableCell align="center">Lesson Name</TableCell>
                    <TableCell align="center">Lesson Status</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {tableData.map((row, index) => (
                          <Draggable key={row.id} draggableId={row.id} index={index}>
                            {(provided, snapshot) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell align="center">{row.key}</TableCell>
                                <TableCell title={row.lessonName} align="left">
                                  {row.lessonName}
                                </TableCell>
                                <TableCell align="center">
                                  <LessonState lesson={row} moduleId={moduleId} />
                                </TableCell>
                                <TableCell align="center">
                                  <ActionMenu
                                    param={row}
                                    moduleId={moduleId}
                                    moduleData={moduleData}
                                    updateTable={updateTable}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          ) : (
            <>
              <Typography sx={{ margin: 1 }} variant="h3" align="center">
                No records found!
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default ViewModule
