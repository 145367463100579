import { Box, List, ListItem, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React from 'react'
import Feedback from './feedback'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
const QuestionsSummary = ({ answers, correctAnswers, questionNumber, question, selectedAnswer, feedback }) => {
  return (
    <Box sx={{ padding: '20px 0', borderBottom: '2px solid #F2F6F7', width: '100%' }}>
      <Typography>
        {typeof questionNumber === 'number' && (
          <Typography
            component="span"
            sx={{ fontWeight: 600 }}>
            Question {questionNumber}:{' '}
          </Typography>
        )}
        {question}
      </Typography>
      <List>
        {answers.map(({ text, uuid }) => (
          <Answer
            key={uuid}
            text={text}
            isSelected={selectedAnswer === uuid}
            isCorrect={correctAnswers.correctIds.some((id) => id === uuid)}
          />
        ))}
      </List>
      <Feedback
        feedback={
          <Typography component="div">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}>
              {feedback}
            </ReactMarkdown>
          </Typography>
        }
        isCorrect={correctAnswers.correctIds.some((id) => id === selectedAnswer)}
      />
    </Box>
  )
}

export const Answer = ({ text, isCorrect, isSelected }) => (
  <ListItem sx={{ bgcolor: isSelected ? 'background.main' : 'transparent' }}>
    <Box sx={{ bgcolor: 'background.main', borderRadius: '50%', height: '24px', marginRight: '10px', width: '24px' }}>
      {isSelected && !isCorrect && <CancelIcon color="error" />}
      {isCorrect && <CheckCircleIcon color="greenTint" />}
    </Box>
    <Typography>{text}</Typography>
  </ListItem>
)

export default QuestionsSummary
