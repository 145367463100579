import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import axios from 'axios'
import theme from '../../theme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useSnackbar } from 'notistack'

const LessonState = ({ lesson }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [value, setValue] = useState(lesson.isActive)

  let timer = undefined

  const handleChange = async (lesson) => {
    try {
      await axios.post('/lesson/change-lesson-state', {
        uuid: lesson.id,
        isActive: !value,
      })

      setValue(!value)
      enqueueSnackbar('Lesson Status Updated Successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }
  }

  const debounce = (lesson, timeout = 300) => {
    clearTimeout(timer)
    timer = setTimeout(() => handleChange(lesson), timeout)
  }

  return (
    <FormControlLabel
      control={<Switch onChange={() => debounce(lesson)} checked={value} />}
      label={value ? 'Active' : 'Inactive'}
    />
  )
}

const ActionMenu = ({ param, moduleData, defaultSelectedModuleId, updateTable }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteLessonId, setdeleteLessonId] = useState('')
  const [addLessonId, setAddLessonId] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedModuleId, setSelectedModuleId] = useState('')
  const [moduleDialogOpen, setModuleDialogOpen] = useState(false)

  if (defaultSelectedModuleId) {
    moduleData = moduleData.filter((module) => module.id !== defaultSelectedModuleId)
  }

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDialogOpen = (lessonId) => {
    setdeleteLessonId(lessonId)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setdeleteLessonId('')
    setDialogOpen(false)
  }

  const handleModuleDialogOpen = (lessonId) => {
    setAddLessonId(lessonId)
    setModuleDialogOpen(true)
  }

  const handleModuleDialogClose = () => {
    setAddLessonId('')
    setSelectedModuleId('')
    setModuleDialogOpen(false)
  }

  const handleSelectChange = (event) => {
    setSelectedModuleId(event.target.value)
  }

  const deleteLesson = async (lessonId) => {
    try {
      await axios.post(`/lesson/delete-lesson/${lessonId}`)
      enqueueSnackbar('Lesson Deleted Successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }

    handleDialogClose()
    handleClose()
    updateTable({ id: lessonId, action: 'delete' })
  }

  const addLessonToModule = async (addLessonId) => {
    try {
      let response = await axios.post(`/lesson/add-lesson-to-module`, {
        lessonId: addLessonId,
        moduleId: selectedModuleId,
      })
      response = response.data

      let snackbarType = 'success'
      let snackbarMessage = 'Lesson added Successfully!'

      if (Object.keys(response).length > 0 && response.hasOwnProperty('message')) {
        snackbarType = 'warning'
        snackbarMessage = response.message
      }

      enqueueSnackbar(snackbarMessage, { variant: snackbarType })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }

    handleModuleDialogClose()
    handleClose()
  }

  return (
    <div>
      <IconButton
        id={`basic-button-${param.row.id}`}
        aria-controls={open ? `basic-menu-${param.row.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label="actions"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`basic-menu-${param.row.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-${param.row.id}`,
        }}
      >
        <Link style={{ textDecoration: 'none' }} to={`/admin/lesson/edit-lesson/${param.row.id}`}>
          <MenuItem onClick={handleClose}>Edit Lesson</MenuItem>
        </Link>
        <MenuItem onClick={() => handleModuleDialogOpen(param.row.id)}>Add to Module</MenuItem>
        <MenuItem sx={{ color: theme.palette.error.main }} onClick={() => handleDialogOpen(param.row.id)}>
          Delete Lesson
        </MenuItem>
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => deleteLesson(deleteLessonId)} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={moduleDialogOpen}
        onClose={handleModuleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box>
            <DialogContentText id="alert-dialog-description">Add To Module</DialogContentText>
            <br />
            <FormControl fullWidth>
              <InputLabel id="module-filter">Select Module</InputLabel>
              <Select
                defaultValue=""
                labelId="add-module-filter-label"
                id="add-module-filter"
                value={selectedModuleId}
                onChange={handleSelectChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                {moduleData.map((module) => (
                  <MenuItem value={module.id} key={module.id}>
                    {module.moduleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModuleDialogClose} color="info">
            Cancel
          </Button>
          <Button
            disabled={selectedModuleId ? false : true}
            onClick={() => addLessonToModule(addLessonId)}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const AllLessons = () => {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)
  const [moduleData, setModuleData] = useState([])
  const [selectedModuleId, setSelectedModuleId] = useState('')

  const columns = [
    { field: 'key', headerName: 'Sr No.', headerAlign: 'center', align: 'center', type: 'number', filterable: false },
    { field: 'lessonName', headerName: 'Lesson Name', flex: 1, headerAlign: 'center' },
    {
      field: 'isActive',
      headerName: 'Lesson Status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return <LessonState lesson={param.row} />
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return (
          <ActionMenu
            param={param}
            moduleData={moduleData}
            defaultSelectedModuleId={selectedModuleId}
            updateTable={updateTable}
          />
        )
      },
    },
  ]

  const handleSelectChange = (event) => {
    setSelectedModuleId(event.target.value)
  }

  const updateTable = ({ id, action }) => {
    switch (action) {
      case 'delete':
        const updateData = tableData.filter((row) => row.id !== id)
        setTableData(updateData)
        break

      default:
        break
    }
  }

  useEffect(() => {
    setLoading(true)

    let url = '/lesson/all-lessons'

    if (selectedModuleId !== '') {
      url = `/lesson/get-module-lessons/${selectedModuleId}`
    }

    axios.get(url).then((data) => {
      const tableData = data.data.map((lesson, key) => {
        return {
          key: key + 1,
          id: lesson.uuid,
          lessonName: lesson.name,
          isActive: lesson.is_active,
        }
      })

      setLoading(false)
      setTableData(tableData)
    })
  }, [selectedModuleId])

  useEffect(() => {
    axios.get('/module/get-all-modules').then((data) => {
      const responseData = data.data.map((module) => {
        return {
          id: module.uuid,
          moduleName: module.name,
          isActive: module.is_active,
          lessonCount: module.lesson_count,
        }
      })

      setModuleData(responseData)
    })
  }, [])

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        All Lessons
      </Typography>
      <Box sx={{ background: '#fff', padding: 5 }}>
        <Link to="/admin-dashboard?tab=2">
          <Button sx={{ padding: '0 10px' }} variant="text">
            <ArrowBackIcon />
            Back
          </Button>
        </Link>
        <br />
        <Grid
          container
          direction="row"
          columnSpacing={1}
          sx={{ margin: 1, marginTop: 0, marginLeft: 1 }}
          justifyContent="flex-end"
          alignItems="center"
          flexWrap="wrap"
        >
          <Grid item sx={{ padding: 0 }} xs={3}>
            <FormControl fullWidth>
              <InputLabel id="module-filter">Filter By Module</InputLabel>
              <Select
                defaultValue=""
                labelId="module-filter-label"
                id="module-filter"
                value={selectedModuleId}
                onChange={handleSelectChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {moduleData.map((module) => (
                  <MenuItem value={module.id} key={module.id}>
                    {module.moduleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ padding: 0 }} xs={2}>
            <Link className="top-bar-button" to="/admin/lesson/add-lesson">
              <Button variant="contained">Add Lesson</Button>
            </Link>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            onCellClick={() => null}
            onCellDoubleClick={() => null}
            onCellFocusOut={() => null}
            onRowClick={() => null}
            onColumnHeaderClick={() => null}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu={true}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                quickFilterProps: { debounceMs: 200 },
              },
            }}
            rows={tableData}
            columns={columns}
            pageSizeOptions={[10, 25, 100]}
            loading={loading}
            autoHeight={loading}
          />
        </Box>
      </Box>
    </Container>
  )
}

export default AllLessons
