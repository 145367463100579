import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import BadgeAward from '../common-components/custom-snackbars/badge-award'

export const BadgesContext = React.createContext(null)

export const BadgesProvider = ({ children }) => {
  const [badges, setBadges] = useState({})
  const [userBadgeIds, setUserBadgeIds] = useState([])
  const [loading, setLoading] = useState(true)
  const [awarding, setAwarding] = useState(null)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (loading) {
      Promise.all([axios('/badge'), axios('/badge/user')]).then(([allBadges, userBadges]) => {
        setBadges(
          allBadges.data.reduce((acc, badge) => {
            acc[badge.badgeId] = badge
            return acc
          }, {})
        )
        setUserBadgeIds(userBadges.data.map(({ badgeId }) => badgeId))
        setLoading(false)
      })
    }
  }, [loading])

  useEffect(() => {
    if (awarding) {
      axios({
        url: `/badge/award`,
        method: 'post',
        data: { badgeId: awarding },
      }).then((r) => {
        const { name, description, imageUrl } = badges[awarding]
        setUserBadgeIds([...userBadgeIds, awarding])
        enqueueSnackbar(
          { name, description, imageUrl },
          {
            disableWindowBlurListener: false,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            content: (key, message) => <BadgeAward id={key} message={message} />,
          }
        )
      })
    }
  }, [awarding])

  function awardBadge(id) {
    if (!userBadgeIds.includes(id)) {
      setAwarding(id)
    }
  }

  return (
    <BadgesContext.Provider
      value={{
        awardBadge,
        badges,
        loading,
        userBadgeIds,
      }}
    >
      {children}
    </BadgesContext.Provider>
  )
}

export function useBadges() {
  return React.useContext(BadgesContext)
}
