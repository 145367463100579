import React, { useState, useEffect } from 'react'
import { Box, Button, CircularProgress, List, LinearProgress, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import axios from 'axios'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import Dial from './dial'
import Question from './question'
import Summary from '../results/summary'
import QuestionSummary from '../results/questions'
import { useLesson } from '../lesson/context'
import { useBadges } from '../badges/context'
import MobileNotSupported from '../components/MobileNotSupported'
import './index.css'

export const FRUIT_MACHINE = 'FruitMachine'

export function randomList(limit, list) {
  return list.sort(() => 0.5 - Math.random()).slice(0, limit)
}

const FruitMachine = ({ next, uuid }) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [spin, setSpin] = useState(false)
  const [spinEnabled, setSpinEnabled] = useState(true)
  const [combinationList, setCombinationList] = useState([])
  const [currentIndex, setCurrentIndex] = useState(null)
  const [showSummaryButton, setShowSummaryButton] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const [answers, setAnswers] = useState({})
  const [disableNext, setDisableNext] = useState(false)

  const { moduleId } = useLesson()

  useEffect(() => {
    if (showSummary) {
      setDisableNext(true)
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: FRUIT_MACHINE,
          uuid: uuid,
        },
      }).then(() => {
        setDisableNext(false)
      })
    }
  }, [showSummary])

  useEffect(() => {
    if (spin) {
      setSpinEnabled(false)
      setTimeout(() => {
        setCurrentIndex(currentIndex !== null ? currentIndex + 1 : 0)
        setSpin(false)
      }, 2000)
    }
  }, [spin])

  useEffect(() => {
    if (loading) {
      // be9355f3-7136-4e3e-b1ac-ea0ad042bbe2
      axios(`/fruit-machine/${uuid}`).then((r) => {
        setData(r.data)
        setCombinationList(randomList(10, r.data.combinations))
        setLoading(false)
      })
    }
  }, [loading, uuid])

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '1000px',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  const options = data.combinations.reduce(
    (acc, current) => {
      current.elements.forEach((el, index) => {
        acc[index].push(el)
      })
      return acc
    },
    data.labels.map((l) => [])
  )

  const showQuestion = !spin && currentIndex !== null
  const isLastQuestion = currentIndex === combinationList.length - 1

  if (showSummary) {
    return <Done disableNext={disableNext} next={next} questions={combinationList.map(({ question }) => question)} answers={answers} />
  }

  return (
    <>
      <MobileNotSupported />

      <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ maxWidth: '1000px' }}>
          <Typography sx={{ alignSelf: 'flex-start', textAlign: 'left' }}>
            <Typography component="span" sx={{ margin: '5px', fontWeight: 'bold' }}>
              {currentIndex + 1}
            </Typography>
            of
            <Typography component="span" sx={{ margin: '5px', fontWeight: 'bold' }}>
              {combinationList.length}
            </Typography>
          </Typography>
          <LinearProgress color={'greenTint'} variant="determinate" value={((currentIndex + 1) / combinationList.length) * 100} />
          {data.description && <Description text={data.description} />}
          <Box sx={{ display: 'flex', width: '1000px', justifyContent: 'space-around' }}>
            {data.labels.map((label, index) => {
              return <Dial targetIndex={currentIndex} shouldSpin={spin} key={`dial-${index}`} options={options[index]} label={label} />
            })}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{
                alignSelf: 'flex-end',
                fontSize: '40px',
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                marginTop: '20px',
                textTransform: 'uppercase',
              }}
              disabled={!spinEnabled || isLastQuestion}
              onClick={() => setSpin(true)}
            >
              Spin!
            </Button>
          </Box>
          {showQuestion && (
            <Question
              fruitMachineId={uuid}
              {...combinationList[currentIndex].question}
              key={combinationList[currentIndex].question.uuid}
              onAnswerCheck={(answerId, isCorrect, correctIds) => {
                setAnswers({
                  ...answers,
                  [combinationList[currentIndex].question.uuid]: {
                    answerId,
                    correctIds,
                    isCorrect,
                  },
                })
                isLastQuestion ? setShowSummaryButton(true) : setSpinEnabled(true)
              }}
            />
          )}
          {showSummaryButton && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button variant="contained" onClick={() => setShowSummary(true)}>
                Summary
              </Button>
            </Box>
          )}
          {!showSummaryButton && showQuestion && answers[combinationList[currentIndex].question.uuid] && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button variant="contained" onClick={() => setSpin(true)}>
                Spin again!
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

const Done = ({ disableNext, next, questions, answers }) => {
  const correct = Object.entries(answers).filter(([key, value]) => value.isCorrect).length
  const total = questions.length
  const { width, height } = useWindowSize()
  const percentCompleted = ((correct / total) * 100).toFixed(2)
  const { awardBadge } = useBadges()
  useEffect(() => {
    awardBadge('FIRST_FRUIT_MACHINE')
    if (correct === total) {
      awardBadge('FRUIT_MACHINE_100%')
    }
    if (correct === 0) {
      awardBadge('ANY_0%')
    }
  }, [correct, total])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      {percentCompleted >= 80 && <Confetti width={width} height={height} recycle={false} numberOfPieces={500} />}
      <Summary correct={correct} total={total} />
      <List>
        {questions.map((question, index) => {
          return (
            <QuestionSummary
              key={question.uuid}
              answers={question.answers}
              correctAnswers={answers[question.uuid]}
              questionNumber={index}
              question={question.text}
              selectedAnswer={answers[question.uuid].answerId}
              feedback={question.feedback}
            />
          )
        })}
      </List>
      <Button disabled={disableNext} variant="contained" color="primary" onClick={() => next(true)}>
        Next Step
        <ArrowForwardIosIcon />
      </Button>
    </Box>
  )
}

const Description = ({ text }) => {
  return (
    <Box sx={{ backgroundColor: '#F2F6F7', padding: '20px' }}>
      {text.split(/\n/).map((line, index) => (
        <Typography key={`description-line-${index}`}>{line}</Typography>
      ))}
    </Box>
  )
}

export default FruitMachine
