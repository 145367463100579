import React from 'react'
import PreferenceCenter from './preference-center'

const NotificationsCenter = (props) => {
  return (
    <>
      <PreferenceCenter />
    </>
  )
}

export default NotificationsCenter
