import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Chip, Stack, Divider, Card, Fade } from '@mui/material'
import axios from 'axios'
import classnames from 'classnames'
import Feedback from '../results/feedback'
import { useLesson } from '../lesson/context'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './question.css'
import rehypeRaw from 'rehype-raw'
const Question = ({ questionNumber, totalQuestions, isLastQuestion, next, questionId, quizId }) => {
  const [answerId, setAnswerId] = useState(null)
  const [correctIds, setCorrectIds] = useState(null)
  const [checkAnswer, setCheckAnswer] = useState(false)
  const [feedback, setFeedback] = useState(null)
  const [altExplanation, setAltExplanation] = useState(null)
  const [video, setVideo] = useState({})
  const [videoUUID, setVideoUUID] = useState(null)
  const [remedialQuestionId, setRemedialQuestionId] = useState(null)
  const [isRemedialQuestion, setIsRemedialQuestion] = useState(false)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { moduleId } = useLesson()

  useEffect(() => {
    axios(`/question/${questionId}`)
      .then((r) => {
        setData(r.data)
        setLoading(false)
      })
      .catch(setError)
  }, [questionId])

  useEffect(() => {
    if (answerId) {
      axios
        .post(`/quiz/${quizId}/question/${data.uuid}/check`, {
          answerId,
          moduleId,
        })
        .then(({ data }) => {
          setCorrectIds(data.correctIds)
          setFeedback(data.feedback)
          setAltExplanation(data.alt_explanation)
          setVideo({ url: data.video_uuid, timestamp: data.video_timestamp, timestampEnd: data.video_timestamp_end })
          setVideoUUID(data.video_uuid)
          setRemedialQuestionId(data.remedial_question_uuid)
          setIsRemedialQuestion(data.is_remedial_question)
        })
    }
  }, [checkAnswer])

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const answeredCorrectly = answerId && correctIds && correctIds.some((id) => id === answerId)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <FormControl className="question-mui-wrapper">
        <Stack direction="row" spacing={1}>
          {totalQuestions > 1 && (
            <Typography variant="subtitle1" component="div">
              Question {questionNumber} of {totalQuestions}
            </Typography>
          )}
        </Stack>
        <FormLabel id="question">
          <Divider />
          <Card sx={{ padding: '20px', margin: '20px 0 20px 0' }}>
            <Typography component="div">
              <ReactMarkdown className="question-text" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                {data.text}
              </ReactMarkdown>
            </Typography>
          </Card>
        </FormLabel>
        <Divider />
        <RadioGroup aria-labelledby="question" name="answers" value={answerId} onChange={(e) => setAnswerId(e.target.value)}>
          {data.answers.map(({ uuid, text }, index) => {
            const isCorrect = correctIds && correctIds.some((id) => id === uuid)
            return (
              <FormControlLabel
                sx={{ margin: 0, marginBottom: '10px' }}
                className={classnames({
                  'mcq-item': true,
                  'mcq-item-selected': answerId === uuid,
                  'mcq-item-correct': correctIds !== null && isCorrect,
                  'mcq-item-incorrect': correctIds !== null && answerId === uuid && !isCorrect,
                })}
                disabled={checkAnswer}
                key={`answer-${uuid}`}
                value={uuid}
                control={<Radio />}
                label={
                  <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                    {text}
                  </ReactMarkdown>
                }
              />
            )
          })}
        </RadioGroup>
      </FormControl>

      {!checkAnswer && (
        <Button
          sx={{
            alignSelf: 'center',
            marginTop: '10px',
          }}
          disabled={answerId === null || checkAnswer}
          variant="contained"
          color="primary"
          onClick={() => setCheckAnswer(true)}
        >
          Check
        </Button>
      )}
      {checkAnswer && correctIds && (
        <Fade in={checkAnswer}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '1000px',
            }}
          >
            <Feedback
              isCorrect={answeredCorrectly}
              feedback={
                <Typography component="div">
                  <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                    {feedback}
                  </ReactMarkdown>
                </Typography>
              }
              altExplanation={altExplanation}
              video={video}
              uuid={quizId}
              remedialQuestionId={remedialQuestionId}
              isRemedialQuestion={isRemedialQuestion}
            />
            {!isRemedialQuestion && (
              <Button
                sx={{
                  alignSelf: 'center',
                  marginTop: '10px',
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  next(answeredCorrectly)
                }}
              >
                {isLastQuestion ? 'Summary' : 'Next'}
              </Button>
            )}
          </Box>
        </Fade>
      )}
    </Box>
  )
}

export default Question
