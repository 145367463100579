import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Box, Modal, Typography, Button } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import { useUser } from '../login/user-context'
import mixpanel from 'mixpanel-browser'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { Global, css } from '@emotion/react'
import axios from 'axios'
const globalStyles = css`
  @keyframes pulseBorder {
    0% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0.7);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(23, 86, 163, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0);
    }
  }
`

const JumpBackInModel = ({ modules }) => {
  const hideJumpBackInModel = sessionStorage.getItem('hideJumpBackIn')
  const hideWelcomeModel = localStorage.getItem('hideWelcome') // hide if first time in browser
  const [open, setOpen] = useState(!hideJumpBackInModel && !!hideWelcomeModel)
  const [ready, setReady] = useState(false)
  const [resize, setResize] = useState(false)
  const ref = useRef(null)
  const [width, setWidth] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [jumpBackIn, setJumpBackIn] = useState(null)
  const { name } = useUser()
  const navigate = useNavigate()
  const [gotProgress, setGotProgress] = useState(false)

  function capitalise(text) {
    try {
      return text.charAt(0).toUpperCase() + text.slice(1)
    } catch (e) {
      return text
    }
  }

  const onClose = () => {
    sessionStorage.setItem('hideJumpBackIn', true)
    setOpen(false)
  }

  async function getLastProgress(modules) {
    if (modules.length > 0 && loading && !gotProgress) {
      try {
        const response = await axios('/progress')
        const data = response.data

        if (data && data.length > 0) {
          const lastElement = data[0] // Assuming 'data[0]' has a uuid property
          let jumpBackInModule = null
          let jumpBackInLesson = null
          let jumpBackInElement = null
          // Traverse through modules to find the element
          modules.some((module) => {
            jumpBackInModule = module
            return module.lessons.some((lesson) => {
              jumpBackInLesson = lesson
              let nextElementIndex = lesson.elements.findIndex((element) => element.uuid === lastElement)

              if (nextElementIndex !== -1) {
                nextElementIndex = nextElementIndex + 1
                if (nextElementIndex >= 0 && nextElementIndex < lesson.elements.length) {
                  jumpBackInElement = lesson.elements[nextElementIndex]
                }
                return true // Found, exit loops
              }

              return false // Not found, continue
            })
          })

          // If the next element wasn't found, try to find the next lesson
          if (!jumpBackInElement && jumpBackInLesson) {
            const nextLessonIndex = jumpBackInModule.lessons.indexOf(jumpBackInLesson) + 1
            if (nextLessonIndex < jumpBackInModule.lessons.length) {
              jumpBackInLesson = jumpBackInModule.lessons[nextLessonIndex]
              jumpBackInElement = jumpBackInLesson.elements[0]
            }
          }

          // Configure the jump back in object if all properties are set
          if (jumpBackInModule && jumpBackInLesson && jumpBackInElement) {
            setJumpBackIn({
              module: jumpBackInModule,
              lesson: jumpBackInLesson,
              element: jumpBackInElement,
              url: `/module/${jumpBackInModule.uuid}/lesson/${jumpBackInLesson.uuid}/${jumpBackInElement.uuid}`,
            })
            setLoading(false)
          }
        }

        setGotProgress(true)
      } catch (error) {
        console.error('Error fetching progress:', error)
      }
    }
  }

  useEffect(() => {
    if (!open) return

    if (modules.length > 0) {
      getLastProgress(modules)
    }
    if (!gotProgress) return

    if (!jumpBackIn && modules) {
      const jumpBackInModule = modules.find((module) => module.lessons.some((lesson) => lesson.elements.some((element) => !element.complete)))

      if (jumpBackInModule) {
        const jumpBackInLesson = jumpBackInModule.lessons.find((lesson) => lesson.elements.some((element) => !element.complete))

        if (jumpBackInLesson) {
          const jumpBackInElement = jumpBackInLesson.elements.find((element) => !element.complete)

          setJumpBackIn({
            module: jumpBackInModule,
            lesson: jumpBackInLesson,
            element: jumpBackInElement,
            url: `/module/${jumpBackInModule.uuid}/lesson/${jumpBackInLesson.uuid}/${jumpBackInElement.uuid}`,
          })
        }
      }

      setLoading(false)
    }
  }, [loading, modules, gotProgress]) // Ensure all dependencies are listed to control re-rendering

  function handleResize() {
    setWidth(ref.current && ref.current.offsetWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [resize])

  useLayoutEffect(() => {
    setWidth(ref.current && ref.current.offsetWidth)
  }, [ready])

  if (loading || !!!jumpBackIn) return
  return (
    <Modal open={open} onClose={() => onClose()} sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex' } }}>
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#FFFFFF',
          border: '0',
          boxShadow: 24,
          padding: '35px',
          maxWidth: '1500px',
          display: 'flex',
          flexDirection: 'row',
          margin: '10px',
          width: 'fit-content',
          height: 'fit-content', // Changed to use vh for consistency
          alignItems: 'center',
          borderRadius: '4px',
          justifyContent: 'space-around',
          backgroundColor: 'secondary.main',
          '&:focus': {
            border: '0',
            outline: 'none',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <CloseIcon onClick={onClose} sx={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }} />

          <Typography
            variant="h3"
            sx={{ fontWeight: 'bold', color: 'white', fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.5rem' }, alignSelf: { xs: 'center' }, textAlign: 'center' }}
            mt={'10px'}
            mb={'10px'}
          >
            Welcome back, {capitalise(name)} 😊
          </Typography>
          <CardMedia
            sx={{
              height: { xs: '20vh', md: '25vh' },
              width: { xs: '35vh', md: '45vh' },
              maxHeight: '300px',
              maxWidth: '500px',
              opacity: 1,
              backgroundColor: 'white',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              alignSelf: 'center',
              textAlign: 'center',
            }}
            image={process.env.NODE_ENV === 'development' ? `https://dev.examfly-app.com${jumpBackIn.lesson.imageUrl}` : jumpBackIn.lesson.imageUrl}
            title={jumpBackIn.lesson.name}
          />
          <Typography
            variant="h3"
            sx={{ fontWeight: 'bold', color: 'white', fontStyle: 'italic', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.3rem' }, alignSelf: 'center', textAlign: 'center' }}
            mt={'10px'}
          >
            {jumpBackIn.module.name} - {jumpBackIn.lesson.name}
          </Typography>
          <Global styles={globalStyles} />
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.dark' },
              fontSize: '1.5rem',
              paddingLeft: '10px',
              paddingRight: '10px',
              width: 'fit-content',
              alignSelf: 'center',
              marginTop: '20px',
              animation: 'pulseBorder 2s infinite',
            }}
            variant="contained"
            onClick={() => {
              mixpanel.track('button_clicked', {
                Feature: 'modal_jump_back_in',
                Location: 'dashboard',
                Name: 'Jump Back In Clicked',
                Link: jumpBackIn.url,
              })
              onClose()
              navigate(jumpBackIn.url)
            }}
          >
            Jump back in!
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default JumpBackInModel
