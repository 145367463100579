import React from 'react'
import { Typography } from '@mui/material'

const DragItem = ({checking, onDragEnd, onDragStart, text}) => {
  return (
    <Typography 
      draggable={checking ? 'false' : 'true'}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      sx={{
        background: '#1756A3',
        borderRadius: '5px',
        cursor: 'pointer',
        color: '#ffffff',
        display: 'inline-block',
        fontWeight: 'bold',
        opacity: checking ? '0.25' : '1.00',
        lineHeight: '50px',
        padding: '0 10px',
        marginRight: '10px',
        marginBottom: '10px',
      }}
    >
      {text}
    </Typography>
    )
}

export default DragItem