import { Alert, AlertTitle, Avatar, Typography, Button, Link, Card, CardHeader, CardContent, CircularProgress, Fade, Grow } from '@mui/material'

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import React, { useEffect, useState } from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { red } from '@mui/material/colors'
import { css, Global } from '@emotion/react'

const globalStyles = css`
  @keyframes shake-bottom {
    0%,
    33.333%,
    100% {
      transform: translateY(0);
    }
    10%,
    30% {
      transform: translateY(5px);
    }
    20% {
      transform: translateY(-5px);
    }
  }

  .shake-bottom {
    animation: shake-bottom 1.5s ease-in-out infinite;
  }
`

const Feedback = ({ feedback, isCorrect, altExplanation, video, uuid, remedialQuestionId, isRemedialQuestion = false }) => {
  return (
    <Card>
      <Global styles={globalStyles} />

      <CardHeader
        variant={isCorrect ? 'feedbackPanelCorrect' : 'feedbackPanelIncorrect'}
        title={<Typography variant="">{isCorrect ? 'Correct' : 'Incorrect'}</Typography>}
        avatar={
          isCorrect ? (
            <Avatar
              sx={{
                backgroundColor: 'rgb(245, 255, 245)',
              }}
            >
              <CheckCircleIcon fontSize="large" sx={{ color: '#4caf50' }} />
            </Avatar>
          ) : (
            <Avatar sx={{ backgroundColor: 'rgb(255, 245, 245)' }}>
              <CancelIcon fontSize="large" sx={{ color: red[500] }} />
            </Avatar>
          )
        }
      />
      <CardContent variant={isCorrect ? 'feedbackPanelCorrect' : 'feedbackPanelIncorrect'}>
        <Typography variant="">{feedback}</Typography>
      </CardContent>
    </Card>
  )
}

export default Feedback
