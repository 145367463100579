import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Typography } from '@mui/material'
import axios from 'axios'
import classnames from 'classnames'

import { useLesson } from '../lesson/context'
import './question.css'

const Question = ({ gameId, next, text, uuid }) => {
  const [answers, setAnswers] = useState([])
  const [loadingAnswers, setLoadingAnswers] = useState(true)
  const [answerId, setAnswerId] = useState(null)
  const [correctIds, setCorrectIds] = useState([])
  const [checkAnswer, setCheckAnswer] = useState(false)

  const { moduleId } = useLesson()

  useEffect(() => {
    setLoadingAnswers(true)
    axios(`/question/${uuid}`).then((r) => {
      setAnswers(r.data.answers)
      setLoadingAnswers(false)
    })
  }, [uuid])

  useEffect(() => {
    if (checkAnswer) {
      axios
        .post(`/ct-computations/${gameId}/question/${uuid}/check`, {
          answerId,
          moduleId,
        })
        .then(({ data }) => {
          setCorrectIds(data.correctIds)
          setCheckAnswer(false)
        })
    }
  }, [checkAnswer])

  if (loadingAnswers) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const isCorrect = correctIds.includes(answerId)

  return (
    <Box>
      <FormControl sx={{ width: '100%' }}>
        <FormLabel id="question">
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{text}</Typography>
        </FormLabel>
        <Box>
          <RadioGroup aria-labelledby="question" name="answers" value={answerId} onChange={(e) => setAnswerId(e.target.value)}>
            {answers.map(({ uuid: currentAnswerId, text }, index) => {
              return (
                <FormControlLabel
                  sx={{ boxSizing: 'border-box', background: '#F2F6F7', padding: '10px', marginLeft: 0, marginTop: '10px', width: '100%' }}
                  className={classnames({
                    answer: true,
                    'answer-selected': answerId === currentAnswerId,
                    'answer-correct': !!correctIds.length ? correctIds.includes(currentAnswerId) : false,
                    'answer-incorrect': !!correctIds.length && answerId === currentAnswerId && !correctIds.includes(currentAnswerId),
                  })}
                  disabled={checkAnswer || correctIds.length > 0}
                  key={`answer-${index}`}
                  value={currentAnswerId}
                  control={<Radio />}
                  label={text}
                />
              )
            })}
          </RadioGroup>
        </Box>
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        {!correctIds.length && (
          <Button disabled={!answerId} variant="contained" onClick={() => setCheckAnswer(true)}>
            Check
          </Button>
        )}
        {!!correctIds.length && (
          <Button variant="contained" onClick={() => next(isCorrect)}>
            Next
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default Question
