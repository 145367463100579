// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme) {
  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  }

  return {
    // MuiButton: {
    //   defaultProps: {
    //     disableElevation: true
    //   },
    //   styleOverrides: {
    //     root: {
    //       fontWeight: 400
    //     },
    //     contained: {
    //       ...disabledStyle
    //     },
    //     outlined: {
    //       ...disabledStyle
    //     }
    //   }
    // }
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          border: '2px solid #1756A3',
          fontSize: '18px',
          fontWeight: 600,
          padding: '6px 18px',
          textTransform: 'capitalize',
          '&:disabled': {
            border: '2px solid #CED4DA',
          },
          color: 'rgb(23, 86, 163)',
        },
        text: {
          border: 'none',
          '&.Mui-disabled': {
            border: 0,
          },
        },
        outlined: {
          ...disabledStyle,
          border: '2px solid #1756A3',
          '&:hover': {
            border: '2px solid #1756A3',
          },
          color: 'rgb(23, 86, 163)',
        },
        contained: {
          ...disabledStyle,
          border: '2px solid #1756A3',
          color: '#FFFFFF',
          '&:hover': {
            border: '2px solid #1756A3',
          },
          backgroundColor: '#1756A3',
        },
      },
      variants: [
        {
          props: { variant: 'furtherInfo' },
          style: {
            textTransform: 'none',
            border: `none`,
            fontSize: '18px',
            fontWeight: 600,
            margin: '0',
            padding: '6px 18px',
            '&:disabled': {
              border: '2px solid #CED4DA',
            },
            color: 'rgb(23, 86, 163)',
          },
        },
        {
          props: { variant: 'delete' },
          style: {
            border: '2px solid #FF0000',

            backgroundColor: '#FF0000',
            '&:disabled': {
              border: '2px solid #FF0000',
            },
            color: '#FFFFFF',
            '&:hover': {
              border: '2px solid #FF0000',
              color: '#FFFFFF',
              backgroundColor: '#FF4D4D',
            },
          },
        },
        {
          props: { variant: 'chatMsgSend' },
          style: {
            textTransform: 'none',
            border: `none`,
            fontSize: '16px',
            fontWeight: 400,
            margin: '0',
            padding: '8px 26px',
            // '&:disabled': {
            //   border: '2px solid #CED4DA',
            // },
            '&:hover': {
              textTransform: 'none',
              border: `none`,
              color: '#FFFFFF',
              backgroundColor: '#1756A3',
            },
            color: '#FFFFFF',
            backgroundColor: '#1756A3',
          },
        },
        {
          props: { variant: 'progressBtn' },
          style: {
            textTransform: 'none',
            border: `none`,
            fontSize: '14px',
            fontWeight: 600,
            margin: '0',
            padding: '0px 18px',
            // padding: '6px 18px',
            // '&:disabled': {
            //   border: '2px solid #CED4DA',
            // },
            color: 'rgb(23, 86, 163)',
          },
        },
      ],
    },
    // MuiLink: {
    //   styleOverrides: {
    //     root: {
    //       textDecoration: "none",
    //       fontFamily: ['"Inter"'],
    //     },
    //   },
    // },
    // MuiCircularProgress: {
    //   styleOverrides: {
    //     root: {
    //       alignSelf: "center",
    //     },
    //   },
    // },
  }
}
