import React, { Fragment, useEffect, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import axios from 'axios'

import Answer from './upsert-answer'

import './upsert.css'

const UpsertQuestion = (props) => {
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [text, setText] = useState(props.text || '')
  const [feedback, setFeedback] = useState(props.feedback || '')
  const [answers, setAnswers] = useState(props.answers || [])
  const [newAnswer, setNewAnswer] = useState(false)
  const [fetchAnswers, setFetchAnswers] = useState(false)

  useEffect(async () => {
    if (saving) {
      axios({
        url: `/question/${props.uuid || ''}`,
        method: 'post',
        data: {
          feedback,
          text,
        },
      })
        .then((r) => {
          props.onSave(r.data)
          setSaving(false)
        })
        .catch(setError)
    }
  }, [saving])

  useEffect(async () => {
    if (fetchAnswers === true && props.uuid) {
      const { data } = await axios(`/question/${props.uuid}/edit-answers`)
      setFetchAnswers('DONE')
      setAnswers(data)
    }
  }, [fetchAnswers])

  useEffect(() => {
    if (deleting) {
      if (props.uuid) {
        axios({
          url: `question/${props.uuid}`,
          method: 'delete',
        })
          .then((r) => {
            setDeleting(false)
            props.onDelete(props.uuid)
          })
          .catch(setError)
      } else {
        props.onDelete()
      }
    }
  }, [deleting])

  const changed = text !== props.text || feedback !== props.feedback

  return (
    <Accordion
      onChange={(e, expanded) => {
        if (expanded && answers.length === 0) {
          setFetchAnswers(true)
        }
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{text || 'New Question'}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="wrapper">
          <TextField
            name={`question-${props.uuid}`}
            value={text}
            onChange={(e) => setText(e.target.value)}
            label="Question text"
            variant="outlined"
            sx={{ flex: 1 }}
            size="small"
            multiline
            rows={4}
          />
          <TextField
            name={`question-${props.uuid}`}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            label="Feedback text"
            variant="outlined"
            sx={{ flex: 1 }}
            size="small"
            multiline
            rows={4}
          />
          <Button disabled={!changed} onClick={() => setSaving(true)}>
            {saving ? <CircularProgress size="1rem" /> : 'Save'}
          </Button>
          <Button onClick={() => setDeleting(true)}>
            <DeleteIcon />
          </Button>
        </div>
        {answers.map((answer, i) => (
          <Answer
            key={`answer-${answer.uuid || i}`}
            questionId={props.uuid}
            onSave={(newAnswer) => {
              const index = answers.findIndex(
                ({ uuid }) => uuid === newAnswer.uuid
              )
              setAnswers([
                ...answers.slice(0, index),
                newAnswer,
                ...answers.slice(index + 1),
              ])
            }}
            {...answer}
          />
        ))}
        {newAnswer && (
          <Answer
            questionId={props.uuid}
            onSave={(answer) => {
              setNewAnswer(false)
              setAnswers([...answers, answer])
            }}
          />
        )}
        <Button
          disabled={!props.uuid}
          onClick={() => setNewAnswer(true)}
          sx={{ width: 'fit-content' }}
          size="small"
        >
          Add answer
        </Button>
      </AccordionDetails>
    </Accordion>
  )
}

export default UpsertQuestion
