import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'
import CopyToClipboardButton from '../components/copy-to-clipboard-button'
import Typography from '@mui/material/Typography'
import * as Yup from 'yup'
import { FastField, FieldArray, Form, Formik, getIn } from 'formik'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link, useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import theme from '../../theme'
import AddRemedialQuestion from './add-remedial-question'
import { useSnackbar } from 'notistack'
import { debounce } from '@mui/material/utils'
import '@mdxeditor/editor/style.css'
import { MDXEditor } from '@mdxeditor/editor'
import { tablePlugin } from '@mdxeditor/editor/plugins/table'

import { handleFileUpload } from 'admin/utils/file-uploader'
import { quotePlugin } from '@mdxeditor/editor/plugins/quote'
import { listsPlugin } from '@mdxeditor/editor/plugins/lists'
import { toolbarPlugin } from '@mdxeditor/editor/plugins/toolbar'
import { headingsPlugin } from '@mdxeditor/editor/plugins/headings'
import { markdownShortcutPlugin } from '@mdxeditor/editor/plugins/markdown-shortcut'
import { imagePlugin } from '@mdxeditor/editor/plugins/image'

import { UndoRedo } from '@mdxeditor/editor/plugins/toolbar/components/UndoRedo'
import { ListsToggle } from '@mdxeditor/editor/plugins/toolbar/components/ListsToggle'
import { InsertTable } from '@mdxeditor/editor/plugins/toolbar/components/InsertTable'
import { BlockTypeSelect } from '@mdxeditor/editor/plugins/toolbar/components/BlockTypeSelect'
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles'
import { InsertImage } from '@mdxeditor/editor/plugins/toolbar/components/InsertImage'
import './mdx.css'

const MAX_FILE_SIZE = 2000000
const validFileExtensions = { image: ['jpg', 'png', 'jpeg', 'webp', 'gif', 'svg'] }
const ui_env = document.location.hostname

let s3BucketName = 'dev.examfly-app.com-images'
const fileUploadPath = 'images/module/lesson/quiz/'
let image_location = 'https://dev.examfly-app.com/'

switch (ui_env) {
  case 'dev.examfly-app.com':
    s3BucketName = 'dev.examfly-app.com-images'
    image_location = 'https://dev.examfly-app.com/'
    break
  case 'staging.examfly-app.com':
    s3BucketName = 'staging.examfly-app.com-images'
    image_location = 'https://staging.examfly-app.com/'
    break
  case 'www.examfly-app.com':
    s3BucketName = 'www.examfly-app.com-images'
    image_location = 'https://www.examfly-app.com/'
    break
  default:
    s3BucketName = 'dev.examfly-app.com-images'
    image_location = 'https://dev.examfly-app.com/'
    break
}

const imageFullURI = (image_name) => {
  return `${image_location}${fileUploadPath}${image_name}`
}

const AddQuizSchema = Yup.object().shape({
  quiz_title: Yup.string().trim().required('Quiz title is required!'),
  quiz_questions: Yup.array().of(
    Yup.object({
      question: Yup.string().trim().required('Question is required!'),
      feedback: Yup.string().trim().required('Feedback is required!'),
      is_correct: Yup.string().trim().required('Select an option!'),
      alt_explanation: Yup.string().trim().nullable('Alternative Explanation is optional!'),
      video_uuid: Yup.string().trim().nullable('Video UUID is optional!'),
      video_timestamp: Yup.string().trim().nullable('Video Timestamp Start time is optional!'),
      video_timestamp_end: Yup.string().trim().nullable('Video Timestamp End time is optional!'),
      options: Yup.array()
        .of(
          Yup.object({
            option_title: Yup.string().trim().required('Option cannot be empty!'),
          })
        )
        .min(2, 'Minimum 2 options should be added!'),
    })
  ),
})

const getErrorMessage = (name, errors, touched) => ({
  status: Boolean(getIn(errors, name) && getIn(touched, name)),
  message: getIn(errors, name),
})

const AddQuiz = ({ setDisplayQuiz, setQuizzes, preFillQuiz, newQuestionVideoID }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [openRemedialQForm, setOpenRemedialQForm] = useState(false)
  const [remedialQuestionSelected, setRemedialQuestionSelected] = useState(null)
  const [quizDataLoaded, setQuizDataLoaded] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteQuestionId, setdeleteQuestionId] = useState('')

  const initialQuestionObject = {
    id: uuidv4(),
    question: '',
    options: [
      {
        option_id: uuidv4(),
        option_title: '',
      },
    ],
    feedback: '',
    alt_explanation: '',
    video_uuid: newQuestionVideoID.length === 1 ? newQuestionVideoID[0] : '',
    video_timestamp: 0,
    video_timestamp_end: undefined,
  }

  const [quizData, setQuizData] = useState({
    id: uuidv4(),
    quiz_title: '',
    quiz_description: '',
    quiz_questions: [initialQuestionObject],
  })

  useEffect(() => {
    if (Object.keys(preFillQuiz).length > 0) {
      setLoading(true)
      setQuizData({ ...preFillQuiz, is_update: true })
      setTimeout(() => {
        setQuizDataLoaded(true)
        setLoading(false)
      }, 300)
    } else {
      setQuizDataLoaded(true)
    }

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [preFillQuiz])

  const handleRemedialQFormClickOpen = (remedial_question_uuid) => {
    setRemedialQuestionSelected(remedial_question_uuid)
    setOpenRemedialQForm(true)
  }

  const handleRemedialQFormClickClose = () => {
    setRemedialQuestionSelected(null)
    setOpenRemedialQForm(false)
  }

  const handleDialogOpen = (index) => {
    setdeleteQuestionId(index)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setdeleteQuestionId('')
    setDialogOpen(false)
  }

  return (
    <>
      <Typography sx={{ margin: 5 }} variant="h2">
        Add Quiz
      </Typography>
      <Box sx={{ background: '#ffffff', padding: 5, position: 'relative' }}>
        <Link
          to={'#'}
          onClick={(event) => {
            event.preventDefault()
            setDisplayQuiz(false)
          }}
        >
          <Button sx={{ padding: '0 10px', margin: '5px 0' }} variant="text">
            <ArrowBackIcon />
            Back
          </Button>
        </Link>
        <br />
        <Box sx={{ width: '100%' }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <Formik
            initialValues={quizData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={AddQuizSchema}
            onSubmit={async (values, actions) => {
              try {
                actions.setSubmitting(false)
                setQuizzes({ ...values })
                setDisplayQuiz(false)
              } catch (e) {
                console.log(e)
                enqueueSnackbar('Something went wrong!', { variant: 'error' })
                navigate('/admin/lesson/all')
              }
            }}
          >
            {({ errors, touched, values, handleSubmit, setFieldValue }) => (
              <Form onSubmit={(event) => handleSubmit(event)}>
                <Grid container rowSpacing={3} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                  <Grid item xs={12}>
                    <FastField name="quiz_title">
                      {({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          name="quiz_title"
                          label="Quiz title"
                          multiline
                          maxRows={10}
                          error={getErrorMessage('quiz_title', errors, touched).status}
                          helperText={getErrorMessage('quiz_title', errors, touched).status ? getErrorMessage('quiz_title', errors, touched).message : undefined}
                        />
                      )}
                    </FastField>
                  </Grid>

                  <Grid sx={{ height: '400px' }} item xs={12}>
                    <Typography sx={{ margin: 0, marginBottom: 0.8 }} variant="h4" gutterBottom>
                      Quiz Description
                    </Typography>
                    <Typography sx={{ margin: 0, marginBottom: 1 }} variant="">
                      <CopyToClipboardButton content={values.id} />
                      ID: {values.id}
                    </Typography>
                    {quizDataLoaded ? (
                      <FastField name="quiz_description">
                        {({ field }) => (
                          <MDXEditor
                            placeholder="Write quiz description here..."
                            onChange={(content) => debounce(setFieldValue('quiz_description', content), 100)}
                            className="mdx-container"
                            markdown={values.quiz_description || ''}
                            plugins={[
                              toolbarPlugin({
                                toolbarContents: () => (
                                  <>
                                    <UndoRedo />
                                    <BoldItalicUnderlineToggles />
                                    <ListsToggle />
                                    <BlockTypeSelect />
                                    <InsertTable />
                                  </>
                                ),
                              }),
                              tablePlugin(),
                              quotePlugin(),
                              listsPlugin(),
                              headingsPlugin(),
                              markdownShortcutPlugin(),
                            ]}
                          />
                        )}
                      </FastField>
                    ) : (
                      ''
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>

                  <FieldArray
                    name="quiz_questions"
                    render={(arrayHelpers) => (
                      <Grid item xs={12}>
                        {values.quiz_questions.map((value, index) => {
                          return (
                            <Grid key={value.id} item xs={12} sx={{ paddingY: 2 }}>
                              <Accordion TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  <Grid item xs={12}>
                                    <Grid sx={{ marginBottom: 1 }} container direction="row" justifyContent="space-between" alignItems="center">
                                      <Grid item>
                                        <Typography sx={{ margin: 0 }} variant="h3">
                                          {index + 1}. Question
                                        </Typography>

                                        <Typography sx={{ margin: 0, marginBottom: 1 }} variant="">
                                          <CopyToClipboardButton content={value.id} />
                                          ID: {value.id}
                                        </Typography>
                                      </Grid>
                                      <Grid item sx={{ paddingX: 2, paddingTop: 0.5 }}>
                                        {values.quiz_questions.length > 1 && (
                                          <IconButton
                                            onClick={() => {
                                              handleDialogOpen(index)
                                              // arrayHelpers.remove(index)
                                            }}
                                            aria-label="delete"
                                            size="large"
                                          >
                                            <DeleteIcon fontSize="inherit" />
                                          </IconButton>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid sx={{ height: '400px' }} item xs={12}>
                                    <FormControl fullWidth sx={{ height: '90%' }}>
                                      {quizDataLoaded ? (
                                        <FastField name={`quiz_questions[${index}][question]`}>
                                          {({ field }) => (
                                            <MDXEditor
                                              placeholder="Write question here..."
                                              onChange={(content) => debounce(setFieldValue(`quiz_questions[${index}][question]`, content), 100)}
                                              className="mdx-container"
                                              markdown={values.quiz_questions[index]['question'] || ''}
                                              plugins={[
                                                imagePlugin({
                                                  imageUploadHandler: async (image) => {
                                                    if (typeof image === 'object') {
                                                      let image_name = new Date().getTime() + '_' + image.name
                                                      image_name = image_name.trim().replace(/\s/g, '_')

                                                      if (image.size > MAX_FILE_SIZE) {
                                                        enqueueSnackbar('File size is too large!', { variant: 'error' })
                                                        return
                                                      }

                                                      if (image.type && !validFileExtensions.image.includes(image.type.split('/')[1])) {
                                                        enqueueSnackbar('Invalid file type!', { variant: 'error' })
                                                        return
                                                      }

                                                      await handleFileUpload(image, image_name, fileUploadPath, s3BucketName, false, (fileUrl) => {
                                                        enqueueSnackbar(`Image upload failed to location: ${fileUrl}`, { variant: 'error' })
                                                      })

                                                      return imageFullURI(image_name)
                                                    }
                                                  },
                                                }),

                                                toolbarPlugin({
                                                  toolbarContents: () => (
                                                    <>
                                                      <UndoRedo />
                                                      <BoldItalicUnderlineToggles />
                                                      <ListsToggle />
                                                      <BlockTypeSelect />
                                                      <InsertTable />
                                                      <InsertImage />
                                                    </>
                                                  ),
                                                }),
                                                tablePlugin(),
                                                quotePlugin(),
                                                listsPlugin(),
                                                headingsPlugin(),
                                                markdownShortcutPlugin(),
                                              ]}
                                            />
                                          )}
                                        </FastField>
                                      ) : (
                                        ''
                                      )}

                                      <Typography
                                        sx={{
                                          color: theme.palette.error.main,
                                          marginTop: 2,
                                        }}
                                      >
                                        {getErrorMessage(`quiz_questions[${index}][question]`, errors, touched).status
                                          ? getErrorMessage(`quiz_questions[${index}][question]`, errors, touched).message
                                          : ''}
                                      </Typography>
                                    </FormControl>
                                  </Grid>

                                  <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <FormLabel>
                                      <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                                        Options
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: theme.palette.error.main,
                                          marginBottom: 2,
                                        }}
                                      >
                                        {Boolean(getIn(errors, `quiz_questions[${index}][is_correct]`))
                                          ? getErrorMessage(`quiz_questions[${index}][is_correct]`, errors, touched).message
                                          : ''}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: theme.palette.error.main,
                                          marginBottom: 2,
                                        }}
                                      >
                                        {typeof getIn(errors, `quiz_questions[${index}][options]`) !== 'object'
                                          ? getErrorMessage(`quiz_questions[${index}][options]`, errors, touched).message
                                          : ''}
                                      </Typography>
                                    </FormLabel>
                                    <RadioGroup onChange={(event) => setFieldValue(`quiz_questions[${index}][is_correct]`, event.target.value)}>
                                      <FieldArray
                                        name={`quiz_questions[${index}][options]`}
                                        render={(optionArrayHelpers) => (
                                          <Grid container direction="column" rowSpacing={1} columnSpacing={1}>
                                            {values.quiz_questions[index]['options'].map((option_value, option_index) => {
                                              return (
                                                <Grid key={option_value.option_id} item xs={12} marginY={1}>
                                                  <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={1} key={option_value.option_id}>
                                                    <div className="option-width" style={{ display: 'flex', flexDirection: 'column', height: '250px', gap: '0.2rem' }}>
                                                      <FastField name={`quiz_questions[${index}][options][${option_index}][option_title]`}>
                                                        {({ field }) => (
                                                          <MDXEditor
                                                            placeholder="Write option here..."
                                                            onChange={(content) =>
                                                              debounce(setFieldValue(`quiz_questions[${index}][options][${option_index}][option_title]`, content), 100)
                                                            }
                                                            className="mdx-container"
                                                            markdown={values.quiz_questions[index]['options'][option_index]['option_title'] || ''}
                                                            plugins={[
                                                              toolbarPlugin({
                                                                toolbarContents: () => (
                                                                  <>
                                                                    <UndoRedo />
                                                                    <BoldItalicUnderlineToggles />
                                                                    <ListsToggle />
                                                                    <BlockTypeSelect />
                                                                    <InsertTable />
                                                                  </>
                                                                ),
                                                              }),
                                                              tablePlugin(),
                                                              quotePlugin(),
                                                              listsPlugin(),
                                                              headingsPlugin(),
                                                              markdownShortcutPlugin(),
                                                            ]}
                                                          />
                                                        )}
                                                      </FastField>
                                                      <Typography
                                                        component="span"
                                                        sx={{
                                                          color: theme.palette.error.main,
                                                        }}
                                                      >
                                                        {getErrorMessage(`quiz_questions[${index}][options][${option_index}][option_title]`, errors, touched).status
                                                          ? getErrorMessage(`quiz_questions[${index}][options][${option_index}][option_title]`, errors, touched).message
                                                          : ''}
                                                      </Typography>
                                                    </div>
                                                    <FormControlLabel
                                                      sx={{ margin: 0 }}
                                                      value={option_index}
                                                      control={<Radio checked={option_index === parseInt(values.quiz_questions[index]['is_correct']) ? true : false} />}
                                                    />
                                                    {values.quiz_questions[index]['options'].length > 1 && (
                                                      <IconButton
                                                        onClick={() => {
                                                          setFieldValue(`quiz_questions[${index}][is_correct]`, '')
                                                          optionArrayHelpers.remove(option_index)
                                                        }}
                                                        aria-label="delete"
                                                        size="large"
                                                      >
                                                        <DeleteIcon fontSize="inherit" />
                                                      </IconButton>
                                                    )}
                                                  </Stack>
                                                </Grid>
                                              )
                                            })}
                                            <Grid item alignSelf="flex-end">
                                              <Button
                                                onClick={() => {
                                                  optionArrayHelpers.push({
                                                    option_id: uuidv4(),
                                                    option_title: '',
                                                  })
                                                }}
                                                size="small"
                                                variant="contained"
                                                type="button"
                                              >
                                                Add Option
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        )}
                                      />
                                    </RadioGroup>
                                  </FormControl>

                                  <Grid item xs={12}>
                                    <Divider variant="middle" />
                                  </Grid>

                                  <Grid sx={{ height: '400px' }} marginY={2} item xs={12}>
                                    <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                                      Feedback
                                    </Typography>
                                    {quizDataLoaded ? (
                                      <FastField name={`quiz_questions[${index}][feedback]`}>
                                        {({ field }) => (
                                          <MDXEditor
                                            placeholder="Write feedback here..."
                                            onChange={(content) => debounce(setFieldValue(`quiz_questions[${index}][feedback]`, content), 100)}
                                            className="mdx-container"
                                            markdown={values.quiz_questions[index]['feedback'] || ''}
                                            plugins={[
                                              toolbarPlugin({
                                                toolbarContents: () => (
                                                  <>
                                                    <UndoRedo />
                                                    <BoldItalicUnderlineToggles />
                                                    <ListsToggle />
                                                    <BlockTypeSelect />
                                                    <InsertTable />
                                                  </>
                                                ),
                                              }),
                                              tablePlugin(),
                                              quotePlugin(),
                                              listsPlugin(),
                                              headingsPlugin(),
                                              markdownShortcutPlugin(),
                                            ]}
                                          />
                                        )}
                                      </FastField>
                                    ) : (
                                      ''
                                    )}
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Typography
                                      component="span"
                                      sx={{
                                        color: theme.palette.error.main,
                                      }}
                                    >
                                      {getErrorMessage(`quiz_questions[${index}][feedback]`, errors, touched).status
                                        ? getErrorMessage(`quiz_questions[${index}][feedback]`, errors, touched).message
                                        : ''}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Divider variant="middle" />
                                  </Grid>

                                  <br />

                                  <Grid sx={{ height: '400px' }} item xs={12}>
                                    <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                                      Alternate Explanation (Optional)
                                    </Typography>
                                    {quizDataLoaded ? (
                                      <FastField name={`quiz_questions[${index}][alt_explanation]`}>
                                        {({ field }) => (
                                          <MDXEditor
                                            placeholder="Write alt explanation here..."
                                            onChange={(content) => debounce(setFieldValue(`quiz_questions[${index}][alt_explanation]`, content), 100)}
                                            className="mdx-container"
                                            markdown={values.quiz_questions[index]['alt_explanation'] || ''}
                                            plugins={[
                                              toolbarPlugin({
                                                toolbarContents: () => (
                                                  <>
                                                    <UndoRedo />
                                                    <BoldItalicUnderlineToggles />
                                                    <ListsToggle />
                                                    <BlockTypeSelect />
                                                    <InsertTable />
                                                  </>
                                                ),
                                              }),
                                              tablePlugin(),
                                              quotePlugin(),
                                              listsPlugin(),
                                              headingsPlugin(),
                                              markdownShortcutPlugin(),
                                            ]}
                                          />
                                        )}
                                      </FastField>
                                    ) : (
                                      ''
                                    )}
                                    <Typography
                                      sx={{
                                        color: theme.palette.error.main,
                                        marginTop: 2,
                                      }}
                                    >
                                      {getErrorMessage(`quiz_questions[${index}][alt_explanation]`, errors, touched).status
                                        ? getErrorMessage(`quiz_questions[${index}][alt_explanation]`, errors, touched).message
                                        : ''}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                                      Video UID (UID of the video in the lesson - See prev lesson page)
                                    </Typography>
                                    <FastField name={`quiz_questions[${index}][video_uuid]`}>
                                      {({ field }) => (
                                        <TextField
                                          fullWidth
                                          {...field}
                                          name={`quiz_questions[${index}][video_uuid]`}
                                          label="video_uuid"
                                          multiline
                                          maxRows={10}
                                          error={getErrorMessage(`quiz_questions[${index}][video_uuid]`, errors, touched).status}
                                          helperText={
                                            getErrorMessage(`quiz_questions[${index}][video_uuid]`, errors, touched).status
                                              ? getErrorMessage(`quiz_questions[${index}][video_uuid]`, errors, touched).message
                                              : undefined
                                          }
                                        />
                                      )}
                                    </FastField>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                                      Video Timestamp Start Time in seconds(Optional)
                                    </Typography>
                                    <FastField name={`quiz_questions[${index}][video_timestamp]`}>
                                      {({ field }) => (
                                        <TextField
                                          fullWidth
                                          {...field}
                                          name={`quiz_questions[${index}][video_timestamp]`}
                                          value={values.quiz_questions[index]['video_timestamp'] ?? 0}
                                          label="video_timestamp"
                                          multiline
                                          maxRows={10}
                                          error={getErrorMessage(`quiz_questions[${index}][video_timestamp]`, errors, touched).status}
                                          helperText={
                                            getErrorMessage(`quiz_questions[${index}][video_timestamp]`, errors, touched).status
                                              ? getErrorMessage(`quiz_questions[${index}][video_timestamp]`, errors, touched).message
                                              : undefined
                                          }
                                        />
                                      )}
                                    </FastField>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                                      Video Timestamp End Time in seconds(Optional)
                                    </Typography>
                                    <FastField name={`quiz_questions[${index}][video_timestamp_end]`}>
                                      {({ field }) => (
                                        <TextField
                                          fullWidth
                                          {...field}
                                          value={values.quiz_questions[index]['video_timestamp_end'] ?? 0}
                                          name={`quiz_questions[${index}][video_timestamp_end]`}
                                          label="video_timestamp_end"
                                          multiline
                                          maxRows={10}
                                          error={getErrorMessage(`quiz_questions[${index}][video_timestamp_end]`, errors, touched).status}
                                          helperText={
                                            getErrorMessage(`quiz_questions[${index}][video_timestamp_end]`, errors, touched).status
                                              ? getErrorMessage(`quiz_questions[${index}][video_timestamp_end]`, errors, touched).message
                                              : undefined
                                          }
                                        />
                                      )}
                                    </FastField>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                                      Associated Remedial Question(Optional) {quizData.quiz_questions[index]?.remedial_question_uuid}
                                      {quizData.quiz_questions[index]?.hasOwnProperty('remedial_question_uuid') &&
                                        quizData.quiz_questions[index].remedial_question_uuid !== null && (
                                          <Typography>
                                            <Button onClick={() => handleRemedialQFormClickOpen(quizData.quiz_questions[index])}>Edit</Button>
                                          </Typography>
                                        )}
                                      {quizData.quiz_questions[index]?.hasOwnProperty('remedial_question_uuid') &&
                                        quizData.quiz_questions[index].remedial_question_uuid === null && (
                                          <Typography>
                                            <Button onClick={() => handleRemedialQFormClickOpen(quizData.quiz_questions[index])}>Add</Button>
                                          </Typography>
                                        )}
                                    </Typography>
                                  </Grid>

                                  <br />
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          )
                        })}

                        <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleDialogClose}>Cancel</Button>
                            <Button
                              onClick={() => {
                                arrayHelpers.remove(deleteQuestionId)
                                handleDialogClose()
                              }}
                              color="error"
                              autoFocus
                            >
                              Delete
                            </Button>
                          </DialogActions>
                        </Dialog>

                        <br />

                        <Grid item xs={12}>
                          <Grid container gap={1} direction="row" justifyContent="flex-end">
                            <Grid item>
                              <Button
                                onClick={() => {
                                  arrayHelpers.push({
                                    id: uuidv4(),
                                    question: '',
                                    feedback: '',
                                    alt_explanation: '',
                                    video_timestamp: 0,
                                    video_timestamp_end: 0,
                                    video_uuid: newQuestionVideoID.length === 1 ? newQuestionVideoID[0] : '',
                                    options: [{ option_id: uuidv4(), option_title: '' }],
                                  })
                                }}
                                variant="contained"
                                type="button"
                              >
                                Add New Question
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        <br />

                        <Grid item xs={12}>
                          <Divider variant="middle" />
                        </Grid>
                      </Grid>
                    )}
                  />

                  <Grid item xs={12}>
                    <Grid container gap={1} direction="row" justifyContent="flex-end">
                      <Grid item>
                        <Button
                          onClick={() => {
                            setDisplayQuiz(false)
                          }}
                          variant="contained"
                          type="reset"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" type="submit">
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {remedialQuestionSelected && (
            <AddRemedialQuestion open={openRemedialQForm} handleClose={handleRemedialQFormClickClose} question={remedialQuestionSelected} quizId={quizData.id} />
          )}
        </Box>
      </Box>
    </>
  )
}

export default AddQuiz
