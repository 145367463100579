import React, { forwardRef } from 'react'
import { SnackbarContent } from 'notistack'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'

const PointAward = forwardRef((props, ref) => {
  return (
    <SnackbarContent ref={ref}>
      <Box sx={{ display: 'flex', padding: 2, boxShadow: 8, borderRadius: '4px', backgroundColor: 'white' }}>
        <Typography component="span">
          You've been awarded <b>{props.message.value}</b>{' '}
          <StarIcon sx={{ verticalAlign: 'middle', '&&': { color: 'orange' } }} /> Points
        </Typography>
      </Box>
    </SnackbarContent>
  )
})

export default PointAward
