import React from 'react'
import { Box, Button, Link, Typography, Card, CardHeader, CardActions, CardMedia, CardContent } from '@mui/material'
import StopWatchImage from './stopwatch.svg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
const AssessmentCard = React.forwardRef((props, ref) => {
  const { name, uuid, isCompleted } = props
  const assessmentUrl = `/assessment/${uuid}`
  return (
    <Card
      ref={ref}
      sx={{
        maxWidth: 345,
        minWidth: { xs: '320px', sm: '400px', lg: '450px' },
        width: { xs: '320px', sm: '400px', lg: '450px' },
      }}
    >
      <Link href={assessmentUrl} aria-label={`Link to module assessment ${name}`}>
        <CardHeader title={name} />
      </Link>
      <Link href={assessmentUrl} aria-label={`Link to module assessment ${name}`}>
        <Box
          sx={{
            position: 'relative',
            height: 200,
            backgroundColor: '#FFFFFF',
            backgroundImage: `url(${StopWatchImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '400px 170px',
            backgroundPosition: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
          }}
          title={name}
        >
          {isCompleted && (
            <CheckCircleIcon
              color="greenTint"
              sx={{
                backgroundColor: '#FFFFFF',
                padding: 0,
                borderRadius: '50%',
                width: '50px',
                height: '50px',
                position: 'absolute',
                top: '10px',
                left: '10px',
              }}
            />
          )}
        </Box>
      </Link>

      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Typography sx={{ textTransform: 'capitalize' }} variant="body1" color="text.secondary">
            {isCompleted ? 'You have already completed this assessment' : 'Assessment is not completed yet'}
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ marginTop: '15px', marginBottom: '15px', padding: '0 4px 4px 4px' }}>
        <Link sx={{ mx: 'auto' }} href={assessmentUrl} aria-label={`Enter module assessment ${name}`}>
          <Button variant="contained">Enter Assessment</Button>
        </Link>
      </CardActions>
    </Card>
  )
})

export default AssessmentCard
