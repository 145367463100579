import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, Checkbox, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as Yup from 'yup'
import { FastField, Form, Formik } from 'formik'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { DateTime } from 'luxon'

const AddAssessmentSchema = Yup.object().shape({
  name: Yup.string().trim().required('Assessment Name is required!'),
  description: Yup.string().trim().required('Assessment description is required!'),
  duration: Yup.number().required('Duration is required!').min(1, 'Duration should be greater than 0!'),
  pass_percentage: Yup.number()
    .required('Pass percentage is required!')
    .min(1, 'Pass percentage should be greater than 0!')
    .max(100, 'Pass percentage should be less than or equal to 100!'),
})

const AddAssessment = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [selectedModuleId, setSelectedModuleId] = useState('')
  const [modules, setModules] = useState([])
  const { assessmentId } = useParams()

  const [assessmentData, setAssessmentData] = useState({
    name: '',
    module_uuid: '',
    is_active: false,
    description: '',
    duration: 0,
    pass_percentage: 0,
    created_at: DateTime.now(),
    updated_at: DateTime.now(),
  })

  const fetchSessionData = async (assessmentId) => {
    setLoading(true)
    const data = await axios.get(`/admin/assessment/${assessmentId}`)

    return {
      name: data.data.name,
      module_uuid: data.data.module_uuid,
      pass_percentage: data.data.pass_percentage,
      duration: data.data.duration,
      is_active: data.data.is_active,
      description: data.data.description,
      created_at: DateTime.fromMillis(data.data.created_at),
      updated_at: DateTime.fromMillis(data.data.updated_at),
    }
  }

  useEffect(() => {
    axios.get(`/module/get-all-modules`).then((data) => {
      setModules(data.data)
    })
  }, [selectedModuleId])

  useEffect(() => {
    if (assessmentId) {
      fetchSessionData(assessmentId)
        .then((data) => {
          setAssessmentData(data)
          setSelectedModuleId(data.module_uuid)
          setLoading(false)
        })
        .catch((data) => {
          setLoading(false)
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
          navigate('/admin-dashboard?tab=3')
        })
    } else {
      setLoading(false)
    }
  }, [assessmentId])

  const handleModuleSelectChange = async (event) => {
    setSelectedModuleId(event.target.value)
  }

  const handleDeleteAssessment = async () => {
    if (assessmentId) {
      const confirmDelete = window.confirm('Are you sure you want to delete this assessment?')
      if (confirmDelete) {
        try {
          await axios.delete(`/admin/assessment/${assessmentId}`)
          enqueueSnackbar('Assessment Deleted Successfully!', { variant: 'success' })
          navigate('/admin-dashboard?tab=4')
        } catch (e) {
          enqueueSnackbar('Something went wrong when trying to delete the assessment!', { variant: 'error' })
        }
      }
    }
  }

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        {assessmentId ? 'Edit Assessment' : 'Add Assessment'}
      </Typography>
      <Box sx={{ background: '#ffffff', padding: 5, position: 'relative' }}>
        <Link variant="body1" to="/admin-dashboard?tab=4">
          <Button sx={{ padding: '0 10px', marginBottom: 1 }} variant="text">
            <ArrowBackIcon />
            Back
          </Button>
        </Link>
        <br />
        <Box sx={{ width: '100%' }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <Formik
            initialValues={assessmentData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={AddAssessmentSchema}
            onSubmit={async (values) => {
              try {
                setLoading(true)

                const requestData = {
                  name: values.name,
                  description: values.description,
                  duration: values.duration,
                  pass_percentage: values.pass_percentage,
                  image_url: values.image_url,
                  is_active: values.is_active,
                  module_uuid: selectedModuleId,
                }

                let url = '/admin/assessment/create-assessment'

                if (assessmentId) {
                  requestData['uuid'] = assessmentId
                  url = '/admin/assessment/update-assessment'
                }

                await axios.post(url, requestData)

                let snackbarMessage = 'Assessment Added Successfully!'

                if (assessmentId) {
                  snackbarMessage = 'Assessment Updated Successfully!'
                }

                enqueueSnackbar(snackbarMessage, { variant: 'success' })
              } catch (e) {
                enqueueSnackbar('Something went wrong!', { variant: 'error' })
              }

              setLoading(false)
              navigate('/admin-dashboard?tab=4')
            }}
          >
            {({ errors, touched, values, handleSubmit, setFieldValue, validateField }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container rowSpacing={3} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                  <Grid item xs={12}>
                    <FastField name="name">
                      {({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          name="name"
                          label="Assessment Name"
                          error={Boolean(errors.name && touched.name)}
                          helperText={errors.name && touched.name ? String(errors.name) : undefined}
                        />
                      )}
                    </FastField>
                  </Grid>
                  <Grid item xs={12}>
                    <FastField name="description">
                      {({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          name="description"
                          label="Assessment description"
                          multiline
                          maxRows={4}
                          error={Boolean(errors.description && touched.description)}
                          helperText={errors.description && touched.description ? String(errors.description) : undefined}
                        />
                      )}
                    </FastField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel id="is_active">Is Assessment Active</InputLabel>
                    <FastField name="is_active">{({ field }) => <Checkbox checked={values.is_active} {...field} name="is_active" label="Is Assessment Active?" />}</FastField>
                  </Grid>
                  <Grid item xs={12}>
                    <FastField name="duration">
                      {({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          name="duration"
                          label="Assessment Duration (in minutes)"
                          error={Boolean(errors.duration && touched.duration)}
                          helperText={errors.duration && touched.duration ? String(errors.duration) : undefined}
                        />
                      )}
                    </FastField>
                  </Grid>
                  <Grid item xs={12}>
                    <FastField name="pass_percentage">
                      {({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          name="pass_percentage"
                          label="Assessment pass_percentage (in %)"
                          error={Boolean(errors.pass_percentage && touched.pass_percentage)}
                          helperText={errors.pass_percentage && touched.pass_percentage ? String(errors.pass_percentage) : undefined}
                        />
                      )}
                    </FastField>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="group-filter">Select Group</InputLabel>
                      <Select
                        defaultValue=""
                        value={groupData.length ? selectedGroupId : ''}
                        label="Select Group"
                        onChange={async (event) => {
                          await handleGroupSelectChange(event)
                          setFieldValue('selected_lessons', [])
                          setFieldValue('session_lessons', [])

                          if (event.target.value !== '') {
                            setFieldValue('session_group_id', event.target.value)
                          }
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                            },
                          },
                        }}
                      >
                        <MenuItem disabled value="">
                          Select Group
                        </MenuItem>
                        {groupData.map((group) => (
                          <MenuItem value={group.id} key={group.id}>
                            {group.groupName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="module-filter">Select Module associated with the Assessment here: {selectedModuleId}</InputLabel>
                      <Select
                        defaultValue=""
                        value={selectedModuleId}
                        label="Select Module"
                        onChange={async (event) => {
                          await handleModuleSelectChange(event)
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select Module</MenuItem>

                        {modules.map((module) => (
                          <MenuItem value={module.uuid} key={module.uuid}>
                            {module.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container gap={1} direction="row" justifyContent="flex-end">
                      {assessmentId && (
                        <Grid item>
                          <Button onClick={handleDeleteAssessment} variant="delete" type="button" color="error">
                            Delete Assessment
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Link className="top-bar-button" to="/admin-dashboard?tab=4">
                          <Button variant="contained" type="reset">
                            Cancel
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Container>
  )
}

export default AddAssessment
