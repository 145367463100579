import React, { useEffect, useState } from 'react'
import { Alert, Box, CircularProgress, Link, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import { LessonProvider, useLesson } from './context'
import ModuleCompletionModal from './module-completion-modal'

import Video, { VIDEO } from '../video/index'
import Quiz, { QUIZ } from '../quiz/index'
import MultiStep, { MULTI_STEP } from '../multi-step/index'
import Buckets, { BUCKETS } from '../buckets/index'
import FruitMachine, { FRUIT_MACHINE } from '../fruit-machine/index'
import Paragraphs, { PARAGRAPHS_GAME } from '../paragraphs/index'
import CTComputations, { CT_COMPUTATIONS } from '../ct-computations/index'
import SFMACalculations, { SFMA_GAME } from '../sfma-calculations/index'
import DEBKGame, { DEBK_GAME } from '../accounting/index'
import DataGridGame, { POT_DATAGRID_GAME } from '../games/PoT-datagrid-game/index'
import MISpreadSheetGame, { MI_SPREADSHEET_GAME } from '../games/MI-spreadsheet-game/index'
import MIInventoryValuationGame, { MI_Inventory_Valuation_Game } from '../games/MI-inventory/index'
import LegislativeInterpretationGame, { LEGISLATIVE_INTERPRETATION_GAME } from '../games/LegislativeInterpretationGame/index'
import './index.css'

const Lesson = (props) => {
  const navigate = useNavigate()
  const { lessonId, moduleId, elementId } = useParams()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [moduleName, setModuleName] = useState('')
  const [moduleOrder, setModuleOrder] = useState([])
  const [lessonIndex, setLessonIndex] = useState(1)
  const [name, setName] = useState('')
  const [order, setOrder] = useState([])
  const [types, setTypes] = useState({})
  const [elementIndex, setElementIndex] = useState(null)
  const [lessonComplete, setLessonComplete] = useState(false)
  const [elementsComplete, setElementsComplete] = useState([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios(`module/${moduleId}/explore`)
      .then(({ data }) => {
        setModuleName(data.name)
        setModuleOrder(data.order)
        setLessonIndex(data.order.indexOf(lessonId) + 1)
        setOrder(data.lessons[lessonId].order)
        setTypes(data.lessons[lessonId].types)
        setName(data.lessons[lessonId].name)
        setElementsComplete(data.lessons[lessonId].order.filter((id) => data.lessons[lessonId].elements[id].isCompleted))
        setLessonComplete(data.lessons[lessonId].isCompleted)
        setElementIndex(elementId ? data.lessons[lessonId].order.findIndex((id) => id === elementId) : 0)
        setLoading(false)
      })
      .catch(setError)
  }, [moduleId, lessonId])

  useEffect(() => {
    const moreElements = order.length - elementIndex !== 0
    const lessonIndex = moduleOrder.indexOf(lessonId)
    const moreLessons = moduleOrder.length - lessonIndex !== 1
    if (!loading && !moreElements) {
      if (moreLessons) {
        navigate(`/module/${moduleId}/lesson/${moduleOrder[lessonIndex + 1]}`)
      } else {
        navigate('/')
        setElementIndex(0)
      }
    }
  }, [loading, elementIndex])

  if (error) {
    return error.response.status === 403 ? <Alert severity="error">You do not have access to this module</Alert> : <Alert severity="error">An error has occured</Alert>
  }

  if (loading)
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )

  return (
    <LessonProvider value={{ lessonId, moduleId }}>
      {showModal && <ModuleCompletionModal setShowModal={setShowModal} />}

      <Box>
        <Link sx={{ marginRight: '10px' }} href="/">
          Dashboard
        </Link>
        &#62;
        <Link sx={{ marginLeft: '10px', marginRight: '10px' }} href={`/module/${moduleId}/explore`}>
          {moduleName}
        </Link>
        &#62;
        <Link sx={{ marginLeft: '10px', textTransform: 'capitalize' }} href={`/module/${moduleId}/explore#${lessonId}`}>
          {name}
        </Link>
        <Typography sx={{ textAlign: 'center', padding: '10px', margin: '10px' }} variant="h3">
          Lesson {lessonIndex}: {name}
        </Typography>
      </Box>
      <Box
        sx={{
          background: '#FFFFFF',
          borderRadius: '5px',
          padding: { xs: '5px', sm: '10px' },
        }}
      >
        {elementIndex < order.length ? (
          <LessonElement
            key={order[elementIndex]}
            type={types[order[elementIndex]]}
            uuid={order[elementIndex]}
            next={(completed) => {
              const lessonIndex = moduleOrder.indexOf(lessonId)
              const moreLessons = moduleOrder.length - lessonIndex !== 1

              // If this is the last element in the lesson and the lesson is completed, show the module completion modal
              if (!moreLessons && order[elementIndex] === order[order.length - 1] && completed) {
                setShowModal(true)
              } else {
                if (completed) {
                  setElementsComplete([...elementsComplete, order[elementIndex]])
                }
                setElementIndex(elementIndex + 1)
              }
            }}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
    </LessonProvider>
  )
}

export const LessonElement = ({ next, type, uuid }) => {
  console.log('LessonElement', type, uuid)
  switch (type) {
    case VIDEO: {
      return <Video uuid={uuid} next={next} />
    }
    case QUIZ: {
      return <Quiz uuid={uuid} next={next} />
    }
    case MULTI_STEP: {
      return <MultiStep uuid={uuid} next={next} />
    }
    case BUCKETS: {
      return <Buckets uuid={uuid} next={next} />
    }
    case FRUIT_MACHINE: {
      return <FruitMachine uuid={uuid} next={next} />
    }
    case PARAGRAPHS_GAME: {
      return <Paragraphs uuid={uuid} next={next} />
    }
    case CT_COMPUTATIONS: {
      return <CTComputations uuid={uuid} next={next} />
    }
    case SFMA_GAME: {
      return <SFMACalculations uuid={uuid} next={next} />
    }
    case DEBK_GAME: {
      return <DEBKGame uuid={uuid} next={next} />
    }
    case POT_DATAGRID_GAME: {
      return <DataGridGame uuid={uuid} next={next} />
    }
    case MI_SPREADSHEET_GAME: {
      return <MISpreadSheetGame uuid={uuid} next={next} />
    }
    case MI_Inventory_Valuation_Game: {
      return <MIInventoryValuationGame uuid={uuid} next={next} />
    }
    case LEGISLATIVE_INTERPRETATION_GAME: {
      return <LegislativeInterpretationGame uuid={uuid} next={next} />
    }
    default: {
      return <Alert severity="error">This type of lesson element does not exist yet</Alert>
    }
  }
}

export default Lesson
