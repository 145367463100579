// this is not used.
// Instead use the theme in src/themes/
// Keeping here for reference.

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      main: "#F2F6F7",
    },
    darkGrey: {
      main: "#A9B3BE",
    },
    primary: {
      main: "#1756A3",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#5E85C4",
    },
    greenTint: {
      main: "#5EC2A6",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#EB464C",
    },
    warning: {
      main: "#FFC939",
    },
    text: {
      primary: "#0A2645",
      secondary: "#0A2645",
    },
    action: {
      disabledBackground: "#FFFFFF",
      disabled: "#CED4DA",
    },
  },
  typography: {
    fontFamily: ["sans-serif", '"Arial"', '"Helvetica"', '"Inter"'],
    h1: {
      color: "#0A2645",
      fontWeight: 800,
      fontSize: "40px",
      fontFamily: ['"Montserrat"'],
      lineHeight: "50px",
      margin: "35px",
    },
    h2: {
      color: "#0A2645",
      fontWeight: 600,
      fontSize: "28px",
      fontFamily: ['"Montserrat"'],
      lineHeight: "36px",
      margin: "35px",
    },
    h3: {
      color: "#0A2645",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
    },
    h4: {
      color: "#0A2645",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
    },
    h5: {
      color: "#0A2645",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "16px",
    },
    h6: {
      color: "#0A2645",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "1em",
    },
    body1: {
      color: "#0A2645",
    },
    body2: {
      color: "#0A2645",
    },
    caption: {},
    overline: {},
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          border: "2px solid #1756A3",
          fontSize: "18px",
          fontWeight: 600,
          padding: "6px 18px",
          textTransform: "capitalize",
          "&:disabled": {
            border: "2px solid #CED4DA",
          },
        },
        text: {
          border: "none",
          "&.Mui-disabled": {
            border: 0,
          },
        },
        outlined: {
          border: "2px solid #1756A3",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          fontFamily: ['"Inter"'],
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          alignSelf: "center",
        },
      },
    },
  },
});

export default theme;
