import { Box, Link, Typography } from '@mui/material'
import React from 'react'

const Complete = props => {
  return <Box sx={{background: '#FFFFFF', borderRadius: '5px', padding: '40px', width: '100%'}}>
    <Typography sx={{textAlign: 'center'}} variant="h1"><span role='img' aria-label='1st'>⭐</span> Congratulations! <span role='img' aria-label='1st'>⭐</span></Typography>
    <Typography sx={{textAlign: 'center'}} >You completed all the lessons in this session!</Typography>
    <Typography sx={{textAlign: 'center'}}>If you want to learn more, return to the <Link href="/">dashboard</Link> and explore.</Typography>
  </Box>
}

export default Complete