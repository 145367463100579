import React, {useState, useEffect} from 'react'
import { 
  Box,
  Button,
  LinearProgress,
  Typography,
  CircularProgress
} from '@mui/material'
import MultiStepQuestion from './question'
import StoryFeedback from './story-feedback'
import axios from 'axios'

const MultiStepStory = ({description, id, multiStepId, next, storyNumber, totalStories}) => {
  const [loading, setLoading] = useState(true)
  
  const [started, setStarted] = useState(false)
  
  const [story, setStory] = useState(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [questionIds, setQuestionIds] = useState([])
  const [questions, setQuestions] = useState({})
  const [userAnswerIds, setUserAnswerIds] = useState({})

  useEffect(() => {
    axios.get(`/multi-step/story/${id}`).then(res => {
    setStory(res.data)
    setQuestionIds(res.data.order)
    Promise.all(res.data.order.map(id => axios.get(`/question/${id}`))).then((responses) => {
      setQuestions(responses.reduce((acc, {data}) => {
        acc[data.uuid] = data
        return acc
      }, {}))
      setLoading(false)
    })
  })
  }, [id])

  const nextQuestion = (answerId, questionId) => {
    setUserAnswerIds({
      ...userAnswerIds,
      [questionId]: answerId
    })
    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }

  if(loading){
    return <CircularProgress />
  }

  const showFeedback = currentQuestionIndex >= story.order.length
  const currentQuestionId = questionIds[currentQuestionIndex]

  return showFeedback? 
    <StoryFeedback 
      multiStepId={multiStepId}
      questions={story.order.map(id => questions[id])}
      userAnswerIds={userAnswerIds}
      next={next} /> :
    <Box sx={{maxWidth: '1000px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <Typography sx={{alignSelf: 'flex-start', textAlign: 'left', marginBottom: '10px'}}>
        <Typography component="span" sx={{margin: '5px', fontWeight: 'bold'}}>{storyNumber}</Typography>
         of 
         <Typography component="span" sx={{margin: '5px', fontWeight: 'bold'}}>{totalStories}</Typography>
      </Typography>
      <LinearProgress color={'greenTint'} variant="determinate" value={storyNumber / totalStories * 100} />
      {description && <Description text={description}/>}
      
      <Typography sx={{fontSize: '16px'}}>{story.text}</Typography>
      {started ? 
        <MultiStepQuestion 
          multiStepId={multiStepId}
          key={currentQuestionId}
          text={questions[currentQuestionId].text}
          answers={questions[currentQuestionId].answers}
          questionNumber={currentQuestionIndex + 1}
          totalQuestions={story.questions.length}
          next={id => nextQuestion(id, currentQuestionId)} 
        /> :
        <Button sx={{alignSelf: 'center'}} variant="contained" onClick={() => setStarted(true)}>Start answering questions</Button>
      }
    </Box>
}

const Description = ({text}) => {
  return <Box sx={{backgroundColor: '#F2F6F7', padding: '20px', marginBottom: '20px'}}>
    {text.split(/\n/).map((line, index) => <Typography key={`description-line-${index}`}>{line}</Typography>)}
  </Box>
}

export default MultiStepStory