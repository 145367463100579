import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Stack, Table, TableBody, TableCell, Divider, TableHead, TableRow, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import axios from 'axios'

import Question from './question'
import NumberEntry from './number-entry'
import Feedback from './feedback'
import Summary from '../results/summary'

import { useLesson } from '../lesson/context'

// TODO: figure out how to make jagged edge work
// import './index.css'

export const CT_COMPUTATIONS = 'CTComputationsGame'

const CTComputations = ({ next, uuid }) => {
  const [loading, setLoading] = useState(true)
  const [overview, setOverview] = useState(null)
  const [contextsOrder, setContextsOrder] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [correctCount, setCorrectCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [disableNext, setDisableNext] = useState(true)

  const { moduleId } = useLesson()

  useEffect(() => {
    if (loading) {
      axios(`/ct-computations/${uuid}`).then((r) => {
        setOverview(r.data.overview)
        setContextsOrder(r.data.order)
        setLoading(false)
      })
    }
  }, [uuid])

  useEffect(() => {
    if (contextsOrder && currentIndex >= contextsOrder.length) {
      setDisableNext(true)
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: CT_COMPUTATIONS,
          uuid: uuid,
        },
      }).then(() => {
        setDisableNext(false)
      })
    }
  }, [currentIndex])

  function nextCtx(correct, total) {
    setCorrectCount(correctCount + correct)
    setTotalCount(totalCount + total)
    setCurrentIndex(currentIndex + 1)
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ maxWidth: '1000px' }}>
        {currentIndex + 1 <= contextsOrder.length && (
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle1" component="div" align="center">
              Part {currentIndex + 1} of {contextsOrder.length}
            </Typography>
          </Stack>
        )}
        <Divider />
        <Typography sx={{ margin: '20px 0px 10px 0px' }} variant="subtitle2" align="center">
          Overview
        </Typography>
        {overview.split(/\n/).map((line, index) => (
          <Typography sx={{ marginBottom: '10px' }} key={`overview-${index}`}>
            {line}
          </Typography>
        ))}
        {currentIndex < contextsOrder.length ? (
          <Context key={contextsOrder[currentIndex]} uuid={contextsOrder[currentIndex]} next={nextCtx} />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Summary correct={correctCount} total={totalCount} />
            <Button sx={{ marginTop: '20px' }} disabled={disableNext} variant="contained" color="primary" onClick={() => next(true)}>
              Next Step
              <ArrowForwardIosIcon />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const Context = ({ next, uuid }) => {
  const [loading, setLoading] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [lines, setLines] = useState([])
  const [order, setOrder] = useState([])
  const [elements, setElements] = useState({})
  const [correct, setCorrect] = useState(0)

  useEffect(() => {
    setLoading(true)
    axios(`/ct-computations/context/${uuid}`).then((r) => {
      setElements(r.data.elementsById)
      setOrder(r.data.order)
      setLines(JSON.parse(r.data.lines))
      setLoading(false)
    })
  }, [uuid])

  function nextElement(wasCorrect) {
    if (wasCorrect === true) {
      setCorrect(correct + 1)
    }
    if (currentIndex + 1 === order.length) {
      next(correct, order.map((id) => elements[id].type).filter((type) => type !== 'CTComputationsFeedback').length)
    } else {
      setCurrentIndex(currentIndex + 1)
    }
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Box className="jagged-edge" sx={{ background: '#FFF5D8', padding: '10px 10px', marginTop: '10px', marginBottom: '20px' }}>
        <InfoSection lines={lines} />
      </Box>
      <Element key={elements[order[currentIndex]].uuid} gameId={uuid} next={nextElement} element={elements[order[currentIndex]]} />
    </>
  )
}

const InfoSection = ({ lines }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ border: 0, padding: '5px 10px', fontWeight: 'bold' }}>Income</TableCell>
          <TableCell sx={{ border: 0, padding: '5px 10px', fontWeight: 'bold' }}>Value</TableCell>
          <TableCell sx={{ border: 0, padding: '5px 10px', fontWeight: 'bold' }}>Additional Information</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((row, index) => {
          return (
            <TableRow key={`row-${index}`}>
              {row.map((val, cellIndex) => (
                <TableCell sx={{ border: 0, padding: '5px 10px' }} key={`cell-${index}-${cellIndex}`}>
                  {val}
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

const Element = ({ element, gameId, next }) => {
  switch (element.type) {
    case 'Question': {
      return <Question gameId={gameId} next={next} {...element} />
    }
    case 'NumberQuestion': {
      return <NumberEntry gameId={gameId} next={next} {...element} />
    }
    case 'CTComputationsFeedback': {
      return <Feedback gameId={gameId} next={next} {...element} />
    }
    default: {
      return <h1>Element does not exist?! {element.type}</h1>
    }
  }
}

export default CTComputations
