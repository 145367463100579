import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, Button, FormControlLabel, IconButton, Menu, MenuItem, Stack, Switch } from '@mui/material'
import { Link } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { DateTime } from 'luxon'

const SessionState = ({ session }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [value, setValue] = useState(session.isActive)

  let timer = undefined

  const handleChange = async (session) => {
    try {
      await axios.post('/dynamic-session/change-session-state', {
        uuid: session.id,
        isActive: !value,
      })

      setValue(!value)
      enqueueSnackbar('Session Status Updated Successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }
  }

  const debounce = (session, timeout = 300) => {
    clearTimeout(timer)
    timer = setTimeout(() => handleChange(session), timeout)
  }

  return (
    <FormControlLabel
      control={<Switch onChange={() => debounce(session)} checked={value} />}
      label={value ? 'Active' : 'Inactive'}
    />
  )
}

const ActionMenu = ({ param }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        id={`basic-button-${param.row.id}`}
        aria-controls={open ? `basic-menu-${param.row.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label="actions"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`basic-menu-${param.row.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-${param.row.id}`,
        }}
      >
        <Link style={{ textDecoration: 'none' }} to={`/edit-dynamic-session/${param.row.id}`}>
          <MenuItem onClick={handleClose}>Edit Session</MenuItem>
        </Link>
      </Menu>
    </div>
  )
}

const ManageDynamicSessions = () => {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)

  const columns = [
    { field: 'key', headerName: 'Sr No.', headerAlign: 'center', align: 'center', type: 'number', filterable: false },
    { field: 'sessionName', headerName: 'Dynamic Session Name', flex: 1, headerAlign: 'center' },
    {
      field: 'startTime',
      headerName: 'Date',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => {
        return DateTime.fromMillis(value).toFormat('dd-MM-yyyy')
      },
      sortComparator: (a, b) => DateTime.fromMillis(a) - DateTime.fromMillis(b),
    },
    {
      field: 'sessionTimeSlot',
      headerName: 'Time Slot',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      valueGetter: (param) => {
        return (
          DateTime.fromMillis(param.row.startTime).toFormat('T') +
          ' - ' +
          DateTime.fromMillis(param.row.endTime).toFormat('T')
        )
      },
    },
    {
      field: 'isActive',
      headerName: 'Session Status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return <SessionState session={param.row} />
      },
    },
    {
      field: 'sessionCurrentStatus',
      headerName: 'Current Status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      valueGetter: (param) => {
        let status = 'Ongoing'

        let currentTime = new Date().getTime()
        let startTime = param.row.startTime
        let endTime = param.row.endTime

        if (currentTime < startTime) {
          status = 'Upcoming'
        } else if (currentTime > endTime) {
          status = 'Ended'
        }

        return status
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return <ActionMenu param={param} />
      },
    },
  ]

  useEffect(() => {
    axios.get('/dynamic-session').then((data) => {
      const tableData = data.data.map((session, key) => {
        return {
          key: key + 1,
          id: session.uuid,
          endTime: session.endTime,
          sessionName: session.name,
          startTime: session.startTime,
          isActive: session.isActive,
        }
      })

      setTableData(tableData)
      setLoading(false)
    })
  }, [])

  return (
    <div>
      <Stack direction="row" spacing={1} sx={{ margin: 1 }} justifyContent="flex-end" flexWrap="wrap">
        <Link className="top-bar-button" to="/add-dynamic-session">
          <Button variant="contained">Create Dynamic Session</Button>
        </Link>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          onCellClick={() => null}
          onCellDoubleClick={() => null}
          onCellFocusOut={() => null}
          onRowClick={() => null}
          onColumnHeaderClick={() => null}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 200 },
            },
          }}
          rows={tableData}
          columns={columns}
          pageSizeOptions={[10, 25, 100]}
          loading={loading}
          autoHeight={loading}
        />
      </Box>
    </div>
  )
}

export default ManageDynamicSessions
