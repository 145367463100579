import React, { useEffect, useState } from 'react'
import { Box, Button, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material'
import classnames from 'classnames'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import Feedback from '../results/feedback'
import { useLesson } from '../lesson/context'

import './question.css'

const Question = ({ answers, fruitMachineId, onAnswerCheck, text, uuid }) => {
  const [answerId, setAnswerId] = useState(null)
  const [correctIds, setCorrectIds] = useState(null)
  const [checkAnswer, setCheckAnswer] = useState(false)
  const [feedback, setFeedback] = useState(null)

  const { moduleId } = useLesson()

  useEffect(() => {
    if (answerId) {
      axios
        .post(`/fruit-machine/${fruitMachineId}/question/${uuid}/check`, {
          answerId,
          moduleId,
        })
        .then(({ data }) => {
          setCorrectIds(data.correctIds)
          setFeedback(data.feedback)
          onAnswerCheck(answerId, data.correctIds.includes(answerId), data.correctIds)
        })
    }
  }, [checkAnswer])

  const answeredCorrectly = answerId && correctIds && correctIds.some((id) => id === answerId)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ marginTop: '20px' }}>{text}</Typography>
      <RadioGroup
        aria-labelledby="question"
        name="answers"
        value={answerId}
        onChange={(e) => setAnswerId(e.target.value)}
        sx={{ marginTop: '20px' }}>
        {answers.map(({ uuid, text }) => {
          const isCorrect = correctIds && correctIds.some((id) => id === uuid)
          return (
            <FormControlLabel
              className={classnames({
                answer: true,
                'answer-selected': answerId === uuid,
                'answer-correct': correctIds !== null && isCorrect,
                'answer-incorrect': correctIds !== null && answerId === uuid && !isCorrect,
              })}
              disabled={checkAnswer}
              key={`answer-${uuid}`}
              value={uuid}
              control={<Radio />}
              label={<Typography sx={{ fontWeight: 'bold' }}>{text}</Typography>}
            />
          )
        })}
      </RadioGroup>
      {checkAnswer && correctIds ? (
        <Feedback
          isCorrect={answeredCorrectly}
          feedback={
            <Typography component="div">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}>
                {feedback}
              </ReactMarkdown>
            </Typography>
          }
        />
      ) : (
        <Button
          sx={{ alignSelf: 'center', marginTop: '20px' }}
          disabled={answerId === null || checkAnswer}
          variant="contained"
          onClick={() => setCheckAnswer(true)}>
          Check answer
        </Button>
      )}
    </Box>
  )
}

export default Question
