import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Box, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Link from '@mui/material/Link'
import RocketIcon from '@mui/icons-material/Rocket'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, CardActionArea, CardActions } from '@mui/material'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
const ModuleCompletionModal = ({ setShowModal }) => {
  const [open, setOpen] = useState(true)
  const { width, height } = useWindowSize()
  const onClose = () => {
    setShowModal(false)
  }

  const [ready, setReady] = useState(false)
  const [resize, setResize] = useState(false)

  const ref = useRef(null)

  const [widthModal, setModalWidth] = useState(0)

  function handleResize() {
    setModalWidth(Math.round(width * 0.4))
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    setModalWidth(Math.round(width * 0.4))
    console.log('width', width)
  }, [resize, width])

  useLayoutEffect(() => {
    setModalWidth(ref.current && Math.round(ref.current.offsetWidth))
  }, [ready])

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#FFFFFF',
          border: '0',
          boxShadow: 24,
          padding: '55px',
          paddingTop: '35px',
          maxWidth: '1500px',
          minwidth: '360px',
          width: widthModal > 360 ? widthModal : 360,
          '&:focus': {
            border: '0',
            outline: 'none',
          },
        }}
      >
        <Confetti width={widthModal > 360 ? widthModal : 360} height={height} recycle={false} numberOfPieces={500} />
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }} />
        <CompletionBox />
      </Box>
    </Modal>
  )
}

function CompletionBox() {
  const handleReturnToDashboard = () => {
    window.location.href = '/'
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <RocketIcon sx={{ fontSize: '6rem', color: 'primary.main', marginTop: '0' }} />
      <Typography variant="h5" component="h2" sx={{ mt: 2, fontWeight: 'bold', fontSize: '2rem' }}>
        Congratulations!
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
        Well done. You have successfully completed the module.
      </Typography>
      <Button size="small" color="primary" sx={{ mt: 2, fontSize: '0.8rem' }} onClick={handleReturnToDashboard}>
        Return to Dashboard
      </Button>
    </Box>
  )
}

export default ModuleCompletionModal
