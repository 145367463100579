import React, { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import Summary from '../results/summary'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { useSearchParams } from 'react-router-dom'
import { useBadges } from '../badges/context'

const QuizSummary = ({ correctAnswers, disableNext, next, resetQuiz, totalQuestions, retryWrongAnswers }) => {
  const { awardBadge } = useBadges()
  const { width, height } = useWindowSize()
  const percentCompleted = ((correctAnswers / totalQuestions) * 100).toFixed(2)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    awardBadge('FIRST_QUIZ')
    if (correctAnswers === totalQuestions) {
      awardBadge('QUIZ_100%')
    }
    if (correctAnswers === 0) {
      awardBadge('ANY_0%')
    }
  }, [correctAnswers, totalQuestions])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {percentCompleted >= 80 && <Confetti width={width} height={height} recycle={false} numberOfPieces={500} />}
      <Summary correct={correctAnswers} total={totalQuestions} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <Button variant="contained" color="primary" onClick={resetQuiz}>
          <RestartAltIcon />
          Restart Quiz
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        {correctAnswers !== totalQuestions && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#ffa552',
              '&:hover': {
                backgroundColor: '#ffd484',
                border: '2px solid #ffa552',
              },
              border: '2px solid #ffa552',
            }}
            onClick={retryWrongAnswers}
          >
            <RestartAltIcon />
            Retry Incorrect Questions
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <Button disabled={disableNext} variant="contained" color="primary" onClick={() => next(true)}>
          Next Step
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </Box>
  )
}

export default QuizSummary
