import React, { useState, useEffect } from 'react'
import { Box, Typography} from '@mui/material'

import './dial.css'

const Dial = ({ label, options, targetIndex, shouldSpin }) => {
  const [ shouldMove, setShouldMove ] = useState(false)

  return (
    <Box sx={{width: `200px`, marginRight: '20px'}}>
      <Typography sx={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '19px', lineHeight: '35px', textAlign: 'center'}}>{label}</Typography>
      <Box className='dial-viewport-wrapper'>
        <Box className='dial-viewport'>
          <Box className='dial-wrapper' sx={{
            top: !shouldSpin && targetIndex ? -(targetIndex * 190) : 0,
            animation: shouldSpin ? 'spin .5s infinite linear' : 'none',
            "@keyframes spin": {
              "0%": {
                top: 0,
              },
              "100%": {
                top: `${-((options.length) * 190)}px`
              }
            },
          }}>
            {options.map(({imageUrl, text, uuid}) => (
              <DialOption key={uuid} imageUrl={imageUrl} text={text} onClick={() => setShouldMove(!shouldMove)}/>
            ))}
            <DialOption key={`${options[0].uuid}-copy`} imageUrl={options[0].imageUrl} text={options[0].text} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const DialOption = ({imageUrl, text}) => {
  return <Box className='dial-option'>
    {imageUrl && <img className='dial-image' src={imageUrl} />}
    <Typography sx={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '14px', lineHeight: '20px', marginTop: imageUrl ? '20px' : 0, textAlign: 'center'}}>{text}</Typography>
  </Box>
}

export default Dial