import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CircularProgress, Grid } from '@mui/material'
import { Link, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import MainCard from 'components/MainCard'
import theme from 'theme'
import Question from './question'
import Points from './points'
import PieChart from 'components/PieChart'
import { useUser } from '../login/user-context'
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use'

const PractieAreaSummary = ({ incorrectAnswers, screen }) => {
  const navigate = useNavigate()
  const { width, height } = useWindowSize()
  let title = 'First attempt the Module Questions'
  let bodyText = 'Questions you answered incorrectly will appear here.'
  let summaryData = []

  switch (screen) {
    case 1:
      // Already there as default value
      break

    case 2:
      title = 'Congratulations!'
      bodyText = 'All questions answered correctly. Check back here to retry incorrectly answered questions.'
      break

    case 3:
      summaryData = incorrectAnswers
      break

    default:
      break
  }
  return (
    <Box>
      <Box width="100%">
        {summaryData.length > 0 ? (
          <>
            <Typography variant="h3">Summary</Typography>
            <Typography>Review your incorrectly answered questions.</Typography>
            {summaryData.map((question, index) => {
              return (
                <div style={{ margin: '1rem auto' }} key={index}>
                  <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ border: '2px solid #eb464c', backgroundColor: '#eb464c10' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ wordBreak: 'break-all' }}>
                      <div>
                        <Typography sx={{ margin: 0, fontSize: '3ch' }}>{index + 1}. Question</Typography>
                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                          {question.text}
                        </ReactMarkdown>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingY: 0, wordBreak: 'break-all' }}>
                      <hr />
                      <Typography sx={{ margin: 0, fontSize: '3ch' }}>Feedback</Typography>
                      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                        {question.feedback}
                      </ReactMarkdown>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )
            })}
          </>
        ) : (
          <Box sx={{ backgroundColor: '#F2F6F7', textAlign: 'center', padding: '60px', borderRadius: '8px' }}>
            <Typography variant="h3" color={theme.palette.primary.main}>
              {title}
            </Typography>
            <Typography marginTop="4px">{bodyText}</Typography>
            {screen === 2 && <Confetti width={width} height={height} recycle={false} numberOfPieces={500} />}
          </Box>
        )}
      </Box>
      <br />
      {summaryData.length ? (
        <Box textAlign="center">
          <Button variant="contained" onClick={() => window.location.reload()}>
            <RestartAltIcon />
            Retry
          </Button>
        </Box>
      ) : (
        ''
      )}
      <br />
      <Box textAlign="center">
        <Button variant="contained" onClick={() => navigate('/')}>
          Back to Dashboard
        </Button>
      </Box>
    </Box>
  )
}

const PracticeArea = (props) => {
  const navigate = useNavigate()
  const { stats } = useUser()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [incorrectAnswers, setIncorrectAnswers] = useState([])

  /**
   * Screen 1: "First Attempt" text screen
   * Screen 2: "Congratulation" text screen
   * Screen 3: "Question Summary" screen
   */
  const [screen, setScreen] = useState(0)

  useEffect(() => {
    axios(`/user/practice-area`)
      .then((response) => {
        // For future reference
        // const questions = response.data.interactions.filter((i) => i.type === 'question')
        const questions = response.data.interactions
        setQuestions(questions)
        setLoading(false)
      })
      .catch((error) => {
        console.log('[Error]', error)
        enqueueSnackbar('Something went wrong!', { variant: 'error' })
        setLoading(false)
        navigate('/')
      })
  }, [])

  useEffect(() => {
    if (!questions.length) {
      if (!Object.keys(stats).length || stats?.questions?.total === 0) {
        setScreen(1)
      } else {
        setScreen(2)
      }
    }
  }, [stats])

  if (loading) {
    return <CircularProgress />
  }

  const isLastQuestion = currentQuestion === questions.length - 1

  return (
    <Box>
      <Link sx={{ marginRight: '10px' }} href="/">
        Dashboard
      </Link>
      &#62;
      <Link sx={{ marginLeft: '10px', marginRight: '10px' }} href="/practice-area">
        Practice Area
      </Link>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography sx={{ margin: 0 }} variant="h2">
            Practice Area
          </Typography>
          <Typography>Practice helps us to learn new concepts and recall them. Retry the questions you answered incorrectly below.</Typography>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <Points />
        </Box>
      </Box>
      <Box sx={{ padding: 0.2, marginTop: '24px' }}>
        <Grid container spacing={4} flexWrap="wrap">
          <Grid item xs={12} sm={8}>
            <MainCard sx={{ padding: '16px' }} border={false}>
              {currentQuestion < questions.length ? (
                <Question
                  questionNumber={currentQuestion + 1}
                  totalQuestions={questions.length}
                  key={`question-${questions[currentQuestion].uuid}`}
                  questionId={questions[currentQuestion].uuid}
                  next={(wasCorrect, question) => {
                    let updatedCorrectAnswers = correctAnswers
                    if (wasCorrect) {
                      updatedCorrectAnswers = correctAnswers + 1
                      setCorrectAnswers(updatedCorrectAnswers)
                      if (isLastQuestion) {
                        if (questions.length === updatedCorrectAnswers) {
                          setScreen(2)
                        }
                      }
                    }
                    let updatedIncorrectAnswers = [...incorrectAnswers]
                    if (!wasCorrect && question) {
                      updatedIncorrectAnswers.push(question)
                      setIncorrectAnswers(updatedIncorrectAnswers)
                      if (isLastQuestion) {
                        if (questions.length === updatedIncorrectAnswers.length) {
                          setScreen(3)
                        }
                      }
                    }
                    if (isLastQuestion) {
                      if (updatedIncorrectAnswers.length > 0 && updatedCorrectAnswers > 0) {
                        setScreen(3)
                      }
                    }
                    setCurrentQuestion(currentQuestion + 1)
                  }}
                  isLastQuestion={isLastQuestion}
                />
              ) : (
                <PractieAreaSummary incorrectAnswers={incorrectAnswers} screen={screen} />
              )}
            </MainCard>
          </Grid>
          <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Grid container spacing={5} display="flex" flexDirection="column">
              <Grid item>
                {Object.keys(stats).length ? (
                  <MainCard sx={{ padding: '16px' }} content={false} border={false}>
                    <Typography variant="h5">Test Performance</Typography>
                    <PieChart stats={stats} />
                  </MainCard>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default PracticeArea
