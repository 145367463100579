import React, { useEffect, useState, useRef } from 'react'
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  duration,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  IconButton,
  Switch,
  TextField,
  Stack,
  FormControlLabel,
  FormLabel,
  Divider,
  RadioGroup,
  Radio,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import CopyToClipboardButton from '../components/copy-to-clipboard-button'
import * as Yup from 'yup'
import { string, number } from 'yup'
import { FastField, FieldArray, Form, Formik, getIn } from 'formik'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import theme from '../../theme'
import { debounce } from '@mui/material/utils'
import '@mdxeditor/editor/style.css'
import { MDXEditor } from '@mdxeditor/editor'
import { tablePlugin } from '@mdxeditor/editor/plugins/table'
import { quotePlugin } from '@mdxeditor/editor/plugins/quote'
import { listsPlugin } from '@mdxeditor/editor/plugins/lists'
import { toolbarPlugin } from '@mdxeditor/editor/plugins/toolbar'
import { headingsPlugin } from '@mdxeditor/editor/plugins/headings'
import { markdownShortcutPlugin } from '@mdxeditor/editor/plugins/markdown-shortcut'
import { UndoRedo } from '@mdxeditor/editor/plugins/toolbar/components/UndoRedo'
import { ListsToggle } from '@mdxeditor/editor/plugins/toolbar/components/ListsToggle'
import { InsertTable } from '@mdxeditor/editor/plugins/toolbar/components/InsertTable'
import { BlockTypeSelect } from '@mdxeditor/editor/plugins/toolbar/components/BlockTypeSelect'
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles'
import Cookies from 'js-cookie'
import './mdx.css'

const assessmentGroupIdCookieKey = 'admin_panel_assessement_question_group_Id'

const getErrorMessage = (name, errors, touched) => {
  return {
    status: Boolean(getIn(errors, name) && getIn(touched, name)),
    message: getIn(errors, name),
  }
}

const AddQuestionSchema = Yup.object().shape({
  question_text: Yup.string().trim().required('Question Text is required!'),
  // question_feedback: Yup.string().trim().required('Feedback is required!'),
  is_correct: Yup.string().trim().required('Select a correct answer!'),
  question_is_active: Yup.bool().required('Active status is required!'),
  question_answers: Yup.array()
    .of(
      Yup.object({
        text: Yup.string().trim().required('Answer Option cannot be empty!'),
      })
    )
    .min(2, 'Minimum 2 options should be added!'),
  lesson_uuid: Yup.string().trim().required('Select a lesson as a proficiency!'),
})

const AddAssessmentQuestion = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [selectedGroupId, setSelectedGroupId] = useState('')
  const [selectedModuleId, setSelectedModuleId] = useState('')
  const [groupModuleLessonApiData, setGroupModuleLessonApiData] = useState([])
  const [groupModules, setGroupModules] = useState([])
  const [groupModuleLessons, setGroupModuleLessons] = useState([])
  const [groupData, setGroupData] = useState([])
  const [selectedLessonUUID, setSelectedLessonUUID] = useState('')
  const [selectedLessonName, setSelectedLessonName] = useState('')
  const { assessmentId } = useParams()
  const { questionId } = useParams()

  const [questionData, setQuestionData] = useState({
    questionId: '',
    question_text: '',
    question_is_active: false,
    // question_feedback: '',
    question_answers: [],
    lesson_uuid: '',
    lesson_name: '',
    is_correct: '',
  })

  const fetchQuestionData = async (questionId) => {
    setLoading(true)
    const data = await axios.get(`/admin/assessment/${assessmentId}/question/${questionId}`)
    return {
      questionId: data.data.uuid,
      question_text: data.data.text,
      question_is_active: data.data.is_active,
      // question_feedback: data.data.feedback,
      question_answers: data.data.answers,
      lesson_uuid: data.data.lesson_uuid,
      lesson_name: data.data.lesson_name,
      is_correct: data.data.is_correct,
    }
  }

  useEffect(() => {
    if (questionId) {
      fetchQuestionData(questionId)
        .then((data) => {
          setQuestionData(data)
          setSelectedLessonUUID(data.lesson_uuid)
          setSelectedLessonName(data.lesson_name)
          setTimeout(() => {
            setDataLoaded(true)
          }, 300)
          setLoading(false)
        })
        .catch((data) => {
          setLoading(false)
          setTimeout(() => {
            setDataLoaded(true)
          }, 300)
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
          navigate('/admin-dashboard?tab=4')
        })
    } else {
      setTimeout(() => {
        setDataLoaded(true)
      }, 300)
      setLoading(false)
    }
  }, [questionId])

  const handleDeleteQuestion = async () => {
    if (questionId) {
      const confirmDelete = window.confirm('Are you sure you want to delete this question?')
      if (confirmDelete) {
        try {
          await axios.delete(`/admin/assessment/${assessmentId}/question/${questionId}`)
          enqueueSnackbar('Question Deleted Successfully!', { variant: 'success' })
          navigate(`/admin-panel/assessment/${assessmentId}/questions`)
        } catch (e) {
          enqueueSnackbar('Something went wrong when trying to delete the assessment!', { variant: 'error' })
        }
      }
    }
  }

  useEffect(() => {
    axios.get('/group/get-all-groups').then(async (data) => {
      if (data.data && data.data.length) {
        const responseData = data.data.map((group) => {
          return {
            id: group.uuid,
            groupName: group.name,
          }
        })

        setGroupData(responseData)

        const groupIdCookie = Cookies.get(assessmentGroupIdCookieKey)
        if (groupIdCookie) {
          await handleGroupSelectChange({ target: { value: groupIdCookie } })
        }
      }
    })
  }, [])
  const handleGroupSelectChange = async (event) => {
    setSelectedModuleId('')
    setGroupModuleLessons([])

    setSelectedGroupId(event.target.value)
    Cookies.set(assessmentGroupIdCookieKey, event.target.value, { expires: 7 })

    let data = await axios.get(`/group/get-group-module-lessons/${event.target.value}`)

    let modules = []
    let moduleIdArr = []

    data.data.forEach((element) => {
      if (!moduleIdArr.includes(element.module_uuid)) {
        modules.push({
          module_uuid: element.module_uuid,
          module_name: element.module_name,
        })

        moduleIdArr.push(element.module_uuid)
      }
    })

    setGroupModules(modules)
    setGroupModuleLessonApiData(data.data)
  }

  const handleModuleSelectChange = async (event) => {
    setSelectedModuleId(event.target.value)

    let moduleLessons = groupModuleLessonApiData.filter((element) => element.module_uuid === event.target.value)
    Cookies.set('admin_panel_assessement_question_module_Id', event.target.value, { expires: 7 })

    setGroupModuleLessons(moduleLessons)
  }

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        {questionId ? 'Edit Assessment Question' : 'Add Assessment Question'}
      </Typography>
      <Box sx={{ background: '#ffffff', padding: 5, position: 'relative' }}>
        <Link variant="body1" to={`/admin-panel/assessment/${assessmentId}/questions`}>
          <Button sx={{ padding: '0 10px', marginBottom: 1 }} variant="text">
            <ArrowBackIcon />
            Back to Assessment
          </Button>
        </Link>
        <br />
        <Box sx={{ width: '100%' }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <Formik
            initialValues={questionData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={AddQuestionSchema}
            onSubmit={async (values) => {
              try {
                setLoading(true)

                const requestData = {
                  text: values.question_text,
                  // feedback: values.question_feedback,
                  is_active: values.question_is_active,
                  answers: values.question_answers,
                  lesson_uuid: selectedLessonUUID,
                  is_correct: values.is_correct,
                }

                let url = `/admin/assessment/${assessmentId}/create-question`

                if (questionId) {
                  requestData['uuid'] = questionId
                  url = `/admin/assessment/${assessmentId}/question/${questionId}/update`
                }

                await axios.post(url, requestData)

                let snackbarMessage = 'Question Added'

                if (questionId) {
                  snackbarMessage = 'Question Updated'
                }

                enqueueSnackbar(snackbarMessage, { variant: 'success' })
              } catch (e) {
                console.log(e)
                enqueueSnackbar('Something went wrong!', { variant: 'error' })
              }

              setLoading(false)
              navigate(`/admin-panel/assessment/${assessmentId}/questions`)
            }}
          >
            {({ errors, touched, values, handleSubmit, setFieldValue, validateField }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container rowSpacing={3} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                  <Grid item xs={12}>
                    {questionId && (
                      <Typography sx={{ margin: 0, marginBottom: 1 }} variant="">
                        <CopyToClipboardButton content={questionId} />
                        UUID: {questionId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>
                      <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                        Question
                      </Typography>
                    </FormLabel>

                    {dataLoaded && (
                      <FastField name="question_text">
                        {({ field }) => (
                          <MDXEditor
                            placeholder="Write question here..."
                            onChange={(content) => debounce(setFieldValue('question_text', content), 100)}
                            className="mdx-container"
                            markdown={values.question_text || ''}
                            plugins={[
                              toolbarPlugin({
                                toolbarContents: () => (
                                  <>
                                    <UndoRedo />
                                    <BoldItalicUnderlineToggles />
                                    <ListsToggle />
                                    <BlockTypeSelect />
                                    <InsertTable />
                                  </>
                                ),
                              }),
                              tablePlugin(),
                              quotePlugin(),
                              listsPlugin(),
                              headingsPlugin(),
                              markdownShortcutPlugin(),
                            ]}
                          />
                        )}
                      </FastField>
                    )}
                    <Typography sx={{ color: 'error.main' }} variant="subtitle2" gutterBottom>
                      {errors.question_text ? String(errors.question_text) : undefined}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormLabel>
                      <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                        Feedback
                      </Typography>
                    </FormLabel>
                    {dataLoaded && (
                      <FastField name="question_feedback">
                        {({ field }) => (
                          <MDXEditor
                            placeholder="Write feedback here..."
                            onChange={(content) => debounce(setFieldValue(`question_feedback`, content), 100)}
                            className="mdx-container"
                            markdown={values.question_feedback || ''}
                            plugins={[
                              toolbarPlugin({
                                toolbarContents: () => (
                                  <>
                                    <UndoRedo />
                                    <BoldItalicUnderlineToggles />
                                    <ListsToggle />
                                    <BlockTypeSelect />
                                    <InsertTable />
                                  </>
                                ),
                              }),
                              tablePlugin(),
                              quotePlugin(),
                              listsPlugin(),
                              headingsPlugin(),
                              markdownShortcutPlugin(),
                            ]}
                          />
                        )}
                      </FastField>
                    )}
                    <Typography sx={{ color: 'error.main' }} variant="subtitle2" gutterBottom>
                      {errors.question_feedback ? String(errors.question_feedback) : undefined}
                    </Typography>
                  </Grid> */}

                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                      Select Lesson Proficiency
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="group-filter">Select Group</InputLabel>
                      <Select
                        defaultValue=""
                        value={groupData.length ? selectedGroupId : ''}
                        label="Select Group"
                        onChange={async (event) => {
                          await handleGroupSelectChange(event)
                          setFieldValue('selected_lessons', [])
                          setFieldValue('session_lessons', [])

                          if (event.target.value !== '') {
                            setFieldValue('session_group_id', event.target.value)
                          }
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                            },
                          },
                        }}
                      >
                        <MenuItem disabled value="">
                          Select Group
                        </MenuItem>
                        {groupData.map((group) => (
                          <MenuItem value={group.id} key={group.id}>
                            {group.groupName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="module-filter">Select Module</InputLabel>
                      <Select
                        defaultValue=""
                        value={selectedModuleId}
                        label="Select Module"
                        onChange={async (event) => {
                          await handleModuleSelectChange(event)
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                            },
                          },
                        }}
                      >
                        <MenuItem disabled value="">
                          Select Module
                        </MenuItem>
                        {groupModules.map((module) => (
                          <MenuItem value={module.module_uuid} key={module.module_uuid}>
                            {module.module_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="select-lesson-label">Lesson Proficiency: {selectedLessonUUID}</InputLabel>
                      <Select
                        labelId="select-lesson-label"
                        id="lesson-select"
                        value={groupModuleLessons.some((lesson) => lesson.lesson_uuid === selectedLessonUUID) ? selectedLessonUUID : selectedLessonUUID}
                        label="Lesson Proficiency"
                        onChange={async (event) => {
                          if (event.target.value !== '') {
                            setSelectedLessonUUID(event.target.value)
                            setFieldValue('lesson_uuid', event.target.value)
                          }
                        }}
                      >
                        {groupModuleLessons.length ? (
                          groupModuleLessons.map((name) => (
                            <MenuItem key={name.lesson_uuid} value={name.lesson_uuid}>
                              {name.lesson_name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value={selectedLessonUUID}>{selectedLessonName}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <Typography
                      sx={{
                        color: theme.palette.error.main,
                        marginBottom: 2,
                      }}
                    >
                      {typeof getIn(errors, `lesson_uuid`) !== 'object' ? getErrorMessage(`lesson_uuid`, errors, touched).message : ''}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                      Is Question Active?
                    </Typography>
                    <FastField name="question_is_active">
                      {({ field }) => {
                        return <FormControlLabel control={<Switch {...field} checked={field.value} />} label="Is Active" />
                      }}
                    </FastField>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                      <FormLabel>
                        <Typography sx={{ margin: 0, marginBottom: 1 }} variant="h4">
                          Options
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.error.main,
                            marginBottom: 2,
                          }}
                        >
                          {Boolean(getIn(errors, `is_correct`)) ? getErrorMessage(`is_correct`, errors, touched).message : ''}
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.error.main,
                            marginBottom: 2,
                          }}
                        >
                          {typeof getIn(errors, `question_answers`) !== 'object' ? getErrorMessage(`question_answers`, errors, touched).message : ''}
                        </Typography>
                      </FormLabel>

                      <RadioGroup onChange={(event) => setFieldValue(`is_correct`, event.target.value)}>
                        <FieldArray
                          name={`question_answers`}
                          render={(optionArrayHelpers) => (
                            <Grid container direction="column" rowSpacing={1} columnSpacing={1}>
                              {values.question_answers.map((answer, answer_index) => {
                                return (
                                  <Grid key={answer.uuid} item xs={12} marginY={1}>
                                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={1} key={answer.uuid}>
                                      <div className="option-width" style={{ display: 'flex', flexDirection: 'column', height: '250px', gap: '0.2rem' }}>
                                        <FastField name={`question_answers[${answer_index}][text]`}>
                                          {({ field }) => (
                                            <MDXEditor
                                              placeholder="Write option here..."
                                              onChange={(content) => debounce(setFieldValue(`question_answers[${answer_index}][text]`, content), 100)}
                                              className="mdx-container"
                                              markdown={values.question_answers[answer_index]['text'] || ''}
                                              plugins={[
                                                toolbarPlugin({
                                                  toolbarContents: () => (
                                                    <>
                                                      <UndoRedo />
                                                      <BoldItalicUnderlineToggles />
                                                      <ListsToggle />
                                                      <BlockTypeSelect />
                                                      <InsertTable />
                                                    </>
                                                  ),
                                                }),
                                                tablePlugin(),
                                                quotePlugin(),
                                                listsPlugin(),
                                                headingsPlugin(),
                                                markdownShortcutPlugin(),
                                              ]}
                                            />
                                          )}
                                        </FastField>
                                        <Typography
                                          component="span"
                                          sx={{
                                            color: theme.palette.error.main,
                                          }}
                                        >
                                          {getErrorMessage(`question_answers[${answer_index}][text]`, errors, touched).status
                                            ? getErrorMessage(`question_answers[${answer_index}][text]`, errors, touched).message
                                            : ''}
                                        </Typography>
                                      </div>

                                      <FormControlLabel
                                        sx={{ margin: 0 }}
                                        value={answer_index}
                                        control={<Radio checked={answer_index === parseInt(values.is_correct) ? true : false} />}
                                      />
                                      {values.question_answers.length > 1 && (
                                        <IconButton
                                          onClick={() => {
                                            setFieldValue(`correct`, '')
                                            optionArrayHelpers.remove(answer_index)
                                          }}
                                          aria-label="delete"
                                          size="large"
                                        >
                                          <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                      )}
                                    </Stack>
                                  </Grid>
                                )
                              })}
                              <Grid item alignSelf="flex-end">
                                <Button
                                  onClick={() => {
                                    optionArrayHelpers.push({
                                      uuid: uuidv4(),
                                      text: '',
                                      correct: false,
                                    })
                                  }}
                                  size="small"
                                  variant="contained"
                                  type="button"
                                >
                                  Add Option
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        />
                      </RadioGroup>
                    </FormControl>

                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container gap={1} direction="row" justifyContent="flex-end">
                      {questionId && (
                        <Grid item>
                          <Button onClick={handleDeleteQuestion} variant="delete" type="button" color="error">
                            Delete Question
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Link className="top-bar-button" to={`/admin-panel/assessment/${assessmentId}/questions`}>
                          <Button variant="contained" type="reset">
                            Cancel
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Container>
  )
}

export default AddAssessmentQuestion
