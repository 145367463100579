import { useParams } from 'react-router-dom'
import { Link, Grid, Typography, Card } from '@mui/material'
import MainCard from 'components/MainCard'
import Divider from '@mui/material/Divider'
import mixpanel from 'mixpanel-browser'

import Stack from '@mui/material/Stack'

const getPastPaperByModuleId = (moduleId) => {
  const papers = [
    {
      moduleId: 'b27de238-db46-4d91-90e9-a8a49be0fec8',
      paperTitle: 'Business Taxes for Part II students',
      paperURL: 'https://www.examfly-app.com/files/business_taxes_past_papers_review_2019_to_2023.pdf',
    },
    {
      moduleId: '88af8897-89b9-40a5-bc0e-93f7011b3f0a',
      paperTitle: 'VAT Fundamentals for Part II students',
      paperURL: 'https://www.examfly-app.com/files/indirect_taxes_past_paper_review_2019_to_2023.pdf',
    },
    {
      moduleId: '555b6d33-e138-41dd-8c40-e9a9abbbf9a1',
      paperTitle: 'Stamp Duty for Part II',
      paperURL: 'https://www.examfly-app.com/files/capital_taxes_part_II_past_paper_review_2019_to_2023.pdf',
    },
    {
      moduleId: '7c21d6fd-a2ec-4aae-802b-9365c8bc79ee',
      paperTitle: 'Capital Acquisition Taxes for Part II',
      paperURL: 'https://www.examfly-app.com/files/capital_taxes_part_II_past_paper_review_2019_to_2023.pdf',
    },
  ]

  return papers.filter((paper) => paper.moduleId === moduleId)
}

const checkIfPastPaperExists = (moduleId) => {
  return getPastPaperByModuleId(moduleId).length > 0
}

const PastPapersComponent = () => {
  const { moduleId } = useParams()

  return (
    <Grid item xs={12} sm={8}>
      <MainCard sx={{ padding: '16px' }} border={false}>
        <Typography variant="h5">Download Past Exam Paper Reviews</Typography>

        {getPastPaperByModuleId(moduleId).map((paper, index) => (
          <Card key={index} sx={{ padding: '16px', margin: '16px 16px 0px 16px' }}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h6">{paper.paperTitle}</Typography>
              <Link
                href={paper.paperURL}
                target="_blank"
                onClick={(event) => {
                  event.preventDefault()
                  mixpanel.track('button_clicked', {
                    Feature: 'past_exam_paper_reviews',
                    Location: 'module_past_papers',
                    Name: 'Past Exam Paper Review Link Clicked',
                    Link: paper.paperURL,
                  })
                  window.open(paper.paperURL, '_blank')
                }}
              >
                Download {paper.paperTitle}
              </Link>
            </Stack>
          </Card>
        ))}
      </MainCard>
    </Grid>
  )
}
export { PastPapersComponent, checkIfPastPaperExists }
