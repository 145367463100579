import React from 'react'
import { Box, List, ListItemAvatar, ListItem, ListItemText, Avatar } from '@mui/material'
import ConstructionIcon from '@mui/icons-material/Construction'
const MobileNotSupported = () => {
  return (
    <Box sx={{ textAlign: 'center', display: { xs: 'flex', sm: 'none' }, flexDirection: 'column', maxWidth: '1000px' }}>
      <List sx={{ width: '100%' }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <ConstructionIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Sorry! Game requires desktop browser"
            secondary="This game is not yet supported  on mobile devices. Please use a desktop or laptop computer. If you are not on a mobile device try increase your browser window size."
          />
        </ListItem>
      </List>
    </Box>
  )
}

export default MobileNotSupported
