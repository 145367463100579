import React, { useEffect, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormLabel,
  FormControlLabel,
  Input,
  Link,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StarIcon from '@mui/icons-material/Star'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CopyToClipboardButton from '../components/copy-to-clipboard-button'
import axios from 'axios'

import ModulesList from './group-module-list'
import AssessmentsList from './group-assessment-list'
import CreateUser from '../users/create-user'
import { UserList } from '../users/manage'

const EditableGroup = ({
  company: originalCompany,
  onDelete,
  onUpdate,
  leaderboardActive: originalLA,
  isActive: originalGroupIsActive,
  name: originalName,
  uuid,
  proficiencyActive: originalPA,
}) => {
  const [name, setName] = useState(originalName)
  const [company, setCompany] = useState(originalCompany || '')
  const [loading, setLoading] = useState(true)
  const [pointsLoading, setPointsLoading] = useState(true)
  const [points, setPoints] = useState(null)
  const [deleting, setDeleting] = useState(false)
  const [users, setUsers] = useState([])
  const [modules, setModules] = useState([])
  const [assessments, setAssessments] = useState([])
  const [leaderboardActive, setLeaderboardActive] = useState(originalLA)
  const [isActive, setIsActive] = useState(originalGroupIsActive)
  const [proficiencyActive, setProficiencyActive] = useState(originalPA)

  const [update, setUpdate] = useState(false)
  const [addUser, setAddUser] = useState(false)
  const [usersToLink, setUsersToLink] = useState(false)
  useEffect(() => {
    if (deleting) {
      const deleteUsers = window.confirm('The users of this group will be deleted! Are you sure?')
      if (deleteUsers) {
        Promise.all([
          axios({
            url: '/user/bulk',
            method: 'delete',
            data: {
              uuids: users.map((u) => u.uuid),
            },
          }),
          axios({
            url: `/group/`,
            method: 'delete',
            data: {
              groupId: uuid,
            },
          }),
        ]).then((r) => {
          setDeleting(false)
          onDelete(uuid)
        })
      }
    }
  }, [deleting])

  useEffect(() => {
    if (usersToLink) {
      const usersToAdd = usersToLink.existing.concat(usersToLink.created)
      axios({
        url: `/group/${uuid}/users`,
        method: 'post',
        data: {
          emails: usersToAdd.map(({ email }) => email),
        },
      }).then((r) => {
        setUsers(users.concat(usersToAdd.filter((user) => !users.some((u) => u.email === user.email))))
      })
    }
  }, [uuid, usersToLink])

  const setLeaderboard = (leaderboardActive) => {
    axios({
      url: `/group/${uuid}/set-leaderboard`,
      method: 'post',
      data: { leaderboardActive },
    })

    setLeaderboardActive(leaderboardActive)
  }

  const handleIsActive = (isActive) => {
    axios({
      url: `/group/${uuid}/set-active`,
      method: 'post',
      data: { is_active: isActive },
    })

    setIsActive(isActive)
  }

  const handleSetProficiencyActive = (proficiencyActive) => {
    axios({
      url: `/group/${uuid}/set-proficiency-active`,
      method: 'post',
      data: { proficiency_active: proficiencyActive },
    })

    setProficiencyActive(proficiencyActive)
  }
  const handleChange = (uuid) => (event, isExpanded) => {
    if (loading) {
      axios(`/group/${uuid}`).then((group) => {
        setUsers(group.data.users)
        setModules(group.data.modules)
        setAssessments(group.data.assessments)
        setLeaderboardActive(!!group.data.leaderboardActive)
        setLoading(false)
      })
    }
    if (pointsLoading) {
      axios(`/reports/points/group/${uuid}`).then((r) => {
        setPoints(r.data.total)
        setPointsLoading(false)
      })
    }
  }
  useEffect(() => {
    if (!loading && update) {
      axios({
        url: `/group/${uuid}/update`,
        method: 'post',
        data: { name, company },
      }).then((r) => {
        setUpdate(false)
        onUpdate({
          company,
          leaderboardActive,
          name,
          isActive,
          proficiencyActive,
        })
      })
    }
  }, [loading, update])

  return (
    <Accordion sx={{ flexGrow: 1, marginLeft: 'auto' }} onChange={handleChange(uuid)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {name} - {modules.length > 0 ? modules.length : 'Expand for info on'} Modules - {users.length > 0 ? `${users.length} Users` : ''}
          <Typography component="span" sx={{ backgroundColor: '#F2F6F7', display: 'inline-flex', padding: '10px', fontWeight: 'bold' }}>
            <StarIcon sx={{ '&&': { color: 'orange' } }} /> {typeof points === 'number' ? `${points} Total points` : 'Expand group for points'}
          </Typography>
        </Typography>
        <Typography sx={{ marginLeft: 'auto' }}>
          <Link href={`/group/${uuid}`}>Go to report</Link>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h3">Group details</Typography>
        <Typography sx={{ margin: 0, marginBottom: 1 }} variant="">
          <CopyToClipboardButton content={uuid} />
          UUID: {uuid}
        </Typography>
        <Box sx={{ borderBottom: '2px solid #F2F6F7', display: 'flex', flexDirection: 'column' }}>
          {!loading && <FormControlLabel control={<Checkbox checked={isActive} onChange={(e) => handleIsActive(e.target.checked)} />} label="IsActive" />}
          {!loading && <FormControlLabel control={<Checkbox checked={leaderboardActive} onChange={(e) => setLeaderboard(e.target.checked)} />} label="Leaderboard" />}
          {!loading && (
            <FormControlLabel control={<Checkbox checked={proficiencyActive} onChange={(e) => handleSetProficiencyActive(e.target.checked)} />} label="Proficiency Active" />
          )}

          <TextField
            name="name"
            label="Name of the group"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ flex: 1, margin: '10px 0', width: '250px' }}
            size="small"
          />
          <TextField
            name="name-of-company"
            label="Name of the company"
            variant="outlined"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            sx={{ flex: 1, margin: '10px 0', width: '250px' }}
            size="small"
          />

          <Button sx={{ alignSelf: 'flex-start', margin: '10px 0' }} onClick={() => setUpdate(true)}>
            {update && <CircularProgress />}
            Update Name & Company
          </Button>
          <Button sx={{ alignSelf: 'flex-start', margin: '10px 0' }} disabled={deleting} onClick={() => setDeleting(true)}>
            <DeleteForeverIcon />
            Delete Group
          </Button>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box sx={{ borderBottom: '2px solid #F2F6F7' }}>
              <ModulesList
                addedModule={(module) => setModules([...modules, module])}
                removedModule={(uuid) => setModules(modules.filter((m) => m.uuid !== uuid))}
                groupId={uuid}
                modules={modules}
              />
            </Box>
            <Box sx={{ borderBottom: '2px solid #F2F6F7' }}>
              <AssessmentsList
                addedAssessment={(assessment) => setAssessments([...assessments, assessment])}
                removedAssessment={(uuid) => setAssessments(assessments.filter((m) => m.uuid !== uuid))}
                groupId={uuid}
                assessments={assessments}
              />
            </Box>
            <Box sx={{ marginBottom: '20px' }}>
              <Typography variant="h3">Users:</Typography>
              <Button onClick={() => setAddUser(true)}>Add Single New User</Button>
            </Box>
            <Modal open={addUser} onClose={() => setAddUser(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <CreateUser onAdd={() => setAddUser(false)} groupId={uuid} />
              </Box>
            </Modal>
            <CreateFromCsv onCreate={setUsersToLink} />
          </>
        )}
        <UserList
          onDelete={(uuid) => {
            setUsers(users.filter((u) => u.uuid !== uuid))
          }}
          userList={users}
          onUpdate={(uuid, newData) => {
            setUsers(users.map((u) => (u.uuid === uuid ? newData : u)))
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}

function fromCSV(csvValue) {
  return csvValue
    .split(/\r?\n/)
    .slice(1)
    .map((l) => {
      const [name, email] = l.split(',')
      return { name, email }
    })
}

const CreateFromCsv = ({ onCreate }) => {
  const [users, setUsers] = useState('')
  const [savingUsers, setSavingUsers] = useState(false)

  const [isSSO, setIsSSO] = useState(null)

  useEffect(() => {
    if (savingUsers) {
      axios({
        url: '/user/bulk/create',
        method: 'post',
        data: {
          isSSO,
          users,
        },
      }).then((r) => {
        setSavingUsers(false)
        onCreate(r.data)
      })
    }
  }, [savingUsers])

  return (
    <Box sx={{ backgroundColor: '#F2F6F7', padding: '20px' }}>
      <Typography sx={{ fontWeight: 'bold' }}>Create or Add users from CSV</Typography>
      <Box>
        <Input type="file" inputProps={{ accept: '.csv' }} onChange={(e) => e.target.files[0].text().then((r) => setUsers(fromCSV(r)))} />
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <FormLabel id="SSO-label">Are they SSO users?</FormLabel>
        <RadioGroup aria-labelledby="SSO-label">
          <FormControlLabel selected={isSSO === true} onChange={() => setIsSSO(true)} value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel selected={isSSO === false} onChange={() => setIsSSO(false)} value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </Box>
      <Button disabled={isSSO === null || savingUsers || users === ''} onClick={() => setSavingUsers(true)}>
        {savingUsers && (
          <>
            <CircularProgress />
            Creating users...
          </>
        )}
        Create/Add users to group
      </Button>
    </Box>
  )
}

export default EditableGroup
