import { ACTIONS, CREDIT, DEBIT, calculateAccountEntryValue } from './definitions'

function getMistakes(accountDefinitions, user, correct) {
      return Object.entries(user).reduce((acc, [account, values]) => {
    let correctFinalValue = 0
        ;[DEBIT, CREDIT].forEach((type) => {
      const correctValue = correct[account] && correct[account][type]
      const userValue = values[type]
      const targetLabel = accountDefinitions[account].label
      
      if (userValue !== correctValue) {
        const hasUserValue = userValue !== 0
        const hasExpectedValue = correctValue !== undefined
        if (!acc[account]) {
          acc[account] = []
        }
        if (hasUserValue && hasExpectedValue) {
          acc[account].push(`${ACTIONS[type].label}ed ${targetLabel} ${userValue} instead of ${correctValue}`)
          correctFinalValue += calculateAccountEntryValue(type, accountDefinitions[account].account || account, correctValue)
        } else if (hasExpectedValue && !hasUserValue) {
          acc[account].push(`${ACTIONS[type].label}ed nothing on ${targetLabel} instead of ${correctValue}`)
          correctFinalValue += calculateAccountEntryValue(type, accountDefinitions[account].account || account,  correctValue)
        } else if (!hasExpectedValue && hasUserValue) {
          acc[account].push(`${ACTIONS[type].label}ed ${userValue} on ${targetLabel}, no ${ACTIONS[type].label} was needed.`)
        }
      } else {
        correctFinalValue += calculateAccountEntryValue(type, accountDefinitions[account].account || account,  correctValue)
      }
    })
    if (acc[account] && acc[account].length > 0) {
      acc[account].push(correctFinalValue)
    }
        return acc
  }, {})
}

export default getMistakes
