import React, { useEffect, useState, useCallback } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, Button, FormControlLabel, IconButton, Menu, MenuItem, Stack, Switch, Divider, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Link } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import axios from 'axios'
import { useSnackbar } from 'notistack'

const AssessmentStatus = ({ assessment }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [value, setValue] = useState(assessment.assessment_is_active)

  let timer = undefined

  const handleChange = async (session) => {
    try {
      await axios.post(`/admin/assessment/${assessment.id}/change-assessment-state`, {
        is_active: !value,
      })

      setValue(!value)
      enqueueSnackbar('Assessment Status Updated Successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }
  }

  const debounce = (assessment, timeout = 300) => {
    clearTimeout(timer)
    timer = setTimeout(() => handleChange(assessment), timeout)
  }

  return <FormControlLabel control={<Switch onChange={() => debounce(assessment)} checked={value} />} label={value ? 'Active' : 'Inactive'} />
}

const ActionMenu = ({ param }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        id={`basic-button-${param.row.id}`}
        aria-controls={open ? `basic-menu-${param.row.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label="actions"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`basic-menu-${param.row.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-${param.row.id}`,
        }}
      >
        <Link style={{ textDecoration: 'none' }} to={`/admin-panel/edit-assessment/${param.row.id}`}>
          <MenuItem onClick={handleClose}>Edit Assessment</MenuItem>
        </Link>
        <Link style={{ textDecoration: 'none' }} to={`/admin-panel/assessment/${param.row.id}/questions`}>
          <MenuItem onClick={handleClose}>Assessment Questions</MenuItem>
        </Link>
      </Menu>
    </div>
  )
}

const ManageAssessments = () => {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)
  const [groupFilter, setGroupFilter] = useState('')
  const [groups, setGroups] = useState([])

  const getGroupsAPI = useCallback((selectedGroupID) => {
    setGroupFilter(selectedGroupID)
    axios(`/group/?is_active=true`).then((r) => {
      setGroups(r.data)
    })
  }, [])

  useEffect(() => {
    if (loading) {
      setLoading(false)
      getGroupsAPI()
    }
  }, [loading])

  const columns = [
    { field: 'key', headerName: '#', headerAlign: 'center', align: 'center', type: 'number', filterable: false },
    { field: 'assessment_name', headerName: 'Assessment Name', flex: 1, headerAlign: 'center' },
    {
      field: 'assessment_is_active',
      headerName: 'Assessment Status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return <AssessmentStatus assessment={param.row} />
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return <ActionMenu param={param} />
      },
    },
  ]

  useEffect(() => {
    if (groups.length > 0 && !loading) {
      const assessmentGroupFilter = groupFilter ? `?group_uuid=${groupFilter}` : ''
      axios.get(`/admin/assessment/${assessmentGroupFilter}`).then((data) => {
        const tableData = data.data.map((assessment, key) => {
          return {
            key: key + 1,
            id: assessment.assessment_uuid,
            assessment_name: assessment.assessment_name,
            group_name: assessment.group_name,
            assessment_is_active: assessment.assessment_is_active,
          }
        })

        setTableData(tableData)
        setLoading(false)
      })
    }
  }, [groups, loading])

  return (
    <div>
      <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
      <Stack direction="row" spacing={2} justifyContent="space-between" divider={<Divider orientation="vertical" flexItem />}>
        {!loading && <GroupFilter groups={groups} setGroupFilter={getGroupsAPI} groupFilter={groupFilter} />}
      </Stack>
      <Divider sx={{ marginTop: '10px' }} />
      <Stack direction="row" spacing={1} sx={{ margin: 1 }} justifyContent="flex-end" flexWrap="wrap">
        <Link className="top-bar-button" to="/admin-panel/create-assessment">
          <Button variant="contained">Create Assessment</Button>
        </Link>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          onCellClick={() => null}
          onCellDoubleClick={() => null}
          onCellFocusOut={() => null}
          onRowClick={() => null}
          onColumnHeaderClick={() => null}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 200 },
            },
          }}
          rows={tableData}
          columns={columns}
          pageSizeOptions={[10, 25, 100]}
          loading={loading}
          autoHeight={loading}
        />
      </Box>
    </div>
  )
}

export default ManageAssessments

const GroupFilter = ({ groups, groupFilter, setGroupFilter }) => {
  const handleChange = (event) => {
    if (event.target.value === 'ALL_GROUPS') {
      setGroupFilter('')
      return
    }
    setGroupFilter(event.target.value)
  }
  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>Filter by</Typography>
        <FormControl fullWidth={true}>
          <InputLabel id="demo-simple-select-label">Group</InputLabel>

          <Select defaultValue="" sx={{ minWidth: '200px' }} labelId="demo-simple-select-label" id="demo-simple-select" value={groupFilter || ''} onChange={handleChange}>
            <MenuItem key="blank" value="ALL_GROUPS">
              All Groups
            </MenuItem>
            {groups.map((group) => (
              <MenuItem key={group.uuid} value={group.uuid}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}
