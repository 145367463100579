import { useState, useRef } from 'react'
import { KnockProvider, KnockFeedProvider, NotificationIconButton, NotificationFeedPopover } from '@knocklabs/react'

import '@knocklabs/react/dist/index.css'
import { useUser } from '../login/user-context'
import { getKnockKey, getKnockFeedID } from 'utils/knock-config'
import './notification.css'

const NotificationsButton = () => {
  const [isVisible, setIsVisible] = useState(false)
  const notifButtonRef = useRef(null)
  const { uuid, knockToken } = useUser()

  return (
    uuid && (
      <KnockProvider apiKey={getKnockKey()} userId={uuid} userToken={knockToken}>
        <KnockFeedProvider feedId={getKnockFeedID()}>
          <>
            <NotificationIconButton ref={notifButtonRef} onClick={(e) => setIsVisible(!isVisible)} />
            <NotificationFeedPopover buttonRef={notifButtonRef} isVisible={isVisible} onClose={() => setIsVisible(false)} />
          </>
        </KnockFeedProvider>
      </KnockProvider>
    )
  )
}

export default NotificationsButton
