import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

export const SessionContext = React.createContext(null)

export const SessionProvider = (props) => {
  const { sessionId } = useParams()
  const [session, setSession] = useState({})
  const [loading, setLoading] = useState(true)
  const [lessons, setLessons] = useState({})
  const [currentModule, setCurrentModule] = useState(null)
  const [currentLesson, setCurrentLesson] = useState(null)
  const [currentElement, setCurrentElement] = useState(null)

  const navigate = useNavigate()
  // const { state } = useLocation()
  // const { lessonId, elementId } = useParams()

  // useEffect(() => {
  //   if(state){
  //     const { lessonId, elementId } = state
  //     if(lessonId !== currentLesson) {
  //       setCurrentLesson(lessonId)
  //     }
  //     if(elementId !== currentElement) {
  //       setCurrentElement(elementId)
  //     }
  //   }
  // }, [state, lessonId, elementId ])

  useEffect(() => {
    if (sessionId && loading) {
      axios(`/dynamic-session/${sessionId}`).then((sessionResponse) => {
        setSession(sessionResponse.data)
        axios({
          url: '/progress/lessons',
          method: 'post',
          data: { lessonIds: sessionResponse.data.lessonIds },
        }).then((r) => {
          const currentLesson = sessionResponse.data.lessonIds.find((lessonId) => !r.data[lessonId].complete)
          if (!currentLesson) {
            navigate(`/dynamic-session/${sessionResponse.data.uuid}/complete`)
            setCurrentLesson(sessionResponse.data.lessonIds[sessionResponse.data.lessonIds.length - 1])
            setLessons(r.data)
            setLoading(false)
            setLoading(false)
          } else {
            setCurrentLesson(currentLesson)
            setCurrentModule(r.data[currentLesson].moduleId)
            const currentElement = r.data[currentLesson].elements.find((element) => !element.complete).uuid
            setCurrentElement(currentElement)

            setLessons(r.data)
            setLoading(false)

            navigate(`/dynamic-session/${sessionResponse.data.uuid}/${currentLesson}/${currentElement}`, { state: { lessonId: currentLesson, elementId: currentElement } })
          }
        })
      })
    }
  }, [loading, sessionId])

  const next = () => {
    const currentLessonIndex = session.lessonIds.indexOf(currentLesson)
    const elements = lessons[currentLesson].elements
    const currentElementIndex = elements.findIndex(({ uuid }) => uuid === currentElement)
    const isLastElement = currentElementIndex + 1 === elements.length

    if (isLastElement) {
      const nextLessonIndex = currentLessonIndex + 1
      if (nextLessonIndex < session.lessonIds.length) {
        const nextLessonId = session.lessonIds[nextLessonIndex]
        const nextElementId = lessons[nextLessonId].elements[0].uuid

        const updatedElements = elements.map((element) => {
          let complete = element.complete

          if (element.uuid === currentElement) {
            complete = true
          }

          return { ...element, complete }
        })

        setLessons({
          ...lessons,
          [currentLesson]: {
            ...lessons[currentLesson],
            elements: updatedElements,
            complete: true,
          },
        })
        setCurrentLesson(nextLessonId)
        setCurrentElement(nextElementId)

        navigate(`/dynamic-session/${session.uuid}/${currentLesson}/${currentElement}`, { state: { lessonId: nextLessonId, elementId: nextElementId } })
      } else {
        const updatedElements = elements.map((element) => {
          let complete = element.complete

          if (element.uuid === currentElement) {
            complete = true
          }

          return { ...element, complete }
        })

        setLessons({
          ...lessons,
          [currentLesson]: {
            ...lessons[currentLesson],
            elements: updatedElements,
            complete: true,
          },
        })

        navigate(`/dynamic-session/${session.uuid}/complete`)
      }
    } else {
      const nextElement = elements[currentElementIndex + 1].uuid
      setLessons(getLessonsWithElementComplete(elements, lessons, currentLesson, currentElement))
      setCurrentElement(nextElement)
      navigate(`/dynamic-session/${session.uuid}/${currentLesson}/${nextElement}`, { state: { lessonId: currentLesson, elementId: nextElement } })
    }
  }

  return (
    <SessionContext.Provider
      value={{
        sessionId: props.value.sessionId,
        lessonIds: session.lessonIds,
        currentModule,
        currentLesson,
        currentElement,
        session,
        lessons,
        loading,
        next,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  )
}

export function useSession() {
  return React.useContext(SessionContext)
}

function getLessonsWithElementComplete(elements, lessons, currentLesson, currentElement) {
  const currentElementIndex = elements.findIndex(({ uuid }) => uuid === currentElement)
  // HACK: because slice passes references
  const nextElements = elements.map((element) => ({
    ...element,
  }))
  nextElements[currentElementIndex].complete = true

  return {
    ...lessons,
    [currentLesson]: {
      ...lessons[currentLesson],
      elements: nextElements,
    },
  }
}
