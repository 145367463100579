import React, { forwardRef } from 'react'
import { SnackbarContent } from 'notistack'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

const ClipSegment = forwardRef((props, ref) => {
  return (
    <SnackbarContent ref={ref}>
      <Box sx={{ display: 'flex', padding: 2, boxShadow: 8, borderRadius: '4px', backgroundColor: 'white' }}>
        <Box>
          <Typography
            textAlign={'center'}
            sx={{ fontWeight: 'bold', color: 'rgb(23, 86, 163)', fontSize: '18px', paddingBottom: '10px' }}
          >
            {props.message.title}
          </Typography>
          <Typography component="div" textAlign={'center'}>
            {props.message.content}
          </Typography>
        </Box>
      </Box>
    </SnackbarContent>
  )
})

export default ClipSegment
