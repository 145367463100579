import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import axios from 'axios'

import './upsert.css'

const Answer = (props) => {
  const [text, setText] = useState(props.text || '')
  const [correct, setCorrect] = useState(!!props.correct)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (saving) {
      axios({
        url: `question/${props.questionId}/answer/${props.uuid || ''}`,
        method: 'post',
        data: {
          correct,
          text,
        },
      })
        .then((r) => {
          setSaving(false)
          props.onSave(r.data)
        })
        .catch((e) => {
          setError(e)
          setSaving(false)
        })
    }
  }, [saving])

  useEffect(() => {
    if (deleting) {
      axios({
        url: `question/${props.questionId}/answer/${props.uuid}`,
        method: 'delete',
      })
        .then((r) => {
          setDeleting(false)
        })
        .catch((e) => {
          setError(e)
          setDeleting(false)
        })
    }
  }, [deleting])

  const changed = text !== props.text || correct !== props.correct

  return (
    <div className="wrapper">
      <TextField
        aria-labelledby={`answer-text-${props.uuid}`}
        name={`answer-text-${props.uuid}`}
        value={text}
        onChange={(e) => setText(e.target.value)}
        label="Answer text"
        variant="outlined"
        sx={{ flex: 1 }}
        size="small"
        multiline
        rows={2}
      />
      <FormControlLabel
        sx={{ marginLeft: 1 }}
        onChange={(e) => setCorrect(e.target.checked)}
        control={<Checkbox checked={correct} />}
        label="Correct"
      />
      <Button disabled={!changed} onClick={() => setSaving(true)}>
        {saving && <CircularProgress size="1rem" />}
        Save
      </Button>
      <Button onClick={() => setDeleting(true)}>
        <DeleteIcon />
      </Button>
    </div>
  )
}

export default Answer
