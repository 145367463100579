import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material'

import './upsert.css'

const EditVideoWrapper = ({ linkingToLesson, uuid, onSave }) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  useEffect(() => {
    if (uuid) {
      axios(`/video/${uuid}`)
        .then((r) => {
          setData(r.data)
          setLoading(false)
        })
        .catch(setError)
    } else {
      setLoading(false)
    }
  }, [uuid])

  return (
    <div>
      {loading && <CircularProgress />}
      {error && (
        <Alert severity="error">Error! {error.response.data.message}</Alert>
      )}
      {data && !loading && (
        <EditVideo
          onSave={onSave}
          linkingToLesson={linkingToLesson}
          {...data}
        />
      )}
    </div>
  )
}

const EditVideo = (props) => {
  const { linkingToLesson } = props
  const [vimeoUrl, setVimeoUrl] = useState(props.vimeoUrl || '')
  const [description, setDescription] = useState(props.description || '')
  const [saving, setSaving] = useState(false)
  const [saveError, setSaveError] = useState(null)

  useEffect(() => {
    if (saving) {
      axios
        .post(`/video/${props.uuid || ''}`, {
          description: description,
          vimeoUrl: vimeoUrl,
        })
        .then((r) => {
          setSaving(false)
          typeof props.onSave === 'function' && props.onSave(r.data.uuid)
        })
        .catch((e) => {
          console.log(e)
          setSaveError(e)
        })
    }
  }, [saving])

  return (
    <FormControl className="question-mui-wrapper">
      {saveError && (
        <Alert severity="error">Error! {saveError.response.data.message}</Alert>
      )}
      <div className="video-line-wrapper">
        <TextField
          aria-labelledby="description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label="Description of the video"
          variant="outlined"
          sx={{ flex: 1 }}
          size="small"
        />
      </div>
      <div className="video-line-wrapper">
        <TextField
          aria-labelledby="video"
          name="video"
          value={vimeoUrl}
          onChange={(e) => setVimeoUrl(e.target.value)}
          label="Video Url"
          variant="outlined"
          sx={{ flex: 1 }}
          size="small"
        />
      </div>
      <Button
        disabled={
          vimeoUrl === props.vimeoUrl && description === props.description
        }
        onClick={() => setSaving(true)}
        sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
        size="small"
      >
        {saving || linkingToLesson ? <CircularProgress size="1rem" /> : null}
        Save
      </Button>
    </FormControl>
  )
}

export default EditVideoWrapper
