import React, {useEffect, useState} from 'react'
import { Typography,
    Box,
    CircularProgress,
    Button
} from '@mui/material'
import axios from 'axios'

import Summary from '../results/summary'
import QuestionsSummary from '../results/questions'
import { useLesson } from '../lesson/context'

const StoryFeedback = ({questions, multiStepId, next, userAnswerIds}) => {
  const [loading, setLoading] = useState(true)
  const [correctAnswers, setCorrectAnswers] = useState({})
  console.log(correctAnswers)
  const { moduleId } = useLesson()

  useEffect(() => {
    Promise.all(questions.map(({uuid}) => {
      return axios.post(`/multi-step/${multiStepId}/question/${uuid}/check`, {
        answerId: userAnswerIds[uuid],
        moduleId
      })
    })).then((responses) => {
      const correctByQuestionId = questions.reduce((acc, {uuid}, index) => {
        acc[uuid] = {
          correctIds: responses[index].data.correctIds,
          feedback: responses[index].data.feedback
        }
        return acc
      }, {})
      setCorrectAnswers(correctByQuestionId)
      setLoading(false)
    })
  }, [userAnswerIds, questions])

  if (loading) {
    return <>
      <CircularProgress />
      <Typography variant="p" component="p">Checking your answers</Typography>
    </>
  }
  
  const score = correctAnswers && questions.filter(({uuid}) => correctAnswers[uuid].correctIds.includes(userAnswerIds[uuid])).length

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '1000px'}}>
      <Box sx={{marginBottom: '20px'}}>
        <Summary correct={score} total={questions.length} />
      </Box>
      {questions.map((q, i) => (
        <QuestionsSummary
          key={q.uuid}
          answers={q.answers}
          correctAnswers={correctAnswers[q.uuid]}
          questionNumber={i + 1}
          question={q.text}
          selectedAnswer={userAnswerIds[q.uuid]}
          feedback={correctAnswers[q.uuid].feedback}
        />
      ))}
      <Button sx={{marginTop: '20px'}} variant="contained" onClick={() => next(questions.length, score)}>Next</Button> 
    </ Box>
  )
}

export default StoryFeedback