import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import Feedback from '../results/feedback'
import { useLesson } from '../lesson/context'

const NumberEntry = ({ next, text, uuid }) => {
  const [answer, setAnswer] = useState('')
  const [checking, setChecking] = useState(false)
  const [correctAnswer, setCorrectAnswer] = useState(null)

  const { moduleId } = useLesson()

  useEffect(() => {
    if (checking || correctAnswer)
      axios({
        url: `/number-question/${uuid}/check`,
        method: 'post',
        data: {
          answer,
          moduleId,
        },
      }).then((r) => setCorrectAnswer(r.data.correctAnswer))
  }, [checking, correctAnswer])

  return (
    <Box sx={{ background: '#F2F6F7', padding: '40px' }}>
      <Typography id="question">{text}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <TextField
          aria-labelledby="question"
          name="answer"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={answer}
          onChange={(e) => setAnswer(parseInt(e.target.value.replace(/[^0-9]/g, '')))}
          label="Calculation result ..."
          variant="outlined"
          sx={{ background: '#FFFFFF', flex: 1, marginTop: '20px', width: '75%' }}
          size="small"
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}>
        {!correctAnswer && (
          <Button disabled={typeof answer !== 'number'} sx={{ alignSelf: 'center', marginTop: '20px' }} variant="contained" onClick={() => setChecking(true)}>
            Check
          </Button>
        )}
        {correctAnswer && (
          <>
            <Feedback
              feedback={
                <Typography component="div">
                  <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                    {`The correct answer is ${correctAnswer}`}
                  </ReactMarkdown>
                </Typography>
              }
              isCorrect={correctAnswer === answer}
            />
            <Button sx={{ alignSelf: 'center', marginTop: '20px' }} variant="contained" onClick={() => next(correctAnswer === answer)}>
              Next
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}

export default NumberEntry
