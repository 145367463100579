import React, { useEffect, useState } from 'react'

import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material'

import axios from 'axios'
import throttle from 'lodash/throttle'

const fetchAssessments = throttle((partialName, startSearch, callback) => {
  if (partialName.length >= 3) {
    startSearch()
    return axios({
      url: '/admin/assessment/find',
      method: 'post',
      data: {
        partialName,
      },
    }).then((r) =>
      callback(
        r.data.map(({ name, uuid }) => ({
          label: name,
          id: uuid,
        }))
      )
    )
  }
  return Promise.resolve([])
}, 500)

const AssessmentList = ({ addedAssessment, removedAssessment, groupId, assessments }) => {
  const [selectedAssessment, setSelectedAssessment] = useState('')
  const [removeAssessment, setRemoveAssessment] = useState('')
  const [assessmentOptions, setAssessmentOptions] = useState([])
  const [assessmentOptionsLoading, setAssessmentOptionsLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (selectedAssessment && selectedAssessment !== '') {
      axios({
        url: `/enrollment/group/${groupId}/assessment`,
        method: 'post',
        data: {
          assessmentId: selectedAssessment.id,
        },
      }).then((r) => {
        setSelectedAssessment('')
        addedAssessment({ uuid: selectedAssessment.id, name: selectedAssessment.label })
      })
    }
  }, [selectedAssessment])

  useEffect(() => {
    if (removeAssessment !== '') {
      axios({
        url: `/enrollment/group/${groupId}/assessment`,
        method: 'delete',
        data: {
          assessmentId: removeAssessment,
        },
      }).then((r) => {
        setRemoveAssessment('')
        removedAssessment(removeAssessment)
      })
    }
  }, [removeAssessment])

  return (
    <Box>
      <Typography variant="h3">Assessments:</Typography>
      <Autocomplete
        loading={assessmentOptionsLoading}
        value={selectedAssessment}
        onChange={(event, newValue) => {
          setSelectedAssessment(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.length >= 3) {
            fetchAssessments(
              newInputValue,
              () => setAssessmentOptionsLoading(true),
              (newAssessments) => {
                setAssessmentOptions(newAssessments)
                setAssessmentOptionsLoading(false)
              }
            )
          } else {
            setAssessmentOptions([])
          }
          setInputValue(newInputValue)
        }}
        disablePortal
        options={assessmentOptions}
        renderInput={(params) => <TextField {...params} label="Add assessment ..." />}
      />
      <Box sx={{ padding: '10px 0' }}>
        {assessments.map(({ name, uuid }) => (
          <Chip label={name} key={uuid} onDelete={() => setRemoveAssessment(uuid)} />
        ))}
      </Box>
    </Box>
  )
}

export default AssessmentList
