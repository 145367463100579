import { Box, Link, Typography, Container, AppBar, useTheme, Toolbar } from '@mui/material'
import React from 'react'
import MainCard from '../components/MainCard'
import logo from '../top-bar/examfly-logo-blue.png'
import TopBar from '../top-bar'
import { useUser } from '../login/user-context'

const ContactUs = () => {
  return <Link href="mailto:support@examfly.com">contact us</Link>
}

const Maintenance = (props) => {
  const theme = useTheme()
  const isLogin = useUser().username !== '' ? true : false

  return (
    <>
      {isLogin ? (
        <TopBar />
      ) : (
        <AppBar position="static" sx={{ bgcolor: '#FFFFFF', borderBottom: `1px solid ${theme.palette.divider}`, boxShadow: 'none' }}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Link href="/">
                <img alt="Examfly logo" className="top-bar-logo" src={logo} />
              </Link>
            </Toolbar>
          </Container>
        </AppBar>
      )}
      <Container maxWidth="xl" sx={{ padding: { xs: 1 } }}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box
            component="main"
            sx={{
              width: '100%',
              flexGrow: 1,
              paddingTop: 0,
              paddingRight: { xs: 1, sm: 3 },
              paddingLeft: { xs: 1, sm: 3 },
            }}
          >
            <MainCard title="">
              <Typography variant="h1">Maintenance Page</Typography>
              <Box sx={{ background: '#FFFFFF', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ maxWidth: '1000px', padding: '20px', textAlign: 'center' }}>
                  <Typography sx={{ marginBlockStart: '1em', marginBlockEnd: '1em', fontWeight: 'bold' }}>
                    We're currently undergoing scheduled maintenance to improve our service for you.
                  </Typography>
                  <Typography sx={{ marginBlockStart: '1em', marginBlockEnd: '1em' }}>
                    We apologize for any inconvenience this may cause. We'll be back in about 20 minutes.
                  </Typography>
                  <Typography sx={{ marginBlockStart: '1em', marginBlockEnd: '1em' }}>In the meantime if you have some feedback for us or need assistance with a query?</Typography>
                  <Typography>
                    Be sure to&nbsp;
                    {<ContactUs />}, we are happy to help!
                  </Typography>
                  <Typography sx={{ marginBlockStart: '1em', marginBlockEnd: '1em' }}>Thank you for your patience and understanding.</Typography>
                </Box>
              </Box>
            </MainCard>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default Maintenance
