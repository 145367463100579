import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        {...props}
        size={40}
        thickness={6}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: props.value < 90 ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          variant="caption"
          component="div"
          color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default function CircularWithValueLabel({ switchLoaderAndAdaptivePanel }) {
  const [progress, setProgress] = React.useState(10)
  React.useEffect(() => {
    const timer = setInterval(() => {
      if (progress > 90) {
        clearInterval(timer)
        switchLoaderAndAdaptivePanel(false)
      } else {
        setProgress((prevProgress) => prevProgress + 10)
      }
    }, 220)
    return () => {
      clearInterval(timer)
    }
  }, [progress, switchLoaderAndAdaptivePanel])

  return <CircularProgressWithLabel value={progress} />
}
