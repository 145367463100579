import React, { useState } from 'react'
import { addUpTransactions, calculateBalanceSheet } from './definitions'

export const BalanceContext = React.createContext({})

export const BalanceProvider = ({ children, accountDefinitions: ACC_DEF }) => {
  const [accountDefinitions, setAccountDefinitions] = useState(ACC_DEF)
  const [correctTransactions, setCorrectTransactions] = useState([])
  const [userTransactions, setUserTransactions] = useState([])
  const [userMistakes, setUserMistakes] = useState({})

  const addTransactions = (user, correct, mistakes) => {
    setUserTransactions([...userTransactions, user])
    setCorrectTransactions([...correctTransactions, correct])

    setUserMistakes(Object.entries(mistakes).reduce((acc, [accountId, m]) => {
      if(!acc[accountId]) {
        acc[accountId] = []
      }
      acc[accountId] = acc[accountId].concat(m)
      return acc
    },{...userMistakes}))
  }

  const resetData = () => {
    setUserTransactions([])
    setCorrectTransactions([])
    setUserMistakes({})
  }

  // const getProfitLoss = (transactions) => transactions[REVENUE] - transactions[EXPENSES]

  const getBothBalanceSheets = () => {
    const userAccounts = addUpTransactions(accountDefinitions, userTransactions)
    const correctAccounts = addUpTransactions(accountDefinitions, correctTransactions)
    const userSheet = calculateBalanceSheet(accountDefinitions, userAccounts)
    const correctSheet = calculateBalanceSheet(accountDefinitions, correctAccounts)

    return {
      correctAccounts,
      correctSheet,
      userAccounts,
      userSheet
    }
  }

  return (
    <BalanceContext.Provider value={{
      accountDefinitions,
      addTransactions,
      correctTransactions,
      getBothBalanceSheets,
      resetData,
      setAccountDefinitions,
      userTransactions,
      userMistakes,
    }}>
      {children}
    </BalanceContext.Provider>
  )
}

export function useBalance() {
  return React.useContext(BalanceContext)
}