import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Divider, Table, TableBody, TableCell, TableRow, TextField, Typography, CardHeader, Avatar, Fade, Zoom } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import Feedback from './feedback'
import MobileNotSupported from '../../components/MobileNotSupported'
import InventoryValuationGame from '../MI-inventory/inventory-valuation-game.js'
import CalculateIcon from '@mui/icons-material/Calculate'
import { useLesson } from '../../lesson/context'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DataGridComponent from '../../common-components/datagrid'
import { Global, css } from '@emotion/react'
import { set } from 'lodash'
export const MI_Inventory_Valuation_Game = 'MI_Inventory_Valuation_Game'

const globalStyles = css`
  @keyframes pulseBorder {
    0% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(23, 86, 163, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0);
    }
  }
`

const MIInventoryValuationGame = ({ next, uuid = '5f78682f-ec35-44c7-a87e-9277ec990a98' }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const { moduleId } = useLesson()

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
        <CircularProgress />
      </Box>
    )
  }

  return <GameScreen gameId={uuid} next={next} />
}

const GameScreen = ({ gameId, next }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [checking, setChecking] = useState(false)
  const [results, setResults] = useState([])
  const { moduleId } = useLesson()
  const [highlightedCells, setHighlightedCells] = React.useState([])
  const [allCorrect, setAllCorrect] = React.useState(false)
  const [feedback, setFeedback] = React.useState(false)
  const [wrongIndexes, setWrongIndexes] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [nextQuestion, setNextQuestion] = useState(0)
  const [wasDataGridChanged, setWasDataGridChanged] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    // axios(`/datagrid-game/${gameId}`).then((r) => {
    //   setData(r.data)

    setLoading(false)
    // })
  }, [gameId])



  const doProgress = () => {
    axios({
      url: `/progress/`,
      method: 'post',
      data: {
        moduleId,
        type: MI_Inventory_Valuation_Game,
        uuid: gameId,
      },
    }).then(() => {
      next(true)
    })
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <>
      <MobileNotSupported />

      <Box sx={{ display: { xs: 'none', sm: 'flex', minWidth: '800px', maxWidth: '1200px', margin: '0px auto 0px auto' }, justifyContent: 'center' }}>
        <Box sx={{ backgroundColor: '#F2F6F7', padding: '5px 10px', marginBottom: '1px', width: '100%' }}>
          {/* <Typography variant="h4">Inventory Calculation Game</Typography>
          <Typography>
            The aim of this game is to correctly categorise the different types of income in the Incomes Table below and apportion the personal allowance of £12,570 correctly.
            <br />
            Enter the first income value in the correct row and category. <br />
            When you are ready click 'Check' to proceed to the next Income.
          </Typography> */}

          <Typography variant="h5" gutterBottom>
            Welcome to the Inventory Valuation Quiz Game!
          </Typography>
          <Typography variant="body1" paragraph>
            This game is designed to teach and test your understanding of FIFO (First-In, First-Out) and LIFO (Last-In, First-Out) inventory valuation methods. These methods are
            crucial in accounting for determining the cost of goods sold and the value of remaining inventory. Have your calculator at the ready.
            <CalculateIcon style={{ verticalAlign: 'middle' }} />
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
        <Box sx={{ background: '#FFFFFF', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ background: '#ffffff', margin: '0px 0px 0px 40px', Width: '600px' }}>
            <InventoryValuationGame />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'flex' }, background: '#F2F6F7', justifyContent: 'center', marginTop: '40px', padding: '5px' }}>
        <Button sx={{ margin: '0 auto' }} variant="contained" onClick={() => doProgress()}>
          Next
        </Button>
      </Box>
    </>
  )
}

export default MIInventoryValuationGame
