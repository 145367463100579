import axios from 'axios'

const MODULE = 'Module'
const LESSON = 'Lesson'
const QUIZ = 'Quiz'
const VIDEO = 'Video'
const QUESTION = 'Question'
const ANSWER = 'Answer'
const MULTI_STEP = 'MultiStep'
const STORY = 'Story'
const FRUIT_MACHINE = 'FruitMachine'
const FRUIT_MACHINE_COMBINATION = 'FruitMachineCombination'
const FRUIT_MACHINE_ELEMENT = 'FruitMachineElement'
const BUCKET_GAME = 'BucketGame'
const BUCKET_TARGET = 'BucketTarget'
const BUCKET_ITEM = 'BucketItem'
const PARAGRAPHS_GAME = 'ParagraphsGame'
const PARAGRAPHS_INSTANCE = 'ParagraphsInstance'
const PARAGRAPHS_TEXT = 'ParagraphsText'
const PARAGRAPHS_TARGET = 'ParagraphsTarget'
const PARAGRAPHS_ITEM = 'ParagraphsItem'

// TODO: update recursively

function updateNode(data) {
  return axios({
    url:'/update-node',
    method: 'post',
    data
  })
}

export async function updateLesson(data) {
  const { imageUrl, order, name, uuid } = data
  await updateNode({
    imageUrl,
    name,
    type: LESSON,
    uuid
  })
  await Promise.all(order.map(element => {
    switch(element.type) {
      case 'Video': {
        return updateNode({
          ...element,
          type: VIDEO
        })
      }
      case 'Quiz': {
        return updateQuiz(element)
      }
      case 'Buckets': {
        return updateBuckets(element)
      }
      case 'FruitMachine': {
        return updateFruitMachine(element)
      }
      case 'MultiStep': {
        return updateMultiStep(element)
      }
      case 'Paragraphs': {
        return updateParagraphs(element)
      }
      // case 'CTComputations': {
      //   return updateCTGame(element)
      // }
    }
  }))
}

export async function updateQuiz({name, questions, uuid}) {
  await updateNode({
    name,
    uuid,
    type: QUIZ
  })
  await Promise.all(questions.map(updateQuestion))
}

export async function updateBuckets({name, items, question, targets, uuid}) {
  await updateNode({
    name,
    question,
    type: BUCKET_GAME,
    uuid
  })
  await Promise.all(targets.map((target) => {
    return updateNode({
      ...target,
      type: BUCKET_TARGET
    })
  }))
  await Promise.all(items.map((item) => {
    return updateNode({
      ...item,
      type: BUCKET_ITEM
    })
  }))
}

export async function updateParagraphs({instances, name, uuid}) {
  await updateNode({
    name,
    type: PARAGRAPHS_GAME,
    uuid
  })
  await Promise.all(instances.map(({elements, feedback, name, options, uuid}) => {
    return Promise.all([
      updateNode({
        name,
        feedback,
        type: PARAGRAPHS_INSTANCE,
        uuid
      }),
      Promise.all(elements.map((element) => {
        return updateNode({
          ...element,
          type: element.type === 'target' ? PARAGRAPHS_TARGET : PARAGRAPHS_TEXT
        })
      })),
      Promise.all(options.map((option) => {
        return updateNode({
          ...option,
          type: PARAGRAPHS_ITEM
        })
      }))
    ])
  }))
}

export async function updateMultiStep(data) {
  await updateNode({
    name: data.name,
    type: MULTI_STEP
  })
  await Promise.all(data.stories.map((story) => {
    return Promise.all([
      updateNode({
        name: story.name,
        text: story.text.join('\n'),
        type: STORY,
        uuid: story.uuid
      }),
      Promise.all(story.questions.map(updateQuestion)) 
    ])
  }))
}

export async function updateFruitMachine(data) {
  await Promise.all(data.combinations.map(({elements, question, uuid}) => {
    return Promise.all([
      Promise.all(elements.map(({imageUrl, text, uuid}) => {
        return updateNode({
          imageUrl,
          text,
          type: FRUIT_MACHINE_ELEMENT,
          uuid
        })
      })),
      updateQuestion(question)
    ])
  }))
}

export async function updateQuestion(data) {
  const question = await updateNode({
    text: data.text,
    feedback: data.feedback,
    type: QUESTION,
    uuid: data.uuid
  })

  await Promise.all(data.answers.map((answer) => {
    return updateNode({
      ...answer,
      type: ANSWER
    })
  }))
}