import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, Checkbox, Chip, CircularProgress, Container, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as Yup from 'yup'
import { FastField, Form, Formik } from 'formik'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'

const AddSessionSchema = Yup.object().shape({
  session_name: Yup.string().trim().required('Session Name is required!'),
})

const MultipleSelectCheckmarks = ({ apiData, setSelectedSessionData, selectedValues }) => {
  const [selectedLessonName, setSelectedLessonName] = useState(selectedValues)

  useEffect(() => {
    setSelectedLessonName(selectedValues)
  }, [selectedValues])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    let newData = value
      .map((id) => {
        let record = apiData.find((x) => x.lesson_uuid === id)
        if (record) {
          return record
        }

        return undefined
      })
      .filter((x) => x)

    setSelectedLessonName(value) // Do this because select doesn't accept obj[] as value prop
    setSelectedSessionData(newData)
  }

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="multiple-lesson-selection">Select Lessons</InputLabel>
        <Select
          labelId="multiple-lesson-selection"
          id="multiple-lesson-checkbox"
          multiple
          defaultValue=""
          value={selectedLessonName}
          onChange={handleChange}
          renderValue={(selected) => {
            let data = selected
              .map((id) => {
                let record = apiData.find((x) => x.lesson_uuid === id)
                if (record) {
                  return record
                }

                return undefined
              })
              .filter((x) => x)

            return data.length + ' Selected'
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 230,
                width: 250,
              },
            },
          }}
        >
          {apiData.length ? (
            apiData.map((name) => (
              <MenuItem key={name.lesson_uuid} value={name.lesson_uuid}>
                <Checkbox checked={selectedLessonName.includes(name.lesson_uuid)} />
                <ListItemText sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }} primary={name.lesson_name} />
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled value={''}>
              <ListItemText primary={'No Data!'} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  )
}

const AddDynamicSession = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedGroupId, setSelectedGroupId] = useState('')
  const [selectedModuleId, setSelectedModuleId] = useState('')
  const [selectedLessons, setSelectedLessons] = useState([])
  const [groupModuleLessonApiData, setGroupModuleLessonApiData] = useState([])
  const [groupModules, setGroupModules] = useState([])
  const [groupModuleLessons, setGroupModuleLessons] = useState([])
  const [groupData, setGroupData] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const { sessionId } = useParams()

  const [sessionData, setSessionData] = useState({
    session_name: '',
    session_lessons: [],
    session_group_id: '',
    session_date: DateTime.now(),
    session_start_time: DateTime.now(),
    session_end_time: DateTime.now().plus({ hours: 1 }),
  })

  const fetchSessionData = async (sessionId) => {
    setLoading(true)
    const data = await axios.get(`/dynamic-session/get-dynamic-session/${sessionId}`)

    return {
      session_name: data.data.name,
      session_lessons: data.data.selected_session_lessons ?? [],
      session_group_id: data.data.groupId,
      session_date: DateTime.fromMillis(data.data.startTime),
      session_start_time: DateTime.fromMillis(data.data.startTime),
      session_end_time: DateTime.fromMillis(data.data.endTime),
      all_session_lessons: data.data.session_lessons,
    }
  }

  useEffect(() => {
    if (sessionId) {
      fetchSessionData(sessionId)
        .then((data) => {
          let modules = []
          let moduleIdArr = []

          data.all_session_lessons.forEach((element) => {
            if (!moduleIdArr.includes(element.module_uuid)) {
              modules.push({
                module_uuid: element.module_uuid,
                module_name: element.module_name,
              })

              moduleIdArr.push(element.module_uuid)
            }
          })

          setGroupModuleLessonApiData(data.all_session_lessons)

          delete data['all_session_lessons']

          setGroupModules(modules)
          setSessionData(data)
          setSelectedGroupId(data.session_group_id)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
          navigate('/admin-dashboard?tab=3')
        })
    } else {
      setLoading(false)
    }
  }, [sessionId])

  useEffect(() => {
    switch (error) {
      case 'minTime': {
        setErrorMessage('Session end time should be greater than session start time')
        break
      }
      default: {
        setErrorMessage('')
        break
      }
    }
  }, [error])

  useEffect(() => {
    axios.get('/group/get-all-groups').then((data) => {
      if (data.data && data.data.length) {
        const responseData = data.data.map((group) => {
          return {
            id: group.uuid,
            groupName: group.name,
          }
        })

        setGroupData(responseData)
      }
    })
  }, [])

  const handleGroupSelectChange = async (event) => {
    setSelectedModuleId('')
    setSelectedLessons([])
    setGroupModuleLessons([])

    setSelectedGroupId(event.target.value)

    let data = await axios.get(`/group/get-group-module-lessons/${event.target.value}`)

    let modules = []
    let moduleIdArr = []

    data.data.forEach((element) => {
      if (!moduleIdArr.includes(element.module_uuid)) {
        modules.push({
          module_uuid: element.module_uuid,
          module_name: element.module_name,
        })

        moduleIdArr.push(element.module_uuid)
      }
    })

    setGroupModules(modules)
    setGroupModuleLessonApiData(data.data)
  }

  const handleModuleSelectChange = async (event, sessionLessons) => {
    setSelectedModuleId(event.target.value)

    let moduleLessons = groupModuleLessonApiData.filter((element) => element.module_uuid === event.target.value)

    let selectedLessonsId = sessionLessons.map((x) => x.lesson_uuid)

    let selectLessonsArr = moduleLessons
      .map((x) => {
        if (selectedLessonsId.includes(x.lesson_uuid)) {
          return x.lesson_uuid
        } else {
          return undefined
        }
      })
      .filter((x) => x)

    setGroupModuleLessons(moduleLessons)
    setSelectedLessons(selectLessonsArr)
  }

  const handleLessonDelete = (lessonId, lessons) => {
    return lessons.filter((x) => x.lesson_uuid !== lessonId)
  }

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        {sessionId ? 'Edit Session' : 'Add Session'}
      </Typography>
      <Box sx={{ background: '#ffffff', padding: 5, position: 'relative' }}>
        <Link variant="body1" to="/admin-dashboard?tab=3">
          <Button sx={{ padding: '0 10px', marginBottom: 1 }} variant="text">
            <ArrowBackIcon />
            Back
          </Button>
        </Link>
        <br />
        <Box sx={{ width: '100%' }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <Formik
            initialValues={sessionData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={AddSessionSchema}
            onSubmit={async (values) => {
              try {
                setLoading(true)

                let lesson_ids = values.session_lessons.map((x) => x.lesson_uuid)

                let start_ts = values.session_date.setZone('utc').toFormat('yyyy-MM-dd') + 'T' + values.session_start_time.setZone('utc').toFormat("HH:mm:00.000'Z'")
                start_ts = DateTime.fromISO(start_ts).toUTC().toJSON()

                let end_ts = values.session_date.setZone('utc').toFormat('yyyy-MM-dd') + 'T' + values.session_end_time.setZone('utc').toFormat("HH:mm:00.000'Z'")
                end_ts = DateTime.fromISO(end_ts).toUTC().toJSON()

                const requestData = {
                  session_name: values.session_name,
                  start_ts: start_ts,
                  end_ts: end_ts,
                  lesson_ids: lesson_ids,
                }

                if (values.session_group_id !== '') {
                  requestData['group_uuid'] = values.session_group_id
                }

                let url = '/dynamic-session/create-dynamic-session'

                if (sessionId) {
                  requestData['dynamic_session_uuid'] = sessionId
                  url = '/dynamic-session/update-dynamic-session'
                }

                await axios.post(url, requestData)

                let snackbarMessage = 'Dynamic Session Added Successfully!'

                if (sessionId) {
                  snackbarMessage = 'Dynamic Session Updated Successfully!'
                }

                enqueueSnackbar(snackbarMessage, { variant: 'success' })
              } catch (e) {
                enqueueSnackbar('Something went wrong!', { variant: 'error' })
              }

              setLoading(false)
              navigate('/admin-dashboard?tab=3')
            }}
          >
            {({ errors, touched, values, handleSubmit, setFieldValue, validateField }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container rowSpacing={3} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                  <Grid item xs={12}>
                    <FastField name="session_name">
                      {({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          name="session_name"
                          label="Dynamic Session Name"
                          error={Boolean(errors.session_name && touched.session_name)}
                          helperText={errors.session_name && touched.session_name ? String(errors.session_name) : undefined}
                        />
                      )}
                    </FastField>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" flexWrap="nowrap" justifyContent="space-between" alignItems="center" gap={1}>
                      <Grid item>
                        <MobileDatePicker
                          label="Select Date"
                          format="dd-MM-yyyy"
                          value={values.session_date}
                          onAccept={(newValue) => {
                            setFieldValue('session_date', newValue)
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <MobileTimePicker
                          label="Select Start Time"
                          format="hh:mm a"
                          value={values.session_start_time}
                          onAccept={(newValue) => {
                            setFieldValue('session_start_time', newValue)
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <MobileTimePicker
                          minTime={values.session_start_time}
                          label="Select End Time"
                          format="hh:mm a"
                          value={values.session_end_time}
                          onAccept={(newValue) => {
                            setFieldValue('session_end_time', newValue)
                          }}
                          onError={(newError, value) => {
                            setError(newError)
                          }}
                          slotProps={{
                            textField: {
                              helperText: errorMessage,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="group-filter">Select Group</InputLabel>
                      <Select
                        defaultValue=""
                        value={groupData.length ? selectedGroupId : ''}
                        label="Select Group"
                        onChange={async (event) => {
                          await handleGroupSelectChange(event)
                          setFieldValue('selected_lessons', [])
                          setFieldValue('session_lessons', [])

                          if (event.target.value !== '') {
                            setFieldValue('session_group_id', event.target.value)
                          }
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                            },
                          },
                        }}
                      >
                        <MenuItem disabled value="">
                          Select Group
                        </MenuItem>
                        {groupData.map((group) => (
                          <MenuItem value={group.id} key={group.id}>
                            {group.groupName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="module-filter">Select Module</InputLabel>
                      <Select
                        defaultValue=""
                        value={selectedModuleId}
                        label="Select Module"
                        onChange={async (event) => {
                          await handleModuleSelectChange(event, values.session_lessons)
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                            },
                          },
                        }}
                      >
                        <MenuItem disabled value="">
                          Select Module
                        </MenuItem>
                        {groupModules.map((module) => (
                          <MenuItem value={module.module_uuid} key={module.module_uuid}>
                            {module.module_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <MultipleSelectCheckmarks
                      selectedValues={selectedLessons}
                      apiData={groupModuleLessons}
                      setSelectedSessionData={(value) => {
                        let selectedLessonsId = value.map((x) => x.lesson_uuid)
                        let newLessons = values.session_lessons
                        let removeLessons = groupModuleLessons.filter((x) => !selectedLessonsId.includes(x.lesson_uuid))
                        removeLessons = removeLessons.map((x) => x.lesson_uuid)

                        value.forEach((lesson) => {
                          if (!newLessons.find((x) => x.lesson_uuid === lesson.lesson_uuid)) {
                            newLessons.push(lesson)
                          }
                        })

                        newLessons = newLessons.filter((lesson) => !removeLessons.includes(lesson.lesson_uuid))

                        let selectedLessonsIdArr = newLessons.map((l) => l.lesson_uuid)

                        setSelectedLessons(selectedLessonsIdArr)
                        setFieldValue('session_lessons', newLessons)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container gap={1} direction="row">
                      {values.session_lessons.map((lesson) => {
                        return (
                          <Grid item key={lesson.lesson_uuid}>
                            <Chip
                              label={lesson.lesson_name}
                              onDelete={() => {
                                let newLessons = handleLessonDelete(lesson.lesson_uuid, values.session_lessons)
                                let moduleSelectedLessons = selectedLessons.filter((x) => x !== lesson.lesson_uuid)

                                setSelectedLessons(moduleSelectedLessons)
                                setFieldValue('session_lessons', newLessons)
                              }}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container gap={1} direction="row" justifyContent="flex-end">
                      <Grid item>
                        <Link className="top-bar-button" to="/admin-dashboard?tab=3">
                          <Button variant="contained" type="reset">
                            Cancel
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Container>
  )
}

export default AddDynamicSession
