import React, { useEffect, useState } from 'react'

import { 
  Autocomplete,
  Box,
  Chip,
  TextField,
  Typography
} from '@mui/material'

import axios from 'axios'
import throttle from 'lodash/throttle'

const fetchModules = throttle((partialName, startSearch, callback) => {
  if(partialName.length >= 3) {
    startSearch()
    return axios({
      url: '/module/find',
      method: 'post',
      data: {
        partialName
      }
    }).then(r => callback(r.data.map(({name, uuid}) => ({
      label: name,
      id: uuid,
    }))))
  }
  return Promise.resolve([])
},500)

const ModulesList = ({addedModule, removedModule, groupId, modules}) => {
  const [ selectedModule, setSelectedModule ] = useState('')
  const [ removeModule, setRemoveModule ] = useState('')
  const [ moduleOptions, setModuleOptions ] = useState([])
  const [ moduleOptionsLoading, setModuleOptionsLoading ] = useState(false)
  const [ inputValue, setInputValue ] = useState('')

  useEffect(() => {
    if(selectedModule && selectedModule !== '') {
      axios({
        url: `/enrollment/module/${selectedModule.id}/group`,
        method: 'post',
        data: {
          groupId
        }
      }).then(r => {
        setSelectedModule('')
        addedModule({uuid: selectedModule.id, name: selectedModule.label})
      })
      
    }
  },[selectedModule])

  useEffect(() => {
    if(removeModule !== '') {
      axios({
        url: `/enrollment/module/${removeModule}/group`,
        method: 'delete',
        data: {
          groupId
        }
      }).then(r => {
        setRemoveModule('')
        removedModule(removeModule)
      })
    }
  }, [removeModule])

  return <Box>
    <Typography variant="h3">Modules:</Typography>
    <Autocomplete 
      loading={moduleOptionsLoading}
      value={selectedModule}
      onChange={(event, newValue) => {
        setSelectedModule(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if(newInputValue.length >= 3) {
          fetchModules(
            newInputValue,
            () => setModuleOptionsLoading(true), 
            (newModules) => {
              setModuleOptions(newModules)
              setModuleOptionsLoading(false)
            }
          )
        } else {
          setModuleOptions([])
        }
        setInputValue(newInputValue)
      }}
      disablePortal
      options={moduleOptions}
      renderInput={(params) => <TextField {...params} label="Add module ..." />}
    />
    <Box sx={{padding: '10px 0'}}>
    {modules.map(({name, uuid}) => <Chip label={name} key={uuid} onDelete={() => setRemoveModule(uuid)} />)}
    </Box>
  </Box>
}

export default ModulesList