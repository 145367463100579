import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {
  Alert,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import MoveUpIcon from '@mui/icons-material/MoveUp'

import { VIDEO } from '../video/index'
import { QUIZ } from '../quiz/index'
import UpsertVideo from '../video/upsert'
import UpsertQuiz from '../quiz/upsert'
import { MULTI_STEP } from '../multi-step/index'
import { BUCKETS } from '../buckets/index'
import { FRUIT_MACHINE } from '../fruit-machine/index'
import { PARAGRAPHS_GAME } from '../paragraphs/index'
import { CT_COMPUTATIONS } from '../ct-computations/index'

import CAT_DATA from '../import-data/modules/CAT-data.json'
import SD_DATA from '../import-data/modules/SD-data.json'
import VAT_DATA from '../import-data/modules/VAT-data.json'
import BT_DATA from '../import-data/modules/BT-data.json'
import SFMA_DATA from '../import-data/modules/SFMA-data.json'
import TAXII_DATA from '../import-data/modules/TAXII-data.json'
import { updateLesson } from '../import-data/update-data'

import './edit.css'

const EditLessonWrapper = () => {
  const { lessonId, moduleId } = useParams()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [ updateFromData, setUpdateFromData ] = useState(false)

  useEffect(() => {
    axios(`/lesson/${lessonId}`)
      .then((r) => {
        setLoading(false)
        setData(r.data)
      })
      .catch((e) => setError(e))
  }, [lessonId])

  const onSave = (newData) => setData({ ...data, ...newData })
  const onDelete = (uuid) =>
    setData({
      ...data,
      order: data.order.filter((id) => id !== uuid),
    })

  return (
    <div className="lesson-edit-wrapper">
      {loading && <CircularProgress />}
      {error && (
        <Alert severity="error">Error! {error.response.data.message}</Alert>
      )}
      {Object.keys(data).length !== 0 && (
          <EditLesson onDelete={onDelete} onSave={onSave} moduleId={moduleId} {...data} />
      )}
    </div>
  )
}

const EditLesson = (props) => {
  const [adding, setAdding] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [linking, setLinking] = useState(null)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [order, setOrder] = useState(props.order || [])
  const [lessonName, setLessonNname] = useState(props.name || '')
  const [ updating, setUpdating ] = useState(false)

  const currentModule = [CAT_DATA, SD_DATA, VAT_DATA, BT_DATA, SFMA_DATA, TAXII_DATA].find(({uuid}) => uuid === props.moduleId)
  const currentLesson = currentModule.lessons.find(({uuid}) => uuid === props.uuid)

  useEffect(() => {
    if (saving) {
      axios({
        url: `/lesson/${props.uuid}`,
        method: 'post',
        data: {
          name: lessonName,
          order: order,
        },
      }).then((r) => {
        setSaving(false)
        props.onSave(r.data)
      })
    }
  }, [saving])

  useEffect(() => {
    if (linking) {
      const newOrder = [...order, linking]
      const newTypes = { ...props.types, [linking]: adding }
      axios({
        url: `/lesson/${props.uuid}/link`,
        method: 'post',
        data: {
          order: newOrder,
          uuid: linking,
        },
      }).then((r) => {
        setLinking(null)
        props.onSave({ order: newOrder, types: newTypes })
        setAdding(null)
        setOrder(newOrder)
      })
    }
  }, [linking])

  useEffect(() => {
    if (deleting) {
      axios({
        url: `/lesson/${props.uuid}/unlink`,
        method: 'post',
        data: {
          uuid: deleting,
        },
      }).then(() => {
        props.onDelete(deleting)
        setOrder(order.filter((id) => id !== deleting))
        setDeleting(false)
      })
    }
  }, [deleting])

  const move = (srcId, backward) => {
    const dstIndex = order.findIndex((id) => id === srcId) + backward ? 0 : 1
    const srcIdRemoved = order.filter((id) => id !== srcId)
    const newOrder = [
      ...srcIdRemoved.slice(0, dstIndex),
      srcId,
      ...srcIdRemoved.slice(dstIndex),
    ]
    setOrder(newOrder)
  }

  return (
    <div className="lesson-edit-body">
      <Typography variant="h5">Lesson</Typography>
      <div className="lesson-line-wrapper">
        <TextField
          name="title"
          label="Title of the lesson"
          variant="outlined"
          value={lessonName}
          onChange={(e) => setLessonNname(e.target.value)}
          sx={{ flex: 1 }}
          size="small"
        />
        <Button
          disabled={lessonName === props.name}
          onClick={() => setSaving(true)}
          sx={{ width: 'fit-content' }}
          size="small"
        >
          {saving ? <CircularProgress size="1rem" /> : null}
          Save
        </Button>
        <Button onClick={() => {
          setUpdating(true)
          updateLesson(currentLesson).then(r => {
            setUpdating(false)
          })
        }}>
          {updating ? <CircularProgress /> : 'Update from Data'}
        </Button>
      </div>
      <Typography variant="h5">Lesson Structure</Typography>
      {order.map((uuid, index) => {
        const moveUp = index > 0 ? (e) => move(uuid, true) : null
        const moveDown = index + 1 < order.length ? () => move(uuid) : null
        return (
          <UpsertElement
            key={uuid}
            index={index}
            moveUp={moveUp}
            moveDown={moveDown}
            onDelete={() => setDeleting(uuid)}
            onSave={() => {}}
            type={props.types[uuid]}
            uuid={uuid}
          />
        )
      })}
      {adding && (
        <UpsertElement
          onDelete={() => setAdding(null)}
          onSave={(uuid) => {
            setLinking(uuid)
          }}
          type={adding}
        />
      )}
      <Button disabled={!!adding} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <AddIcon />
        Add
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAdding(VIDEO)
            setAnchorEl(null)
          }}
        >
          {VIDEO}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAdding(QUIZ)
            setAnchorEl(null)
          }}
        >
          {QUIZ}
        </MenuItem>
      </Menu>
      <Button
        disabled={order.every((val, index) => val === props.order[index])}
        onClick={() => setSaving(true)}
      >
        {saving && <CircularProgress size="1rem" />}
        Save Order
      </Button>
    </div>
  )
}

const UpsertElement = ({
  index,
  onDelete,
  moveDown,
  moveUp,
  onSave,
  type,
  uuid,
}) => {
  let Component = null
  
  switch (type) {
    case VIDEO: {
      Component = UpsertVideo
      break
    }
    case QUIZ: {
      Component = UpsertQuiz
      break
    }
    case MULTI_STEP: {
      Component = Game
      break
    }
    case BUCKETS: {
      Component = Game
      break
    }
    case FRUIT_MACHINE: {
      Component = Game
      break
    }
    case PARAGRAPHS_GAME: {
      Component = Game
      break
    }
    case CT_COMPUTATIONS: {
      Component = Game
      break
    }
  }

  return (
    <div className="lesson-element-wrapper">
      <Typography sx={{ marginRight: '10px' }}>Step {index + 1}:</Typography>
      <Accordion sx={{ flexGrow: 1 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {`${!uuid ? 'New ' : ''}${type}`}
        </AccordionSummary>
        <AccordionDetails>
          <Component
            moveDown={moveDown}
            moveUp={moveUp}
            onSave={onSave}
            uuid={uuid}
          />
        </AccordionDetails>
      </Accordion>
      {uuid && (
        <Tooltip title="Move LATER in the Lesson">
          <IconButton disabled={!moveDown} onClick={moveDown}>
            <MoveDownIcon />
          </IconButton>
        </Tooltip>
      )}
      {uuid && (
        <Tooltip title="Move EARLIER in the Lesson">
          <IconButton disabled={!moveUp} onClick={moveUp}>
            <MoveUpIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Remove from Lesson">
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

const Game = ({uuid}) => <Typography>This is a game. Its id is: {uuid}</Typography>

export default EditLessonWrapper
