import {
  Box,
  ListItemButton,
  ListItem,
  ListItemText,
  List,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,

  CircularProgress,
} from '@mui/material'
import React from 'react'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone'
import StopWatchImage from '../dashboard/stopwatch.svg'

const AssessmentListItem = ({ name, id, isCompleted, imageUrl, attemptsRemaining }) => {
  if (!imageUrl) {
    imageUrl = `${StopWatchImage}`
  }
  return (
    <ListItem divider>
      <ListItemButton href={'/assessment/' + id} sx={{ marginRight: '10px' }}>
        <ListItemAvatar>
          <Avatar
            sx={{
              filter: isCompleted ? 'none' : 'grayscale(100%)',
              height: '50px',
              width: '50px',
              marginRight: '20px',
              objectFit: 'cover',
            }}
            variant="square"
            alt={name}
            src={process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' + imageUrl : imageUrl}
          ></Avatar>
        </ListItemAvatar>

        <ListItemText primary={name} secondary={isCompleted ? 'Assessment Completed' : 'Start Assessment '} />
      </ListItemButton>
      <ListItemSecondaryAction>
        {isCompleted ? (
          <CheckCircleTwoToneIcon aria-label="Assessment Completed" color={'greenTint'} fontSize="large" />
        ) : (
          <DoNotDisturbOnTwoToneIcon aria-label="Assessment Not Completed" color={'notAchieved'} fontSize="large" />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}
const AssessmentCompletedPanel = ({ assessments }) => {
  const loading = false

  return (
    <Box sx={{ background: '#FFFFFF', height: '100%', paddingBottom: '20px', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ background: '#FFFFFF' }}>
          <List sx={{ mx: 'auto' }}>
            {assessments.map((assessment, index) => (
              <AssessmentListItem
                key={`assessmentlistitem-${assessment.uuid}`}
                id={assessment.uuid}
                name={assessment.name}
                imageUrl={assessment.imageUrl}
                isCompleted={assessment.isCompleted}
              />
            ))}
          </List>
        </Box>
      )}
    </Box>
  )
}

export default AssessmentCompletedPanel
