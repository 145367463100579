import { useTheme } from '@mui/material/styles'
import * as React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { Box, IconButton, Stack, ListItemIcon, Divider } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { useUser } from '../login/user-context'
import { useAuth } from '../login/auth'
import { useNavigate } from 'react-router-dom'
import './index.css'
import logo from './examfly-logo-blue.png'
import mixpanel from 'mixpanel-browser'
import { ProfileOutlined, LogoutOutlined } from '@ant-design/icons'
import { QuestionCircleOutlined, UnorderedListOutlined, NotificationOutlined } from '@ant-design/icons'
import EditNoteIcon from '@mui/icons-material/EditNote'
import NotificationsButton from 'common-components/notifications'

function TopBar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const { name } = useUser()
  const navigate = useNavigate()
  const { roles } = useUser()
  const { logout } = useAuth()
  const theme = useTheme()

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
    event.preventDefault()
    mixpanel.track('button_clicked', {
      Feature: 'practice_area',
      Location: 'settings_menu',
      Name: 'Practice Area',
    })
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar position="static" sx={{ bgcolor: '#FFFFFF', borderBottom: `1px solid ${theme.palette.divider}`, boxShadow: 'none' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <img alt="Examfly logo" className="top-bar-logo" src={logo} />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            {roles.includes('Admin') && (
              <Link className="top-bar-button" to="/admin-dashboard">
                <Typography>Admin</Typography>
              </Link>
            )}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
              <Typography sx={{ p: 0, color: theme.palette.grey[700] }} variant="subtitle1">
                Welcome back {name ? name.split(/\s/)[0] : ''}
              </Typography>
              <NotificationsButton />

              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: theme.palette.grey[600] }}>
                  <SettingsOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu()
                  navigate('/')
                }}
              >
                <ListItemIcon>
                  <UnorderedListOutlined />
                </ListItemIcon>
                <Typography textAlign="center">Dashboard</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleCloseUserMenu()
                  navigate('/practice-area')
                }}
              >
                <ListItemIcon>
                  <EditNoteIcon />
                </ListItemIcon>
                <Typography textAlign="center">Practice Area</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu()
                  navigate('/notifications')
                }}
              >
                <ListItemIcon>
                  <NotificationOutlined />
                </ListItemIcon>
                <Typography textAlign="center">Notification Centre</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleCloseUserMenu()
                  navigate('/badges')
                }}
              >
                <ListItemIcon>
                  <ProfileOutlined />
                </ListItemIcon>
                <Typography textAlign="center">View Badges</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu()
                  navigate('/support')
                }}
              >
                <ListItemIcon>
                  <QuestionCircleOutlined />
                </ListItemIcon>
                <Typography textAlign="center">Support</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu()
                  logout(() => navigate('/login'))
                }}
              >
                <ListItemIcon>
                  <LogoutOutlined />
                </ListItemIcon>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default TopBar
