import { Box, Button, ListItem, ListItemText, Link, List, ListItemSecondaryAction, ListItemAvatar, Avatar, Typography, Skeleton, Stack, CircularProgress } from '@mui/material'
import React from 'react'
import MainCard from '../components/MainCard'
import { useBadges } from './context'
import { CheckCircleTwoTone, MinusCircleOutlined } from '@ant-design/icons'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone'
import { useTheme } from '@mui/material/styles'
import mixpanel from 'mixpanel-browser'
import { useNavigate } from 'react-router-dom'

export const BadgeSnackbar = (name, description, imageUrl) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <Typography>{name}</Typography>
        <Typography>{description}</Typography>
      </Box>
    </Box>
  )
}

export const BadgesCard = (props) => {
  const { badges, loading, userBadgeIds } = useBadges()
  const navigate = useNavigate()

  const handleOnclick = (event) => {
    event.preventDefault()

    mixpanel.track('Button Clicked', {
      Type: 'See all badges',
    })
    navigate('/badges')
  }
  return (
    <Box sx={{ background: '#FFFFFF', display: 'flow-root', height: '100%', paddingBottom: '20px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box sx={{ p: 3, pb: 0 }}>
            <Stack spacing={2}>
              {userBadgeIds.length > 0 ? (
                <Typography variant="h5" fontWeight="bold">
                  Most recent
                </Typography>
              ) : (
                <Typography variant="h5" fontWeight="bold">
                  No badges unlocked yet
                </Typography>
              )}
            </Stack>
          </Box>
          <List sx={{ mx: 'auto' }}>
            {userBadgeIds.slice(0, 3).map((badgeId) => (
              <Badge key={badgeId} awarded={true} {...badges[badgeId]} />
            ))}
          </List>

          <Link sx={{ display: 'block', textAlign: 'center' }} onClick={handleOnclick} href="/badges">
            <Button sx={{ mb: 2, mt: 2, color: 'theme.primary.main', borderColor: 'theme.primary.main', borderWidth: '2px', borderStyle: 'solid' }}>See all badges</Button>
          </Link>
        </Box>
      )}
    </Box>
  )
}

const Badge = ({ awarded, name, description, imageUrl }) => {
  const theme = useTheme()

  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar
          sx={{
            filter: awarded ? 'none' : 'grayscale(100%)',
            height: '50px',
            width: '50px',
            marginRight: '20px',
            objectFit: 'contain',
          }}
          variant="square"
          alt={name}
          src={process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' + imageUrl : imageUrl}
        ></Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography variant="h5">{name} </Typography>} secondary={description} />

      <ListItemSecondaryAction>
        {awarded ? <CheckCircleTwoToneIcon color={'greenTint'} fontSize="large" /> : <DoNotDisturbOnTwoToneIcon color={'notAchieved'} fontSize="large" />}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const BadgeSmall = ({ imageUrl }) => {
  return <img style={{ height: '50px', width: '50px', marginRight: '20px' }} src={process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' + imageUrl : imageUrl} />
}

const Badges = (props) => {
  const { badges, loading, userBadgeIds } = useBadges()

  return (
    <>
      <MainCard title="">
        <Typography variant="h1">Badges</Typography>
        <Box sx={{ background: '#FFFFFF', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Box sx={{ maxWidth: '1000px', padding: '20px' }}>
            {loading ? (
              <>
                <Box>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="rectangular" width={439} height={300} />
                </Box>
                <Box>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="rectangular" width={439} height={300} />
                </Box>
              </>
            ) : (
              <List>
                {userBadgeIds.map((badgeId) => (
                  <Badge key={badgeId} awarded={true} {...badges[badgeId]} />
                ))}
                {Object.entries(badges)
                  .filter(([badgeId]) => !userBadgeIds.includes(badgeId))
                  .map(([badgeId, badge]) => (
                    <Badge key={badgeId} awarded={false} {...badge} />
                  ))}
              </List>
            )}
          </Box>
        </Box>
      </MainCard>
    </>
  )
}

export default Badges
