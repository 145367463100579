import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, TextField, Typography } from '@mui/material'

import { useAuth } from './auth'
import './index.css'
import logo from '../top-bar/examfly-logo-blue.png'

const ForgotPassword = (props) => {
  const [username, setUsername] = useState('')
  const [resetting, setResetting] = useState(false)
  const auth = useAuth()

  useEffect(() => {
    if (resetting) {
      localStorage.setItem('currentUser', username)
      axios({
        url: '/auth/forgot-password',
        method: 'post',
        data: {
          username,
        },
      }).then(r => {
        window.location = '/password-reset'
      })
    }
  }, [resetting])

  return (
    <div className="login-wrapper">
      <img className="logo" src={logo} />
      <form
        style={{height: '340px'}}
        className="login"
        onSubmit={(e) => {
          e.preventDefault()
          setResetting(true)
        }}
      >
        <Typography variant="h1">Password Reset</Typography>
        <Typography>
          Please confirm your username below. You will receive an email containing a verification code and a link to set a new password
        </Typography>
        <TextField
          sx={{marginTop: '20px'}}
          name="username"
          type="text"
          label="Username"
          variant="outlined"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
        />
        <Button
          sx={{ marginTop: '20px', width: '200px' }}
          variant="contained"
          color="primary"
          type="submit"
        >
          Reset password
        </Button>
      </form>
    </div>
  )
}

export default ForgotPassword
