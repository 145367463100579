import React, { useEffect, useState, useMemo } from 'react'
import Knock from '@knocklabs/client'
import { Box, Typography, Checkbox, Link, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, CircularProgress } from '@mui/material'
import MainCard from 'components/MainCard'
import { useUser } from '../login/user-context'

import { getKnockKey } from 'utils/knock-config'
const workflowLabels = {
  'progress-report': 'Reveive Progress Reports',
  like: 'Document likes',
  alert: 'Alerts',
}

const channelTypeLabels = {
  email: 'Email',
  in_app_feed: 'In App Notifcation',
}

const PreferenceCenter = () => {
  const { uuid, knockToken } = useUser()
  const [preferences, setPreferences] = useState()
  const [errorMsg, setErrorMsg] = useState(false)

  const knockClient = useMemo(() => {
    if (uuid) {
      try {
        const knockClient = new Knock(getKnockKey())
        knockClient.authenticate(uuid, knockToken)
        return knockClient
      } catch (error) {
        console.error('Error setting up Knock client', error)
        setErrorMsg('We have an issue with fetching your preferences. Please try again later.')
      }
    }
  }, [uuid, knockToken])

  // Read the preferences for the current user
  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const preferences = await knockClient.preferences.get()
        setPreferences(preferences)
      } catch (error) {
        console.error('Error fetching preferences', error)
        setErrorMsg('Notifiations are not enabled for this account.')
      }
    }

    if (knockClient) {
      fetchPreferences()
    }
  }, [knockClient])

  const onPreferenceChange = async (workflowKey, channelType, setting) => {
    const preferenceUpdate = {
      workflows: {
        [workflowKey]: {
          channel_types: {
            [channelType]: setting,
          },
        },
      },
    }

    const preferences = await knockClient.preferences.set(preferenceUpdate)

    // Set the updated preferences
    setPreferences(preferences)
  }

  // Show a spinner here or something to indicate prefs are loading
  if (!preferences && !errorMsg) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <Link sx={{ marginRight: '10px' }} href="/">
        Dashboard
      </Link>
      &#62;
      <Link sx={{ marginLeft: '10px', marginRight: '10px' }} href="/notifications">
        Notification Centre
      </Link>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '20px' }}>
        <Box>
          <Typography sx={{ margin: 0 }} variant="h2">
            Notification Centre
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: 0.2, marginTop: '24px' }}>
        <MainCard sx={{ padding: '16px', minHeight: '600px' }} border={false}>
          <Typography variant="subtitle1" sx={{ marginBottom: '16px' }}>
            Manage your notification preferences
          </Typography>
          {errorMsg ? (
            <Box>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'left' }}>
                {errorMsg}
              </Typography>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'left' }}>
                If you would notifications such as progress updates please reach out to us at {'  '}
                <Link href="mailto:support@examfly.com" sx={{ marginLeft: '10px', fontFamily: 'Public Sans, sans-serif' }}>
                  {' '}
                  support@examfly.com
                </Link>
                .
              </Typography>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'right' }}>
                Thank you
              </Typography>
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Notification</TableCell>
                    <TableCell>Channels</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!errorMsg &&
                    Object.keys(preferences.workflows).map((workflowKey, index) => {
                      const workflowChannelPreferences = preferences.workflows[workflowKey].channel_types

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="h6">{workflowLabels[workflowKey]}</Typography>
                          </TableCell>
                          <TableCell>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              {Object.keys(workflowChannelPreferences).map((channelType) => {
                                const preferenceSetting = workflowChannelPreferences[channelType]

                                return (
                                  <div className="preferences__row-channel-type" key={channelType} style={{ marginRight: '10px' }}>
                                    <label>
                                      <Typography>{channelTypeLabels[channelType]}</Typography>
                                      <Checkbox checked={preferenceSetting} onChange={() => onPreferenceChange(workflowKey, channelType, !preferenceSetting)} />
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </MainCard>
      </Box>
    </Box>
  )
}

export default PreferenceCenter
