import React from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Container } from '@mui/material'
import TopBar from './top-bar/index'
import { BadgesProvider } from './badges/context'
import { PointsProvider } from './points/context'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
// import "./App.css";
import mixpanel from 'mixpanel-browser'
const mixpanel_token = document.location.hostname === 'www.examfly-app.com' ? '6f1a66a3627fe5080acf6be1ae6b3ece' : '147ba85d24f08dca7841f40378416a06'
mixpanel.init(mixpanel_token, { debug: false, track_pageview: true, persistence: 'localStorage' })

const App = () => (
  <LocalizationProvider dateAdapter={AdapterLuxon}>
    <SnackbarProvider
      disableWindowBlurListener={true}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <BadgesProvider>
        <PointsProvider>
          <TopBar />
          <Container maxWidth="xl" sx={{ padding: { xs: 1 } }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box
                component="main"
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  paddingTop: 0,
                  paddingRight: { xs: 1, sm: 3 },
                  paddingLeft: { xs: 1, sm: 3 },
                }}
              >
                <Outlet />
              </Box>
            </Box>
          </Container>
        </PointsProvider>
      </BadgesProvider>
    </SnackbarProvider>
  </LocalizationProvider>
)

export default App
