import { Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import Feedback from '../results/feedback'

const FeedbackSection = ({ feedback, mistakes }) => {
  let someIncorrect = Object.values(mistakes).some((m) => m.length > 0)

  return (
    <Feedback
      isCorrect={!someIncorrect}
      feedback={
        <Typography component="div">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}>
            {feedback}
          </ReactMarkdown>
        </Typography>
      }
    />
  )
}

export default FeedbackSection
