import React, { useState } from "react";
import { Box } from "@mui/material";

const DropTarget = ({ dragging, setDragging, droppedItem, onDrop, uuid }) => {
  const [over, setOver] = useState(false);

  let border = over || dragging ? "2px solid #1756a380" : "2px dashed #80CCDC";
  let background = "transparent";
  if (over) {
    background = "#F2F6F7";
  }
  if (droppedItem) {
    background = "#1756A3";
    border = "2px solid #1756A3";
  }
  return (
    <Box
      component="span" // for valid HTML
      sx={{
        background,
        border,
        borderRadius: "10px",
        color: "#ffffff",
        display: "inline-block",
        lineHeight: "50px",
        padding: "0 10px",
        minWidth: droppedItem ? undefined : "100px",
      }}
      dropzone="move"
      onDrop={(e) => {
        e.preventDefault();
        onDrop(uuid);
        setOver(false);
        setDragging(false);
      }}
      onDragEnter={(e) => {
        setOver(true);
        e.preventDefault();
      }}
      onDragLeave={(e) => setOver(false)}
      onDragOver={(e) => e.preventDefault()}
    >
      {droppedItem ? droppedItem.text : "..."}
    </Box>
  );
};

export default DropTarget;
