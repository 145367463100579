import { Alert, AlertTitle, Avatar, Typography, Button, Link, Card, CardHeader, CardContent, CircularProgress, Fade, Grow } from '@mui/material'

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import React, { useEffect, useState } from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import FurtherFeedback from './furtherFeedback'
import { red } from '@mui/material/colors'
import CircularProgressWithLabel from '../components/circle-progress-with-label'
import LinearWithValueLabelDynamic from '../components/linear-progress-with-label-dynamic'
import { css, Global } from '@emotion/react'

const globalStyles = css`
  @keyframes shake-bottom {
    0%,
    33.333%,
    100% {
      transform: translateY(0);
    }
    10%,
    30% {
      transform: translateY(5px);
    }
    20% {
      transform: translateY(-5px);
    }
  }

  .shake-bottom {
    animation: shake-bottom 1.5s ease-in-out infinite;
  }
`

const Feedback = ({ feedback, isCorrect, altExplanation, video, uuid, remedialQuestionId, isRemedialQuestion = false }) => {
  const [open, setOpen] = useState(false) // this is the state for the further feedback dialog
  const [adpativePanelLoader, showAdativePanelLoader] = useState(true)
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Card sx={{ minWidth: { xs: '350px', sm: '500px' } }}>
      <Global styles={globalStyles} />

      <CardHeader
        variant={isCorrect ? 'feedbackPanelCorrect' : 'feedbackPanelIncorrect'}
        title={<Typography variant="">{isCorrect ? 'Correct' : 'Incorrect'}</Typography>}
        avatar={
          isCorrect ? (
            <Avatar
              sx={{
                backgroundColor: 'rgb(245, 255, 245)',
              }}
            >
              <CheckCircleIcon fontSize="large" sx={{ color: '#4caf50' }} />
            </Avatar>
          ) : (
            <Avatar sx={{ backgroundColor: 'rgb(255, 245, 245)' }}>
              <CancelIcon fontSize="large" sx={{ color: red[500] }} />
            </Avatar>
          )
        }
        action={
          altExplanation &&
          !isRemedialQuestion &&
          showAdativePanelLoader && (
            <div sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <Grow orientation="horizontal" in={!adpativePanelLoader}>
                <Button
                  sx={{ marginLeft: '10px', color: isCorrect ? '#4caf50' : '#ff0000', border: isCorrect ? '2px solid #4caf50' : '2px solid #ff0000', backgroundColor: 'white'}}
                  size="medium"
                  variant="furtherInfo"
                  startIcon={<LightbulbIcon className="shake-bottom" />}
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  <Typography variant="span" sx={{ display: { xs: 'none', sm: 'flex', color: isCorrect ? '#4caf50' : '#ff0000' } }}>
                    Learn & Practise More
                  </Typography>
                </Button>
              </Grow>
              {adpativePanelLoader && <CircularProgressWithLabel sx={{ marginRight: '18px' }} switchLoaderAndAdaptivePanel={showAdativePanelLoader} />}
            </div>
          )
        }
      />
      <CardContent variant={isCorrect ? 'feedbackPanelCorrect' : 'feedbackPanelIncorrect'}>
        <Typography variant="">{feedback}</Typography>

        <FurtherFeedback
          altExplanation={
            <Typography component="div">
              <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                {altExplanation}
              </ReactMarkdown>
            </Typography>
          }
          video={video}
          open={open}
          handleClose={handleClose}
          remedialQuestionId={remedialQuestionId}
          quizId={uuid}
        />
      </CardContent>
    </Card>
  )
}

export default Feedback
