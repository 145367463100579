// ==============================|| OVERRIDES - CARD CONTENT ||============================== //

export default function CardHeader(theme) {
  return {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 10,
          color: '#FFFFFF',
          // color: '#363636',
          backgroundColor: '#7bdcb5',
          // backgroundColor: '#FAC748',
          // backgroundColor: '#0a2645',
          textAlign: 'center',
          fontSize: '1.5rem',
          title: {
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: 1.33,
            color: 'FFFFFF',
          },
        },

        title: {
          fontWeight: 600,
          fontSize: '1.2rem',
          lineHeight: 1.2,
          color: 'FFFFFF',
          padding: '0px 0px 0px 0px',
        },
      },

      variants: [
        {
          props: { variant: 'feedbackPanelIncorrect' },
          style: {
            padding: '10px 10px 5px 10px',
            color: 'rgb(102, 65, 63)',
            backgroundColor: 'rgb(255, 245, 245)',
            textAlign: 'left',
            title: {
              fontWeight: 600,
              lineHeight: 1.33,
              color: 'rgb(102, 65, 63)',
            },
            action: {
              marginTop: '0px',
            },
          },

          title: {
            fontWeight: 600,
            fontSize: '1.2rem',
            lineHeight: 1.2,
            color: 'rgb(102, 65, 63)',
            padding: '0px 0px 0px 0px',
          },
        },

        {
          props: { variant: 'feedbackPanelCorrect' },
          style: {
            padding: '10px 10px 5px 10px',
            // color: 'rgb(59, 88, 40)',
            color: 'rgb(20 176 47)',
            backgroundColor: 'rgb(244, 251, 239)',
            textAlign: 'left',
            title: {
              fontWeight: 600,
              lineHeight: 1.33,
              color: 'rgb(59, 88, 40)',
            },
            action: {
              marginTop: '0px',
            },
          },
        },
        {
          props: { variant: 'adminPanelCard' },
          style: {
            padding: '10px 10px 5px 10px',
            color: '#FFFFFF',
            backgroundColor: 'rgb(23, 86, 163)',
            textAlign: 'left',
            title: {
              fontWeight: 600,
              lineHeight: 1.33,
              color: 'rgb(102, 65, 63)',
            },
            action: {
              marginTop: '0px',
            },
          },

          title: {
            fontWeight: 600,
            fontSize: '1.2rem',
            lineHeight: 1.2,
            color: 'rgb(102, 65, 63)',
            padding: '0px 0px 0px 0px',
          },
        },
      ],
    },
  }
}
