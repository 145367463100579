import React, { forwardRef } from 'react'
import { SnackbarContent } from 'notistack'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

const BadgeAward = forwardRef((props, ref) => {
  const { name, description, imageUrl } = props.message

  return (
    <SnackbarContent ref={ref}>
      <Box sx={{ display: 'flex', padding: 2, boxShadow: 8, borderRadius: '4px', backgroundColor: 'white' }}>
        <img
          style={{ height: '50px', width: '50px', marginRight: '20px' }}
          src={process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' + imageUrl : imageUrl}
          alt="badge-icon"
        />
        <Box>
          <Typography component="span">
            <b>{name}</b> badge unlocked!
          </Typography>
          <Typography>{description}</Typography>
        </Box>
      </Box>
    </SnackbarContent>
  )
})

export default BadgeAward
