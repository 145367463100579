import React, { useEffect } from 'react'
import { Box, Button, List, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ResultsSummary from '../results/summary'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

import QuestionSummary from '../results/questions'
import { useBadges } from '../badges/context'

import './summary.css'

const Summary = ({ answers, disableNext, items, next, targets }) => {
  const correct = Object.entries(answers).filter(([key, value]) => value.isCorrect).length
  const total = items.length
  const { width, height } = useWindowSize()
  const { awardBadge } = useBadges()
  const percentCompleted = ((correct / total) * 100).toFixed(2)
  useEffect(() => {
    awardBadge('FIRST_BUCKETS')
    if (correct === total) {
      awardBadge('BUCETS_100%')
    }
    if (correct === 0) {
      awardBadge('ANY_0%')
    }
  }, [correct, total])

  return (
    <>
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        {percentCompleted >= 80 && <Confetti width={width} height={height} recycle={false} numberOfPieces={500} />}
        <Typography variant="h2" sx={{ textAlign: 'center' }}>
          The results are in
        </Typography>
        <ResultsSummary correct={correct} total={total} />
        <List>
          {items.map((item, index) => {
            return (
              <QuestionSummary
                key={item.uuid}
                answers={targets}
                correctAnswers={{ correctIds: answers[item.uuid].correctTargets.map(({ uuid }) => uuid) }}
                question={item.text}
                feedback={item.feedback}
                questionNumber={index + 1}
                selectedAnswer={answers[item.uuid].id}
              />
            )
          })}
        </List>
        <Button disabled={disableNext} variant="contained" color="primary" onClick={() => next(true)}>
          Next Step
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </>
  )
}

export default Summary
