import React, { useLayoutEffect, useState, useRef, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'

const CarouselButtons = ({ canScrollLeft, canScrollRight, buttonWidth, buttonHeight, changePage, mobileMode = false }) => {
  const buttonSx = {
    position: mobileMode ? 'static' : 'absolute',
    width: `${buttonWidth}px`,
    height: `${mobileMode ? buttonWidth : buttonHeight}px`,
    borderRadius: 1,
    borderColor: 'primary.main',
    color: 'primary.main',
    backgroundColor: '#FFF',
    ':hover': { backgroundColor: '#EEE' },
  }
  return (
    <Stack direction="row" alignItems="center" spacing={mobileMode ? '6px' : 0} sx={{ padding: `${mobileMode ? '8px 0 0 8px' : '0'}` }}>
      <IconButton
        disableRipple={mobileMode}
        disabled={!canScrollLeft}
        sx={{
          ...buttonSx,
          left: '40px',
        }}
        aria-label="scroll module carousel left"
        onClick={() => changePage('left')}
      >
        <ArrowBackIosNewIcon fontSize="large" />
      </IconButton>
      <IconButton
        disableRipple={mobileMode}
        disabled={!canScrollRight}
        sx={{
          ...buttonSx,
          right: '35px',
        }}
        aria-label="scroll module carousel right"
        onClick={() => changePage('right')}
      >
        <ArrowForwardIosIcon fontSize="large" />
      </IconButton>
    </Stack>
  )
}

const Carousel = ({ children, buttonWidth, spaceBetween, squareButtons }) => {
  const viewportRef = useRef(null)
  const componentRef = useRef(null)
  const childrenRef = useRef([])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [showButtons, setShowButtons] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(true)
  const [cardWidth, setCardWidth] = useState(0)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  const doubleHeight = buttonWidth * 2
  const buttonHeight = squareButtons ? buttonWidth : doubleHeight

  const calculateCardPosition = (currentPage, width, spaceBetween) => {
    return (currentPage - 1) * (width + spaceBetween)
  }

  const scrollTo = (newPosition) => {
    viewportRef.current.scrollTo({ left: newPosition, behavior: 'smooth' })
  }

  const changePage = (direction) => {
    let newPage
    if (direction === 'left') {
      if (page === 1) return
      newPage = page - 1
    }
    if (direction === 'right') {
      if (page === pageCount) return
      newPage = page + 1
    }
    setPage(newPage)
    scrollTo(calculateCardPosition(newPage, cardWidth, spaceBetween))
  }

  useEffect(() => {
    if (page === 1) {
      setCanScrollLeft(false)
      setCanScrollRight(true)
    }
    if (page > 1 && page < pageCount) {
      setCanScrollLeft(true)
      setCanScrollRight(true)
    }
    if (page === pageCount) {
      setCanScrollRight(false)
      setCanScrollLeft(true)
    }
  }, [page, pageCount])

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return (_) => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // reset the carousel when page width is changed
    setPage(1)
    scrollTo(0)
  }, [windowWidth])

  useLayoutEffect(() => {
    // get card width for calculations
    const cardWidth = childrenRef.current[0].offsetWidth
    setCardWidth(cardWidth)
    const cardCount = children.length
    const allCardsWidths = cardCount * cardWidth
    const allSpaceBetween = (cardCount - 1) * spaceBetween
    const cardsAndSpaces = allCardsWidths + allSpaceBetween
    const componentWidth = componentRef.current.offsetWidth

    // show buttons if too many cards
    if (cardsAndSpaces > componentWidth) {
      const overflowSize = cardsAndSpaces - componentWidth
      setPageCount(Math.ceil(overflowSize / cardWidth) + 1)
      setShowButtons(true)
    } else {
      setShowButtons(false)
      setPageCount(1)
    }
  }, [windowWidth])

  return (
    <>
      <Stack justifyContent={children.length < 3 ? 'center' : 'left'} direction="row" ref={componentRef}>
        <Stack
          ref={viewportRef}
          direction="row"
          spacing={`${spaceBetween}px`}
          justifyContent={children.length < 3 ? 'center' : 'left'}
          sx={{
            padding: '0px',
            overflowX: 'scroll',
            '::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <>
            {React.Children.map(children, (child, index) =>
              React.cloneElement(child, {
                ref: (ref) => (childrenRef.current[index] = ref),
              })
            )}
          </>
        </Stack>
        {showButtons && !isMobile && (
          <CarouselButtons canScrollLeft={canScrollLeft} canScrollRight={canScrollRight} buttonWidth={buttonWidth} buttonHeight={buttonHeight} changePage={changePage} />
        )}
      </Stack>
      {showButtons && isMobile && (
        <CarouselButtons mobileMode canScrollLeft={canScrollLeft} canScrollRight={canScrollRight} buttonWidth={buttonWidth} buttonHeight={buttonHeight} changePage={changePage} />
      )}
    </>
  )
}

Carousel.defaultProps = {
  buttonWidth: 40,
  spaceBetween: 30,
  squareButtons: false,
}

export default Carousel
