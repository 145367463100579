import {
  Box,
  Button,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import axios from 'axios'

import GroupSelect from './group-select'

function isValidEmail(email) {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
}

const EditUser = ({onUpdate, user}) => {
  const [ email, setEmail ] = useState(user.email)
  const [ name, setName ] = useState(user.name)
  const [ loading, setLoading ] = useState(false)
  const [ changingName, setChangingName ] = useState(false)
  const [ newGroup, setNewGroup ] = useState(user.group_uuid)
  const [ changingGroup, setChangingGroup ] = useState(false)
  const { username, uuid } = user

  const [ checkEmail, setCheckEmail ] = useState(false)
  const [ newEmailUser, setNewEmailuser ] = useState(null)
  const [ isSSO, setIsSSO ] = useState(null)

  useEffect(() => {
    if(email && checkEmail) {
      axios({
        method: 'post',
        url: '/user/find',
        data: { email }
      }).then(r => {
        if(r.data.length) {
          const confirmed = window.confirm('A user with that email already exists. Do you want to replace that user?')
          if(confirmed) {
            setNewEmailuser(false)
          } else {
            setLoading(false)
          }
        }
      }).catch(e => setNewEmailuser(false))
    }
  },[checkEmail, email])

  useEffect(() => {
    if(newEmailUser === false) {
      axios({
        method: 'post',
        url: `/user/${uuid}/chage-email`,
        data: {
          newEmail: email,
          email: user.email,
          newIsSSO: isSSO
        }
      }).then(r => {
        setLoading(false)
        setTimeout(onUpdate(uuid,{...user, email}),1)
      })
    }
  }, [ newEmailUser ])

  useEffect(() => {
    if(changingGroup && newGroup !== user.group_uuid) {
      axios({
        method: 'post',
        url: `/user/change-group`,
        data: {
          uuids: [uuid],
          groupUuid: newGroup
        }
      }).then(r => {
        setNewGroup(false)
        setChangingGroup(false)
        setTimeout(onUpdate(uuid,{...user, group_uuid: newGroup}),1)
      })
    }
  },[changingGroup, newGroup])

  useEffect(() => {
    if(changingName) {
      axios({
        method: 'post',
        url: `/user/${uuid}/basic-data`,
        data: {
          name
        }
      }).then(r => {
        setChangingName(false)
        setTimeout(onUpdate(uuid,{...user, name}),1)
      })
    }
  },[changingName])

  return (
    <Box sx={{
      background: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      padding: '40px',
      minWidth: '600px'
    }}>
      <Typography>
        <Typography component='span' sx={{fontWeight: 'bold'}}>Username:</Typography> {username}
      </Typography>
      <Box sx={{display: 'flex', alignItems: 'center', borderBottom: '1px solid #F2F6F7', paddingBottom: '20px'}}>
        <TextField
          sx={{marginTop: '20px'}} 
          label="Name"
          variant="outlined"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          disabled={name === user.name || changingName}
          onClick={() => setChangingName(true)}  
        >Change name</Button>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', borderBottom: '1px solid #F2F6F7', paddingBottom: '20px'}}>
        <GroupSelect initialUuid={user.group_uuid} onChange={(groupId) => setNewGroup(groupId)}/>
        <Button
          disabled={user.group_uuid === newGroup}
          onClick={() => setChangingGroup(true)}  
        >Change group</Button>
      </Box>
      <TextField
        sx={{marginTop: '20px'}} 
        label="Email"
        variant="outlined"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value.replace(/\s/g,''))}
      />
      {email !== user.email && (<>
        <FormLabel id="SSO-label">Will this user use SSO with the new email?</FormLabel>
        <RadioGroup aria-labelledby="SSO-label">
          <FormControlLabel selected={isSSO === true} onChange={() => setIsSSO(true)} value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel selected={isSSO === false} onChange={() => setIsSSO(false)} value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </>)}
      {newEmailUser === null && isSSO !== null && <Button
        disabled={email === user.email || !isValidEmail(email)}
        onClick={() => {
          setCheckEmail(true)
          setLoading(true)
        }}
      >
        {loading && <CircularProgress />}
        Change email
      </Button>}
    </Box>
  )
}

export default EditUser