import React, { useEffect, useState } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

import axios from 'axios'

export const AuthContext = React.createContext(null)

function isTokenExpired(token) {
  return JSON.parse(atob(token.split('.')[1], 'base64')).exp * 1000 > Date.now()
}

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || '')
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') || '')
  const [loggedIn, setLoggedIn] = useState(token && isTokenExpired(token))

  // TODO: find a nicer way to deal with Axios defaults & interceptors
  // const backendUrl = process.env.NODE_ENV === 'development' ? 'https://api.dev.examfly-app.com' : `https://api.${document.location.hostname}`
  const backendUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : `https://api.${document.location.hostname}`

  axios.defaults.baseURL = backendUrl

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        console.log('401 Response. Unauthorized, logging out')
        setToken('')
        localStorage.removeItem('token')
        setRefreshToken('')
        localStorage.removeItem('refreshToken')
        setLoggedIn(false)
      }
      return Promise.reject(error)
    }
  )

  useEffect(() => {
    const tokenRefreshInterval = setInterval(async () => {
      console.log('Refreshing auth token')
      if (refreshToken) {
        axios({
          url: `/auth/refresh-token`,
          method: 'post',
          data: {
            refreshToken,
          },
        }).then((r) => {
          const { token, refreshToken } = r.data
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          setToken(token)
          setRefreshToken(refreshToken)
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        })
        // in case of error I don't delete the refresh token, because it could be just network problems
      }
    }, 9 * 60 * 1000) // every nine minutes
    return () => clearInterval(tokenRefreshInterval)
  }, [refreshToken])

  const value = {
    loggedIn,
    setToken: (token, callback) => {
      localStorage.setItem('token', token)
      setToken(token)
      setLoggedIn(true)
      if (callback) {
        callback()
      }
    },
    getToken: () => {
      return token
    },
    // set token and refresh token
    setTokens: (token, refreshToken, callback) => {
      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refreshToken)
      setToken(token)
      setRefreshToken(refreshToken)
      setLoggedIn(true)
      if (callback) {
        callback()
      }
    },
    logout: (callback) => {
      axios({
        url: `/auth/logout`,
        method: 'get',
      }).then((r) => {
        localStorage.setItem('postLoginPath', '/')
        localStorage.setItem('token', '')
        localStorage.setItem('refreshToken', '')
        setToken('')
        setRefreshToken('')
        setLoggedIn(false)
        if (callback) {
          callback()
        }
      })
    },
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return React.useContext(AuthContext)
}

export const RequireAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.loading) {
    return <CircularProgress />
  }

  if (!auth.loggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}
