import { Box, Typography, CircularProgress, Chip, Paper, Fade } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone'
import axios from 'axios'

const ProficiencyChip = ({ achieved, name }) => (
  <Fade in={true} timeout={1500}>
    <Chip
      sx={{ margin: '4px' }}
      label={name}
      variant={achieved ? 'filled' : 'outlined'}
      color={achieved ? 'greenTint' : 'notAchieved'}
      icon={achieved ? <CheckCircleTwoToneIcon color={'greenTint'} fontSize="small" /> : <DoNotDisturbOnTwoToneIcon color={'notAchieved'} fontSize="small" />}
    />
  </Fade>
)

const ProficiencyPanel = (props) => {
  const [loading, setLoading] = useState(true)
  const [proficienciesCompleted, setProficienciesCompleted] = useState([])
  const [proficienciesRemaining, setProficienciesRemaining] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios(`proficiency`)
      .then(({ data }) => {
        setProficienciesCompleted(data.proficiencies.filter((proficiency) => proficiency.completed === true))
        setProficienciesRemaining(data.proficiencies.filter((proficiency) => proficiency.completed !== true))
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setError(e)
      })
  }, [])

  return (
    <Box sx={{ background: '#1756A3', padding: '0px', display: 'flex', flexDirection: 'column' }}>
      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ background: '#FFFFFF' }}>
          <Box sx={{ p: 0, pb: 0, background: '#1756A3' }}>
            <Paper sx={{ padding: '10px', backgroundColor: '#1756A3' }}>
              <Typography variant="body1" color="#FFFFFF">
                Complete the Quizzes (score &gt;80%) and Module Assessments to gain your proficiencies. Achieved proficiencies are below:
              </Typography>
            </Paper>
          </Box>
          <Box sx={{ p: 2, pb: 0, background: '#FFFFFF', overflow: 'auto', maxHeight: '300px' }}>
            {proficienciesCompleted.map((proficiency, index) => (
              <ProficiencyChip key={`proficiency-completed-${proficiency.name}-${index}`} achieved={true} name={proficiency.name} />
            ))}

            {proficienciesRemaining.map((proficiency, index) => (
              <ProficiencyChip key={`proficiency-remaining-${proficiency.name}-${index}`} achieved={false} name={proficiency.name} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ProficiencyPanel
