import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Divider, Fade } from '@mui/material'
import axios from 'axios'
import classnames from 'classnames'
import Feedback from '../results/feedback'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './question.css'
import rehypeRaw from 'rehype-raw'
import { Link } from 'react-router-dom'
import { useUser } from 'login/user-context'
import { usePoints } from 'points/context'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import theme from 'theme'

const Question = ({ questionNumber, totalQuestions, isLastQuestion, next, questionId }) => {
  const [loading, setLoading] = useState(true)
  const { stats, setStats } = useUser()
  const { points, setPoints } = usePoints()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [answerId, setAnswerId] = useState(null)
  const [correctIds, setCorrectIds] = useState(null)
  const [checkAnswer, setCheckAnswer] = useState(false)
  const [feedback, setFeedback] = useState(null)
  const [data, setData] = useState({})
  const [quizId, setQuizId] = useState('')
  const [moduleId, setModuleId] = useState('')
  const [lessonId, setLessonId] = useState('')
  const [contentId, setContentId] = useState('')

  useEffect(() => {
    axios(`/question/${questionId}`)
      .then((response) => {
        if (!response.data.lesson_details || !response.data.modules_details) {
          next(false, response.data) // If did not received module or lesson details then skip question
        } else {
          setData(response.data)
          setQuizId(response.data.quiz_uuid)
          setModuleId(response.data.modules_details[0].uuid)
          setLessonId(response.data.lesson_details.uuid)
          setContentId(response.data.lesson_details.first_content_uuid)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log({ error })
        enqueueSnackbar('Sorry. Something went wrong!', { variant: 'error' })
        navigate('/')
      })
  }, [questionId])

  useEffect(() => {
    if (answerId) {
      axios
        .post(`/quiz/${quizId}/question/${data.uuid}/check`, {
          answerId,
          moduleId,
        })
        .then(({ data }) => {
          setCorrectIds(data.correctIds)
          setFeedback(data.feedback)

          const total = stats.questions.total
          let correct = stats.questions.correct
          let incorrect = stats.questions.incorrect

          if (data.correct) {
            correct += 1
            incorrect -= 1
            setPoints(points + data.points)
          }

          const correctPercentage = Math.round((correct / total) * 100)
          const incorrectPercentage = Math.round((incorrect / total) * 100)
          setStats({
            questions: {
              total: total,
              correct: correct,
              incorrect: incorrect,
              correctPercentage: correctPercentage,
              incorrectPercentage: incorrectPercentage,
            },
          })
        })
    }
  }, [checkAnswer])

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const answeredCorrectly = answerId && correctIds && correctIds.some((id) => id === answerId)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl className="question-mui-wrapper">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', flexWrap: 'wrap' }}>
          <Link
            style={{ fontWeight: 'bold', textDecoration: 'none', color: theme.palette.primary.main }}
            to={`/module/${moduleId}/explore`}
            onClick={(event) => {
              event.preventDefault()
              mixpanel.track('button_clicked', {
                Feature: 'practice_area',
                Location: 'practice_area_link_module',
                Name: 'Practice Area Module Link Clicked',
              })
              navigate(`/module/${moduleId}/explore`)
            }}
          >
            Module: {data.modules_details[0].module_name}
          </Link>
          <Link
            style={{ fontWeight: 'bold', textDecoration: 'none', color: theme.palette.primary.main }}
            to={`/module/${moduleId}/lesson/${lessonId}/${contentId}`}
            onClick={(event) => {
              event.preventDefault()
              mixpanel.track('button_clicked', {
                Feature: 'practice_area',
                Location: 'practice_area_link_lesson',
                Name: 'Practice Area Lesson Link Clicked',
              })
              navigate(`/module/${moduleId}/lesson/${lessonId}/${contentId}`)
            }}
          >
            Lesson: {data.lesson_details.lesson_name}
          </Link>
        </Box>
        <FormLabel id="question">
          <Box>
            {totalQuestions > 1 && (
              <Typography fontSize="1.3rem" color={theme.palette.primary.main} fontWeight="bold" component="div" paddingY={0.4}>
                Question {questionNumber} of {totalQuestions}
              </Typography>
            )}

            <Typography component="div" sx={{ overflow: 'auto' }}>
              <ReactMarkdown className="question-text" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                {data.text}
              </ReactMarkdown>
            </Typography>
          </Box>
        </FormLabel>
        {/* <hr style={{ width: '100%' }} /> */}
        <Divider sx={{ width: '100%', marginY: 1 }}></Divider>
        <RadioGroup aria-labelledby="question" name="answers" value={answerId} onChange={(e) => setAnswerId(e.target.value)}>
          {data.answers.map(({ uuid, text }, index) => {
            const isCorrect = correctIds && correctIds.some((id) => id === uuid)
            return (
              <FormControlLabel
                sx={{ margin: 0, marginBottom: '10px' }}
                className={classnames({
                  'mcq-item': true,
                  'mcq-item-selected': answerId === uuid,
                  'mcq-item-correct': correctIds !== null && isCorrect,
                  'mcq-item-incorrect': correctIds !== null && answerId === uuid && !isCorrect,
                })}
                disabled={checkAnswer}
                key={`answer-${uuid}`}
                value={uuid}
                control={<Radio />}
                label={
                  <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                    {text}
                  </ReactMarkdown>
                }
              />
            )
          })}
        </RadioGroup>
      </FormControl>

      {!checkAnswer && (
        <Box display="flex" justifyContent="center">
          <Button
            sx={{
              alignSelf: 'center',
              marginTop: '10px',
            }}
            disabled={answerId === null || checkAnswer}
            variant="contained"
            color="primary"
            onClick={() => {
              setCheckAnswer(true)

              mixpanel.track('button_clicked', {
                Feature: 'practice_area',
                Location: 'practice_area_button_check',
                Name: 'Check',
              })
            }}
          >
            Check
          </Button>
          <Button
            sx={{
              alignSelf: 'center',
              marginTop: '10px',
            }}
            color="primary"
            onClick={() => {
              next(false, data) // False because just need to set next question

              mixpanel.track('button_clicked', {
                Feature: 'practice_area',
                Location: 'practice_area_button_skip',
                Name: 'Skip',
              })
            }}
          >
            Skip
          </Button>
        </Box>
      )}
      {checkAnswer && correctIds && (
        <Fade in={checkAnswer}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '1000px',
            }}
          >
            <Feedback
              isCorrect={answeredCorrectly}
              feedback={
                <Typography component="div">
                  <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                    {feedback}
                  </ReactMarkdown>
                </Typography>
              }
              uuid={quizId}
            />
            <Button
              sx={{
                alignSelf: 'center',
                marginTop: '10px',
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                next(answeredCorrectly, data)
                mixpanel.track('button_clicked', {
                  Feature: 'practice_area',
                  Location: 'practice_area_button_next',
                  Name: 'Next',
                })
              }}
            >
              {isLastQuestion ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </Fade>
      )}
      <br />
      <Box textAlign="center">
        <Button
          onClick={() => {
            mixpanel.track('button_clicked', {
              Feature: 'practice_area',
              Location: 'practice_area_button_dashboard',
              Name: 'Back to Dashboard',
            })
            navigate('/')
          }}
        >
          Back to Dashboard
        </Button>
      </Box>
    </Box>
  )
}

export default Question
