// material-ui
import { createTheme } from '@mui/material/styles'

// third-party
import { presetPalettes } from '@ant-design/colors'

// project import
import ThemeOption from './theme'

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {
  const colors = presetPalettes

  const greyPrimary = ['#ffffff', '#fafafa', '#f5f5f5', '#f0f0f0', '#d9d9d9', '#bfbfbf', '#8c8c8c', '#595959', '#262626', '#141414', '#000000']
  const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f']
  const greyConstant = ['#fafafb', '#e6ebf1']

  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant]

  const paletteColor = ThemeOption(colors)

  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff',
      },
      ...paletteColor,
      text: {
        primary: paletteColor.grey[700],
        secondary: paletteColor.grey[600],
        // secondary: '#767676',
        disabled: paletteColor.grey[600],
      },
      action: {
        disabled: paletteColor.grey[400],
      },
      divider: paletteColor.grey[200],
      background: {
        paper: paletteColor.grey[0],
        default: paletteColor.grey.A50,
      },
      notAchieved: {
        // main: paletteColor.grey[600],
        main: '#5077b6',
        // contrastText: '#FFFFFF',
        contrastText: '#5077b6',
      },
      greenTint: {
        main: '#5EC2A6',
        // main: '#25865f',
        contrastText: '#FFFFFF',
      },
    },
  })
}

export default Palette
