import React, { useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './highlight.css';

export default function HighlightableContent({ content, setHighlights, activeHighlightName, correctHighlights, highlights, maxHighlightLength }) {
  const contentRef = useRef(null);

  const handleMouseUp = (e) => {
    if (e.target.classList.contains('highlight-text')) {
      e.stopPropagation();
      const highlightId = e.target.dataset.highlightId;
      removeHighlight(highlightId);
      return;
    }
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const adjustedRange = adjustRangeToWordBoundaries(range);
    const selectedText = adjustedRange.toString().trim();

    if (selectedText) {
      const highlightId = uuidv4();
      
      handleExistingHighlights(adjustedRange);
      handleMultiNodeSelection(adjustedRange, highlightId);
      
      setHighlights(prevState => ({
        ...prevState,
        [activeHighlightName]: [...(prevState[activeHighlightName] || []), { id: highlightId, text: selectedText }],
      }));
      selection.removeAllRanges();
    }
  };

  const handleExistingHighlights = (newRange) => {
    const highlightsToModify = [];
    const treeWalker = document.createTreeWalker(
      newRange.commonAncestorContainer,
      NodeFilter.SHOW_ELEMENT,
      {
        acceptNode: (node) => {
          return node.classList.contains('highlight-text') && newRange.intersectsNode(node)
            ? NodeFilter.FILTER_ACCEPT
            : NodeFilter.FILTER_SKIP;
        }
      }
    );

    while (treeWalker.nextNode()) {
      highlightsToModify.push(treeWalker.currentNode);
    }

    highlightsToModify.forEach(highlight => {
      const highlightRange = document.createRange();
      highlightRange.selectNodeContents(highlight);

      if (newRange.compareBoundaryPoints(Range.START_TO_START, highlightRange) <= 0 &&
          newRange.compareBoundaryPoints(Range.END_TO_END, highlightRange) >= 0) {
        removeHighlight(highlight.dataset.highlightId);
      } else {
        splitHighlight(highlight, newRange);
      }
    });
  };

  const splitHighlight = (highlight, newRange) => {
    const highlightRange = document.createRange();
    highlightRange.selectNodeContents(highlight);

    const beforeRange = document.createRange();
    beforeRange.setStart(highlightRange.startContainer, highlightRange.startOffset);
    beforeRange.setEnd(newRange.startContainer, newRange.startOffset);

    const afterRange = document.createRange();
    afterRange.setStart(newRange.endContainer, newRange.endOffset);
    afterRange.setEnd(highlightRange.endContainer, highlightRange.endOffset);

    if (!beforeRange.collapsed) {
      wrapRangeWithHighlight(beforeRange, highlight.dataset.highlightId);
    }

    if (!afterRange.collapsed) {
      wrapRangeWithHighlight(afterRange, highlight.dataset.highlightId);
    }

    removeHighlight(highlight.dataset.highlightId);
  };

  const adjustRangeToWordBoundaries = (range) => {
    const { startContainer, endContainer, startOffset, endOffset } = range;
    const adjustedRange = range.cloneRange();

    if (startContainer.nodeType === Node.TEXT_NODE) {
      const startText = startContainer.textContent;
      let newStartOffset = startOffset;

      while (newStartOffset > 0 && !/\s/.test(startText[newStartOffset - 1])) {
        newStartOffset--;
      }

      adjustedRange.setStart(startContainer, newStartOffset);
    }

    if (endContainer.nodeType === Node.TEXT_NODE) {
      const endText = endContainer.textContent;
      let newEndOffset = endOffset;

      while (newEndOffset < endText.length && !/\s/.test(endText[newEndOffset])) {
        newEndOffset++;
      }

      adjustedRange.setEnd(endContainer, newEndOffset);
    }

    return adjustedRange;
  };

  const handleMultiNodeSelection = (range, highlightId) => {
    const { startContainer, endContainer } = range;
    if (startContainer === endContainer) {
      wrapRangeWithHighlight(range, highlightId);
    } else {
      const { commonAncestorContainer } = range;
      const treeWalker = document.createTreeWalker(
        commonAncestorContainer,
        NodeFilter.SHOW_TEXT,
        {
          acceptNode: (node) => {
            return range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
          }
        }
      );
      const nodesToHighlight = [];
      while (treeWalker.nextNode()) {
        nodesToHighlight.push(treeWalker.currentNode);
      }
      nodesToHighlight.forEach((node, index) => {
        const nodeRange = document.createRange();
        nodeRange.selectNodeContents(node);
        if (index === 0) {
          nodeRange.setStart(node, node === startContainer ? range.startOffset : 0);
        }
        if (index === nodesToHighlight.length - 1) {
          nodeRange.setEnd(node, node === endContainer ? range.endOffset : node.length);
        }
        wrapRangeWithHighlight(nodeRange, highlightId);
      });
    }
  };

  const wrapRangeWithHighlight = (range, id, className = 'highlight-text') => {
    const span = document.createElement('span');
    span.className = className;
    span.dataset.highlightId = id;
    range.surroundContents(span);
  };

  const removeHighlight = (id) => {
    const highlightsToRemove = document.querySelectorAll(`[data-highlight-id="${id}"]`);
    highlightsToRemove.forEach((span) => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
      parent.normalize();
    });
    setHighlights(prevDictionary => {
      const newDictionary = {};
      for (const key in prevDictionary) {
        if (prevDictionary.hasOwnProperty(key)) {
          if (Array.isArray(prevDictionary[key])) {
            newDictionary[key] = prevDictionary[key].filter(item => item.id !== id);
          } else {
            newDictionary[key] = prevDictionary[key];
          }
        }
      }
      return newDictionary;
    });
  };

  const handleHover = (e) => {
    if (e.target.classList.contains('highlight-text')) {
      const highlightId = e.target.dataset.highlightId;
      const allHighlights = document.querySelectorAll(`[data-highlight-id="${highlightId}"]`);
      allHighlights.forEach((highlight) => {
        highlight.classList.toggle('hover-active', e.type === 'mouseover');
      });
    }
  };

  const findTextNodes = (element) => {
    const walker = document.createTreeWalker(
      element,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );
    const textNodes = [];
    let node;
    while (node = walker.nextNode()) {
      textNodes.push(node);
    }
    return textNodes;
  };

  const applyCorrectHighlights = () => {
    if (!contentRef.current) return;
    
    // Remove existing correct highlights
    const existingCorrectHighlights = contentRef.current.querySelectorAll('.correct-highlight-text');
    existingCorrectHighlights.forEach((highlight) => {
      const parent = highlight.parentNode;
      while (highlight.firstChild) {
        parent.insertBefore(highlight.firstChild, highlight);
      }
      parent.removeChild(highlight);
      parent.normalize();
    });

    // Remove existing user highlights if there are correct highlights
    if (correctHighlights.length > 0) {
      const existingHighlights = contentRef.current.querySelectorAll('.highlight-text');
      existingHighlights.forEach((highlight) => {
        const parent = highlight.parentNode;
        while (highlight.firstChild) {
          parent.insertBefore(highlight.firstChild, highlight);
        }
        parent.removeChild(highlight);
        parent.normalize();
      });
    }
  
    // Apply new correct highlights
    correctHighlights.forEach((highlightInstruction) => {
      const [elementId, range] = highlightInstruction.split(':');
      const element = contentRef.current.querySelector(`#e${elementId}`);
      
      if (element && range) {
        const [start, end] = range.split('-').map(Number);
        const textNodes = findTextNodes(element);

        let currentIndex = 0;
        let startNode, startOffset, endNode, endOffset;

        for (let i = 0; i < textNodes.length; i++) {
          const node = textNodes[i];
          const nodeLength = node.textContent.length;

          if (!startNode && currentIndex + nodeLength > start) {
            startNode = node;
            startOffset = start - currentIndex;
          }

          if (currentIndex + nodeLength >= end) {
            endNode = node;
            endOffset = end - currentIndex;
            break;
          }

          currentIndex += nodeLength;
        }

        if (startNode && endNode) {
          const highlightRange = document.createRange();
          highlightRange.setStart(startNode, startOffset);
          highlightRange.setEnd(endNode, endOffset);
          wrapRangeWithHighlight(highlightRange, `correct-${elementId}-${start}-${end}`, 'correct-highlight-text');
        } else {
          console.error('Unable to create highlight range');
        }
      } else if (element) {
        // If no range is specified, highlight the entire element
        const range = document.createRange();
        range.selectNodeContents(element);
        wrapRangeWithHighlight(range, `correct-${elementId}`, 'correct-highlight-text');
      } else {
        console.error('Element not found:', elementId);
      }
    });
  };

  useEffect(() => {
    const content = contentRef.current;
    content.addEventListener('mouseup', handleMouseUp);
    content.addEventListener('mouseover', handleHover);
    content.addEventListener('mouseout', handleHover);

    return () => {
      content.removeEventListener('mouseup', handleMouseUp);
      content.removeEventListener('mouseover', handleHover);
      content.removeEventListener('mouseout', handleHover);
    };
  }, []);

  useEffect(() => {
    applyCorrectHighlights();
  }, [correctHighlights, content]);

  return (
    <div>
      <div 
        className="highlight-container" 
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}