import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Vimeo from '@u-wave/react-vimeo'
import { Box, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const WELCOME_VIDEO_URL = 'https://vimeo.com/768308298/3be66df5df'

const WelcomeModal = props => {
  const hideWelcome = window.innerWidth < 900 || !!localStorage.getItem('hideWelcome') 
  const [ open, setOpen ] = useState(!hideWelcome)

  const onClose = () => {
    localStorage.setItem('hideWelcome', true)
    setOpen(false)
  }

  const [ready, setReady] = useState(false)
  const [resize, setResize] = useState(false)

  const ref = useRef(null);

  const [width, setWidth] = useState(0)
  
  function handleResize() {
    setWidth(ref.current && ref.current.offsetWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  },[resize])

  useLayoutEffect(() => {
    setWidth(ref.current && ref.current.offsetWidth)
  }, [ready])


  return (
    <Modal
      open={open}
      onClose={() => onClose()}
    >
      <Box 
        ref={ref}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#FFFFFF',
          border: '0',
          boxShadow: 24,
          padding: '35px',
          paddingTop: '55px',
          maxWidth: '1500px',
          width: '80vw',
          '&:focus': { 
            border: '0',
            outline: 'none'
          }
        }}
      >
        <CloseIcon onClick={onClose} sx={{cursor: 'pointer', position: 'absolute', right: '10px', top: '10px'}}/>
        <Vimeo
          onReady={() => setReady(true)}
          video={WELCOME_VIDEO_URL}
          width={`${width - 70}px`}
          height="550px"
        />
      </Box>
    </Modal>
  )
}

export default WelcomeModal