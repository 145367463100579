import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material'
import './upsert.css'
import UpsertQuestions from './upsert-questions'

const UpsertQuizWrapper = ({ linkingToLesson, onSave, uuid }) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  useEffect(() => {
    if (uuid) {
      axios(`/quiz/${uuid}`)
        .then((r) => {
          setData(r.data)
          setLoading(false)
        })
        .catch(setError)
    } else {
      setLoading(false)
    }
  }, [uuid])

  return (
    <div>
      {loading && <CircularProgress />}
      {error && (
        <Alert severity="error">Error! {error.response.data.message}</Alert>
      )}
      {data && !loading && (
        <EditQuiz onSave={onSave} linkingToLesson={linkingToLesson} {...data} />
      )}
    </div>
  )
}

const EditQuiz = (props) => {
  const { linkingToLesson } = props
  const [passPercentage, setPassPercentage] = useState(
    props.passPercentage || 75
  )
  const [questions, setQuestions] = useState(props.questions || [])
  const [saving, setSaving] = useState(false)
  const [saveError, setSaveError] = useState(null)
  const [linkingQuestion, setLinkingQuestion] = useState(false)
  const [linkingError, setLinkingError] = useState(false)

  useEffect(async () => {
    if (saving) {
      try {
        const r = await axios({
          url: `/quiz/${props.uuid || ''}`,
          method: 'post',
          data: {
            name: '',
            passPercentage,
          },
        })
        setSaving(false)
        typeof props.onSave === 'function' && props.onSave(r.data.uuid)
      } catch (e) {
        setSaveError(e)
      }
    }
  }, [saving])

  useEffect(async () => {
    if (linkingQuestion) {
      try {
        await axios({
          url: `/quiz/${props.uuid}/question`,
          method: 'post',
          data: {
            questionId: linkingQuestion,
          },
        })
        setLinkingQuestion(false)
      } catch (e) {
        setLinkingError(e)
      }
    }
  }, [linkingQuestion])

  return (
    <FormControl className="question-mui-wrapper">
      {saveError && (
        <Alert severity="error">Error! {saveError.response.data.message}</Alert>
      )}
      <div className="quiz-line-wrapper">
        <TextField
          aria-labelledby="passPercentage"
          name="passPercentage"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={passPercentage}
          onChange={(e) => setPassPercentage(e.target.value)}
          label="Percentage required to pass the quiz"
          variant="outlined"
          sx={{ flex: 1 }}
          size="small"
        />
        <Button
          disabled={passPercentage === props.passPercentage}
          onClick={() => setSaving(true)}
          sx={{ width: 'fit-content' }}
          size="small"
        >
          {saving || linkingToLesson ? <CircularProgress size="1rem" /> : null}
          Save
        </Button>
      </div>
      {props.uuid && (
        <UpsertQuestions
          questions={questions}
          linkQuestion={(uuid) => {
            setLinkingQuestion(uuid)
          }}
        />
      )}
    </FormControl>
  )
}

export default UpsertQuizWrapper
