import React, { useState, useEffect } from 'react'
import {
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Box,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Fade,
  ListItemIcon,
  styled,
  keyframes,
  Divider,
} from '@mui/material'
import { Inventory, ShoppingCart, LocalShipping } from '@mui/icons-material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Global, css } from '@emotion/react'
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@mui/icons-material'
import { green, red, blue, orange } from '@mui/material/colors'
import CalculateIcon from '@mui/icons-material/Calculate'
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded'
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'
// Styled components
const CompactList = styled(List)({
  padding: 0,
})

const CompactListItem = styled(ListItem)({
  padding: '4px 0',
})

const CompactListItemText = styled(ListItemText)({
  margin: 0,
  '& .MuiListItemText-primary': {
    lineHeight: 1.2,
  },
})

const spin = keyframes`
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(360deg); }
`

// Update the MethodBox styled component
const MethodBox = styled(Box)(({ theme, method }) => ({
  backgroundColor: method === 'FIFO' ? blue[100] : orange[100],
  color: method === 'FIFO' ? blue[800] : orange[800],
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '1rem',
  animation: `${spin} 2s ease-out`,
  perspective: 1000,
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const QuestionCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}))

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
}))

const globalStyles = css`
  @keyframes pulseBorder {
    100% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0.7);
    }
    0% {
      box-shadow: 0 0 0 10px rgba(23, 86, 163, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0);
    }
  }
`

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
})

const InventoryValuationQuizGame = () => {
  const [gameState, setGameState] = useState('start')
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [score, setScore] = useState(0)
  const [userAnswer, setUserAnswer] = useState({ cogs: '', endingValue: '' })
  const [feedback, setFeedback] = useState(null)
  const [questions, setQuestions] = useState([])
  const [spinning, setSpinning] = useState(true)
  const [fadeIn, setFadeIn] = useState(true)
  useEffect(() => {
    setSpinning(true)
    const timer = setTimeout(() => setSpinning(false), 1000)
    return () => clearTimeout(timer)
  }, [currentQuestion])
  const totalQuestions = 3

  const generateQuestion = () => {
    const startDate = new Date(2024, 6, 1) // July 1, 2024
    const startingInventory = Math.floor(Math.random() * 100) + 50
    const purchases = [
      {
        date: new Date(startDate.getTime() + Math.random() * 7 * 24 * 60 * 60 * 1000),
        quantity: Math.floor(Math.random() * 50) + 10,
        price: Math.floor(Math.random() * 10) + 5,
      },
      {
        date: new Date(startDate.getTime() + Math.random() * 14 * 24 * 60 * 60 * 1000),
        quantity: Math.floor(Math.random() * 50) + 10,
        price: Math.floor(Math.random() * 10) + 5,
      },
    ]
    const salesDate = new Date(purchases[1].date.getTime() + Math.random() * 7 * 24 * 60 * 60 * 1000)
    const salesQuantity = Math.floor(Math.random() * (startingInventory + purchases[0].quantity + purchases[1].quantity - 10)) + 10

    return {
      startingInventory,
      purchases,
      salesDate,
      salesQuantity,
      method: Math.random() < 0.5 ? 'FIFO' : 'LIFO',
    }
  }
  useEffect(() => {
    setQuestions(
      Array(totalQuestions)
        .fill()
        .map(() => generateQuestion())
    )
  }, [])

  const calculateAnswer = (question) => {
    let inventory = [{ quantity: question.startingInventory, price: 10 }]
    question.purchases.forEach((purchase) => {
      inventory.push(purchase)
    })

    let remainingSales = question.salesQuantity
    let cogs = 0

    if (question.method === 'FIFO') {
      for (let i = 0; i < inventory.length && remainingSales > 0; i++) {
        const soldFromBatch = Math.min(remainingSales, inventory[i].quantity)
        cogs += soldFromBatch * inventory[i].price
        inventory[i].quantity -= soldFromBatch
        remainingSales -= soldFromBatch
      }
    } else {
      // LIFO
      for (let i = inventory.length - 1; i >= 0 && remainingSales > 0; i--) {
        const soldFromBatch = Math.min(remainingSales, inventory[i].quantity)
        cogs += soldFromBatch * inventory[i].price
        inventory[i].quantity -= soldFromBatch
        remainingSales -= soldFromBatch
      }
    }

    const endingValue = inventory.reduce((sum, batch) => sum + batch.quantity * batch.price, 0)

    return {
      cogs: Math.round(cogs * 100) / 100,
      endingValue: Math.round(endingValue * 100) / 100,
    }
  }

  const handleSubmit = () => {
    const correctAnswer = calculateAnswer(questions[currentQuestion])
    const isCorrect = Math.abs(parseFloat(userAnswer.cogs) - correctAnswer.cogs) <= 0.01 && Math.abs(parseFloat(userAnswer.endingValue) - correctAnswer.endingValue) <= 0.01

    if (isCorrect) {
      setScore(score + 1)
      setFeedback({
        isCorrect: true,
        message: 'Correct! Well done.',
        correctAnswer,
      })
    } else {
      setFeedback({
        isCorrect: false,
        message: '',
        correctAnswer,
        userAnswer,
      })
    }
  }

  const nextQuestion = () => {
    setFadeIn(false)
    setTimeout(() => {
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1)
        setUserAnswer({ cogs: '', endingValue: '' })
        setFeedback(null)
        setFadeIn(true)
      } else {
        setGameState('end')
        setFadeIn(true)
      }
    }, 300) // This duration should match the fade-out duration in the Fade component
  }

  const restartGame = () => {
    setQuestions(
      Array(totalQuestions)
        .fill()
        .map(() => generateQuestion())
    )
    setCurrentQuestion(0)
    setScore(0)
    setUserAnswer({ cogs: '', endingValue: '' })
    setFeedback(null)
    setGameState('start')
  }

  const renderStartScreen = () => (
    <Fade in={true}>
      <Box>
        <Typography variant="h6" gutterBottom>
          How to Play:
        </Typography>
        <List>
          <ListItem sx={{ padding: '4px 8px' }}>
            <ListItemText primary="1. You'll be presented with 3 inventory scenarios." />
          </ListItem>
          <ListItem sx={{ padding: '4px 8px' }}>
            <ListItemText primary="2. Each scenario will specify whether to use FIFO or LIFO method." />
          </ListItem>
          <ListItem sx={{ padding: '4px 8px' }}>
            <ListItemText
              primary={
                <Typography component="span">
                  3. Calculate the Cost of Goods Sold and Ending Inventory Value.
                  <CalculateIcon style={{ verticalAlign: 'middle' }} />
                </Typography>
              }
            />
          </ListItem>

          <ListItem sx={{ padding: '4px 8px' }}>
            <ListItemText
              primary={
                <Typography component="span">
                  4. Enter your answers and submit.
                  <ContentPasteGoOutlinedIcon style={{ verticalAlign: 'middle' }} />
                </Typography>
              }
            />
          </ListItem>

          <ListItem sx={{ padding: '4px 8px' }}>
            <ListItemText
              primary={
                <Typography component="span">
                  5. You'll receive immediate feedback on your answers.
                  <FeedbackOutlinedIcon style={{ verticalAlign: 'middle' }} />
                </Typography>
              }
            />
          </ListItem>

          <ListItem sx={{ padding: '4px 8px' }}>
            <ListItemText primary="6. Try to get all 3 questions correct!" />
          </ListItem>
        </List>
        <Box display="flex" justifyContent="center" mt={3}>
          <Global styles={globalStyles} />
          <Button
            onClick={() => setGameState('playing')}
            sx={{
              margin: '0 8px',
              animation: 'pulseBorder 2s infinite',
              marginTop: '0px',
              cursor: 'pointer',
              borderRadius: 28,
              border: '2px solid #1756A3',
              fontSize: '14px',
              fontWeight: 600,
              padding: '6px 18px',
            }}
            variant="contained"
            color="primary"
          >
            Start Game
          </Button>
        </Box>
      </Box>
    </Fade>
  )

  // Helper function to format date as DD/MM/YY
  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().slice(-2)}`
  }

  const renderQuestion = () => {
    const q = questions[currentQuestion]
    return (
      <Fade in={fadeIn} timeout={300}>
        <QuestionCard variant="outlined">
          <CardContent>
            <Typography variant="h5" gutterBottom align="center">
              Question {currentQuestion + 1} of {totalQuestions}
            </Typography>
            <MethodBox method={q.method} key={currentQuestion}>
              Use {q.method} Method
            </MethodBox>
            <Paper
              elevation={2}
              style={{
                padding: '16px',
                marginBottom: '16px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <InventoryRoundedIcon
                style={{
                  position: 'absolute',
                  right: '-20px',
                  bottom: '-20px',
                  fontSize: '150px',
                  opacity: 0.18,
                  transform: 'rotate(-15deg)',
                  color: theme.palette.primary.main,
                }}
              />
              <CompactList>
                <CompactListItem>
                  <CompactListItemText
                    primary={
                      <Typography component="span">
                        <strong>Starting Inventory:</strong> {q.startingInventory} units at $10 each
                      </Typography>
                    }
                  />
                </CompactListItem>
                {q.purchases.map((purchase, index) => (
                  <CompactListItem key={index}>
                    <CompactListItemText
                      primary={
                        <Typography component="span">
                          <strong>
                            Purchase {index + 1} ({formatDate(purchase.date)}):
                          </strong>{' '}
                          {purchase.quantity} units at ${purchase.price} each
                        </Typography>
                      }
                    />
                  </CompactListItem>
                ))}
                <CompactListItem>
                  <CompactListItemText
                    primary={
                      <Typography component="span">
                        <strong>Sales ({formatDate(q.salesDate)}):</strong>
                        {q.salesQuantity} units
                      </Typography>
                    }
                  />
                </CompactListItem>
              </CompactList>
            </Paper>
            <Box my={2}>
              <TextField
                label="Cost of Goods Sold ($)"
                type="number"
                value={userAnswer.cogs}
                onChange={(e) => setUserAnswer({ ...userAnswer, cogs: e.target.value })}
                fullWidth
                margin="normal"
                size="small"
              />
              <TextField
                label="Ending Inventory Value ($)"
                type="number"
                value={userAnswer.endingValue}
                onChange={(e) => setUserAnswer({ ...userAnswer, endingValue: e.target.value })}
                fullWidth
                margin="normal"
                size="small"
              />
            </Box>
          </CardContent>
          <CardActions style={{ justifyContent: 'center' }}>
            <Button onClick={handleSubmit} disabled={spinning} variant="contained" color="primary" size="large">
              Check Answer
            </Button>
          </CardActions>
        </QuestionCard>
      </Fade>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', width: '600px', minHeight: '250px' }}>
          {gameState === 'start' && renderStartScreen()}
          {gameState === 'playing' && (
            <Box>
              {renderQuestion()}
              <FeedbackDialog feedback={feedback} nextQuestion={nextQuestion} currentQuestion={currentQuestion} totalQuestions={questions.length} />
            </Box>
          )}
          {gameState === 'end' && (
            <Box>
              <CardContent>
                <Typography variant="h4" gutterBottom align="center">
                  Game Over
                </Typography>

                <Paper elevation={2} style={{ padding: '16px', marginBottom: '16px', minHeight: '200px', position: 'relative', overflow: 'hidden' }}>
                  <EmojiEventsOutlinedIcon
                    style={{
                      position: 'absolute',
                      right: '20px',
                      bottom: '20px',
                      fontSize: '150px',
                      opacity: 0.18,
                      // transform: 'rotate(-15deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography variant="body1" paragraph align="center">
                    Your score: {score} out of {totalQuestions}
                  </Typography>
                  <Box display="flex" justifyContent="center" sx={{ marginTop: '30px' }}>
                    <Button onClick={restartGame} variant="contained" color="primary">
                      Play Again
                    </Button>
                  </Box>
                </Paper>
              </CardContent>
              <Box display="flex" justifyContent="center">
                <Typography variant="body2" align="center" color="text.secondary">
                  Click Next to continue on the lesson.
                </Typography>
              </Box>
            </Box>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  )
}

const FeedbackDialog = ({ feedback, nextQuestion, currentQuestion, totalQuestions }) => {
  if (!feedback) return null

  return (
    <StyledDialog open={feedback !== null} onClose={nextQuestion} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" backgroundColor>
          {/* {feedback.isCorrect ? <CheckCircleIcon fontSize="large" sx={{ color: '#4caf50', mr: 1 }} /> : <CancelIcon fontSize="large" sx={{ color: red[500], mr: 1 }} />}
          {feedback.isCorrect ? 'Correct!' : 'Incorrect'} */}
          Feedback
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          {feedback.message}
        </Typography>
        <Box bgcolor="rgb(244, 251, 239)" sx={{ border: '1px solid #5ec2a6' }} p={2} borderRadius={1} mb={2}>
          <Typography variant="h6" gutterBottom>
            Correct Answer:
          </Typography>
          <Typography variant="body1">Cost of Goods Sold: ${feedback.correctAnswer.cogs}</Typography>
          <Typography variant="body1">Ending Inventory Value: ${feedback.correctAnswer.endingValue}</Typography>
        </Box>
        {!feedback.isCorrect && (
          <Box bgcolor="rgb(255, 245, 245)" sx={{ border: '1px solid #eb464c' }} p={2} borderRadius={1}>
            <Typography variant="h6" gutterBottom>
              Your Answer:
            </Typography>
            <Typography variant="body1">Cost of Goods Sold: ${feedback.userAnswer.cogs}</Typography>
            <Typography variant="body1">Ending Inventory Value: ${feedback.userAnswer.endingValue}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={nextQuestion} color="primary" variant="contained">
          {currentQuestion < totalQuestions - 1 ? 'Next Question' : 'Finish Game'}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default InventoryValuationQuizGame
