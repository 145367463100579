// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (fontFamily) => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 600,
    fontSize: '2.375rem',
    lineHeight: 1.21,
  },
  // h2: {
  //   fontWeight: 600,
  //   fontSize: '1.875rem',
  //   lineHeight: 1.27
  // },

  h2: {
    color: '#0A2645',
    fontWeight: 600,
    fontSize: '28px',
    fontFamily: ['"Montserrat"'],
    lineHeight: '36px',
    margin: '10px 20px 30px 20px',
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: 1.33,
    padding: '5px',
    color: '#1756A3',
  },
  h4: {
    // fontWeight: 600,
    // fontSize: "1.25rem",
    // lineHeight: 1.4,

    color: '#0A2645',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  h5: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  h6: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    color: 'rgb(38, 38, 38)',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
  },
  // body1: {
  //   fontSize: "0.875rem",
  //   lineHeight: 1.57,
  // },
  body1: {
    color: '#0A2645',
    fontSize: '0.9rem',
  },
  body2: {
    color: '#0A2645',
    fontSize: '0.75rem',
    lineHeight: 1.66,
  },

  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: 1.57,
  },
  subtitle2: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 1.66,
  },
  overline: {
    lineHeight: 1.66,
  },
  button: {
    textTransform: 'capitalize',
  },
  cardHeader: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: 1.33,
    color: 'FFFFFF',
  },
})

export default Typography
