import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, CircularProgress, Container, Grid, Divider, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as Yup from 'yup'
import { FastField, Form, Formik } from 'formik'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { handleFileUpload, handleFileDelete } from '../utils/file-uploader'
import { useSnackbar } from 'notistack'
import { debounce } from '@mui/material/utils'
import '@mdxeditor/editor/style.css'
import { MDXEditor } from '@mdxeditor/editor'

import { tablePlugin } from '@mdxeditor/editor/plugins/table'
import { quotePlugin } from '@mdxeditor/editor/plugins/quote'
import { listsPlugin } from '@mdxeditor/editor/plugins/lists'
import { toolbarPlugin } from '@mdxeditor/editor/plugins/toolbar'
import { headingsPlugin } from '@mdxeditor/editor/plugins/headings'
import { markdownShortcutPlugin } from '@mdxeditor/editor/plugins/markdown-shortcut'

import { UndoRedo } from '@mdxeditor/editor/plugins/toolbar/components/UndoRedo'
import { ListsToggle } from '@mdxeditor/editor/plugins/toolbar/components/ListsToggle'
import { InsertTable } from '@mdxeditor/editor/plugins/toolbar/components/InsertTable'
import { BlockTypeSelect } from '@mdxeditor/editor/plugins/toolbar/components/BlockTypeSelect'
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles'

import './mdx.css'

const MAX_FILE_SIZE = 2000000
const validFileExtensions = { image: ['jpg', 'png', 'jpeg', 'webp', 'gif', 'svg'] }
const ui_env = document.location.hostname
let s3BucketName = 'dev.examfly-app.com-images'
const fileUploadPath = 'images/module/'

switch (ui_env) {
  case 'dev.examfly-app.com':
    s3BucketName = 'dev.examfly-app.com-images'
    break
  case 'staging.examfly-app.com':
    s3BucketName = 'staging.examfly-app.com-images'
    break
  case 'www.examfly-app.com':
    s3BucketName = 'www.examfly-app.com-images'
    break
  default:
    s3BucketName = 'dev.examfly-app.com-images'
    break
}

const AddModuleSchema = Yup.object().shape({
  module_name: Yup.string().trim().required('Module Name is required!'),
  module_image: Yup.mixed()
    .required('Upload Image file')
    .test('is-valid-type', 'Not a valid image type!', (value) => {
      if (typeof value === 'object') {
        return value.name && validFileExtensions['image'].indexOf(value.name.toLowerCase().split('.').pop()) > -1
      } else {
        return true
      }
    })
    .test('is-valid-size', 'Max allowed size is 2MB', (value) => (typeof value === 'string' ? true : typeof value === 'object' && value.size <= MAX_FILE_SIZE)),
  module_image2: Yup.mixed()
    .nullable()
    .test('is-valid-type', 'Not a valid image type!', (value) => {
      if (typeof value === 'object' && value) {
        return value.name && validFileExtensions['image'].indexOf(value.name.toLowerCase().split('.').pop()) > -1
      } else {
        return true
      }
    })
    .test('is-valid-size', 'Max allowed size is 2MB', (value) => {
      if (value === null) {
        return true
      } else {
        return typeof value === 'string' ? true : typeof value === 'object' && value.size <= MAX_FILE_SIZE
      }
    }),
})

const AddModule = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [dataLoaded, setDataLoaded] = useState(false)

  const { moduleId } = useParams()

  const [moduleData, setModuleData] = useState({
    module_name: '',
    module_image: null,
    module_image2: null,
    module_description: '',
  })

  const fetchModuleData = async (moduleId) => {
    setLoading(true)
    const data = await axios.get(`/module/get-module/${moduleId}`)

    return {
      module_name: data.data.name,
      module_image: data.data.image_url,
      module_image2: data.data.image_url2,
      module_description: data.data.description,
    }
  }

  useEffect(() => {
    if (moduleId) {
      fetchModuleData(moduleId)
        .then((data) => {
          setModuleData(data)
          setTimeout(() => {
            setDataLoaded(true)
          }, 300)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
          navigate('/admin-dashboard?tab=2')
        })
    } else {
      setDataLoaded(true)
      setLoading(false)
    }
  }, [moduleId])

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        {moduleId ? 'Edit Module' : 'Add Module'}
      </Typography>
      <Box sx={{ background: '#ffffff', padding: 5, position: 'relative' }}>
        <Link variant="body1" to="/admin-dashboard?tab=2">
          <Button sx={{ padding: '0 10px' }} variant="text">
            <ArrowBackIcon />
            Back
          </Button>
        </Link>
        <br />
        <Box sx={{ width: '100%' }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <Formik
            initialValues={moduleData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={AddModuleSchema}
            onSubmit={async (values) => {
              try {
                setLoading(true)

                const requestData = {
                  name: values.module_name,
                }

                console.log(requestData)
                // Module Image
                if (typeof values.module_image === 'object') {
                  console.log('Uploading Image')
                  let image_name = new Date().getTime() + '_' + values.module_image.name
                  image_name = image_name.trim().replace(/\s/g, '_')

                  if (moduleId) {
                    console.log('Deleteing module iamge 1 with module ID: ', moduleId)
                    await handleFileDelete(moduleData.module_image, fileUploadPath, s3BucketName)
                  }
                  console.log('Uploading module image: ', values.module_image, image_name, fileUploadPath, s3BucketName)

                  await handleFileUpload(values.module_image, image_name, fileUploadPath, s3BucketName)

                  requestData['image_url'] = '/images/module/' + image_name
                } else {
                  console.log('no module image for this one...')
                  requestData['image_url'] = values.module_image
                }
                // Module Image2
                if (typeof values.module_image2 === 'object' && values.module_image2 !== null) {
                  let image_name2 = new Date().getTime() + '_' + values.module_image2.name
                  console.log('there is another image:')
                  if (moduleId && moduleData?.module_image2 && moduleData?.module_image2 !== '') {
                    console.log('Deleteing module iamge 2 with module ID: ', moduleId)

                    await handleFileDelete(moduleData.module_image2, fileUploadPath, s3BucketName)
                  }
                  console.log('Uploading module image: ', values.module_image2, image_name2, fileUploadPath, s3BucketName)

                  await handleFileUpload(values.module_image2, image_name2, fileUploadPath, s3BucketName)
                  requestData['image_url2'] = '/images/module/' + image_name2
                } else {
                  requestData['image_url2'] = values.module_image2 || null
                }
                if (values.module_description) {
                  requestData['description'] = values.module_description
                }

                if (moduleId) {
                  requestData['module_uuid'] = moduleId
                }

                console.log('/module/save-module: ', requestData)
                await axios.post(`/module/save-module`, requestData)

                let snackbarMessage = 'Module Added Successfully!'

                if (moduleId) {
                  snackbarMessage = 'Module Updated Successfully!'
                }

                enqueueSnackbar(snackbarMessage, { variant: 'success' })
              } catch (e) {
                console.log(e)
                enqueueSnackbar('Something went wrong!', { variant: 'error' })
              }

              setLoading(false)
              navigate('/admin-dashboard?tab=2')
            }}
          >
            {({ errors, touched, values, handleSubmit, setFieldValue, validateField }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container rowSpacing={3} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                  <Grid item xs={12}>
                    <FastField name="module_name">
                      {({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          name="module_name"
                          label="Module Name"
                          error={Boolean(errors.module_name && touched.module_name)}
                          helperText={errors.module_name && touched.module_name ? String(errors.module_name) : undefined}
                        />
                      )}
                    </FastField>
                  </Grid>
                  <Grid item xs={12} direction="row" justifyContent="space-between" alignItems="center" container>
                    <Grid item>
                      {values.module_image !== null && (
                        <img
                          src={
                            typeof values.module_image === 'object'
                              ? URL.createObjectURL(values.module_image)
                              : `${process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' : ''}${values.module_image}`
                          }
                          style={{ borderRadius: '50%', objectFit: 'cover' }}
                          alt="Module"
                          width={100}
                          height={100}
                        />
                      )}
                    </Grid>

                    <Grid item>
                      <Button fullWidth variant="contained" component="label">
                        Upload Image
                        <FastField
                          name="module_image"
                          validate={(image) => {
                            const imageSchema = Yup.mixed()
                              .required('Upload Image file')
                              .test('is-valid-type', 'Not a valid image type!', (value) => {
                                if (typeof value === 'object') {
                                  return value.name && validFileExtensions['image'].indexOf(value.name.toLowerCase().split('.').pop()) > -1
                                } else {
                                  return true
                                }
                              })
                              .test('is-valid-size', 'Max allowed size is 2MB', (value) =>
                                typeof value === 'string' ? true : typeof value === 'object' && value.size <= MAX_FILE_SIZE
                              )
                            try {
                              imageSchema.validateSync(image, { abortEarly: true })
                            } catch (error) {
                              return error.errors[0]
                            }
                          }}
                          as={() => (
                            <input
                              hidden
                              id="file"
                              // name="module_image"
                              type="file"
                              accept=".jpg,.jpeg,.png"
                              onChange={(event) => {
                                setFieldValue('module_image', event.currentTarget.files[0])

                                setTimeout(() => {
                                  validateField('module_image')
                                }, 200)
                              }}
                            />
                          )}
                        />
                      </Button>
                      <Typography sx={{ color: 'error.main' }} variant="subtitle2" gutterBottom>
                        {errors.module_image ? String(errors.module_image) : undefined}
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem></Divider>
                    <Grid item>
                      {!values.module_image2 && <Typography>No Image</Typography>}
                      {values.module_image2 && (
                        <img
                          src={
                            typeof values.module_image2 === 'object'
                              ? URL.createObjectURL(values.module_image2)
                              : `${process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' : ''}${values.module_image2}`
                          }
                          style={{ borderRadius: '50%', objectFit: 'cover' }}
                          alt="Module"
                          width={100}
                          height={100}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Button fullWidth variant="contained" component="label">
                        Upload GIF
                        <FastField
                          name="module_image2"
                          validate={(image) => {
                            const imageSchema = Yup.mixed()
                              .nullable()
                              .test('is-valid-type', 'Not a valid image type!', (value) => {
                                if (typeof value === 'object' && value) {
                                  return value.name && validFileExtensions['image'].indexOf(value.name.toLowerCase().split('.').pop()) > -1
                                } else {
                                  return true
                                }
                              })
                              .test('is-valid-size', 'Max allowed size is 2MB', (value) => {
                                if (value === null) {
                                  return true
                                } else {
                                  return typeof value === 'string' ? true : typeof value === 'object' && value.size <= MAX_FILE_SIZE
                                }
                              })

                            try {
                              imageSchema.validateSync(image, { abortEarly: true })
                            } catch (error) {
                              console.log(error)
                              return error.errors[0]
                            }
                          }}
                          as={() => (
                            <input
                              hidden
                              id="file2"
                              //name="module_image2"
                              type="file"
                              accept=".gif"
                              onChange={(event) => {
                                setFieldValue('module_image2', event.currentTarget.files[0])

                                setTimeout(() => {
                                  validateField('module_image2')
                                }, 200)
                              }}
                            />
                          )}
                        />
                      </Button>
                      <Button onClick={() => setFieldValue('module_image2', null)}>Remove</Button>
                      <Typography sx={{ color: 'error.main' }} variant="subtitle2" gutterBottom>
                        {errors.module_image2 ? String(errors.module_image2) : undefined}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ height: '500px' }} item xs={12}>
                    <Typography sx={{ margin: 0, marginBottom: 0.8 }} variant="h4" gutterBottom>
                      Module Description
                    </Typography>
                    {dataLoaded ? (
                      <FastField name="module_description">
                        {({ field }) => (
                          <MDXEditor
                            placeholder="Write module description here..."
                            onChange={(content) => debounce(setFieldValue('module_description', content), 100)}
                            className="mdx-container"
                            markdown={values.module_description || ''}
                            plugins={[
                              toolbarPlugin({
                                toolbarContents: () => (
                                  <>
                                    <UndoRedo />
                                    <BoldItalicUnderlineToggles />
                                    <ListsToggle />
                                    <BlockTypeSelect />
                                    <InsertTable />
                                  </>
                                ),
                              }),
                              tablePlugin(),
                              quotePlugin(),
                              listsPlugin(),
                              headingsPlugin(),
                              markdownShortcutPlugin(),
                            ]}
                          />
                        )}
                      </FastField>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container gap={1} direction="row" justifyContent="flex-end">
                      <Grid item>
                        <Link className="top-bar-button" to="/admin-dashboard?tab=2">
                          <Button variant="contained" type="reset">
                            Cancel
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Container>
  )
}

export default AddModule
