import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'

import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import Summary from '../results/summary'
import { useBadges } from '../badges/context'

const Done = ({answers, disableNext, next, reset}) => {
  const totalAnswers = Object.keys(answers).length
  const correctAnswers = Object.values(answers).filter(({isCorrect}) => isCorrect).length
  
  const { awardBadge } = useBadges()
  useEffect(() => {
    awardBadge('FIRST_PARAGRAPHS')
    if(correctAnswers === totalAnswers) {
      awardBadge('PARAGRAPHS_100%')
    }
    if(correctAnswers === 0){
      awardBadge('ANY_0%')
    }
  },[correctAnswers, totalAnswers])

  return <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <Summary correct={correctAnswers} total={totalAnswers} />
    <Box sx={{display: 'flex', marginTop: '20px'}}>
      <Button variant="contained" color="primary" onClick={reset}>
        <RestartAltIcon />
        Restart
      </Button>
      <Button disabled={disableNext} variant="contained" color="primary" onClick={() => next(true)}>
        Next Step
        <ArrowForwardIosIcon />
      </Button>
    </Box>
  </Box>
}

export default Done