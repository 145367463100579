import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material'
import axios from 'axios'

import Feedback from '../results/feedback'
import ResultsSummary from '../results/summary'
import MobileNotSupported from '../components/MobileNotSupported'
import { useLesson } from '../lesson/context'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

export const SFMA_GAME = 'SFMAGame'

function getValue(values) {
  return values[parseInt(Math.random() * (values.length - 1))]
    }

const CalculationsWrapper = ({ next, uuid = 'eac72544-5a68-48c7-abd1-0ba82696145c' }) => {
  const [topicIndex, setTopicIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [disableNext, setDisableNext] = useState(true)

  const { moduleId } = useLesson()

  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [totalAnswers, setTotalAnswers] = useState(0)

  useEffect(() => {
    if (loading) {
      axios(`/sfma/${uuid}`).then((r) => {
        setData(r.data)
        setLoading(false)
      })
    }
  }, [uuid])
  useEffect(() => {
    if (data.topics && topicIndex >= data.topics.length) {
      setDisableNext(true)
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: SFMA_GAME,
          uuid: uuid,
        },
      }).then(() => {
        setDisableNext(false)
      })
    }
  }, [topicIndex, data])

  if (loading) {
    return <CircularProgress />
  }

  return topicIndex >= data.topics.length ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ResultsSummary correct={correctAnswers} total={totalAnswers} />
      <Button disabled={disableNext} sx={{ marginTop: '20px' }} variant="contained" onClick={() => next(true)}>
        Next
      </Button>
    </Box>
  ) : (
    <Topic
      key={`topic-${topicIndex}`}
      gameId={uuid}
      {...data.topics[topicIndex]}
      next={(correctCount, total) => {
        setCorrectAnswers(correctAnswers + correctCount)
        setTotalAnswers(totalAnswers + total)
        setTopicIndex(topicIndex + 1)
      }}
    />
  )
}

const Topic = ({ calculations, name, next, uuid, gameId }) => {
  const [calculationIndex, setCalculationIndex] = useState(0)
  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [totalAnswers, setTotalAnswers] = useState(0)

  return (
    <Calculation
      key={`calculation-${calculationIndex}`}
      gameId={gameId}
      uuid={calculations[calculationIndex]}
      next={(correctCount, total) => {
        if (calculationIndex + 1 === calculations.length) {
          next(correctAnswers + correctCount, totalAnswers + total)
        } else {
          setCorrectAnswers(correctAnswers + correctCount)
          setTotalAnswers(totalAnswers + total)
          setCalculationIndex(calculationIndex + 1)
        }
      }}
    />
  )
}

const Calculation = ({ gameId, next, uuid }) => {
  const [userInput, setUserInput] = useState([])
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [checking, setChecking] = useState(false)
  const [results, setResults] = useState([])

  const { moduleId } = useLesson()

  useEffect(() => {
    axios(`/sfma/calculation/${uuid}`).then((r) => {
      setData(r.data)
      setUserInput(Array.apply(null, Array(r.data.questions.length)).map(() => ''))
      setLoading(false)
    })
  }, [uuid])

  useEffect(() => {
    if (checking) {
      axios({
        url: `/sfma/${uuid}/check`,
        method: 'post',
        data: {
          moduleId,
          gameId: gameId,
          calculationId: uuid,
          values: data.info.map(({ value }) => parseFloat(value)),
          results: userInput,
        },
      }).then((r) => {
        setResults(r.data)
        setChecking(false)
      })
    }
  }, [checking])

  const changeUserInput = (index, value) => {
    const newInput = [...userInput]
    newInput[index] = value
    setUserInput(newInput)
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <>
      <MobileNotSupported />
      <Box sx={{ background: '#ffffff', display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', padding: '20px 20px 50px 20px' }}>
        <Box sx={{ maxWidth: '650px' }}>
          <Typography variant="h2">{data.label}</Typography>
          <Table sx={{ background: '#F7E2F5' }}>
            <TableBody>
              {data.info.map((line, index) => {
                return <InfoLine key={`info-${index}`} {...line} />
              })}
            </TableBody>
          </Table>
          <Box sx={{ background: '#F2F6F7', display: 'flex', flexDirection: 'column', marginTop: '20px', padding: '35px' }}>
            {data.questions.map(({ label, symbol }, index) => (
              <Box sx={{ marginTop: '20px' }} key={`question-${index}`}>
                <TextField
                  key={`question-${index}`}
                  sx={{
                    background: '#FFFFFF',
                    position: 'relative',
                    width: '100%',
                    '&:after': {
                      content: `'${symbol}'`,
                      position: 'absolute',
                      right: 20,
                      top: 20,
                    },
                  }}
                  disabled={checking || results.length > 0}
                  label={label}
                  value={userInput[index]}
                  onChange={(e) => {
                    const { value } = e.target
                    if (!/[^0123456789.]/.test(value)) {
                      changeUserInput(index, value)
                    }
                  }}
                />
                {!!results.length && (
                  <Feedback
                    key={`feedback-${index}`}
                    feedback={
                      <Typography component="div">
                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                          {`Correct answer is ${results[index].correctResult.toLocaleString()} ${symbol}`}
                        </ReactMarkdown>
                      </Typography>
                    }
                    isCorrect={results[index].wasCorrect}
                  />
                )}
              </Box>
            ))}
            {results.length ? (
              <Button
                sx={{ alignSelf: 'center', marginTop: '20px' }}
                variant="contained"
                onClick={() => next(results.filter(({ wasCorrect }) => wasCorrect).length, results.length)}
              >
                Next
              </Button>
            ) : (
              <Button sx={{ alignSelf: 'center', marginTop: '20px' }} onClick={() => setChecking(true)} variant="contained">
                Check
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

const InfoLine = ({ label, symbol, value }) => {
  return (
    <TableRow>
      <TableCell sx={{ border: 0 }}>{label}</TableCell>
      <TableCell sx={{ border: 0 }}>
        <InfoValue value={value} symbol={symbol} />
      </TableCell>
    </TableRow>
  )
}

const InfoValue = ({ value, symbol }) => {
  switch (symbol) {
    case 'ratio': {
      return value
    }
    case '%': {
      return `${(value * 100).toFixed(2)}%`
    }
    default: {
      return `${value ? value.toLocaleString() : ''} ${symbol}`
    }
  }
}

export default CalculationsWrapper
