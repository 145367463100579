import { Box, Typography, Stack } from '@mui/material'
import React, { useState, useRef, useCallback, useImperativeHandle, forwardRef } from 'react'
import Countdown from 'react-countdown'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
const CountDownWrapper = forwardRef((props, ref) => {
  const countdownApiRef = useRef(null)
  const [endTime, setEndTime] = useState(props.duration * 60 * 1000 + Date.now())
  const handleStartClick = useCallback(() => {
    countdownApiRef.current && countdownApiRef.current.start()
    const endTime = props.duration * 60 * 1000 + Date.now()
    setEndTime(endTime)
  }, [])

  const setRef = useCallback((countdown) => {
    if (countdown) {
      countdownApiRef.current = countdown.getApi()
    }
  }, [])
  useImperativeHandle(ref, () => ({
    start: handleStartClick,
    stop: () => countdownApiRef.current && countdownApiRef.current.stop(),
  }))

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        border: '1px solid #EEEEEE',
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        gap: '5px',
      }}
    >
      <AccessAlarmIcon
        sx={{
          fontSize: '3rem',
          color: 'primary.main',
          marginRight: 2, // Adds space between icon and text
        }}
      />
      <Stack direction="column" spacing={1}>
        <Typography textAlign="left" fontSize="18px" fontWeight="bold">
          Time Remaining
        </Typography>
        <Typography textAlign="left" fontSize="24px" fontWeight="">
          {endTime > 0 && (
            <Countdown ref={setRef} autoStart={false} daysInHours={true} zeroPadHours={false} date={endTime} onComplete={props.handleTimeExpired} controlled={false} />
          )}
        </Typography>
        <Typography variant="body2" textAlign="left">
          Assessment ends when the time runs out
        </Typography>
      </Stack>
    </Box>
  )
})

export default CountDownWrapper
