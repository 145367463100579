import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Divider, IconButton, Grow } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import axios from 'axios'
import classnames from 'classnames'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './question.css'
import rehypeRaw from 'rehype-raw'

import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import theme from 'theme'

const Question = ({ assessmentId, handleTimeExpired, assessmentSessionId, questionNumber, totalQuestions, isLastQuestion, next, questionId }) => {
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [answerId, setAnswerId] = useState(null)
  const [correctIds, setCorrectIds] = useState(null)
  const [checkAnswer, setCheckAnswer] = useState(false)
  const [data, setData] = useState({})
  const [questionButtonDisabled, setQuestionButtonDisabled] = useState(false)

  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [spinner, setSpinner] = useState(false)
  useEffect(() => {
    axios(`/assessment/${assessmentId}/question/${questionId}`)
      .then((response) => {
        setData(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log({ error })
        enqueueSnackbar('Sorry. Something went wrong! Please try again.', { variant: 'error' })
      })
  }, [questionId])

  useEffect(() => {
    if (answerId && checkAnswer) {
      setQuestionButtonDisabled(true)
      setSpinner(true)
      axios
        .post(`/assessment/${assessmentId}/question/${data.uuid}/check`, {
          answerId,
          assessmentSessionId,
        })
        .then(({ data }) => {
          setSpinner(false)
          setCheckAnswer(true)
          setQuestionButtonDisabled(false)
          next(answeredCorrectly, selectedQuestion)
          enqueueSnackbar(`Answer submitted. Click ${isLastQuestion ? 'Finish' : 'Next'} to continue`, { variant: 'info' })
        })
        .catch((error) => {
          setSpinner(false)
          console.log('[Error]', error)
          if (error.response?.status === 410) {
            handleTimeExpired()
            setQuestionButtonDisabled(true)
          } else if (error.response?.status === 409) {
            setQuestionButtonDisabled(false)
            setCheckAnswer(true)
            next(answeredCorrectly, selectedQuestion)
            enqueueSnackbar('Question already answered. Continue to next question ', { variant: 'info' })
          } else {
            setCheckAnswer(false)
            setQuestionButtonDisabled(false)
            console.log('[Error]', error)
            enqueueSnackbar('Sorry. Something went wrong! Please try again', { variant: 'error' })
          }
        })
    }
  }, [checkAnswer, assessmentId, assessmentSessionId, isLastQuestion])

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const answeredCorrectly = answerId && correctIds && correctIds.some((id) => id === answerId)

  return (
    <Grow in={true}>
      <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
        <FormControl className="question-mui-wrapper">
          <FormLabel id="question">
            <Box>
              {totalQuestions > 1 && (
                <Typography fontSize="1.3rem" color={theme.palette.primary.main} fontWeight="bold" component="div" paddingY={0.4}>
                  Question {questionNumber} of {totalQuestions}
                </Typography>
              )}

              <Typography component="div" sx={{ overflow: 'auto' }}>
                <ReactMarkdown className="question-text" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                  {data.text}
                </ReactMarkdown>
              </Typography>
            </Box>
          </FormLabel>
          <Divider sx={{ width: '100%', marginY: 1 }}></Divider>
          <RadioGroup aria-labelledby="question" name="answers" value={answerId} onChange={(e) => setAnswerId(e.target.value)}>
            {data.answers.map(({ uuid, text }, index) => {
              const isCorrect = correctIds && correctIds.some((id) => id === uuid)
              return (
                <FormControlLabel
                  sx={{ margin: 0, marginBottom: '10px' }}
                  className={classnames({
                    'mcq-item': true,
                    'mcq-item-selected': answerId === uuid,
                    'mcq-item-correct': correctIds !== null && isCorrect,
                    'mcq-item-incorrect': correctIds !== null && answerId === uuid && !isCorrect,
                  })}
                  disabled={checkAnswer}
                  key={`answer-${uuid}`}
                  value={uuid}
                  control={<Radio />}
                  label={
                    <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                      {text}
                    </ReactMarkdown>
                  }
                />
              )
            })}
          </RadioGroup>
        </FormControl>
        <Typography textAlign={'right'} variant="subtitle2">
          Lesson: {data.lesson_name}
        </Typography>
        {
          // <Box display="flex" justifyContent="center">
          <Box justifyContent="center" sx={{ display: 'flex', alignItems: 'center', gap: 2, paddingTop: '10px' }}>
            <Button
              sx={{
                alignSelf: 'center',
              }}
              disabled={answerId === null || questionButtonDisabled}
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectedQuestion(data)
                setCheckAnswer(true)
                mixpanel.track('button_clicked', {
                  Feature: 'assessment',
                  Location: 'assessment_button_submit_answer',
                  Name: 'Submit Answer',
                })
              }}
            >
              Submit Answer
            </Button>
            {spinner ? (
              <CircularProgress size={28} />
            ) : (
              <Box sx={{ width: 28, height: 28 }} /> // Placeholder with same dimensions
            )}
          </Box>
        }
        <br />
        <Divider sx={{ width: '100%', marginY: 1 }}></Divider>
        <Box textAlign="center">
          <Button
            onClick={() => {
              const confirmCancel = window.confirm('If you cancel the assessment, you will lose all progress. Are you sure you want to cancel?')
              if (confirmCancel) {
                mixpanel.track('button_clicked', {
                  Feature: 'assessment',
                  Location: 'assessment_button_cancel',
                  Name: 'Cancel Assessment Session',
                })
                navigate('/')
              }
            }}
          >
            Cancel Assessment
          </Button>
        </Box>
      </Box>
    </Grow>
  )
}

export default Question
