import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Chip, Stack, Divider, Card, Fade } from '@mui/material'
import axios from 'axios'
import classnames from 'classnames'
import Feedback from '../../results/feedback'
import { useLesson } from '../../lesson/context'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './question.css'
import rehypeRaw from 'rehype-raw'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import WestIcon from '@mui/icons-material/West'
const Question = ({ questionNumber, totalQuestions, isLastQuestion, next, questionId, quizId, userAnswerHighlighteds, setCorrectHighlights, type }) => {
  const [answerId, setAnswerId] = useState(null)
  const [correctIds, setCorrectIds] = useState(null)
  const [checkAnswer, setCheckAnswer] = useState(false)
  const [feedback, setFeedback] = useState(null)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { moduleId } = useLesson()
  const [isCorrect, setIsCorrect] = useState(false)
  const [randomisedAnswers, setRandomisedAnswers] = useState([])
  function randomList(limit, list) {
    return list.sort(() => 0.5 - Math.random()).slice(0, limit)
  }

  useEffect(() => {
    axios(`/li-game/${quizId}/question/${questionId}`)
      .then((r) => {
        setData(r.data)
        setLoading(false)
      })
      .catch(setError)
  }, [questionId])

  useEffect(() => {
    if (
      (type === 'MCQ' && answerId) ||
      (type === 'HIGHLIGHT' &&
        Object.values(userAnswerHighlighteds).reduce((acc, values) => {
          return acc + (Array.isArray(values) ? values.length : 0)
        }, 0))
    ) {
      axios
        .post(`/li-game/${quizId}/question/${data.uuid}/check`, {
          answerId,
          moduleId,
          questionId,
          userAnswerHighlighteds,
        })
        .then(({ data }) => {
          setCorrectIds(data.correctIds)
          setFeedback(data.feedback)
          setCorrectHighlights(data.correctHighlights)
          setIsCorrect(data.correct)
        })
    }
  }, [checkAnswer])

  useEffect(() => {
    if (!!data && !!data.answers && data.answers.length > 0) {
      let randomAnswers = randomList(data.answers.length, data.answers)
      setRandomisedAnswers(randomAnswers)
    }
  }, [data])

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const answeredCorrectly = (type === 'MCQ' && answerId && correctIds && correctIds.some((id) => id === answerId)) || (type === 'HIGHLIGHT' && correctIds && isCorrect)
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <FormControl className="question-mui-wrapper">
        <Stack direction="row" spacing={1}>
          {totalQuestions > 1 && (
            <Typography variant="subtitle1" component="div" sx={{ alignContent: 'flex-end' }}>
              Question {questionNumber} of {totalQuestions}
            </Typography>
          )}
        </Stack>
        <FormLabel id="question">
          <Divider />
          <Card sx={{ padding: '20px', margin: '20px 0 20px 0' }}>
            <Typography component="div">
              <ReactMarkdown className="question-text" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                {data.text}
              </ReactMarkdown>
            </Typography>
          </Card>
        </FormLabel>
        <Divider />
        <RadioGroup aria-labelledby="question" name="answers" value={answerId} onChange={(e) => setAnswerId(e.target.value)}>
          {type === 'MCQ' &&
            randomisedAnswers.map(({ uuid, text }, index) => {
              const isMCQCorrect = correctIds && correctIds.some((id) => id === uuid)
              return (
                <FormControlLabel
                  sx={{ margin: 0, marginBottom: '10px' }}
                  className={classnames({
                    'mcq-item': true,
                    'mcq-item-selected': answerId === uuid,
                    'mcq-item-correct': correctIds !== null && isMCQCorrect,
                    'mcq-item-incorrect': correctIds !== null && answerId === uuid && !isMCQCorrect,
                  })}
                  disabled={checkAnswer}
                  key={`answer-${uuid}`}
                  value={uuid}
                  control={<Radio />}
                  label={
                    <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                      {text}
                    </ReactMarkdown>
                  }
                />
              )
            })}
        </RadioGroup>
      </FormControl>

      {!checkAnswer && (
        <Button
          sx={{
            alignSelf: 'center',
            marginTop: '10px',
          }}
          disabled={
            (type == 'MCQ' && (answerId === null || checkAnswer)) ||
            (type === 'HIGHLIGHT' &&
              Object.values(userAnswerHighlighteds).reduce((acc, values) => {
                return acc + (Array.isArray(values) ? values.length : 0)
              }, 0)) === 0
          }
          variant="contained"
          color="primary"
          onClick={() => {
            setCheckAnswer(true)
          }}
        >
          Check
        </Button>
      )}
      {type === 'HIGHLIGHT' &&
        Object.values(userAnswerHighlighteds).reduce((acc, values) => {
          return acc + (Array.isArray(values) ? values.length : 0)
        }, 0) === 0 && (
<Box
  sx={{
    marginTop: '20px',
    padding: '16px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  }}
>
  <BorderColorIcon
    sx={{
      position: 'absolute',
      top: '-20px', // Adjusted to position the center of the circle at the corner
      right: '-20px', // Adjusted similarly for horizontal alignment
      fontSize: '40px', // Increased size of the icon
      color: '#1976d2',
      backgroundColor: '#ffffff',
      borderRadius: '50%',
      padding: '8px',
      boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
    }}
  />
  <Typography
    variant="body2"
    sx={{
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      margin: '8px',
      fontSize: '1rem',
    }}
  >
    <WestIcon
      sx={{
        marginRight: '8px',
        color: '#1976d2',
        fontSize: '2rem',
        animation: 'pulse 1.5s infinite ease-in-out', // Adds pulsing animation
        '@keyframes pulse': {
          '0%': { transform: 'translateX(0)' },
          '50%': { transform: 'translateX(-5px)' },
          '100%': { transform: 'translateX(0)' },
        },
      }}
    />
    Please select text to highlight — You can tap a highlight to remove it!
  </Typography>
</Box>
        )}
      {checkAnswer && correctIds && (
        <Fade in={checkAnswer}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '1000px',
            }}
          >
            <Feedback
              isCorrect={answeredCorrectly}
              feedback={
                <Typography component="div">
                  <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} skipHtml={true} unwrapDisallowed={true}>
                    {feedback}
                  </ReactMarkdown>
                </Typography>
              }
              uuid={quizId}
            />
            <Button
              sx={{
                alignSelf: 'center',
                marginTop: '10px',
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                next(answeredCorrectly)
              }}
            >
              {isLastQuestion ? 'Summary' : 'Next'}
            </Button>
          </Box>
        </Fade>
      )}
    </Box>
  )
}

export default Question
