import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './auth'

const Callback = (props) => {
  const navigate = useNavigate()
  const { setToken } = useAuth()
  useEffect(() => {
    setToken(window.location.toString().match(/access_token=([^&]+)/)[1], () =>
      navigate(localStorage.getItem('postLoginPath') || '/')
    )
  })

  return <div>P</div>
}

export default Callback
