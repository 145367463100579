import React from 'react'
import { Box, LinearProgress, Typography } from '@mui/material'

const InteractionProgress = ({current, description, total}) => {
  return (
    <Box>
      <Typography sx={{alignSelf: 'flex-start', textAlign: 'left'}}>
      <Typography component="span" sx={{margin: '5px', fontWeight: 'bold'}}>{current}</Typography>
      of 
      <Typography component="span" sx={{margin: '5px', fontWeight: 'bold'}}>{total}</Typography>
      </Typography>
      <LinearProgress color={'greenTint'} variant="determinate" value={current / total * 100} />
      {description && <Description text={description}/>}
    </Box>
  )
}

const Description = ({text}) => {
  return <Box sx={{backgroundColor: '#F2F6F7', padding: '20px', marginBottom: '20px'}}>
    {text.split(/\n/).map((line, index) => <Typography key={`description-line-${index}`}>{line}</Typography>)}
  </Box>
}

export default InteractionProgress