import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { CircularProgress, Box } from '@mui/material'

import Question from './question'
import Summary from './summary'
import { useLesson } from '../lesson/context'

export function randomList(limit, list) {
  return list.sort(() => 0.5 - Math.random()).slice(0, limit)
}

export const QUIZ = 'Quiz'

const Quiz = ({ next, uuid }) => {
  const [loading, setLoading] = useState(true)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [data, setData] = useState({})
  const [error, setError] = useState(false)
  const [disableNext, setDisableNext] = useState(false)
  const [questionBank, setQuestionBank] = useState([])
  const [questions, setQuestions] = useState([])
  const [quizComplete, setQuizComplete] = useState(false)
  const [adaptive, setAdaptive] = useState(false)

  const { moduleId } = useLesson()

  useEffect(() => {
    if (questions.length && currentQuestion >= questions.length && !adaptive) {
      setDisableNext(true)
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: 'Quiz',
          uuid: uuid,
          quizPercentScore: ((correctAnswers / questions.length) * 100).toFixed(2),
        },
      }).then(() => {
        setDisableNext(false)
      })
    }
  }, [currentQuestion, questions, adaptive])

  useEffect(() => {
    if (uuid && !adaptive) {
      axios(`/quiz/${uuid}`)
        .then((r) => {
          setData(r.data)

          setQuestionBank(r.data.questions)
          setQuestions(randomList(10, r.data.questions))
          setLoading(false)
        })
        .catch(setError)
    }
  }, [uuid, adaptive])

  const resetQuiz = async () => {
    setQuestions(randomList(10, questionBank))
    setCurrentQuestion(0)
    setCorrectAnswers(0)
    axios(`/quiz/${uuid}`)
      .then((r) => {
        setData(r.data)

        setQuestionBank(r.data.questions)
        setQuestions(randomList(10, r.data.questions))
        setLoading(false)
      })
      .catch(setError)
  }

  // get the wrong answers from an api call
  // add these to the question bank
  const retryWrongAnswers = async () => {
    // setAdaptive(true)
    setCurrentQuestion(0)
    setCorrectAnswers(0)
    axios(`/quiz/${uuid}/incorrect_questions`)
      .then((r) => {
        setData(r.data)

        setQuestionBank(r.data.questions)
        setQuestions(randomList(10, r.data.questions))
        setLoading(false)
      })
      .catch(setError)
  }
  if (loading || error) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const isLastQuestion = currentQuestion === questions.length - 1

  return currentQuestion < questions.length ? (
    <Question
      questionNumber={currentQuestion + 1}
      totalQuestions={questions.length}
      quizId={uuid}
      key={`question-${questions[currentQuestion].uuid}`}
      questionId={questions[currentQuestion].uuid}
      next={(wasCorrect) => {
        wasCorrect && setCorrectAnswers(correctAnswers + 1)
        setCurrentQuestion(currentQuestion + 1)
      }}
      isLastQuestion={isLastQuestion}
    />
  ) : (
    <Summary disableNext={disableNext} next={next} resetQuiz={resetQuiz} retryWrongAnswers={retryWrongAnswers} correctAnswers={correctAnswers} totalQuestions={questions.length} />
  )
}

export default Quiz
