import React, { useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import Summary from '../results/summary'
import { useBadges } from '../badges/context'

const MultiStepFeedback = ({disableNext, next, totalAnswers, correctAnswers}) => {
  const { awardBadge } = useBadges()
  useEffect(() => {
    awardBadge('FIRST_MULTI_STEP')
    if(correctAnswers === totalAnswers){
      awardBadge('MULTI_STEP_100%')
    }
    if(correctAnswers === 0){
      awardBadge('ANY_0%')
    }
  }, [correctAnswers, totalAnswers])

  return (
    <>
      <Typography variant="h3">Multi-Steps Finished!</Typography>
      <Summary correct={correctAnswers} total={totalAnswers} />
      <Button disabled={disableNext} sx={{marginTop: '20px'}} variant="contained" color="primary" onClick={() => next(true)}>
        Next Step
        <ArrowForwardIosIcon />
      </Button>
    </>
  )
}

export default MultiStepFeedback;