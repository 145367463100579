import React, { useState } from 'react'
import { Box, Button, Typography, Fade } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Paper from '@mui/material/Paper'
const Feedback = ({ next, text }) => {
  return (
    <Fade in={true}>
      <Paper sx={{ padding: '20px' }}>
        <Typography variant="h5">Feedback</Typography>
        <Typography component="div">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button sx={{ marginTop: '20px' }} variant="contained" color="primary" onClick={next}>
            Next
          </Button>
        </Box>
      </Paper>
    </Fade>
  )
}

export default Feedback
