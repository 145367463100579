import React, { useEffect, useState } from 'react'
import { Alert, Box, Link, Typography, Skeleton, Grid } from '@mui/material'
import Fade from '@mui/material/Fade'
import Carousel from '../components/Carousel'
import ModuleCard from './module-card'
import DynamicSessionCard from './dynamic-session-card'
import AssessmentCard from './assessment-card'

const Modules = ({ dynamicSessions, assessments, modules, modulesLoaded, errorModuleLoad }) => {
  const [transformedModules, setTransformedModules] = useState([])
  const [loading, setLoading] = useState(true)
  const [noModules, setNoModules] = useState(false)

  useEffect(() => {
    if (loading && modules.length > 0) {
      const { incomplete, complete } = modules.reduce(
        (acc, m) => {
          if (m.lessons.length === m.lessons.filter((l) => !!l.complete).length) {
            acc.complete.push(m)
          } else {
            acc.incomplete.push(m)
          }
          return acc
        },
        { complete: [], incomplete: [] }
      )
      setTransformedModules([...incomplete, ...complete])
      setLoading(false)
      setNoModules(false)
    }

    if (modulesLoaded && modules.length === 0) {
      setNoModules(true)
      setLoading(false)
    }
  }, [loading, modules, modulesLoaded])

  if (errorModuleLoad)
    return (
      <Alert severity="error">
        Opps! {errorModuleLoad.response.data.message}. We have encountered an error. Please contact <Link href={`/support}`}>Support Here</Link>{' '}
      </Alert>
    )

  if (loading)
    return (
      <>
        <Grid container wrap="nowrap">
          <Box sx={{ width: '450', mr: '30px' }}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rectangular" width={450} height={340} />
          </Box>

          <Box sx={{ width: '450', mr: '30px' }}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rectangular" width={450} height={340} />
          </Box>

          <Box sx={{ width: '450', mr: '30px' }}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rectangular" width={450} height={340} />
          </Box>
        </Grid>
      </>
    )
  let cardList = []
  if (dynamicSessions) {
    cardList = cardList.concat(dynamicSessions)
  }
  // if (assessments) {
  //   cardList = cardList.concat(assessments)
  // }
  if (transformedModules) {
    cardList = cardList.concat(transformedModules)
  }

  return (
    <>
      <Typography variant="h3" mt={2} mb={2}>
        {cardList.length > 1 ? 'Modules' : 'Module'}
      </Typography>
      {cardList.length ? (
        <Fade in={true} timeout={1000}>
          <div>
            <Carousel>
              {cardList.map((data, index) => {
                return data.startTime ? (
                  <DynamicSessionCard key={data.uuid} {...data} isLast={index === modules.length - 1} />
                ) : data.type === 'Assessment' ? (
                  <AssessmentCard key={data.uuid} {...data} isLast={index === assessments.length - 1} />
                ) : (
                  <ModuleCard key={data.uuid} {...data} isLast={index === modules.length - 1} />
                )
              })}
            </Carousel>
          </div>
        </Fade>
      ) : (
        <>
          {noModules ? (
            <Box sx={{ background: '#FFFFFF', padding: '50px', width: '100%' }}>
              <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                It looks like you have no modules assigned. Please reach out to your group leader, if part of a group, or email us at{' '}
                <Link href="mailto:support@examfly.com">support@examfly.com</Link>. Thank you.
              </Typography>
            </Box>
          ) : null}
        </>
      )}
    </>
  )
}

export default Modules
