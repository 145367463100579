import { useState } from 'react'
import { Box, List, ListItemAvatar, ListItem, ListItemText, Avatar } from '@mui/material'
import { PieChartFilled } from '@ant-design/icons'
import { AreaChartOutlined } from '@ant-design/icons'
import Chart from 'react-apexcharts'

const areaChartOptions = {
  chart: {
    id: 'piechart-dashboard',
    type: 'donut',
  },
  colors: ['#4ed09c', '#ffa552'],
  legend: {
    position: 'bottom',
  },
  labels: ['% Correct', '% Incorrect'],
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return Math.round(val) + '%'
    },
  },
}

const PieChart = ({ slot, stats }) => {
  const [options] = useState(areaChartOptions)

  const series = [stats.questions.correctPercentage || 0, stats.questions.incorrectPercentage || 0]
  return stats.questions.total === 0 ? <NoStatsMsg /> : <Chart options={options} series={series} type="donut" height={350} />
}

const NoStatsMsg = () => {
  return (
    <Box sx={{ textAlign: 'center', flexDirection: 'column' }}>
      <List sx={{ width: '100%' }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PieChartFilled />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Questions and Answers"
            secondary="Start learning by watching the videos and tackling the games and quizzes. Check back here to see your progress."
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AreaChartOutlined />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Ranking" secondary="Check out the leaderboard to see how you compare to other learners" />
        </ListItem>
      </List>
    </Box>
  )
}

export default PieChart
