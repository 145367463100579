import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import axios from 'axios'
import { Box, Container, CircularProgress, Typography, List, ListItem, Autocomplete, TextField, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import throttle from 'lodash/throttle'

const fetchNewUsers = throttle((partialUsername, startSearch, callback) => {
  if(partialUsername.length >= 3) {
    startSearch()
    return axios({
      url: '/user/find',
      method: 'post',
      data: {
        partialUsername
      }
    }).then(r => callback(r.data.map(user => ({
      label: user.username,
      id: user.username,
    }))))
  }
  return Promise.resolve([])
},500)

const Enrollment = (props) => {
  const { moduleId } = useParams()

  const [ module, setModule ] = useState({})
  const [ moduleLoading, setModuleLoading ] = useState(true)

  const [ moduleUsers, setModuleUsers ] = useState([])
  const [ moduleUsersLoading, setModuleUsersLoading ] = useState(true)

  const [selectedUser, setSelectedUser] = React.useState(null)
  const [inputValue, setInputValue] = React.useState('')

  const [ users, setUsers ] = useState([])
  const [ usersLoading, setUsersLoading ] = useState(false)

  const [ enrollingUser, setEnrollingUser ] = useState(false)

  useEffect(() => {
    if(moduleId && moduleLoading) {
      axios(`/module/${moduleId}`).then(r => {
        setModule(r.data)
        setModuleLoading(false)
      })
    }
  }, [moduleId, moduleLoading])

  useEffect(() => {
    if(moduleUsersLoading) {
      axios(`/enrollment/module/${moduleId}/users`).then(r => {
        setModuleUsers(r.data)
        setModuleUsersLoading(false)
      })
    }
  }, [moduleUsersLoading, moduleId])

  useEffect(() => {
    if(enrollingUser) {
      axios({
        url: `/enrollment/module/${moduleId}`,
        method: 'post',
        data: {
          username: enrollingUser
        }
      }).then(r => {
        setModuleUsers([...moduleUsers.filter(u => u.username !== enrollingUser), {username: enrollingUser}])
        setEnrollingUser(false)
      })
    }
  }, [enrollingUser])

  if(moduleLoading) {
    return <CircularProgress />
  }

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        {module.name} - Enrollment
      </Typography>
      <Box sx={{background: '#ffffff', padding: 5}}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Autocomplete
            loading={usersLoading}
            options={users}
            renderInput={(params) => <TextField {...params} label="Start typing a username to enroll in the module..." /> }
            value={selectedUser}
            onChange={(event, newValue) => {
              setSelectedUser(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              if(newInputValue.length > 3) {
                fetchNewUsers(
                  newInputValue,
                  () => setUsersLoading(true), 
                  (newUsers) => {
                    setUsers([...newUsers])
                    setUsersLoading(false)
                })
              } else {
                setUsers([])
              }
              setInputValue(newInputValue)
            }}
          />
          <Button 
            disabled={!(selectedUser && selectedUser.label)}
            onClick={() => setEnrollingUser(selectedUser.id)}
          >
            <AddIcon />
            Enroll
          </Button>
          <Typography variant="h6">{moduleUsers.length === 0 ? 'No users are currently enrolled in this course' : 'Currently enrolled:'}</Typography>
          <UserList moduleId={moduleId} users={moduleUsers} removedUser={(removed) => {
            setModuleUsers(moduleUsers.filter(user => user.username !== removed))
          }}/>
        </Box>
      </Box>
    </Container>
  )
}

const UserList = ({removedUser, moduleId, users}) => {
  const [ removing, setRemoving ] = useState(false)

  useEffect(() => {
    if(removing) {
      axios({
        url: `/enrollment/module/${moduleId}`,
        method: 'delete',
        data: {
          username: removing
        }
      }).then(r => {
        removedUser(removing)
        setRemoving(false)
      })
    }
  },[removing])
  return (
    <List>
      {users.map(({username}) => <ListItem key={username}>{username}<Button onClick={() => setRemoving(username)}>Remove</Button></ListItem>)}
    </List>
  )
}

export default Enrollment
