import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
} from '@mui/material'
import { Link } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import axios from 'axios'
import theme from '../../theme'
import { useSnackbar } from 'notistack'

const ModuleState = ({ module }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [value, setValue] = useState(module.isActive)

  let timer = undefined

  const handleChange = async (module) => {
    try {
      await axios.post('/module/change-module-state', {
        uuid: module.id,
        isActive: !value,
      })

      setValue(!value)
      enqueueSnackbar('Module Status Updated Successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }
  }

  const debounce = (module, timeout = 300) => {
    clearTimeout(timer)
    timer = setTimeout(() => handleChange(module), timeout)
  }

  return (
    <FormControlLabel
      control={<Switch onChange={() => debounce(module)} checked={value} />}
      label={value ? 'Active' : 'Inactive'}
    />
  )
}

const ActionMenu = ({ param, updateTable }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteModuleId, setdeleteModuleId] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDialogOpen = (moduleId) => {
    setdeleteModuleId(moduleId)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setdeleteModuleId('')
    setDialogOpen(false)
  }

  const deleteModule = async (moduleId) => {
    try {
      await axios.post(`/module/delete-module/${moduleId}`)
      enqueueSnackbar('Module Deleted Successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }

    handleDialogClose()
    handleClose()
    updateTable({ id: moduleId, action: 'delete' })
  }

  return (
    <div>
      <IconButton
        id={`basic-button-${param.row.id}`}
        aria-controls={open ? `basic-menu-${param.row.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label="actions"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`basic-menu-${param.row.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-${param.row.id}`,
        }}
      >
        <Link style={{ textDecoration: 'none' }} to={`/view-module/${param.row.id}`}>
          <MenuItem onClick={handleClose}>View Module</MenuItem>
        </Link>
        <Link style={{ textDecoration: 'none' }} to={`/edit-module/${param.row.id}`}>
          <MenuItem onClick={handleClose}>Edit Module</MenuItem>
        </Link>
        <Link style={{ textDecoration: 'none' }} to={`/admin/lesson/add-lesson-to-module/${param.row.id}`}>
          <MenuItem onClick={handleClose}>Add Lesson</MenuItem>
        </Link>
        <MenuItem sx={{ color: theme.palette.error.main }} onClick={() => handleDialogOpen(param.row.id)}>
          Delete Module
        </MenuItem>
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => deleteModule(deleteModuleId)} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const ManageModules = () => {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)

  const columns = [
    { field: 'key', headerName: 'Sr No.', headerAlign: 'center', align: 'center', type: 'number', filterable: false },
    { field: 'moduleName', headerName: 'Module Name', flex: 1, headerAlign: 'center' },
    {
      field: 'isActive',
      headerName: 'Module Status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return <ModuleState module={param.row} />
      },
    },
    {
      field: 'lessonCount',
      headerName: 'Number of lessons',
      flex: 1,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return <ActionMenu param={param} updateTable={updateTable} />
      },
    },
  ]

  const updateTable = ({ id, action }) => {
    switch (action) {
      case 'delete':
        const updateData = tableData.filter((row) => row.id !== id)
        setTableData(updateData)
        break

      default:
        break
    }
  }

  useEffect(() => {
    axios.get('/module/get-all-modules').then((data) => {
      const tableData = data.data.map((module, key) => {
        return {
          key: key + 1,
          id: module.uuid,
          moduleName: module.name,
          isActive: module.is_active,
          lessonCount: module.lesson_count,
        }
      })

      setTableData(tableData)
      setLoading(false)
    })
  }, [])

  return (
    <div>
      <Stack direction="row" spacing={1} sx={{ margin: 1 }} justifyContent="flex-end" flexWrap="wrap">
        <Link className="top-bar-button" to="/admin/lesson/all">
          <Button variant="contained">All Lessons</Button>
        </Link>
        <Link className="top-bar-button" to="/add-module">
          <Button variant="contained">Add Module</Button>
        </Link>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          onCellClick={() => null}
          onCellDoubleClick={() => null}
          onCellFocusOut={() => null}
          onRowClick={() => null}
          onColumnHeaderClick={() => null}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 200 },
            },
          }}
          rows={tableData}
          columns={columns}
          pageSizeOptions={[10, 25, 100]}
          loading={loading}
          autoHeight={loading}
        />
      </Box>
    </div>
  )
}

export default ManageModules
