import React from 'react'
import { Box, CircularProgress, LinearProgress, Link, List, ListItem, Typography, Card } from '@mui/material'
import { Outlet, useParams } from 'react-router-dom'
import Countdown from 'react-countdown'
import LockIcon from '@mui/icons-material/Lock'
import ScheduleIcon from '@mui/icons-material/Schedule'

import { LessonProvider } from '../lesson/context'
import { LessonElement } from '../lesson/index'

import { SessionProvider, useSession } from './context'
import theme from 'theme'

const DynamicSessionWrapper = (props) => {
  const { sessionId } = useParams()

  return (
    <SessionProvider value={{ sessionId }}>
      <DynamicSession />
    </SessionProvider>
  )
}

const LessonElementItem = (props) => {
  const { id, isCurrent, name, complete } = props

  return (
    <ListItem
      key={`list-lesson-element-${id}`}
      sx={{
        marginBottom: '10px',
        justifyContent: 'space-between',
        backgroundColor: isCurrent ? theme.palette.grey.A200 : '',
        borderRadius: 2,
      }}
    >
      <Typography
        sx={{
          paddingLeft: 5,
          textTransform: 'capitalize',
          wordBreak: 'break-word',
        }}
      >
        {name}
      </Typography>
      {complete && (
        <span role="img" aria-label="1st">
          👍
        </span>
      )}
      {!isCurrent && !complete && <LockIcon color="darkGrey" sx={{ alignSelf: 'center' }} />}
    </ListItem>
  )
}

export const DynamicSession = (props) => {
  const { currentLesson, currentElement, lessons, lessonIds, loading, session } = useSession()

  if (loading) {
    return <CircularProgress />
  }

  const total = lessonIds.length
  const complete = lessonIds.filter((id) => lessons[id].complete).length

  const sessionStarted = session.startTime <= Date.now()
  const sessionEnded = sessionStarted && session.endTime < Date.now()
  return (
    <Box>
      <Link sx={{ marginRight: '10px' }} href="/">
        Dashboard
      </Link>
      &#62;
      <Link sx={{ marginLeft: '10px', marginRight: '10px' }} href="">
        {session.name}
      </Link>
      <Typography sx={{ margin: 5, textAlign: 'center', textTransform: 'capitalize' }} variant="h1">
        Lesson {lessonIds.indexOf(currentLesson) + 1}: {lessons[currentLesson].name}
      </Typography>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ background: '#FFFFFF', flexShrink: 0, padding: '40px', marginRight: '20px', width: '330px' }}>
          <Box sx={{ marginBottom: '20px' }}>
            <LinearProgress sx={{ marginBottom: '10px' }} color={'greenTint'} variant="determinate" value={(complete / total) * 100} />
            <Typography sx={{ fontWeight: 'bold' }}>
              {complete}/{total} lessons completed
            </Typography>
          </Box>
          {Date.now() > session.startTime && (
            <Countdown
              date={session.endTime}
              renderer={({ hours, minutes, seconds, completed }) => {
                if (completed) {
                  return <Typography component="span">Session has ended</Typography>
                } else {
                  return (
                    <Typography
                      sx={{
                        alignItems: 'center',
                        background: '#EEF8F6',
                        borderRadius: '20px',
                        display: 'flex',
                        fontWeight: 'bold',
                        height: '40px',
                        paddingLeft: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      <ScheduleIcon sx={{ marginRight: '10px' }} />
                      {hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds} left
                    </Typography>
                  )
                }
              }}
            />
          )}
          <Card elevation={0} sx={{ bgcolor: 'background.main' }}>
            <List>
              {lessonIds.map((id) => {
                const lesson = lessons[id]
                const isCurrent = lesson.uuid === currentLesson
                return (
                  <span key={id}>
                    <ListItem
                      key={`list-lesson-${id}`}
                      sx={{
                        marginBottom: '10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          wordBreak: 'break-word',
                        }}
                      >
                        {lessons[id].name}
                      </Typography>
                      {lesson.complete && (
                        <span role="img" aria-label="1st">
                          👍
                        </span>
                      )}
                      {!isCurrent && !lesson.complete && <LockIcon color="darkGrey" sx={{ alignSelf: 'center' }} />}
                    </ListItem>
                    {lesson.elements.length && (
                      <List sx={{ paddingY: 0 }}>
                        {lesson.elements.map((element) => (
                          <LessonElementItem key={element.uuid} id={element.uuid} isCurrent={currentElement === element.uuid} name={element.type} complete={element.complete} />
                        ))}
                      </List>
                    )}
                  </span>
                )
              })}
            </List>
          </Card>
        </Box>
        {sessionStarted && !sessionEnded && <Outlet />}
        {!sessionStarted && !sessionEnded && (
          <Box sx={{ background: '#FFFFFF', borderRadius: '5px', padding: '40px', width: '100%' }}>
            <Typography sx={{ textAlign: 'center' }} variant="h2">
              Session not started yet
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>Session will start at {new Date(session.startTime).toLocaleString()}</Typography>
          </Box>
        )}
        {sessionStarted && sessionEnded && (
          <Box sx={{ background: '#FFFFFF', borderRadius: '5px', padding: '40px', width: '100%' }}>
            <Typography sx={{ textAlign: 'center' }} variant="h2">
              Session ended
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export const DynamicSessionRenderer = (props) => {
  const { currentElement, currentLesson, currentModule, lessons, next } = useSession()
  const element = lessons[currentLesson].elements.find(({ uuid }) => uuid === currentElement)
  if (element) {
    const { type } = element
    return (
      <LessonProvider value={{ lessonId: currentLesson, moduleId: currentModule }}>
        <Box sx={{ background: '#FFFFFF', borderRadius: '5px', padding: '40px', width: '100%' }}>
          <LessonElement key={currentElement} next={next} type={type} uuid={currentElement} />
        </Box>
      </LessonProvider>
    )
  } else {
    return <CircularProgress />
  }
}

export default DynamicSessionWrapper
