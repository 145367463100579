import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Container, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { LessonWrapperContext } from './lesson-wrapper'
import { useSnackbar } from 'notistack'
import { handleFileUpload, handleFileDelete } from 'admin/utils/file-uploader'

const ui_env = document.location.hostname

let s3BucketName = 'dev.examfly-app.com-images'
const fileUploadPath = 'images/lessons/'
switch (ui_env) {
  case 'dev.examfly-app.com':
    s3BucketName = 'dev.examfly-app.com-images'
    break
  case 'staging.examfly-app.com':
    s3BucketName = 'staging.examfly-app.com-images'
    break
  case 'www.examfly-app.com':
    s3BucketName = 'www.examfly-app.com-images'
    break
  default:
    break
}

const ReorderLessonContent = () => {
  const { enqueueSnackbar } = useSnackbar()

  const initialVimeoObject = {
    id: uuidv4(),
    url: '',
    description: '',
  }

  const initialState = {
    lesson_name: '',
    lesson_image: null,
    lesson_description: '',
    time_to_complete: '',
    vimeo_urls: [initialVimeoObject],
    quizzes: [],
    games: [],
  }

  const { lessonState, setLessonState } = useContext(LessonWrapperContext)

  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])

  const location = useLocation()
  const navigate = useNavigate()

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // styles we need to apply on draggables
      ...draggableStyle,

      display: isDragging ? 'table' : '',
    }
  }

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(tableData, result.source.index, result.destination.index)

    if (result.source.index !== result.destination.index) {
      const newItems = items.map((ele, key) => {
        ele.key = key + 1
        return ele
      })

      setTableData(newItems)
    }
  }

  const saveLesson = async () => {
    setLoading(true)

    try {
      let payload = { ...location.state }
      payload.contents = tableData

      delete payload.games
      delete payload.quizzes
      delete payload.vimeo_urls

      if (payload.lesson_image && typeof payload.lesson_image === 'object') {
        let image_name = new Date().getTime() + '_' + payload.lesson_image.name
        image_name = image_name.trim().replace(/\s/g, '_')

        await handleFileUpload(payload.lesson_image, image_name, fileUploadPath, s3BucketName)

        if (payload.lesson_prev_image_url) {
          await handleFileDelete(payload.lesson_prev_image_url, fileUploadPath, s3BucketName)
        }

        payload['image_url'] = '/images/lessons/' + image_name
        delete payload.lesson_image
      } else {
        payload['image_url'] = payload.lesson_image_url
      }

      let variant = 'success'
      let responseData = undefined
      let snackbarMessage = 'Lesson Added Successfully!'

      if (payload.change_api) {
        responseData = await axios.post(`/lesson/update-lesson`, payload)
        snackbarMessage = 'Lesson Updated Successfully!'
      } else {
        responseData = await axios.post(`/lesson/save-lesson`, payload)
      }

      if (!responseData.data) {
        variant = 'error'
        snackbarMessage = 'Something went wrong!'
      }

      enqueueSnackbar(snackbarMessage, { variant: variant })
    } catch (e) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    }

    setLoading(false)
    setLessonState(initialState)
    navigate('/admin/lesson/all')
  }

  useEffect(() => {
    if (location.state) {
      let data = location.state.contents.map((ele, key) => {
        ele.key = key + 1
        switch (ele.type) {
          case 'game':
            break

          case 'quiz':
            ele.title = ele.quiz_title
            break

          case 'video':
            ele.title = ele.url
            break

          default:
            break
        }

        return ele
      })
      setTableData(data)
      setLoading(false)
    } else {
      navigate('/admin-dashboard')
    }
  }, [location, navigate])

  return (
    <Container display="flex">
      <Typography sx={{ margin: 5 }} variant="h1">
        {location.state && location.state.change_api ? 'Edit Lesson' : 'Add Lesson'}
      </Typography>
      <Box sx={{ background: '#fff', padding: 5 }}>
        <Link
          to={location.state && location.state.change_api ? `/admin/lesson/edit-lesson/${location.state.lesson_uuid}` : '/admin/lesson/add-lesson'}
          state={
            location.state && location.state.change_api
              ? {
                  ...location.state,
                  contents: tableData.map((x) => {
                    return { ...x, key: undefined, order: x.key }
                  }),
                  no_update: true,
                }
              : {
                  ...location.state,
                  contents: tableData.map((x) => {
                    return { ...x, key: undefined, order: x.key }
                  }),
                }
          }
        >
          <Button sx={{ padding: '0 10px', margin: '5px 0' }} variant="text">
            <ArrowBackIcon />
            Back
          </Button>
        </Link>
        <br />
        <Box sx={{ width: '100%', background: '#ffffff', position: 'relative' }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Sr No.</TableCell>
                  <TableCell align="center">Content Type</TableCell>
                  <TableCell align="center">Title</TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                      {tableData.map((row, index) => (
                        <Draggable key={row.id} draggableId={row.id} index={index}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">{row.key}</TableCell>
                              <TableCell align="center">{row.type}</TableCell>
                              <TableCell title={row.title} align="center">
                                {row.title}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>

          <br />

          <Grid item xs={12}>
            <Grid container gap={1} direction="row" justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" type="button" onClick={saveLesson}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default ReorderLessonContent
