import axios from 'axios'

export const bucketRegion = 'eu-west-1'

async function handleFileUpload(file, fileName, filePath, bucketName, setSuccess = false, setError = false) {
  if (!file) return

  try {
    // Step 1: Get pre-signed URL from the server
    const { data } = await axios.get('/presigned-url/put', {
      params: {
        fileName: filePath + fileName,
        fileType: file.type,
        bucketName: bucketName,
        bucketRegion: bucketRegion,
      },
    })

    const { signedUrl, fileUrl } = data

    // Step 2: Upload file to S3 using the pre-signed URL with fetch
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })

    if (response.ok) {
      if (setSuccess) setSuccess(fileUrl)
      console.log('File uploaded successfully')
    } else {
      if (setError) setError(fileUrl)
      console.error('Upload failed:', response.statusText)
    }
  } catch (err) {
    console.error('Error uploading file:', err)
  }
}

const handleFileDelete = async (file, filePath, bucketName, setSuccess = false, setError = false) => {
  if (!file) return

  try {
    // Step 1: Get pre-signed URL from the server
    const { data } = await axios.get('/presigned-url/delete', {
      params: {
        fileName: filePath + file.split('/').pop(),
        bucketName: bucketName,
        bucketRegion: bucketRegion,
      },
    })

    const { signedUrl, fileUrl } = data

    const axiosInstance = axios.create({
      headers: {
        Authorization: undefined, // Do not set Authorization header by default
      },
    })

    const response = await axiosInstance.delete(signedUrl)

    if (response.status === 204) {
      if (setSuccess) setSuccess(fileUrl)
      console.log('File deleted successfully')
    } else {
      if (setError) setError(fileUrl)
      console.error('Upload failed:', response.statusText)
    }
  } catch (err) {
    console.error('Error uploading file:', err)
  }
}

export { handleFileUpload, handleFileDelete }
