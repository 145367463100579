import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material'
import isValid from './password-policy'
import logo from '../top-bar/examfly-logo-blue.png'

import './index.css'

const ChangePass = ({ changePass, verificationCode }) => {
  const [password, setPassword] = useState('')
  const [saving, setSaving] = useState(false)

  return (
    <div className="login-wrapper">
      <img className="logo" src={logo} />
      <form
        className="login"
        onSubmit={(e) => {
          e.preventDefault()
          setSaving(true)
          changePass(password)
        }}
      >
        <Typography>
          Welcome! Please set a new password to continue to Examfly
        </Typography>
        <FormLabel sx={{ padding: 1 }} id="password">
          New Password
        </FormLabel>
        <TextField
          sx={{marginTop: '20px'}}
          aria-labelledby="password"
          name="password"
          type="password"
          label="Password"
          error={!!password.length && !isValid(password)}
          helperText="The password must be at least 8 characters long. It must include both upper and lower case letters and one special symbol"
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <Button
          sx={{ marginTop: '20px' }}
          disabled={saving || !isValid(password)}
          variant="contained"
          color="primary"
          type="submit"
        >
          {saving && <CircularProgress size="1rem" />}
          Set password
        </Button>
      </form>
    </div>
  )
}

export default ChangePass
