import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Link, TextField, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

import logo from '../top-bar/examfly-logo-blue.png'

import './index.css'

const EnterEmail = (props) => {
  const storedEmail = localStorage.getItem('current_email')
  const [email, setEmail] = useState(storedEmail || '')
  const [getRedirect, setGetRedirect] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (getRedirect && email) {
      localStorage.setItem('current_email', email)
      axios({
        url: `/auth/redirect-me`,
        method: 'post',
        data: {
          email,
        },
        maxRedirects: 0,
      }).then((r) => {
        const postLoginPath = location?.state?.from?.pathname || '/'
        if (r.data.goTo) {
          localStorage.setItem('postLoginPath', postLoginPath)
          window.location = r.data.goTo
        } else {
          localStorage.setItem('postLoginPath', postLoginPath)
          navigate('/credentials-login', {
            replace: true,
            state: location.state,
          })
        }
      })
    }
  }, [getRedirect, email])
  return (
    <div className="login-wrapper">
      <img className="logo" src={logo} />
      <form
        className="login"
        style={{ height: '350px' }}
        onSubmit={(e) => {
          e.preventDefault()
          setGetRedirect(true)
        }}
      >
        <Typography variant="h1">Welcome!</Typography>
        <Typography>Please enter your email:</Typography>
        <TextField sx={{ marginTop: '20px' }} label="email" variant="outlined" name="email" value={email} onChange={(e) => setEmail(e.target.value.replace(/\s/g, ''))} />
        {getRedirect ? (
          <CircularProgress />
        ) : (
          <Button variant="contained" sx={{ marginTop: '20px', width: '200px' }} onClick={() => setGetRedirect(true)}>
            Next
          </Button>
        )}
        <Link target="_blank" sx={{ marginTop: '20px', width: '200px' }} href="https://examfly.com/privacy-policy/">
          <Button sx={{ width: '200px' }}>Privacy Policy</Button>
        </Link>
      </form>
    </div>
  )
}

export default EnterEmail
