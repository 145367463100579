import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import axios from 'axios'
import Vimeo from '@u-wave/react-vimeo'
import { useLesson } from '../lesson/context'
import { useBadges } from '../badges/context'

import './index.css'

export const VIDEO = 'Video'

const Video = ({ next, uuid, timestamp = 0, timestampEnd = null, setShowClipSegement = null, pauseVideo = false, setPauseVideo = null }) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [videoWatched, setVideoWatched] = useState(false)
  const [videoProgress, setVideoProgress] = useState(false)
  const [ready, setReady] = useState(false)
  const [resize, setResize] = useState(false)
  const [showClipSegementOnce, setShowClipSegementOnce] = useState(false)
  const { moduleId } = useLesson()
  const { awardBadge } = useBadges()

  const ref = useRef(null)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  function handleResize() {
    if (ref && ref.current) {
      const newWidth = ref.current.offsetWidth
      setWidth(newWidth)
      setHeight(Math.round(newWidth / 1.778))
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [resize])

  useLayoutEffect(() => {
    if (ref && ref.current) {
      const iframe = ref.current.querySelector('iframe')
      if (iframe) {
        iframe.setAttribute('allowfullscreen', 'true')
        iframe.setAttribute('allow', 'autoplay; fullscreen; picture-in-picture')
      }
      const newWidth = ref.current.offsetWidth
      setWidth(newWidth)
      setHeight(Math.round(newWidth / 1.778))
    }
  }, [ready])

  useEffect(() => {
    if (!loading && videoProgress) {
      Promise.all([
        axios({
          url: `/progress/`,
          method: 'post',
          data: {
            moduleId,
            type: 'Video',
            uuid: uuid,
          },
        }),
      ]).then(([pointsResponse]) => {
        // TODO: deal with getting points!
      })
    }
  }, [videoProgress, loading, awardBadge, moduleId, uuid])
  useEffect(() => {
    if (!loading && videoWatched) {
      awardBadge('FIRST_VIDEO')

      Promise.all([
        axios({
          url: `/video/${uuid}/watched`,
          method: 'post',
          data: {
            moduleId,
          },
        }),
      ]).then(([pointsResponse]) => {
        // TODO: deal with getting points!
      })
    }
  }, [videoWatched, loading, awardBadge, moduleId, uuid])

  useEffect(() => {
    if (uuid && loading) {
      axios(`/video/${uuid}`)
        .then((r) => {
          setData(r.data)
          setLoading(false)
        })
        .catch((e) => setError(e))
    }
  }, [uuid, loading])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          ref={ref}
          style={{
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '1000px',
            //  minHeight: 'calc(100vh - 220px)',
            width: '100%',
          }}
        >
          {loading || error ? (
            <CircularProgress />
          ) : (
            <>
              {data.description && (
                <Typography sx={{ margin: '0px 0px 4px 0px' }} variant="body1" className="video-description">
                  {/* <ReactMarkdown
                    className="question-text"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}>
                    {data.description}
                  </ReactMarkdown> */}
                </Typography>
              )}
              {/* <Box className="video-iframe-wrapper">
            <iframe
              src={makePlayerUrl(data.vimeoUrl)}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen={true}
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              width={width}
              height={height}
              frameBorder="0"
            />
          </Box> */}

              <Vimeo
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                speed={true}
                onReady={() => setReady(true)}
                className="video-iframe-wrapper"
                playsInLine={false}
                video={data.vimeoUrl}
                width={width}
                height={height}
                start={timestamp}
                paused={pauseVideo}
                onPause={() => {
                  if (setPauseVideo) setPauseVideo(true)
                }}
                onPlay={() => {
                  setPauseVideo(false)
                }}
                onEnd={() => {
                  console.log('video ended')
                  if (!videoWatched) {
                    setVideoWatched(true)
                  }
                }}
                onProgress={({ duration, seconds }) => {
                  // set video watched as soon as a bit of it is seen
                  if (!videoProgress) {
                    setVideoProgress(true)
                  }
                }}
                onTimeUpdate={({ duration, seconds }) => {
                  if (timestampEnd && seconds > timestampEnd) {
                    if (!showClipSegementOnce) {
                      setShowClipSegementOnce(true)
                      if (setPauseVideo) setPauseVideo(true)
                      if (setShowClipSegement) setShowClipSegement(true)
                    }
                  }
                }}
              />
              {next && (
                <Button sx={{ mt: '10px' }} variant="contained" disabled={!videoProgress} color="primary" onClick={() => next(videoProgress)}>
                  Next Step
                  <ArrowForwardIosIcon />
                </Button>
              )}
            </>
          )}
        </div>
      </Box>
    </>
  )
}

function makePlayerUrl(initialUrl) {
  const [_, part1, part2] = initialUrl.match(/vimeo\.com\/([^\/]+)\/([^\/]+)/)
  console.log(initialUrl, part1)
  return `https://player.vimeo.com/video/${part1}?h=${part2}&dnt=1&app_id=122963`
}

export default Video
