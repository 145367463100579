import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'

const isCellEditable = (params) => {
  return params.row.id !== 'net_income_total' || params.row.id !== 'sum_columns'
}

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#1976d2',
    color: '#fff',
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .highlight': {
    // backgroundColor: '#e6494e',
    backgroundColor: '#f44336',
    border: '1px solid #eb464c10',
    // borderColor: 'red',
    // border: '1px solid',
  },
  // '& .highlight-border': {
  //   border: '2px solid #eb464c10',
  // },
  '& .MuiDataGrid-row': {
    maxHeight: 20,
    minHeight: 20,
  },
  '& .MuiDataGrid-cell': {
    maxHeight: 20,
    lineHeight: 20,
    borderColor: 'primary.light',
    border: '1px solid',
  },
  '& .total-row': {
    backgroundColor: '#f0f0f0',
    borderColor: 'primary.light',
  },
  '& .background-value1': {
    backgroundColor: '#e0f7fa',
    borderColor: 'primary.light',
  },
  '& .background-value2': {
    backgroundColor: '#f1f8e9',
    borderColor: 'primary.light',
  },
  '& .minus_values': {
    color: 'red',
    fontWeight: 'bold',
    borderColor: 'primary.light',
  },
})

const DataGridComponent = ({ handleProcessRowUpdate, rows, columns, highlightedCells }) => {
  return (
    <StyledDataGrid
      rows={rows || []}
      autoHeight
      columns={columns}
      isCellEditable={isCellEditable}
      onProcessRowUpdateError={(params) => {
        console.log('onProcessRowUpdateError', params)
      }}
      processRowUpdate={handleProcessRowUpdate}
      getRowClassName={(params) => (params.row.id === 'total' || params.row.id === 'net_income_total' || params.row.id === 'sum_columns' ? 'total-row' : '')}
      getCellClassName={(params) => {
        if (params.field === 'item') {
          return 'background-value1'
        }
        if (params.field === 'row_total') {
          return 'background-value2'
        }

        // if (params.row.id === 'personal_allowance') {
        //   if (highlightedCells.some((cell) => cell.id === params.id && cell.field === params.field)) {
        //     return 'minus_values highlight'
        //   } else {
        //     return 'minus_values '
        //   }
        // }

        if (highlightedCells.some((cell) => cell.id === params.id && cell.field === params.field)) {
          return 'highlight'
        }

        return ''
      }}
      sx={{
        border: 1,
        borderColor: 'primary.light',
        '& .MuiDataGrid-cell:hover': {
          color: 'primary.main',
        },
        '& .MuiDataGrid-footerContainer': {
          display: 'none',
        },
        '& .MuiDataGrid-cell': {
          borderColor: 'primary.light',
          maxHeight: '20px',
        },
        '& .MuiDataGrid-columnHeaders': {
          borderColor: 'primary.light',
          height: 20,
          minHeight: 20,
          maxHeight: '20px',
          lineHeight: '10px',
        },
        '& .MuiDataGrid-menuIcon': {
          display: 'none',
        },
        '& .MuiDataGrid-sortIcon': {
          display: 'none',
        },
      }}
    />
  )
}

export default DataGridComponent
