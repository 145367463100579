import { Typography, Button, Dialog } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useEffect } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandCircleDownSharpIcon from '@mui/icons-material/ExpandCircleDownSharp'
import { DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { useSnackbar } from 'notistack'
import Question from '../quiz/question'
import Video from '../video'
import ClipSegment from '../common-components/custom-snackbars/clip-segment'

const FurtherFeedback = ({ altExplanation, video, open, handleClose, quizId, remedialQuestionId }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [expanded, setExpanded] = React.useState('panel1')
  const [showClipSegement, setShowClipSegement] = React.useState(false)
  const [pauseVideo, setPauseVideo] = React.useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if (showClipSegement) {
      enqueueSnackbar(
        {
          title: 'Did you get that information?',
          content: 'Watch the animated explainer video again or try a similar question.',
        },
        {
          disableWindowBlurListener: false,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          content: (key, message) => <ClipSegment id={key} message={message} />,
        }
      )
    }
  }, [showClipSegement, enqueueSnackbar])
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="further-feedback-title"
    >
      <DialogTitle
        id="alternative-explanation-title"
        sx={{
          color: '#0A2645',
        }}
      >
        {''}
      </DialogTitle>
      <DialogContent>
        <div>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              onClick={() => setPauseVideo(true)}
              expandIcon={<ExpandCircleDownSharpIcon color="primary" fontSize="large" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="subtitle1" sx={{ width: '33%', flexShrink: 0 }}>
                Alternative Explanation
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Understand from another perspective</Typography>
            </AccordionSummary>
            <AccordionDetails>{altExplanation}</AccordionDetails>
          </Accordion>

          {video?.url && (
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                onClick={() => setPauseVideo(true)}
                expandIcon={<ExpandCircleDownSharpIcon color="primary" fontSize="large" />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography variant="subtitle1" sx={{ width: '33%', flexShrink: 0 }}>
                  Explainer Video
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Watch the relevant part of the video</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {video?.url && (
                  <Video
                    uuid={video?.url}
                    timestamp={video?.timestamp}
                    timestampEnd={video?.timestampEnd}
                    setShowClipSegement={setShowClipSegement}
                    pauseVideo={pauseVideo}
                    setPauseVideo={setPauseVideo}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )}

          {remedialQuestionId && (
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary
                onClick={() => setPauseVideo(true)}
                expandIcon={<ExpandCircleDownSharpIcon color="primary" fontSize="large" />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography variant="subtitle1" sx={{ width: '33%', flexShrink: 0 }}>
                  Remedial Question
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Try a similar question?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Question
                  questionNumber={1}
                  totalQuestions={1}
                  quizId={quizId}
                  isLastQuestion={true}
                  key={`furtherFedback-question-${remedialQuestionId}`}
                  questionId={remedialQuestionId}
                  next={(wasCorrect) => {
                    console.log('next was called')
                  }}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FurtherFeedback
